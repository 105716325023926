import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  DxDataGridComponent,
  DxDataGridModule,
  DxNumberBoxModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'event-configuration-billing-details',
  templateUrl: './event-configuration-billing-details.component.html',
  styleUrls: ['./event-configuration-billing-details.component.scss'],
})
export class EventConfigurationBillingsDetailsFormComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() entity: any;

  @Input() datasource: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  eventConfigurationBillingId: number;

  states: any;
  applicationTypes: any;
  serviceLevels: any;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  constructor() {
    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.applicationTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'applicationTypeId',
        loadUrl: `${this.url}Patricia/ApplicationType/Lookup`,
      }),
      sort: 'ApplicationTypeLabel',
    });

    this.serviceLevels = new DataSource({
      store: AspNetData.createStore({
        key: 'ServiceId',
        loadUrl: `${this.url}Patricia/ServiceLevelLookup/Lookup`,
      }),
      sort: 'ServiceLevelLabel',
    });

    this.states = new DataSource({
      store: AspNetData.createStore({
        key: 'StateId',
        loadUrl: `${this.url}Patricia/State/Lookup`,
      }),
      sort: 'StateLabel',
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onFocusIn = (id: number) => {
    this.datasource = new DataSource({
      store: AspNetData.createStore({
        key: 'EventConfigurationBillingMatrixId',
        loadUrl: `${this.url}Renewal/EventConfigurationBillingMatrix/${id}`,
        insertUrl: `${this.url}Renewal/EventConfigurationBillingMatrix`,
        updateUrl: `${this.url}Renewal/EventConfigurationBillingMatrix`,
        deleteUrl: `${this.url}Renewal/EventConfigurationBillingMatrix`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
          if (id) {
            ajaxOptions.data.EventConfigurationBillingId = id;
          }
        },
      }),
    });
  };
}

@NgModule({
  imports: [CommonModule, DxDataGridModule, DxNumberBoxModule],
  declarations: [EventConfigurationBillingsDetailsFormComponent],
  exports: [EventConfigurationBillingsDetailsFormComponent],
})
export class EventConfigurationBillingsDetailsFormModule {}
