import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Invoice } from 'src/app/types/invoicing/invoice';
import { json } from 'stream/consumers';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class CalystaAPIInvoiceService {
  url = apiUrl + 'api/invoice';

  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  createDraftInvoice(chargeIds: number[]): Observable<Invoice> {
    return this.http.post<Invoice>(`${this.url}/InvoiceHeader/PrepareDraft`, {
      ChargeLines: chargeIds,
    });
  }

  prepareDraftDocument(
    InvoiceHeaderId: number,
    TemplateId: number
  ): Observable<Invoice> {
    return this.http.post<Invoice>(
      `${this.url}/InvoiceHeader/PrepareDraftDocument`,
      {
        InvoiceHeaderId: InvoiceHeaderId,
        InvoiceTemplateId: TemplateId,
      }
    );
  }

  getDraftInvoice(invoiceNumber: number): Observable<Invoice> {
    let params = new HttpParams().set('InvoiceNumber', invoiceNumber);
    return this.http
      .get<any>(`${this.url}/InvoiceHeader/Get/`, { params })
      .pipe(map((data) => data.Invoice));
  }

  getInvoice(invoiceHeaderId: number): Observable<Invoice> {
    let params = new HttpParams().set('InvoiceHeaderId', invoiceHeaderId);
    return this.http
      .get<any>(`${this.url}/InvoiceHeader/Get/`, { params })
      .pipe(map((data) => data.Invoice));
  }

  getInvoiceLineCategories(): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/InvoiceLineCategory/Lookup`);
  }

  getPdfDocument(invoiceHeaderId: number): Observable<any> {
    let params = new HttpParams().set('InvoiceHeaderId', invoiceHeaderId);
    return this.http.get(`${this.url}/InvoiceHeader/PdfDocument/`, {
      responseType: 'blob',
      params,
    });
  }

  removeVat(chargeIds: number[]): Observable<any> {
    return this.http.put<any>(`${this.url}/Charge/RemoveVat`, {
      ChargeIds: chargeIds,
    });
  }

  // TODO: This is a duplicate of the one in charge.service.ts
  cancelDraft(invoiceHeaderId: number): Observable<any> {
    return this.http.post<any>(`${this.url}/InvoiceHeader/CancelDraft`, {
      InvoiceHeaderId: invoiceHeaderId,
    });
  }

  getDraftDocumentLink(invoiceHeaderId: number): Observable<string> {
    let params = new HttpParams().set('InvoiceHeaderId', invoiceHeaderId);
    return this.http
      .get<any>(`${this.url}/InvoiceHeader/GetDraftDocumentLink`, { params })
      .pipe(map((data) => data.DocumentLink));
  }

  finalizeDraft(invoiceHeaderId: number): Observable<Invoice> {
    return this.http.post<any>(`${this.url}/InvoiceHeader/FinalizeDraft`, {
      InvoiceHeaderId: invoiceHeaderId,
    });
  }

  copyToCases(chargeId: number, casesIds: number[]): Observable<any> {
    return this.http.put<any>(`${this.url}/Charge/${chargeId}/CopyToCases`, {
      CaseIds: casesIds,
    });
  }

  moveToCases(chargeIds: number[], casesIds: number): Observable<any> {
    return this.http.put<any>(`${this.url}/Charge/MoveToCases`, {
      CaseIds: casesIds,
      ChargeIds: chargeIds,
    });
  }

  /*creditInvoice(
    invoiceHeaderId: number,
    reopenCharges: boolean = false
  ): Observable<Invoice> {
    return this.http.post<any>(`${this.url}/InvoiceHeader/CreateCreditNote`, {
      InvoiceHeaderId: invoiceHeaderId,
      ReopenCharges: reopenCharges,
    });
  }*/

  linkToInvoice(
    costChargeId: number,
    invoicedChargeId: number,
    handleDiff: boolean = false
  ): Observable<any> {
    return this.http.put<any>(`${this.url}/Charge/ConnectCostToInvoice`, {
      CostChargeId: costChargeId,
      InvoicedChargeId: invoicedChargeId,
      HandleDifference: handleDiff,
    });
  }

  splitCharge(chargeId: number, splitAmount: number): Observable<any> {
    return this.http.put<any>(`${this.url}/Charge/SplitCharge`, {
      ChargeId: chargeId,
      AmountToSplitOff: splitAmount,
    });
  }

  removeCharge(
    chargeId: number,
    removeTime: boolean,
    workCodeId: number | null
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = {
      RemoveTime: removeTime,
      WorkCodeId: workCodeId,
    };

    const params = new HttpParams({
      fromObject: {
        Values: JSON.stringify(body),
        Key: chargeId,
      },
    });

    return this.http.delete(`${this.url}/charge`, {
      headers: headers,
      params: params,
    });
  }

  /*getIsBadPayer(clientId: string): Observable<any> {
    let params = new HttpParams().set('ClientId', clientId);
    return this.http.get(`${this.url}/IsBadPayer`, {
      params,
    });
  }*/

  getAmadeusInvoicesSummary(selectedInvoices: string[]): Observable<any> {
    let params = new HttpParams().set(
      'SelectedInvoices',
      JSON.stringify(selectedInvoices)
    );
    return this.http.get(`${this.url}/AmadeusInvoiceOverview/Summary`, {
      params,
      responseType: 'blob',
    });
  }

  getZipDocuments(invoiceHeaderIds: number[]): Observable<any> {
    let params = new HttpParams().set(
      'InvoiceHeaderIds',
      JSON.stringify(invoiceHeaderIds)
    );

    return this.http.get(`${this.url}/InvoiceHeader/PdfDocument/Multiple`, {
      responseType: 'blob',
      params,
    });
  }

  updateInvoice(invoice: Invoice): Observable<Invoice> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = new HttpParams({
      fromObject: {
        Values: JSON.stringify(invoice),
        Key: invoice.InvoiceHeaderId.toString(),
      },
    });

    return this.http.put<Invoice>(`${this.url}/InvoiceHeader`, body, {
      headers: headers,
    });
  }

  importFromFile(formData: FormData) {
    return this.http.post<any>(`${this.url}/WorkCode/Import`, formData);
  }
}
