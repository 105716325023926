<titlebar
  titleText="WIP"
  [useButton]="true"
  iconButton="fa-solid fa-square-up-right"
  (onButtonClicked)="onClickRedirect()"
/>
<div class="separator"></div>
<dx-data-grid
  #grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [dataSource]="Data"
  height="90%"
  [loadPanel]="{ enabled: true }"
  [wordWrapEnabled]="true"
>
  <dxo-scrolling mode="virtual" [showScrollbar]="'always'" />
  <dxo-grouping [autoExpandAll]="false" [contextMenuEnabled]="true" />

  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate>
        <dx-button
          [text]="'Group by Team'"
          width="136"
          [style]="{
            'background-color': groupType === 'Team' ? 'orangered' : 'initial'
          }"
          (onClick)="ChangeGrouping('Team')"
        />
      </div>
    </dxi-item>
    <dxi-item location="before">
      <div *dxTemplate>
        <dx-button
          [text]="'Group by Client'"
          width="160"
          (onClick)="ChangeGrouping('Client')"
          [style]="{
            'background-color': groupType === 'Client' ? 'orangered' : 'initial'
          }"
        />
      </div>
    </dxi-item>
  </dxo-toolbar>

  <dxi-column
    dataField="ClientName"
    caption="Client"
    dataType="string"
    groupIndex="0"
    sortOrder="asc"
  />
  <dxi-column
    dataField="TeamLabel"
    caption="Team"
    dataType="string"
    groupCellTemplate="GroupingTemplate"
  />
  <dxi-column
    dataField="CalystaRef"
    caption="Case"
    dataType="string"
    groupIndex="1"
    sortOrder="asc"
  />
  <dxi-column dataField="WorkCodeLabel" caption="WorkCode label" />
  <dxi-column dataField="Description" caption="Description" />
  <dxi-column dataField="CreatedOn" caption="CreatedOn" dataType="date" />
  <dxi-column dataField="Margin" caption="Margin" dataType="currency" />
  <dxi-column dataField="ProviderCost" caption="Provider" dataType="currency" />
  <dxi-column dataField="Amount" caption="Amount" dataType="currency" />
  <dxo-summary>
    <dxi-group-item
      column="Margin"
      summaryType="sum"
      [alignByColumn]="true"
      valueFormat="currency"
      displayFormat="{0}"
    />
    <dxi-group-item
      column="Amount"
      summaryType="sum"
      [alignByColumn]="true"
      valueFormat="currency"
      displayFormat="{0}"
    />
  </dxo-summary>

  <div *dxTemplate="let data of 'GroupingTemplate'">
    <div style="color: orangered">
      {{ data.displayValue }}
    </div>
  </div>

  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="gridWipCard"
  />
</dx-data-grid>
