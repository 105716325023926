import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxLookupModule, DxSelectBoxModule } from 'devextreme-angular';
import { AppComponent } from 'src/app/app.component';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'workcode-lookup',
  templateUrl: './workcode-lookup.component.html',
  styleUrls: ['./workcode-lookup.component.scss'],
})
export class WorkcodeLookupComponent implements OnInit {

  @Input() value: any;
  @Input() workCodeCategoryId: any = null;
  @Output() valueChange = new EventEmitter<number>();

  url = environment.CalystaApiBaseURL + 'api/Invoice/WorkCode';
  workcodes: any;

  ngOnInit(): void {
    this.workcodes = new DataSource({
      store: AspNetData.createStore({
        key: 'workCodeId',
        loadUrl: `${this.url}/lookup`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method === 'load') {
            ajaxOptions.data = ajaxOptions.data || {};
            if (this.workCodeCategoryId) {
              ajaxOptions.data.WorkCodeCategoryId = this.workCodeCategoryId;
            }
          }
        },
      }),

      sort: ['WorkCodeLabel', 'WorkCodeDescription'],

    });
  }

  valueChanged(e) {
    this.valueChange.emit(e.value);
  }
}

@NgModule({
  imports: [BrowserModule, DxLookupModule, DxSelectBoxModule],
  declarations: [WorkcodeLookupComponent],
  exports: [WorkcodeLookupComponent],
})
export class WorkcodeLookupModule { }
