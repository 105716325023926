import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import DataSource from 'devextreme/data/data_source';
import { environment } from 'src/environments/environment';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import {
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
} from 'devextreme-angular';

@Component({
  selector: 'crm-ip-assets',
  templateUrl: './crm-ip-assets.component.html',
  styleUrls: ['./crm-ip-assets.component.scss'],
})
export class CrmIpAssetsComponent {
  @Input() visible: boolean = false;

  @Input() clientId: number | undefined;

  @Output() visibleChange = new EventEmitter<boolean>();

  datasource: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {}

  onShow() {
    this.datasource = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${this.url}Patricia/IpAsset/${this.clientId}`,
      }),
    });
  }

  onHide() {
    this.datasource = null;

    this.visibleChange.emit(false);
  }
}

@NgModule({
  declarations: [CrmIpAssetsComponent],
  exports: [CrmIpAssetsComponent],
  bootstrap: [CrmIpAssetsComponent],
  imports: [BrowserModule, DxDataGridModule, DxScrollViewModule, DxPopupModule],
})
export class CrmIpAssetsModule {}
