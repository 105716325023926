<dx-popup
  [title]="titleText"
  [(visible)]="visible"
  [width]="width"
  (onHidden)="close()"
  [showCloseButton]="true"
  (onShown)="onShown()"
  height="60vh"
>
  <div>
    <dx-list
      [dataSource]="permissions"
      [showSelectionControls]="true"
      [selectionMode]="'multiple'"
      [selectAllMode]="'allPages'"
      [selectedItemKeys]="[]"
      (onSelectionChanged)="onSelectionChanged($event)"
      [selectByClick]="false"
      itemTemplate="data"
    >
      <div *dxTemplate="let item of 'data'">
        <div>{{ item.Label }}</div>
        <div>Is Active: {{ item.IsActive }}</div>
      </div>
    </dx-list>
  </div>
</dx-popup>
