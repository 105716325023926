import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { BrowserModule } from '@angular/platform-browser';
import { DxLookupModule, DxSelectBoxModule } from 'devextreme-angular';
import { CaseService } from 'src/app/services/case/case.service';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'case-type-lookup',
  templateUrl: './case-type-lookup.component.html',
  styleUrls: ['./case-type-lookup.component.scss'],
})
export class CaseTypeLookupComponent implements OnInit {
  @Input() value = false;
  @Input() valueChange: (value: number) => void;

  datasource: any;

  url: string;

  constructor(private caseService: CaseService) {
    this.url = this.caseService.getUrl();
  }

  ngOnInit(): void {
    this.datasource = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseTypeId',
        loadUrl: `${this.url}/CaseType/Lookup`,
      }),
      sort: 'CaseTypeLabel',
    });
  }

  valueChanged(e) {
    this.valueChange(e.value);
  }
}

@NgModule({
  imports: [BrowserModule, DxLookupModule, DxSelectBoxModule],
  declarations: [CaseTypeLookupComponent],
  exports: [CaseTypeLookupComponent],
  providers: [CaseService],
})
export class CaseTypeLookupModule {}
