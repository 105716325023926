<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Invoices Overview" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onExporting)="onExporting($event)"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
      [showColumnLines]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export
        [enabled]="true"
        [fileName]="'Invoices'"
        [allowExportSelectedData]="false"
      />

      <dxo-toolbar>
        <dxi-item location="before" locateInMenu="auto">
          <div class="date-range-container">
            <div class="date-range-title">Invoice Date Range</div>
            <dx-date-range-box
              title="Invoice Date Range"
              [startDate]="thisYear"
              [endDate]="now"
              (onValueChanged)="onDateChanged($event)"
              [showClearButton]="false"
              [width]="300"
              [max]="now"
            />
          </div>
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="exportButton" locateInMenu="auto" />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="Id" caption="Id" [visible]="false" />
      <dxi-column
        dataField="InvoiceId"
        caption="Invoice Number"
        width="6%"
        alignment="center"
      />
      <dxi-column
        dataField="CreationDate"
        caption="Creation Date"
        dataType="date"
        width="6%"
        alignment="center"
      />
      <dxi-column
        dataField="InvoiceDate"
        caption="Invoice Date"
        dataType="date"
        width="6%"
        alignment="center"
      />
      <dxi-column
        dataField="InvoiceCategory"
        caption="Invoice Category"
        width="6%"
        alignment="center"
      />
      <dxi-column dataField="NonVatAmount" caption="Amount Non Vat" width="5%">
        <dxo-format type="currency" [precision]="2" />
      </dxi-column>
      <dxi-column dataField="VatAmount" caption="Vat Amount" width="5%">
        <dxo-format type="currency" [precision]="2" />
      </dxi-column>
      <dxi-column dataField="VatRate" caption="Vat Rate" width="5%" />
      <dxi-column dataField="TotalAmount" caption="Total Amount" width="5%">
        <dxo-format type="currency" [precision]="2" />
      </dxi-column>
      <dxi-column dataField="PayerId" caption="Payer Id" [visible]="false" />
      <dxi-column dataField="PayerName" caption="Payer Name" />
      <dxi-column
        dataField="PayerVat"
        caption="Payer Vat Number"
        alignment="center"
        width="8%"
      />
      <dxi-column dataField="PayerAddress" caption="Payer Address" />
      <dxi-column dataField="PayerCountry" caption="Payer Country" />
      <dxi-column
        dataField="CalystaReference"
        caption="Calysta Reference"
        width="8%"
        alignment="center"
      />
      <dxi-column dataField="Payed" caption="Payed" width="5%" />
      <dxi-column
        dataField="CreditDebit"
        caption="Credit Debit"
        alignment="center"
        width="5%"
      />

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridFinanceReportingInvoiceOverview"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
