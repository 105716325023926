import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { AppPermissions } from 'src/app/routes/permissions';

const apiUrl = environment.CalystaApiBaseURL;

interface LocalPermission {
  permissions: string[];
  oid: string;
  expirationDate: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthPermissionService {
  url = apiUrl + 'api/administration';

  private _permissions = new BehaviorSubject<string[]>([]);

  constructor(private http: HttpClient) {
    const localPermissions: LocalPermission = JSON.parse(
      localStorage.getItem('permissions')
    );

    if (
      localPermissions?.permissions.length > 0 &&
      new Date(localPermissions?.expirationDate) > new Date()
    ) {
      this._permissions.next(localPermissions.permissions);
    }
  }

  getUrl() {
    return this.url;
  }

  loadPermissions(userId: string) {
    this.http
      .get(this.url + '/Permission/' + userId)
      .subscribe((result: any) => {
        const permissions = result.Permissions.map((p) => p.Label);
        this._permissions.next(permissions);

        const localPermissions: LocalPermission = {
          permissions,
          oid: userId,
          //expire 1 week after login
          expirationDate: new Date(
            new Date().getTime() + 7 * 24 * 60 * 60 * 1000
          ).toISOString(),
        };
        localStorage.setItem('permissions', JSON.stringify(localPermissions));
      });
  }

  getPermissions() {
    return this._permissions.asObservable();
  }

  // Check if the user has a specific permission
  // Case sensitive
  hasPermission(permission: string): boolean {
    //if containe admin return true
    if (this._permissions.getValue().includes(AppPermissions.ADMIN)) {
      return true;
    }
    return this._permissions.getValue().includes(permission);
  }

  hasPermissions(permission: string[]): boolean {
    if (this._permissions.getValue().includes(AppPermissions.ADMIN)) {
      return true;
    }
    //if permission includes one of the permissions in the array
    return permission.some((p) => this.hasPermission(p));
  }

  hasPermissionsInLocalStore(): boolean {
    return this._permissions.getValue().length > 0;
  }
}
