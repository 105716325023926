<dx-data-grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [dataSource]="dataSource"
  [wordWrapEnabled]="true"
>
  <dxi-column dataField="TotalHours" caption="Total Hours" alignment="center" />
  <dxi-column
    dataField="TotalMinutes"
    caption="Total Minutes"
    alignment="center"
  />
  <dxi-column dataField="HourlyRate" caption="Hourly Rate" alignment="center" />
  <dxi-column
    dataField="BillingType"
    caption="BillingType"
    alignment="center"
  />
</dx-data-grid>
