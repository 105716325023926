<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Countries" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      >
        <dxo-popup
          [showTitle]="true"
          [title]="'Add Country'"
          height="auto"
          width="auto"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form>
          <dxi-item
            dataField="CountryId"
            [editorOptions]="{
              maxLength: 2
            }"
          >
            <dxi-validation-rule type="required" />
            <dxi-validation-rule type="stringLength" [max]="2" />
          </dxi-item>
          <dxi-item dataField="Label" editorType="dxTextBox">
            <dxi-validation-rule type="required" />
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="New Country"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="dataGrid.instance.addRow()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="CountryId" caption="Code" [visible]="false" />
      <dxi-column dataField="Label" caption="Label" />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridAdministrationCountries"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
