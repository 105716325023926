import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  url = apiUrl + 'api/provider';
  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  /*addProvider(provider: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const params = new HttpParams({
      fromObject: {
        Values: JSON.stringify(provider),
      },
    });

    return this.http.post(this.url, params, { headers: headers });
  }*/
}
