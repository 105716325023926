import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxBulletModule,
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxTabsModule,
  DxToolbarModule,
} from 'devextreme-angular';
import { CardAnalyticsModule } from '../../library/card-analytics/card-analytics.component';
import { TickerCardModule } from '../../library/ticker-card/ticker-card.component';
import { ItemClickEvent } from 'devextreme/ui/tabs';
import { SalesAnalyticsService } from 'src/app/services';
import { TitleBarModule } from '../../library/titlebar/titlebar.component';
import { finalize } from 'rxjs';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'monthly-goals-card',
  templateUrl: './monthly-goals-card.component.html',
  styleUrls: ['./monthly-goals-card.component.scss'],
})
export class MonthlyGoalsCardComponent implements OnInit {
  @ViewChild('grid', { static: false }) dataGrid: DxDataGridComponent;
  @Input() Title: string = null;
  @Input() Data: any;
  @Input() showTitleBar: boolean = false;

  selectedTab = 0;
  currentDate: Date = new Date();

  thisMonthStartDate: string = new Date(
    this.currentDate.getFullYear(),
    this.currentDate.getMonth(),
    1
  ).toDateString();

  thisMonthLastDay: string = getLastDayOfCurrentMonth(
    this.currentDate.getFullYear(),
    this.currentDate.getMonth()
  ).toDateString();

  TargetTabs: TargetTab[] = [
    {
      id: 1,
      text: 'This Month',
      startDate: new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        1
      ).toDateString(),
      endDate: getLastDayOfCurrentMonth(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth()
      ).toDateString(),
    },
    {
      id: 2,
      text: 'Last Month',
      startDate: new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() - 1,
        1
      ).toDateString(),
      endDate: getLastDayOfCurrentMonth(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() - 1
      ).toDateString(),
    },
    {
      id: 3,
      text: 'YTD',
      startDate: new Date(this.currentDate.getFullYear(), 0, 1).toDateString(),
      endDate: getLastDayOfCurrentMonth(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth()
      ).toDateString(),
    },
  ];

  constructor(private salesSvc: SalesAnalyticsService) {}

  ngOnInit(): void {
    this.salesSvc
      .getTargetsByBusiness(this.thisMonthStartDate, this.thisMonthLastDay)
      .subscribe({
        next: (data: any) => {
          this.Data = data;
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
      });
  }

  customizeTooltip(arg) {
    return {
      text:
        `Current t&#176: ${arg.value}&#176C<br>` +
        `Average t&#176: ${arg.target}&#176C`,
    };
  }

  onTabClick($event: ItemClickEvent) {
    this.dataGrid.instance.beginCustomLoading('Loading data...');
    this.salesSvc
      .getTargetsByBusiness($event.itemData.startDate, $event.itemData.endDate)
      .pipe(
        finalize(() => {
          this.dataGrid.instance.endCustomLoading();
        })
      )
      .subscribe({
        next: (data: any) => {
          this.Data = data;
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
      });
  }
}

function getLastDayOfCurrentMonth(year, month) {
  // Create a Date object for the first day of next month
  let date = new Date(year, month + 1, 1);
  // Subtract one day to get the last day of the current month
  const lastDayOfCurrentMonth = new Date(date.getTime() - 1);
  // Return the day of the month
  return lastDayOfCurrentMonth;
}

export class TargetTab {
  id: number;
  text?: string;
  startDate?: string;
  endDate?: string;
}

@NgModule({
  declarations: [MonthlyGoalsCardComponent],
  exports: [MonthlyGoalsCardComponent],
  imports: [
    CommonModule,
    DxButtonModule,
    DxTabsModule,
    DxToolbarModule,
    CardAnalyticsModule,
    TickerCardModule,
    DxDataGridModule,
    DxBulletModule,
    TitleBarModule,
  ],
})
export class MonthlyGoalsCardModule {}
