<dx-popup
  [(visible)]="visible"
  (onShowing)="onShown()"
  [showCloseButton]="true"
  (onHidden)="closePopup()"
>
  <dx-data-grid
    [allowColumnResizing]="true"
    class="grid"
    height="auto"
    [width]="'auto'"
    [dataSource]="dataSource"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [showBorders]="true"
    [rowAlternationEnabled]="true"
    [remoteOperations]="true"
    [repaintChangesOnly]="true"
  >
    <dxo-grouping #expand [autoExpandAll]="true" [allowCollapsing]="false" />
    <dxo-load-panel [showPane]="true" />
    <dxo-sorting mode="multiple" />
    <dxo-column-chooser [enabled]="true" />
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
    <dxo-filter-row [visible]="true" />
    <dxo-search-panel [visible]="true" width="20vw" placeholder="Search..." />

    <dxo-editing
      mode="popup"
      [allowAdding]="false"
      [allowUpdating]="false"
      [allowDeleting]="false"
    >
    </dxo-editing>

    <dxo-toolbar>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-button icon="refresh" (onClick)="refreshData()" />
        </div>
      </dxi-item>
      <dxi-item location="after" name="columnChooserButton" />
      <dxi-item location="after" name="searchPanel" locateInMenu="never" />
    </dxo-toolbar>

    <dxi-column
      dataField="WorkCodeLabel"
      caption="Workcode"
      dataType="string"
    />
    <dxi-column dataField="CreatedOn" caption="Created On" dataType="date" />
    <dxi-column
      dataField="CreatedByLabel"
      caption="Created By"
      dataType="date"
    />
    <dxi-column
      dataField="Description"
      caption="Description"
      dataType="string"
      [visible]="false"
    />
    <dxi-column
      dataField="Quantity"
      caption="Quantity"
      [visible]="false"
      dataType="number"
    />
    <dxi-column
      dataField="UnitPrice"
      caption="Unit Price"
      dataType="number"
      [visible]="false"
    />
    <dxi-column
      dataField="VATPercentage"
      caption="VAT %"
      dataType="number"
      [visible]="false"
    />
    <dxi-column dataField="NonVatAmount" caption="Excl. VAT">
      <dxo-format type="currency" [precision]="2" />
    </dxi-column>
    <dxi-column dataField="VatAmount" caption="VAT">
      <dxo-format type="currency" [precision]="2" />
    </dxi-column>
    <dxi-column dataField="AmountTot" caption="Total">
      <dxo-format type="currency" [precision]="2" />
    </dxi-column>

    <dxo-state-storing
      [enabled]="false"
      type="localStorage"
      storageKey="gridTimeRegistrationChareByCaseGrid"
    />
  </dx-data-grid>
</dx-popup>
