import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxTabsModule,
  DxToolbarModule,
} from 'devextreme-angular';
import { CardAnalyticsModule } from 'src/app/components/library/card-analytics/card-analytics.component';
import { TickerCardModule } from '../../library/ticker-card/ticker-card.component';

@Component({
  selector: 'financial-total-kpi',
  templateUrl: './financial-total-kpi.component.html',
  styleUrls: ['./financial-total-kpi.component.scss'],
})
export class FinancialTotalKpiComponent {
  @Input() total: number;

  @Input() title: string;

  @Input() percentage: number;

  @Input() contentClass: string | null = null;

  getTotal(data: Array<{ value?: number; total?: number }>): number {
    return (data || []).reduce(
      (total, item) => total + (item.value || item.total),
      0
    );
  }

  abs(value: number): number {
    return Math.abs(value);
  }
}

@NgModule({
  declarations: [FinancialTotalKpiComponent],
  exports: [FinancialTotalKpiComponent],
  imports: [
    CommonModule,
    DxButtonModule,
    DxTabsModule,
    DxToolbarModule,
    CardAnalyticsModule,
    TickerCardModule,
  ],
})
export class FinancialTotalKpiModule {}
