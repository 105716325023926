import { Component, Input, NgModule, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DxDataGridModule, DxPivotGridModule } from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'wip-reporting',
  templateUrl: './wip-reporting.component.html',
  styleUrls: ['./wip-reporting.component.scss']
})
export class WipReportingComponent implements OnInit, OnChanges {

  @Input() EmployeeId: number = 0;

  wipDataSource: any = null;

  apiUrl = environment.CalystaApiBaseURL;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes) {
      this.initDataSource();
    }
  }

  ngOnInit() {
    this.initDataSource();
  }

  initDataSource() {
    this.wipDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'RegistrationDetailId',
        loadUrl: this.apiUrl + 'api/TimeRegistration/Reporting/WIP',
        loadParams: { EmployeeId: this.EmployeeId },
      }),
    });
  }
}

@NgModule({
  providers: [],
  exports: [WipReportingComponent],
  declarations: [WipReportingComponent],
  imports: [
    DxDataGridModule
  ],
})
export class WipReportingModule {}
