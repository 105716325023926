<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Employees" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      >
        <dxo-popup
          title="Employee"
          [showTitle]="true"
          height="auto"
          width="40vw"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form>
          <dxi-item
            itemType="group"
            caption="Personal Information"
            [colCount]="2"
            [colSpan]="2"
          >
            <dxi-item
              dataField="FirstName"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxi-validation-rule
                type="required"
                message="First Name is required"
              />
              <dxo-label text="First Name" />
            </dxi-item>
            <dxi-item
              dataField="LastName"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxi-validation-rule
                type="required"
                message="Last Name is required"
              />
              <dxo-label text="Last Name" />
            </dxi-item>
            <dxi-item
              dataField="Email"
              [colSpan]="2"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxi-validation-rule
                type="required"
                message="Email is required"
              />
              <dxi-validation-rule type="email" message="Email is invalid" />
              <dxo-label text="Email" />
            </dxi-item>
            <dxi-item
              dataField="MobilePhone"
              [colSpan]="2"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxi-validation-rule
                type="required"
                message="MobilePhone is required"
              />
              <dxo-label text="MobilePhone Number" />
            </dxi-item>
          </dxi-item>
          <dxi-item
            itemType="group"
            caption="Calysta Properties"
            [colCount]="2"
            [colSpan]="2"
            [editorOptions]="{ validationMessageMode: 'always' }"
          >
            <dxi-item dataField="MobilePhoneOwnedByCalysta" [colSpan]="1">
              <dxo-label text="Calysta Phone" />
            </dxi-item>
            <dxi-item
              dataField="FuelCardNumber"
              [colSpan]="1"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="FuelCard Number" />
            </dxi-item>
            <dxi-item
              dataField="HourlyRate"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="Hourly Rate" />
            </dxi-item>
            <dxi-item dataField="ComputerId">
              <dxo-label text="Computer" />
            </dxi-item>
            <dxi-item dataField="CarId" [colSpan]="2">
              <dxo-label text="Car" />
            </dxi-item>
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item location="after">
          <dx-button
            text="New Employee"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="this.dataGrid.instance.addRow()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="FullName" caption="Full Name" alignment="center" />
      <dxi-column dataField="Email" caption="Email" alignment="center" />
      <dxi-column
        dataField="MobilePhone"
        caption="Mobile Phone Number"
        alignment="center"
        width="15%"
      />
      <dxi-column
        dataField="MobilePhoneOwnedByCalysta"
        caption="Has Calysta Phone"
        width="8%"
      />
      <dxi-column
        dataField="FuelCardNumber"
        caption="FuelCard Number"
        alignment="center"
      />
      <dxi-column
        dataField="EmployeeOwning"
        caption="Employee Ownings"
        alignment="center"
      />
      <dxi-column
        dataField="EmployeeId"
        caption="EmployeeId"
        [visible]="false"
      />

      <dxi-column
        dataField="HourlyRate"
        caption="Hourly Rate"
        alignment="center"
        [visible]="false"
      >
        <dxo-format type="fixedPoint" precision="2" />
      </dxi-column>
      <dxi-column
        dataField="FirstName"
        caption="First Name"
        [visible]="false"
        [showInColumnChooser]="false"
      />
      <dxi-column
        dataField="LastName"
        caption="Last Name"
        [visible]="false"
        [showInColumnChooser]="false"
      />
      <dxi-column type="buttons">
        <dxi-button icon="fas fa-clock" [onClick]="openHourlyRatesPopup" />
        <dxi-button name="edit" />
        <dxi-button name="delete" />
      </dxi-column>

      <dxi-column dataField="ComputerId" caption="Computer" [visible]="false">
        <dxo-lookup
          [dataSource]="computers.store()"
          valueExpr="ComputerId"
          displayExpr="Label"
        />
      </dxi-column>
      <dxi-column dataField="CarId" caption="Car" [visible]="false">
        <dxo-lookup
          [dataSource]="cars.store()"
          valueExpr="CarId"
          displayExpr="Reference"
        />
      </dxi-column>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridAdministraionEmployees"
      />
    </dx-data-grid>
    <employee-hourly-rates-settings
      [(visible)]="isHourlyRatesPopupVisible"
      [titleText]="selectedEmployee?.FullName + ' Hourly rates'"
      (visibleChange)="onClosedHourlyRatesPopup($event)"
      [employeeId]="selectedEmployee?.EmployeeId"
    />
  </div>
</dx-scroll-view>
