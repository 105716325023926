import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDateBoxModule,
  DxDateRangeBoxModule,
  DxDropDownButtonModule,
  DxLoadPanelModule,
  DxScrollViewModule,
  DxTabPanelModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { RangeDatePickerModule } from 'src/app/components/utils/range-date-picker/range-date-picker.component';
import { CalystaAPIInvoiceService } from 'src/app/services/calysta-api/invoice.service';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import notify from 'devextreme/ui/notify';
import { FinanceDraftInvoiceModule } from 'src/app/components/finance/finance-draft-invoice/finance-draft-invoice.component';
import { FinanceInvoicesOutDetailsModule } from 'src/app/components/finance/finance-invoices-out/finance-invoices-out-details/finance-invoices-out-details.component';
import { FinanceInvoicesOutTimeEncodedModule } from 'src/app/components/finance/finance-invoices-out/finance-invoices-out-time-encoded/finance-invoices-out-time-encoded.component';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs';
import { CrmContactsListModule } from 'src/app/components/crm/crm-contacts-list/crm-contacts-list.component';

@Component({
  selector: 'finance-invoices-out',
  templateUrl: './finance-invoices-out.component.html',
  styleUrls: ['./finance-invoices-out.component.scss'],
})
export class FinanceInvoicesOutComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  templates: DataSource;

  url: string;

  thisYear = new Date(new Date().getFullYear(), 0, 1);
  now = new Date();

  isEditPopupOpened: boolean = false;

  isViewRelatedTimesheetsPopupOpened: boolean = false;

  editedRow: any = null;

  loading = false;

  constructor(private invoiceService: CalystaAPIInvoiceService) {
    this.url = this.invoiceService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'InvoiceHeaderId',
        loadUrl: `${this.url}/invoiceheader`,
        insertUrl: `${this.url}/invoiceheader`,
        updateUrl: `${this.url}/invoiceheader`,
        deleteUrl: `${this.url}/invoiceheader`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
          if (method == 'load') {
            if (this.thisYear && this.now) {
              ajaxOptions.data.startDate = this.thisYear.toISOString();
              ajaxOptions.data.endDate = this.now.toISOString();
            }
          }
        },
      }),
    });

    this.templates = new DataSource({
      store: AspNetData.createStore({
        key: 'TemplateId',
        loadUrl: `${environment.CalystaApiBaseURL}api/TemplateEngine/Template/Lookup`,
      }),
      sort: 'TemplateLabel',
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onDateChanged = (e: any): void => {
    this.thisYear = e.value[0];
    this.now = e.value[1];

    this.dataGrid.instance.getDataSource().reload();
  };

  onEdit = (e: any) => {
    this.editedRow = e.row.data;
    this.isEditPopupOpened = true;
  };

  onSaveClickEdit(e: any): void {
    this.dataSource.store().update(e.InvoiceHeaderId, this.editedRow);

    var rowIndex = this.dataGrid.instance.getRowIndexByKey(e.InvoiceHeaderId);
    var row = this.dataGrid.instance.getVisibleRows()[rowIndex].data;
    if (!row) return;
    Object.assign(row, this.editedRow);
    this.dataGrid.instance.repaintRows([rowIndex]);
  }

  onCloseEdit = (e: boolean) => {
    this.isEditPopupOpened = e;
  };

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Invoices Out');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'InvoicesOut.xlsx'
        );
      });
    });
  }

  onLinkPdf = (e: any) => {
    window.open(e.PDFFileLink);
  };

  onLinkExcel = (e: any) => {
    window.open(e.ExcelFileLink);
  };

  hasPdfLink = (e: any) => {
    return e.row.data.PDFFileLink != null;
  };

  hasExcelLink = (e: any) => {
    return e.row.data.ExcelFileLink != null;
  };

  Download() {
    this.loading = true;
    var selectedRows = this.dataGrid.instance.getSelectedRowsData();

    if (selectedRows.length == 0) {
      notify('Please select at least one row');
      return;
    }

    var ids = selectedRows.map((x) => x.InvoiceHeaderId);

    this.invoiceService
      .getZipDocuments(ids)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (x) => {
          var blob = new Blob([x], { type: 'application/zip' });
          saveAs(blob, 'Invoices.zip');
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
      });
  }

  onEditorPreparing(e) {
    if (e.type !== 'selection') return;

    if (e.parentType === 'dataRow' && e.row && !this.isSelectable(e.row.data)) {
      e.editorOptions.disabled = true;
    }
  }

  isSelectable(item: any) {
    if (item.PDFFileLink) return true;
    return false;
  }

  selectionChangedHandler(e): void {
    const deselectRowKeys: number[] = [];
    const dataGrid = e.component;

    e.selectedRowsData.forEach((item) => {
      if (!this.isSelectable(item)) deselectRowKeys.push(dataGrid.keyOf(item));
    });

    if (deselectRowKeys.length) {
      dataGrid.deselectRows(deselectRowKeys);
    }
  }

  onDraftClosed = (e: boolean) => {
    this.isEditPopupOpened = e;
    this.refreshData();
  };

  openRelatedTimesheets = (e: any) => {
    this.isViewRelatedTimesheetsPopupOpened = true;
    this.editedRow = e.row.data;
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxButtonModule,
    DxDropDownButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    RangeDatePickerModule,
    FinanceDraftInvoiceModule,
    FinanceInvoicesOutDetailsModule,
    DxDateRangeBoxModule,
    DxLoadPanelModule,
    FinanceInvoicesOutTimeEncodedModule,
    DxTabPanelModule,
    CrmContactsListModule,
  ],
  declarations: [FinanceInvoicesOutComponent],
  exports: [],
  providers: [],
})
export class FinanceInvoicesOutModule {}
