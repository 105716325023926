<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  height="auto"
  width="50vw"
>
  <dx-form [(formData)]="budgetSales">
    <dxi-item itemType="group" colCount="2">
      <dxi-item
        dataField="Year"
        editorType="dxNumberBox"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxi-validation-rule type="required" message="Year is required" />
        <dxo-label text="Year" />
      </dxi-item>
      <dxi-item
        dataField="Month"
        editorType="dxLookup"
        [editorOptions]="{
          validationMessageMode: 'always',
          dataSource: monthsMapping,
          valueExpr: 'key',
          displayExpr: 'value'
        }"
      >
        <dxi-validation-rule type="required" message="Month is required" />
        <dxo-label text="Month" />
      </dxi-item>
    </dxi-item>
    <dxi-item itemType="group" caption="Targets" colCount="2">
      <dxi-item
        dataField="Margin"
        editorType="dxNumberBox"
        [editorOptions]="{
          validationMessageMode: 'always',
          format: { type: 'currency', precision: 2 }
        }"
      >
        <dxo-label text="Margin Amount" />
      </dxi-item>
      <dxi-item
        dataField="Sales"
        editorType="dxNumberBox"
        [editorOptions]="{
          validationMessageMode: 'always',
          format: { type: 'currency', precision: 2 }
        }"
      >
        <dxo-label text="Sales Amount" />
      </dxi-item>
      <dxi-item
        dataField="MarginBonus"
        editorType="dxNumberBox"
        [editorOptions]="{
          validationMessageMode: 'always',
          format: { type: 'currency', precision: 2 }
        }"
      >
        <dxo-label text="Margin Bonus" />
      </dxi-item>
      <dxi-item
        dataField="SalesBonus"
        editorType="dxNumberBox"
        [editorOptions]="{
          validationMessageMode: 'always',
          format: { type: 'currency', precision: 2 }
        }"
      >
        <dxo-label text="Sales Bonus" />
      </dxi-item>
    </dxi-item>
    <dxi-item
      itemType="group"
      caption="Contributors"
      [colCount]="2"
      [colSpan]="2"
    >
      <budget-sales-target-contributors
        [entity]="budgetSales"
        [datasource]="detailDataSource"
      />
    </dxi-item>
  </dx-form>
</form-popup>
