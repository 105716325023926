import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { FinanceCreditcollectAmoundueDetailsModule } from './finance-creditcollect-amoundue-details/finance-creditcollect-amoundue-details.component';
import { FinancialValutaKpiModule } from 'src/app/components/utils/financial-valuta-kpi/financial-valuta-kpi.component';
import { FinancialNumberKpiModule } from 'src/app/components/utils/financial-number-kpi/financial-number-kpi.component';
import { CreditCollectService } from 'src/app/services/creditcollect/creditcollect.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'finance-creditcollect-amountdue',
  templateUrl: './finance-creditcollect-amountdue.component.html',
  styleUrls: ['./finance-creditcollect-amountdue.component.scss'],
})
export class FinanceCreditcollectAmountdueComponent implements OnInit {
  amountDueDataSource: any;
  totals: any;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor(private creditcollectSvc: CreditCollectService) {}

  ngOnInit() {
    this.amountDueDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ClientId',
        loadUrl: `${this.url}CreditCollect/AmountDue/All`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });

    this.totals = this.creditcollectSvc.getAmountDueTotals().subscribe(
      (data) => {
        this.totals = data.Totals;
      },
      (err) => {
        const errorMessage = err.error.Errors.GeneralErrors[0];
        notify(errorMessage, 'Error finalizing draft', 3000);
      },
      () => {}
    );
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    FinanceCreditcollectAmoundueDetailsModule,
    FinancialValutaKpiModule,
    FinancialNumberKpiModule,
  ],
  providers: [],
  declarations: [FinanceCreditcollectAmountdueComponent],
  exports: [FinanceCreditcollectAmountdueComponent],
})
export class FinanceCreditcollectAmountdueModule {}
