<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="To Do" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
      (onEditorPreparing)="onEditorPreparing($event)"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      >
        <dxo-popup
          [showTitle]="true"
          [title]="'To do'"
          height="auto"
          width="30vw"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form>
          <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
            <dxi-item
              dataField="ProspectId"
              editorType="dxLookup"
              [editorOptions]="{
                dataSource: prospects,
                displayExpr: 'ProspectName',
                valueExpr: 'ProspectId',
                searchEnabled: true,
              }"
            >
              <dxi-validation-rule
                type="required"
                message="Prospect is required"
              />
              <dxo-label text="Prospect" />
            </dxi-item>
            <dxi-item dataField="ActionId">
              <dxi-validation-rule
                type="required"
                message="Action is required"
              />
              <dxo-label text="Action" />
            </dxi-item>
            <dxi-item
              dataField="EmployeeId"
              [editorOptions]="{
                validationMessageMode: 'always'
              }"
            >
              <dxi-validation-rule
                type="required"
                message="Employee is required"
              />
              <dxo-label text="Employee" />
            </dxi-item>
            <dxi-item
              dataField="Deadline"
              [editorOptions]="{
                validationMessageMode: 'always'
              }"
            >
              <dxi-validation-rule
                type="required"
                message="Deadline is required"
              >
              </dxi-validation-rule>
              <dxo-label text="Deadline" />
            </dxi-item>
            <dxi-item dataField="Comments">
              <dxo-label text="Comments" />
            </dxi-item>
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" />

      <dxo-toolbar>
        <dxi-item location="before" locateInMenu="auto">
          <employee-name-lookup [valueChange]="onEmployeeSelectionChanged" />
        </dxi-item>
        <dxi-item
          location="before"
          widget="dxCheckBox"
          locateInMenu="never"
          [options]="{
            text: 'Include Done',
            icon: '',
            stylingMode: 'contained',
            value: includeDone,
            onValueChanged: includeDoneChanged
          }"
        />
        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="New Todo"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="this.dataGrid.instance.addRow()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="ProspectId" alignment="center" [visible]="false" />
      <dxi-column
        dataField="ProspectName"
        caption="Prospect"
        alignment="center"
      >
      </dxi-column>
      <dxi-column dataField="ActionId" caption="Action" alignment="center">
        <dxo-lookup
          [dataSource]="actions.store()"
          displayExpr="Name"
          valueExpr="ActionId"
        />
      </dxi-column>
      <dxi-column
        dataField="Deadline"
        caption="Deadline"
        sortOrder="desc"
        dataType="date"
        alignment="center"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="Comments"
        caption="Comments"
        alignment="center"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="EmployeeId"
        caption="Person Responsible"
        [visible]="false"
        alignment="center"
        [allowSearch]="false"
      >
        <dxo-lookup
          [dataSource]="employees.store()"
          displayExpr="FullName"
          valueExpr="EmployeeId"
        />
      </dxi-column>
      <dxi-column
        dataField="Done"
        caption="Is Done"
        [visible]="includeDone"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="DoneDate"
        caption="Done Date"
        dataType="date"
        [visible]="includeDone"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="IsOutcomePositive"
        caption="Is Positive"
        [visible]="includeDone"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="OutcomeComments"
        caption="Outcome Comments"
        [visible]="includeDone"
        alignment="center"
        [allowSearch]="false"
      />
      <dxi-column type="buttons">
        <dxi-button name="edit" />
        <dxi-button name="delete" />
        <dxi-button
          icon="check"
          [onClick]="markAsDone"
          [visible]="isMarkableAsDone"
        />
      </dxi-column>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridCrmTodo"
      />
    </dx-data-grid>
    <crm-todo-mark-as-done-form
      [(visible)]="isMarkAsDonePopupOpened"
      (save)="onTodoMarkAsDoneComplete($event)"
      [titleText]="'Mark Todo As Done'"
      (visibleChange)="onCloseMarkAsDone($event)"
      [todo]="todoToMarkAsDone"
    />
  </div>
</dx-scroll-view>
