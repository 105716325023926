import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxTagBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'conversion-import-case-from-file',
  templateUrl: './conversion-import-case-from-file.component.html',
  styleUrls: ['./conversion-import-case-from-file.component.scss'],
})
export class ConversionImportCaseFromFileComponent {
  @Input() titleText = '';

  @Input() visible = false;

  @Input() height = '50vh';

  @Input() width = '50vw';

  @Output() save = new EventEmitter<any>();

  @Output() visibleChange = new EventEmitter<boolean>();

  url = environment.CalystaApiBaseURL + 'api/';

  info: {
    document: any;
    name: string;
  } = {
    document: null,
    name: '',
  };

  constructor() {}

  handleVisible(e: boolean): void {
    this.visible = e;
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.save.emit(this.info);
  }

  onFileChanged = (e) => {
    this.info.document = e.value[0];
  };

  onConversionNameChanged = (e) => {
    this.info.name = e.value;
  };
}

@NgModule({
  declarations: [ConversionImportCaseFromFileComponent],
  exports: [ConversionImportCaseFromFileComponent],
  bootstrap: [ConversionImportCaseFromFileComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
  ],
})
export class ConversionImportCaseFromFileModule {}
