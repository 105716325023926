import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class DataQualityService {
  url = apiUrl + 'api/DataQuality';
  constructor(private http: HttpClient) {}

  checkData(
    caseNumbers: string[],
    criteria: string,
    remarks: string,
    status: string
  ) {
    var body = {
      caseNumbers: caseNumbers,
      criteria: criteria,
      remarks: remarks,
      status: status,
    };

    return this.http.post<any>(`${this.url}/UserLog/Check`, body);
  }
}
