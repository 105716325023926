import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxFormModule,
  DxSelectBoxModule,
  DxTextAreaModule,
  DxValidatorModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { QuoteService } from 'src/app/services/financial/quote/quote.service';
import { Charge } from 'src/app/types/invoicing/charge';
import DataSource from 'devextreme/data/data_source';
import { ValueChangedEvent } from 'devextreme/ui/text_area';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';
import { RegistrationDetailsFormModule } from 'src/app/components/time-registration/registration-details-form/registration-details-form.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';
import { ChargeDetailsFormModule } from '../charge-details-form/charge-details-form.component';
import { VatService } from 'src/app/services/vat.service';
import { CaseService } from 'src/app/services/case/case.service';
import { WorkcodeService } from 'src/app/services/invoicing/workcode/workcode.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'finance-charge-edit',
  templateUrl: './finance-charge-edit.component.html',
  styleUrls: ['./finance-charge-edit.component.scss'],
})
export class FinanceChargeEditComponent implements OnInit {
  @Input() titleText = '';

  @Input() visible = false;

  @Input() charge: any;

  @Output() save = new EventEmitter<Charge>();

  @Output() visibleChange = new EventEmitter<boolean>();

  vats: number[];

  currencies: any;

  workcodeDataSource: any;

  caseDataSource: any;

  timeDetailsDataSource: any;

  description: string;

  clientLanguageId: number = 3;

  quoteOnce = false;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor(
    private QuoteSvc: QuoteService,
    private vatService: VatService,
    private WorkCodeSvc: WorkcodeService,
    private CaseSvc: CaseService
  ) {}

  ngOnInit(): void {
    this.vats = this.vatService.getVATPercentageList();

    this.workcodeDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkCodeId',
        loadUrl: `${this.url}Invoice/Workcode/lookup`,
      }),
      sort: 'WorkCodeLabel',
    });

    this.caseDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${this.url}Patricia/CaseDetailedInfo/lookup`,
      }),
      sort: 'CaseNumber',
    });

    this.currencies = new DataSource({
      store: AspNetData.createStore({
        key: 'CurrencyId',
        loadUrl: `${this.url}Currency/lookup`,
      }),
      sort: 'CurrencyLabel',
    });
  }

  reCalculateQuote = (): void => {
    if (this.charge.CostLineId !== null) {
      return;
    }

    //delay is a bit of an horrible way to prevent value to be changed on initial load
    let delay = 0;

    if (!this.quoteOnce) {
      delay = 1000;
    }

    setTimeout(() => {
      if (
        this.charge.CaseId != null &&
        this.charge.WorkCodeId != null &&
        this.quoteOnce
      ) {
        this.QuoteSvc.CalculateQuote(
          this.charge.WorkCodeId,
          this.charge.CaseId
        ).subscribe({
          next: (res) => {
            this.charge.UnitPrice = res.Amount;
          },
          error: (err) => {
            const errorMessage = err.error.Errors.GeneralErrors[0];
            notify(errorMessage, 'Error', 3000);
          },
        });
      } else {
        this.quoteOnce = true;
      }
    }, delay);
  };

  reCalculateAmounts = (): void => {
    this.charge.NonVatAmount = this.charge.UnitPrice * this.charge.Quantity;

    this.charge.VatAmount =
      this.charge.UnitPrice *
        this.charge.Quantity *
        (1 + this.charge.VatPercentage / 100) -
      this.charge.UnitPrice * this.charge.Quantity;

    this.charge.AmountTot = this.charge.NonVatAmount + this.charge.VatAmount;
  };

  reCalculateTotalAmount = (): void => {
    this.charge.AmountTot = this.charge.NonVatAmount + this.charge.VatAmount;
  };

  handleVisible(e: boolean): void {
    this.visible = e;
    this.visibleChange.emit(this.visible);
  }

  onSaveClick(): void {
    this.save.emit(this.charge);
  }

  onShow() {
    this.quoteOnce = false;

    this.timeDetailsDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'RegistrationDetailId',
        loadUrl: `${this.url}Invoice/ChargeTimeLnk/${this.charge.ChargeId}`,
        insertUrl: `${this.url}TimeRegistration/Registration/Detail`,
        updateUrl: `${this.url}TimeRegistration/Registration/Detail`,
        deleteUrl: `${this.url}TimeRegistration/Registration/Detail`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });

    this.description = this.charge.Description;
  }

  onDescriptionChange(e: ValueChangedEvent) {
    this.charge.Description = e.value;
  }

  onWorkcodeChanged = (data) => {
    const value = data.value;
    this.charge.WorkCodeId = value;

    this.reCalculateQuote();

    return this.getWorkCodeDescriptionPromise(value).then((data: any) => {
      this.charge.Description = data.WorkCodeDescription;
      this.description = data.WorkCodeDescription;
    });
  };

  getCaseClientLanguagePromise = (caseId): Promise<number> => {
    return new Promise((resolve) => {
      this.CaseSvc.getAccountAddressLanguage(caseId).subscribe((data) => {
        resolve(data.LanguageId);
      });
    });
  };

  getWorkCodeDescriptionPromise = (workCodeId) => {
    return new Promise((resolve) => {
      this.WorkCodeSvc.getWorkCode(workCodeId, this.clientLanguageId).subscribe(
        (data) => {
          resolve({
            WorkCodeDescription: data.InvoiceDescription,
            WorkCodeLabel: data.WorkCodeLabel,
          });
        }
      );
    });
  };

  hasCost = (): boolean => {
    return this.charge?.CostLineId !== null;
  };
}

@NgModule({
  declarations: [FinanceChargeEditComponent],
  exports: [FinanceChargeEditComponent],
  bootstrap: [FinanceChargeEditComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxSelectBoxModule,
    ChargeDetailsFormModule,
    RegistrationDetailsFormModule,
    DxTextAreaModule,
  ],
})
export class FinanceChargeEditModule {}
