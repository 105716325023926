<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Pavis Cases To Renew" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [allowColumnReordering]="true"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-selection
        [selectAllMode]="'allPages'"
        [showCheckBoxesMode]="'always'"
        mode="multiple"
      />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="false"
        [allowDeleting]="true"
      />
      <dxo-paging [pageSize]="100"></dxo-paging>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item
          location="before"
          widget="dxLookup"
          locateInMenu="auto"
          [options]="{
            placeholder: 'Type',
            items: ['Patent', 'Design', 'Trademark'],
            value: type,
            onValueChanged: onTypeChanged
          }"
        />
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: 'Request Quote',
            icon: '',
            stylingMode: 'button',
            onClick: requestQuote
          }"
        />
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: 'Handle Instructions',
            icon: '',
            stylingMode: 'button',
            onClick: handleInstructions
          }"
        />
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        dataField="PavisId"
        caption="Pavis Reference"
        alignment="center"
        width="8%"
      />
      <dxi-column
        dataField="CaseNumber"
        caption="Case Number"
        alignment="center"
        width="8%"
      />
      <dxi-column
        dataField="CaseCatchword"
        caption="Catchword"
        alignment="center"
      />
      <dxi-column
        dataField="StateName"
        caption="Country"
        alignment="center"
        width="8%"
      />
      <dxi-column
        dataField="Deadline"
        caption="Deadline"
        dataType="date"
        alignment="center"
        width="8%"
        sortOrder="asc"
        sortIndex="1"
      />
      <dxi-column dataField="OfficialFee" caption="Official Fee" width="6%">
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column dataField="ServiceFee" caption="Service Fee" width="6%">
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column dataField="CalystaFee" caption="Calysta Fee" width="6%">
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column dataField="TotalFee" caption="Total Fee" width="6%">
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column dataField="CaseId" caption="Case Id" [visible]="false" />
      <dxi-column type="buttons">
        <dxi-button name="delete" />
        <dxi-button
          hint="handle instruction to pavis"
          icon="fas fa-paper-plane"
          [onClick]="startHandleInstruction"
        />
      </dxi-column>
      <dxo-master-detail [enabled]="true" template="detail" />
      <div *dxTemplate="let details of 'detail'">
        <div>
          <div>Quote Details</div>
          <dx-data-grid
            [allowColumnResizing]="true"
            [allowColumnReordering]="true"
            [dataSource]="details.data.QuoteDetails"
            title="Quote Details"
            (onRowUpdating)="onRowUpdating($event)"
          >
            <dxi-column
              dataField="RenewalDueDate"
              caption="Due Date"
              dataType="date"
              alignment="center"
            />
            <dxi-column dataField="ServiceFee" caption="Service Fee" />
            <dxi-column dataField="OfficialFee" caption="Official Fee" />
            <dxi-column
              dataField="ValidFrom"
              caption="Valid From"
              dataType="date"
              alignment="center"
            />
            <dxi-column
              dataField="ValidUntil"
              caption="Valid Until"
              dataType="date"
              alignment="center"
            />
            <dxi-column
              dataField="InstructedOn"
              caption="Instruct Before"
              dataType="date"
              alignment="center"
            />
            <dxi-column
              dataField="RequestedOn"
              caption="Request On"
              dataType="date"
              alignment="center"
            />
            <dxi-column
              dataField="EventSchemeId"
              caption="OfficialEventSchemeId"
              [visible]="false"
            />

            <dxo-state-storing
              [enabled]="false"
              type="localStorage"
              storageKey="gridPavsiInstruction"
            />
          </dx-data-grid>
        </div>
        <div>
          <div>Calysta Fee Details</div>
          <dx-data-grid
            [allowColumnResizing]="true"
            [allowColumnReordering]="true"
            [dataSource]="details.data.CalyFeeDetails"
            (onRowUpdating)="onRowUpdating($event)"
            [wordWrapEnabled]="true"
          >
            <dxi-column dataField="WorkCodeLabel" caption="WorkCode" />
            <dxi-column dataField="InstructionLabel" caption="Instruction" />
            <dxi-column dataField="WorkCodeDescription" caption="Description" />
            <dxi-column dataField="Quantity" caption="Quantity" />
            <dxi-column dataField="UnitPrice" caption="Unit Price" />
            <dxi-column dataField="NonVatAmount" caption="Non VAT Amount " />

            <dxo-state-storing
              [enabled]="false"
              type="localStorage"
              storageKey="storage"
            />
          </dx-data-grid>
        </div>
      </div>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="storage"
      />
    </dx-data-grid>
    <pavis-handle-instruction-form
      [(visible)]="isHandleInstructionPopupVisible"
      (save)="confirmHandleInstruction($event)"
      [titleText]="'Handle instruction'"
      (visibleChange)="cancelHandleInstruction($event)"
      [pavisCase]="caseToHandle"
    />
    <pavis-handle-instruction-form
      [(visible)]="isHandleInstructionMultiPopupVisible"
      (save)="confirmHandleInstructions($event)"
      [titleText]="'Handle instructions'"
      (visibleChange)="cancelHandleInstructions($event)"
      [pavisCase]="caseToHandle"
    />
  </div>
</dx-scroll-view>
