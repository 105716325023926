<dx-scroll-view>
<div id="TimeOverview">
<dx-chart id="TimeOverviewChart">
  <dxo-common-series-settings type="bar"></dxo-common-series-settings>
  <dxo-size [height]="200"></dxo-size>
  <dxo-adaptive-layout [width]="450"></dxo-adaptive-layout>
</dx-chart>

<dx-pivot-grid
  id="timeoverviewpivot"
  [allowSortingBySummary]="true"
  [allowFiltering]="true"
  [showBorders]="true"
  [allowSortingBySummary]="true"
  [showColumnGrandTotals]="true"
  [showRowGrandTotals]="true"
  [showRowTotals]="true"
  [dataSource]="pivotDataSource"
  >
  <dxo-scrolling mode="virtual"></dxo-scrolling>

</dx-pivot-grid>
</div>
</dx-scroll-view>
