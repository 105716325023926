<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  (onShow)="onShow()"
>
  <dx-form [(formData)]="charge" [colCount]="1">
    <dxi-item itemType="group" [colCount]="2" [colSpan]="4" caption="General">
      <dxi-item
        dataField="CaseId"
        editorType="dxLookup"
        [colSpan]="1"
        [editorOptions]="{
          dataSource: caseDataSource,
          displayExpr: 'CaseNumber',
          valueExpr: 'CaseId',
          searchMode: 'contains',
          searchExpr: 'CaseDetailedDescription',
          minsSearchLength: 2,
          showDataBeforeSearch: true,
          searchEnabled: true,
          onValueChanged: onCaseChanged,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="Case is required" />
        <dxo-label text="Case" />
      </dxi-item>
      <dxi-item
        dataField="WorkCodeId"
        editorType="dxLookup"
        [colSpan]="1"
        [editorOptions]="{
          dataSource: workcodeDataSource,
          displayExpr: 'WorkCodeFullDescription',
          valueExpr: 'WorkCodeId',
          searchMode: 'contains',
          searchExpr: 'WorkCodeFullDescription',
          searchEnabled: true,
          minsSearchLength: 2,
          showDataBeforeSearch: true,
          onValueChanged: onWorkcodeChanged,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="WorkCode is required" />
        <dxo-label text="WorkCode" />
      </dxi-item>
      <dxi-item
        dataField="UnitPrice"
        [colSpan]="1"
        editorType="dxNumberBox"
        [editorOptions]="{
          onValueChanged: reCalculateAmounts,
          validationMessageMode: 'always',
          format: { precision: 2, type: 'fixedPoint' }
        }"
      >
        <dxi-validation-rule type="required" message="Unit price is required" />
      </dxi-item>
      <dxi-item
        dataField="Quantity"
        editorType="dxNumberBox"
        [colSpan]="1"
        [editorOptions]="{
          validationMessageMode: 'always',
          onValueChanged: reCalculateAmounts,
          min: 0
        }"
      >
        <dxi-validation-rule type="required" message="Quantity is required" />
      </dxi-item>
      <dxi-item
        dataField="VatPercentage"
        editorType="dxSelectBox"
        [editorOptions]="{
          items: vats,
          validationMessageMode: 'always',
          onValueChanged: reCalculateAmounts
        }"
      >
        <dxi-validation-rule
          type="required"
          message="VAT percentage is required"
        />
      </dxi-item>
    </dxi-item>
    <dxi-item itemType="group" [colCount]="2" [colSpan]="4" caption="Financial">
      <dxi-item
        dataField="NonVatAmount"
        editorType="dxNumberBox"
        [colSpan]="1"
        [disabled]="true"
        [editorOptions]="{
          validationMessageMode: 'always',
          format: {  precision: 2, type: 'fixedPoint'},
          onValueChanged: reCalculateTotalAmount,
        }"
      />
      <dxi-item
        dataField="VatAmount"
        editorType="dxNumberBox"
        [colSpan]="1"
        [disabled]="true"
        [editorOptions]="{
          validationMessageMode: 'always',
          format: {  precision: 2, type: 'fixedPoint' },
          onValueChanged: reCalculateTotalAmount,
        }"
      />
      <dxi-item
        dataField="AmountTot"
        editorType="dxNumberBox"
        [colSpan]="1"
        [disabled]="true"
        [editorOptions]="{
          validationMessageMode: 'always',
          format: { precision: 2, type: 'fixedPoint' }
        }"
      />
      <dxi-item
        dataField="CurrencyId"
        editorType="dxLookup"
        [editorOptions]="{
          dataSource: currencies,
          displayExpr: 'CurrencyLabel',
          valueExpr: 'CurrencyId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="Currency is required" />
        <dxo-label text="Currency" />
      </dxi-item>
      <dxi-item dataField="Description" [colSpan]="2">
        <dx-text-area
          validationMessageMode="always"
          [autoResizeEnabled]="true"
          [(value)]="charge.Description"
          (onValueChanged)="onDescriptionChange($event)"
        />
        <dxi-validation-rule
          type="required"
          message="Description is required"
        />
      </dxi-item>
    </dxi-item>
  </dx-form>
</form-popup>
