import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownBoxModule,
  DxDropDownButtonModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { TitleBarModule } from 'src/app/components';
import { BankImportFileModule } from 'src/app/components/bank/bank-import-file/bank-import-file.component';
import { BankService } from 'src/app/services/financial/bank/bank.service';

@Component({
  selector: 'finance-bank-account-track',
  templateUrl: './finance-bank-account-track.component.html',
  styleUrls: ['./finance-bank-account-track.component.scss'],
})
export class FinanceBankAccountTrackComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  importFromFilePopupVisible: boolean = false;

  constructor(private bankService: BankService) {
    this.url = bankService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'BankAccountTrackId',
        loadUrl: `${this.url}/bankaccounttrack`,
        insertUrl: `${this.url}/bankaccounttrack`,
        updateUrl: `${this.url}/bankaccounttrack`,
        deleteUrl: `${this.url}/bankaccounttrack`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  importFromFile = () => {
    this.importFromFilePopupVisible = true;
  };

  onClosedImportFromFilePopup = (e) => {
    this.importFromFilePopupVisible = false;
  };

  onImportFromFile = (document) => {
    if (document) {
      const formData = new FormData();
      formData.append('File', document);

      this.bankService.importFromFile(formData).subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
        complete: () => {
          this.importFromFilePopupVisible = false;
          this.refreshData();
        },
      });
    }
  };

  process = () => {
    this.bankService.process().subscribe({
      next: (x) => {},
      error: (err) => {
        const errorMessage = err.error.Errors.GeneralErrors[0];
        notify(errorMessage, 'Error', 3000);
      },
      complete: () => {
        this.refreshData();
      },
    });
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    DxDropDownBoxModule,
    DxDropDownButtonModule,
    DxCheckBoxModule,
    DxDateBoxModule,
    TitleBarModule,
    DxScrollViewModule,
    BankImportFileModule,
  ],
  providers: [],
  declarations: [FinanceBankAccountTrackComponent],
  exports: [],
})
export class FinanceInvoicesInModule {}
