<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Client And Prospects" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onExporting)="onExporting($event)"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="false"
        [allowUpdating]="false"
        [allowDeleting]="false"
      />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export
        [enabled]="true"
        [fileName]="'Clients and Prospects'"
        [allowExportSelectedData]="false"
      />
      <dxo-toolbar>
        <dxi-item location="before" locateInMenu="never">
          <div class="date-range-container">
            <div class="date-range-title">Became Prospect Date</div>
            <dx-date-range-box
              title="Became Prospect Date"
              [startDate]="thisYearProspect"
              [endDate]="nowProspect"
              (onValueChanged)="onProspectDateChanged($event)"
              [showClearButton]="true"
              [width]="300"
              [max]="nowProspect"
            />
          </div>
        </dxi-item>
        <dxi-item location="before" locateInMenu="never">
          <div class="date-range-container">
            <div class="date-range-title">Become Client Date</div>
            <dx-date-range-box
              title="Became Client Date"
              [startDate]="thisYearClient"
              [endDate]="nowClient"
              (onValueChanged)="onClientDateChanged($event)"
              [showClearButton]="true"
              [width]="300"
              [max]="nowClient"
            />
          </div>
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="exportButton" locateInMenu="auto" />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="Name" caption="Name" alignment="left" />
      <dxi-column
        dataField="BecameProspectDate"
        caption="Became Prospect Date"
        dataType="date"
        width="8%"
        alignment="center"
      />
      <dxi-column
        dataField="BecameClientDate"
        caption="Became Client Date"
        dataType="date"
        width="8%"
        alignment="center"
      />
      <dxi-column
        dataField="BecameClient"
        caption="Became Client"
        [visible]="false"
      />
      <dxi-column
        dataField="BecameProspect"
        caption="Became Propsect"
        [visible]="false"
      />
      <dxi-column dataField="NameId" caption="NameId" [visible]="false" />

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridPatriciaClientsAndProspects"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
