import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class AdministrationService {
  url = apiUrl + 'api/administration';
  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  assignPermission(userId: string, permissionId: number) {
    const body = {
      UserId: userId,
      PermissionId: permissionId,
    };

    return this.http.post(this.url + '/Permission/User', body);
  }

  removePermission(userId: string, permissionId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = {
      UserId: userId,
      PermissionId: permissionId,
    };

    const params = new HttpParams({
      fromObject: body,
    });

    return this.http.delete(this.url + '/Permission/User', {
      headers: headers,
      body: params,
    });
  }
}
