import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxTagBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { CalystaAPIInvoiceService } from 'src/app/services/calysta-api/invoice.service';

@Component({
  selector: 'time-registration-charge-by-case-grid',
  templateUrl: './time-registration-charge-by-case-grid.component.html',
  styleUrls: ['./time-registration-charge-by-case-grid.component.scss'],
})
export class TimeRegistrationChargeByCaseComponent {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() caseId: number;

  @Input() visible: boolean;

  @Output() visibleChange = new EventEmitter<boolean>();

  dataSource: DataSource;

  constructor(private invoiceService: CalystaAPIInvoiceService) {}

  loadDataSource = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ChargeId',
        loadUrl: `${this.invoiceService.getUrl()}/charge/case/${this.caseId}`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method === 'load') {
            ajaxOptions.data = ajaxOptions.data || {};
          }
        },
      }),
    });
  };

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onShown() {
    this.loadDataSource();
    this.refreshData();
  }

  closePopup() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}

@NgModule({
  declarations: [TimeRegistrationChargeByCaseComponent],
  exports: [TimeRegistrationChargeByCaseComponent],
  bootstrap: [TimeRegistrationChargeByCaseComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
    DxDataGridModule,
    DxPopupModule,
  ],
})
export class TimeRegistrationChargeByCaseModule {}
