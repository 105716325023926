import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxTagBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { WorkcodeLookupModule } from '../../lookups/workcode-lookup/workcode-lookup.component';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { FormPopupModule } from '../../utils/form-popup/form-popup.component';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'crm-prospect-import-to-px',
  templateUrl: './crm-prospect-import-to-px.component.html',
  styleUrls: ['./crm-prospect-import-to-px.component.scss'],
})
export class CrmPropsectImportToPxComponent implements OnInit {
  info: any;

  @Input() titleText = '';

  @Input() visible = false;

  @Input() height = '50vh';

  @Output() save = new EventEmitter<any>();

  @Output() visibleChange = new EventEmitter<boolean>();

  url = environment.CalystaApiBaseURL + 'api/';

  countries: any;

  constructor() {}

  ngOnInit(): void {
    this.countries = new DataSource({
      store: AspNetData.createStore({
        key: 'CountryId',
        loadUrl: `${this.url}Country/Lookup`,
      }),
      sort: 'Label',
    });
  }

  handleVisible(e: boolean): void {
    this.visible = e;
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.save.emit(this.info);
  }
}

@NgModule({
  declarations: [CrmPropsectImportToPxComponent],
  exports: [CrmPropsectImportToPxComponent],
  bootstrap: [CrmPropsectImportToPxComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
  ],
})
export class CrmPropsectImportToPxModule {}
