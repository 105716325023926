<dx-scroll-view class="view-wrapper-scroll"
  ><div class="view-wrapper">
    <titlebar titleText="Pavis Invoice Details" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      [allowColumnResizing]="true"
      (onCellPrepared)="onCellPrepared($event)"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-selection
        [selectAllMode]="'allPages'"
        [showCheckBoxesMode]="'always'"
        mode="multiple"
      />
      <dxo-editing
        mode="cell"
        [allowAdding]="false"
        [allowUpdating]="false"
        [allowDeleting]="false"
      />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: 'Import xml',
            icon: '',
            stylingMode: 'button',
            onClick: importXml
          }"
        />
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: 'Process Invoice',
            icon: '',
            stylingMode: 'button',
            onClick: processInvoice
          }"
        />
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: 'Unload XML',
            icon: '',
            stylingMode: 'button',
            onClick: unloadXml
          }"
        />
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        dataField="SubReference"
        caption="Sub Reference"
        [allowEditing]="false"
      />
      <dxi-column
        dataField="Annuity"
        caption="Annuity"
        [allowEditing]="false"
      />
      <dxi-column
        dataField="CurrentPxWfId"
        caption="[P] Wf n°"
        [allowEditing]="false"
      />
      <dxi-column
        dataField="NextTermToConnect"
        caption="Next WF Step"
        [allowEditing]="false"
      />
      <dxi-column
        dataField="DueDate"
        caption="Due Date"
        [allowEditing]="false"
        dataType="date"
      />
      <dxi-column
        dataField="PavisId"
        caption="Pavis Id"
        [allowEditing]="false"
      />
      <dxi-column
        dataField="FeeKind"
        caption="Fee Kind"
        [allowEditing]="false"
      />
      <dxi-column dataField="Price" caption="[P]Price" [allowEditing]="false" />
      <dxi-column
        dataField="ChargedAmount"
        caption="[C]Amount Invoiced"
        [allowEditing]="false"
      />
      <dxi-column dataField="Delta" caption="Delta" />
      <dxi-column dataField="Currency" />
      <dxi-column
        dataField="Message"
        caption="Message"
        [allowEditing]="false"
      />
      <dxi-column dataField="Match" caption="WF Match" [visible]="false" />
      <dxi-column type="buttons" width="110px">
        <dxi-button
          hint="Add Delta to charge"
          icon="fa-solid fa-circle-plus"
          [visible]="hasDelta"
          [onClick]="onAddDelta"
        />
      </dxi-column>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridRenewalPavisInvoiceDetails"
      />
    </dx-data-grid>
    <renewal-pavis-invoice-details-import-xml
      [(visible)]="isImportXmlPopupVisible"
      (save)="onImportXmlPopup($event)"
      [titleText]="'Import XML'"
      (visibleChange)="onClosedImportXmlPopup($event)"
    />
  </div>
</dx-scroll-view>
