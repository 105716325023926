import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDropDownButtonModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { CalystaAPIInvoiceService } from 'src/app/services/calysta-api/invoice.service';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';

@Component({
  selector: 'finance-reporting-spadel-invoice-overview',
  templateUrl: './finance-reporting-spadel-invoice-overview.component.html',
  styleUrls: ['./finance-reporting-spadel-invoice-overview.component.scss'],
})
export class FinanceReportingSpadelInvoiceOverviewComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  constructor(private invoiceService: CalystaAPIInvoiceService) {
    this.url = invoiceService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}/spadelinvoiceoverview`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Spadel Invoices');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'SpadelInvoices.xlsx'
        );
      });
    });
  }
}

@NgModule({
  declarations: [FinanceReportingSpadelInvoiceOverviewComponent],
  exports: [],
  providers: [],
  imports: [
    CommonModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxButtonModule,
    DxCheckBoxModule,
    TitleBarModule,
    DxScrollViewModule,
  ],
})
export class TimeRegistrationRegisterModule {}
