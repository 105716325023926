import {
  Component,
  NgModule,
  Input,
  Output,
  EventEmitter,
  ContentChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DxButtonModule,
  DxToolbarModule,
  DxPopupModule,
  DxValidationGroupModule,
  DxValidationSummaryModule,
  DxFormComponent,
} from 'devextreme-angular';
import { ScreenService } from 'src/app/services';
import { ApplyPipeModule } from 'src/app/pipes/apply.pipe';

@Component({
  selector: 'form-popup',
  templateUrl: './form-popup.component.html',
  styleUrls: ['./form-popup.component.scss'],
})
export class FormPopupComponent {
  @ContentChild(DxFormComponent, { static: false })
  form: DxFormComponent;

  @Input() titleText = '';

  @Input() width: string = '1000';

  @Input() wrapperAttr: Record<string, string> = {};

  @Input() visible = false;

  @Input() isSaveDisabled = false;

  @Input() saveText? = 'Save';

  @Input() height: string = '90%';

  @Input() closeOnSave = true;

  @Output() save = new EventEmitter();

  @Output() visibleChange = new EventEmitter<boolean>();

  @Output() onShow = new EventEmitter<any>();

  @Output() onShown = new EventEmitter<any>();

  constructor(protected screen: ScreenService) {}

  isValid() {
    return this.form.instance.validate().isValid;
  }

  onSaveClick() {
    //console.log(this.form.instance.validate());
    if (!this.isValid()) {
      return;
    }

    this.save.emit();

    if (this.closeOnSave) {
      this.close();
    }
  }

  close() {
    this.visible = false;

    this.visibleChange.emit(this.visible);
  }

  onShowing() {
    this.onShow.emit();
  }

  onShownEvent() {
    this.onShown.emit();
  }

  getWrapperAttrs = (inputWrapperAttr) => {
    return {
      ...inputWrapperAttr,
      class: `${inputWrapperAttr.class} form-popup`,
    };
  };
}

@NgModule({
  imports: [
    ApplyPipeModule,
    DxButtonModule,
    DxToolbarModule,
    DxPopupModule,
    DxValidationGroupModule,
    DxValidationSummaryModule,
    CommonModule,
  ],
  declarations: [FormPopupComponent],
  exports: [FormPopupComponent],
})
export class FormPopupModule {}
