import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDateRangeBoxModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { TitleBarModule } from 'src/app/components';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver-es';
import { BudgetSubCategoryLookupModule } from 'src/app/components/lookups/budget-subcategory-lookup/budget-subcategory-lookup.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'analytics-cost-follow-up',
  templateUrl: './analytics-cost-follow-up.component.html',
  styleUrls: ['./analytics-cost-follow-up.component.scss'],
})
export class AnalyticsCostFollowUpComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: DataSource;

  periodStart: Date | null = new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  );
  periodEnd: Date | null = new Date();
  maxDate: Date = new Date();
  budgetSubCategoryId: number;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    //get query param subCategoryId, periodStart, periodEnd
    this.budgetSubCategoryId = parseInt(
      this.route.snapshot.queryParamMap.get('subCategoryId')
    );

    this.periodStart =
      this.route.snapshot.queryParamMap.get('periodStart') !== null
        ? new Date(this.route.snapshot.queryParamMap.get('periodStart'))
        : this.periodStart;

    this.periodEnd =
      this.route.snapshot.queryParamMap.get('periodEnd') !== null
        ? new Date(this.route.snapshot.queryParamMap.get('periodEnd'))
        : this.periodEnd;

    this.loadDatasource();
  }

  loadDatasource() {
    let loadParams: any = {
      PeriodStart: this.periodStart.toISOString(),
      PeriodEnd: this.periodEnd.toISOString(),
    };

    // Conditionally add the BudgetSubCategoryId if it's specified
    if (this.budgetSubCategoryId) {
      loadParams.BudgetSubCategoryId = this.budgetSubCategoryId;
    }

    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CostLineId',
        loadUrl: `${this.url}Costs/CostFollowupAnalysis`,
        loadParams: loadParams,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Cost FollowUps');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Cost FollowUps.xlsx'
        );
      });
    });
  }

  onPeriodChanged = (e: any): void => {
    this.periodStart = e.value[0];
    this.periodEnd = e.value[1];

    this.loadDatasource();

    this.refreshData();
  };

  onBudgetSubCategoryChanged = (value: number) => {
    this.budgetSubCategoryId = value;

    this.loadDatasource();

    this.refreshData();
  };

  formatValue = (value: any) => {
    return value.toFixed(2);
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    BudgetSubCategoryLookupModule,
    DxDateRangeBoxModule,
  ],
  declarations: [AnalyticsCostFollowUpComponent],
  exports: [],
})
export class AnalyticsCostFollowUpModule {}
