<titlebar
  titleText="Targets"
  [useButton]="true"
  (onButtonClicked)="onClickRedirectTargets()"
  iconButton="fa-solid fa-square-up-right"
/>
<div class="separator"></div>

<dx-tabs
  #withText
  [dataSource]="TargetTabs"
  [selectedIndex]="selectedTab"
  (onItemClick)="onTabClick($event)"
/>

<dx-data-grid
  #grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [dataSource]="Data"
  [loadPanel]="{ enabled: true }"
  [wordWrapEnabled]="true"
  height="80%"
>
  <dxo-scrolling mode="virtual" [showScrollbar]="'always'" />

  <dxi-column caption="Business" dataField="BusinessName" />
  <dxi-column
    alignment="left"
    caption="Realized"
    dataField="Realized"
    dataType="number"
    format="currency"
  />
  <dxi-column
    alignment="left"
    caption="Target"
    dataField="Target"
    format="currency"
  />
  <dxi-column
    alignment="left"
    caption="Percentage"
    dataField="Percentage"
    name="percentB"
    cellTemplate="salesBullet"
    cssClass="sales-bullet"
  />

  <!--<dxo-master-detail
    [enabled]="true"
    template="salesBullet"
    [autoExpandAll]="true"
    [enabled]="false"
  />-->

  <div *dxTemplate="let cellInfo of 'salesBullet'">
    <dx-bullet
      [showTarget]="true"
      [showZeroLevel]="true"
      [value]="cellInfo.data.Realized"
      [startScaleValue]="0"
      [target]="cellInfo.data.Target"
      [endScaleValue]="cellInfo.data.Target * 1.2"
    >
      <dxo-tooltip [enabled]="false" />
      <!--<dxo-size [width]="450" [height]="20" />-->
      <dxo-size [width]="100" [height]="20" />
    </dx-bullet>
  </div>

  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="gridMonthlyGoalsCard"
  />
</dx-data-grid>
