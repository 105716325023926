<app-header
  class="layout-header"
  [menuToggleEnabled]="true"
  (menuToggle)="menuOpened = !menuOpened"
  [title]="appInfo.title"
  [imageSource]="appInfo.logo"
/>

<dx-drawer
  class="layout-body"
  position="before"
  [closeOnOutsideClick]="shaderEnabled"
  [openedStateMode]="menuMode"
  [revealMode]="menuRevealMode"
  [minSize]="minMenuSize"
  [shading]="shaderEnabled"
  [(opened)]="menuOpened"
>
  <side-navigation-menu
    [compactMode]="!menuOpened"
    [selectedItem]="selectedRoute"
    class="dx-swatch-additional"
    *dxTemplate="let dataMenu of 'panel'"
    (selectedItemChanged)="navigationChanged($event)"
    (openMenu)="navigationClick()"
  >
    <app-footer>
      Copyright © {{ appInfo.currentYear }} <br />
      Calysta
    </app-footer>
  </side-navigation-menu>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
</dx-drawer>
