import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
  ViewChild,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxTagBoxModule,
  DxTextBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import DataSource from 'devextreme/data/data_source';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { DxoFormatModule } from 'devextreme-angular/ui/nested';

@Component({
  selector: 'finance-charges-link-to-existing-invoice',
  templateUrl: './finance-charges-link-to-existing-invoice.component.html',
  styleUrls: ['./finance-charges-link-to-existing-invoice.component.scss'],
})
export class FinanceChargesLinkToExistingInvoiceComponent {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() charge: any;

  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<{
    selectedRowKey: any;
    handleDiff: boolean;
  }>();

  @Output() visibleChange = new EventEmitter<boolean>();

  cases: any;

  url = environment.CalystaApiBaseURL + 'api/';

  dataSource: any;

  selectedRowKey: number | undefined;

  showHandleDiff: boolean;

  invoiceNumberLabel: string = 'Invoice Number : ';

  constructor() {}

  handleVisible(e: boolean): void {
    this.visible = e;
    this.visibleChange.emit(e);

    this.clearData();
  }

  onLinkClick(): void {
    if (this.selectedRowKey === undefined) {
      alert('Please select a row to link');
      return;
    }

    const selectedRow = this.dataGrid.instance.getSelectedRowsData()[0];

    if (this.charge.NonVatAmount !== selectedRow.NonVatAmount) {
      this.showHandleDiff = true;
    } else {
      this.save.emit({
        selectedRowKey: this.selectedRowKey,
        handleDiff: false,
      });
    }
  }

  refreshData(): void {
    this.dataSource.reload();
  }

  onShow() {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ChargeId',
        loadUrl: `${this.url}invoice/charge`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method === 'load') {
            ajaxOptions.data = ajaxOptions.data || {};
            ajaxOptions.data.includeInvoiced = true;
          }
        },
      }),
      filter: [
        [
          ['WorkCodeCategoryId', '=', 7],
          'or',
          ['WorkCodeCategoryId', '=', 8],
          'or',
          ['WorkCodeCategoryId', '=', 9],
          'or',
          ['WorkCodeCategoryId', '=', 10],
          'or',
          ['WorkCodeCategoryId', '=', 1],
        ],
        'and',
        ['ClientId', '=', this.charge.ClientId],
        'and',
        ['InvoiceFinalized', '=', true],
        'and',
        ['CostLineId', '=', null],
        'and',
        ['ChargeId', '<>', this.charge.ChargeId],
      ],
    });

    this.invoiceNumberLabel =
      'Invoice Number : ' + this.charge.CostInvoiceNumber;

    this.selectedRowKey = undefined;
  }

  no = () => {
    this.save.emit({
      selectedRowKey: this.selectedRowKey,
      handleDiff: false,
    });

    this.showHandleDiff = false;
  };

  yes = () => {
    this.save.emit({
      selectedRowKey: this.selectedRowKey,
      handleDiff: true,
    });

    this.showHandleDiff = false;

    this.clearData();
  };

  cancel = () => {
    this.showHandleDiff = false;
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onSelectionChanged(e: SelectionChangedEvent) {
    const selectedRowKey = e.currentSelectedRowKeys[0];

    if (e.selectedRowKeys.length > 1) {
      e.component.deselectAll();

      e.component.selectRows([selectedRowKey], true);
    }

    this.selectedRowKey = selectedRowKey;
  }

  clearData = () => {
    this.dataGrid.instance.clearSelection();
    this.dataSource = null;
  };
}

@NgModule({
  declarations: [FinanceChargesLinkToExistingInvoiceComponent],
  exports: [FinanceChargesLinkToExistingInvoiceComponent],
  bootstrap: [FinanceChargesLinkToExistingInvoiceComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
    DxDataGridModule,
    DxCheckBoxModule,
    DxPopupModule,
    DxTextBoxModule,
    DxoFormatModule,
  ],
})
export class FinanceChargesLinkToExistingInvoiceModule {}
