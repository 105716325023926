import { Component, Input, NgModule, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { FinancialNumberKpiModule } from 'src/app/components/utils/financial-number-kpi/financial-number-kpi.component';
import { FinancialValutaKpiModule } from 'src/app/components/utils/financial-valuta-kpi/financial-valuta-kpi.component';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { TimeReportingService } from 'src/app/services/time-registration/reporting.service';
import { TeamKPI } from 'src/app/types/timeregistration/TeamKPI';

@Component({
  selector: 'app-kpi-toolbar',
  templateUrl: './kpi-toolbar.component.html',
  styleUrls: ['./kpi-toolbar.component.scss']
})
export class KpiToolbarComponent implements OnInit, OnChanges {

  @Input() TeamId: number;
  @Input() Year: number;

  Invoices: number = 0;
  Margin: number = 0;
  Sales: number = 0;
  MarginPerc: number = 0;

  loading: boolean = false;

  TeamKPIs: TeamKPI;

  constructor(private TimeReportingSvc: TimeReportingService) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.TeamId.currentValue !== changes.TeamId.previousValue){
      this.getTeamKPI();
    }
  }

  ngOnInit() {
    this.getTeamKPI();
  }

  getTeamKPI() {
    this.TimeReportingSvc.getKPI(this.TeamId, this.Year).subscribe((data) => {
      this.Invoices = data.Invoices;
      this.Margin = data.Margin;
      this.Sales = data.Sales;
      this.MarginPerc = data.MarginPercentage;
    });
  }
}

@NgModule({
  providers: [],
  exports: [KpiToolbarComponent],
  declarations: [KpiToolbarComponent],
  imports: [
    FinancialNumberKpiModule,
    FinancialValutaKpiModule,
  ],
})
export class KpiToolbarModule {}
