import {
  Component,
  NgModule,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';

import { ThemeSwitcherModule } from 'src/app/components/library/theme-switcher/theme-switcher.component';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxGalleryModule } from 'devextreme-angular';
import { AuthService, IUser } from 'src/app/services/auth/auth.service';
import { TimeRegistrationShortcutModule } from '../time-registration-shortcut/time-registration-shortcut.component';
import { WikiShortcutModule } from '../wiki-shortcut/wiki-shortcut.component';
import { NotificationsPanelModule } from '../notifications-panel/notifications-panel.component';
import { PatriciaShortcutModule } from '../patricia-shortcut/patricia-shortcut.component';
import { PortalShortcutModule } from '../portal-shortcut/portal-shortcut.component';

@Component({
  selector: 'app-header',
  templateUrl: 'app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title!: string;

  @Input()
  imageSource: string;

  user: IUser | null = { email: '' };

  userMenuItems = [
    {
      text: 'Logout',
      icon: 'runner',
      onClick: () => {
        this.authService.logOut();
      },
    },
  ];

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.getUser().then((e) => (this.user = e.data));
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  };
}

@NgModule({
  declarations: [AppHeaderComponent],
  exports: [AppHeaderComponent],
  imports: [
    CommonModule,
    DxButtonModule,
    DxToolbarModule,
    DxGalleryModule,
    ThemeSwitcherModule,
    UserPanelModule,
    TimeRegistrationShortcutModule,
    WikiShortcutModule,
    NotificationsPanelModule,
    PatriciaShortcutModule,
    PortalShortcutModule,
  ],
})
export class AppHeaderModule {}
