import { Component, Input, AfterViewInit, NgModule } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { CalystaAPIInvoiceService } from 'src/app/services/calysta-api/invoice.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { CommonModule } from '@angular/common';
import {
  DxDataGridModule,
  DxDropDownButtonModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components/library/titlebar/titlebar.component';

@Component({
  selector: 'finance-reporting-amadeus-overview-grid-details',
  templateUrl: `./finance-reporting-amadeus-overview-grid-details.component.html`,
  styleUrls: [
    `./finance-reporting-amadeus-overview-grid-details.component.scss`,
  ],
  providers: [CalystaAPIInvoiceService],
})
export class FinanceReportingAmadeusInvoiceOverviewDetailGridComponent
  implements AfterViewInit
{
  @Input() key: number;

  dataSource: DataSource;

  url: string;

  constructor(private invoiceService: CalystaAPIInvoiceService) {
    this.url = this.invoiceService.getUrl();
  }

  ngAfterViewInit() {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'RegistrationDetailId',
        loadUrl: `${this.url}/AmadeusInvoiceOverview/${this.key}/Details`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }
}
@NgModule({
  declarations: [FinanceReportingAmadeusInvoiceOverviewDetailGridComponent],
  exports: [FinanceReportingAmadeusInvoiceOverviewDetailGridComponent],
  providers: [],
  imports: [
    CommonModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxButtonModule,
    DxCheckBoxModule,
    TitleBarModule,
    DxScrollViewModule,
  ],
})
export class FinanceReportingAmadeusInvoiceOverviewDetailGridModule {}
