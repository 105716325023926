<dx-data-grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  class="grid"
  height="60vh"
  [dataSource]="dataSource"
  [showBorders]="true"
  [remoteOperations]="true"
  (onRowUpdating)="onRowUpdating($event)"
  [wordWrapEnabled]="true"
>
  <dxo-load-panel [enabled]="true" />
  <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
  <dxo-header-filter [visible]="true" />
  <dxo-filter-row [visible]="true" />
  <dxo-sorting mode="multiple" />
  <dxo-editing
    mode="popup"
    [allowAdding]="false"
    [allowUpdating]="false"
    [allowDeleting]="false"
  />

  <dxo-column-chooser [enabled]="true" />
  <dxo-search-panel [visible]="true" width="20vw" />

  <dxo-toolbar>
    <dxi-item
      location="after"
      widget="dxButton"
      locateInMenu="never"
      [options]="{
        text: '',
        icon: 'refresh',
        stylingMode: 'text',
        onClick: refreshData
      }"
    />
    <dxi-item location="after" name="columnChooserButton" locateInMenu="auto" />
    <dxi-item location="after" name="searchPanel" locateInMenu="never" />
  </dxo-toolbar>

  <dxi-column type="buttons">
    <dxi-button
      hint="See in patricia"
      icon="fas fa-horse"
      [onClick]="openPatricia"
    />
  </dxi-column>
  <dxi-column
    dataField="CaseNumber"
    caption="Case Number"
    [allowHeaderFiltering]="false"
  />
  <dxi-column dataField="Client" [allowHeaderFiltering]="false" />
  <dxi-column
    dataField="OpenedBy"
    caption="Opened By"
    [visible]="false"
    [allowHeaderFiltering]="false"
  />
  <dxi-column
    dataField="TeamLabel"
    caption="Team Label"
    [allowHeaderFiltering]="false"
  />
  <dxi-column
    dataField="ResponsiblePerson"
    caption="Responsible Person"
    [allowHeaderFiltering]="true"
  />
  <dxi-column
    dataField="InternalTermDate"
    caption="Internal Term Date"
    dataType="date"
    sortOrder="asc"
    sortIndex="0"
    [allowHeaderFiltering]="false"
  />
  <dxi-column
    dataField="InternalResponsibleLogin"
    caption="Internal Responsible Login"
    [visible]="false"
    [allowHeaderFiltering]="false"
  />
  <dxi-column
    dataField="InternalStatusText"
    caption="Internal Status Text"
    [allowHeaderFiltering]="false"
  />
  <dxi-column
    dataField="InternalActionText"
    caption="Internal Action Text"
    [allowHeaderFiltering]="false"
  />
  <dxi-column
    dataField="InternalEventSchemeId"
    caption="Internal Event Scheme Id"
    [visible]="false"
    [allowHeaderFiltering]="false"
  />
  <dxi-column
    dataField="EventNotes"
    caption="Event Notes"
    [visible]="false"
    [allowHeaderFiltering]="false"
  />

  <dxo-master-detail [enabled]="true" template="detail" />
  <div *dxTemplate="let task of 'detail'">
    <div class="master-detail-caption">
      {{ "Official Deadline:" }}
    </div>
    <app-deadline-details [deadline]="task.data" />
  </div>

  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="gridPatriciaEventsDeadlinesPersonal"
  />
</dx-data-grid>
