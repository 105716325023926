<dx-popup
  [(visible)]="visible"
  titleText="Time related"
  (onShowing)="onShow()"
  [showCloseButton]="true"
  (onHidden)="onHide()"
  height="70vh"
  width="70vw"
>
  <dx-scroll-view class="view-wrapper-scroll">
    <div class="view-wrapper">
      <div>
        <dx-data-grid
          [allowColumnResizing]="true"
          [allowColumnReordering]="true"
          id="gridContainer"
          [dataSource]="datasource"
          [showBorders]="true"
          [remoteOperations]="true"
          width="100%"
          height="40em"
          [showBorders]="true"
          [repaintChangesOnly]="true"
          [wordWrapEnabled]="true"
        >
          <dxo-load-panel [enabled]="true" />
          <dxo-sorting mode="multiple" />
          <dxo-scrolling mode="virtual" [showScrollbar]="true" />

          <dxi-column dataField="BillingType" caption="Billing Type">
            <dxo-lookup
              valueExpr="BillingTypeId"
              displayExpr="BillingTypeLabel"
              [dataSource]="billingTypes.store()"
            />
          </dxi-column>
          <dxi-column dataField="Description" />
          <dxi-column dataField="HourlyRate" />
          <dxi-column dataField="Time" dataType="time" />
          <dxi-column dataField="TotalAmount" />
        </dx-data-grid>
      </div>
    </div>
  </dx-scroll-view>
</dx-popup>
