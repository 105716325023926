<div>
  <dx-data-grid
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    id="gridContainer"
    [dataSource]="datasource"
    [showBorders]="true"
    [remoteOperations]="true"
    height="30vh"
    (onRowUpdating)="onRowUpdating($event)"
    [wordWrapEnabled]="true"
  >
    <dxo-toolbar>
      <dxi-item location="after" name="addRowButton" />
      <dxi-item
        location="after"
        widget="dxButton"
        locateInMenu="never"
        [options]="{
          text: '',
          icon: 'refresh',
          stylingMode: 'text',
          onClick: refreshData
        }"
      />
    </dxo-toolbar>

    <dxi-column dataField="Label" />

    <dxo-editing
      mode="popup"
      [allowUpdating]="true"
      [allowAdding]="true"
      [allowDeleting]="true"
    >
      <dxo-popup
        title="Response Type Option"
        [showTitle]="true"
        width="20vw"
        height="auto"
      >
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="saveButtonOptions"
        />
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="cancelButtonOptions"
        />
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colSpan]="2">
          <dxi-item
            dataField="Label"
            editorType="dxTextBox"
            [editorOptions]="{ validationMessageMode: 'always' }"
          >
            <dxi-validation-rule type="required" message="Label is required" />
          </dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxo-state-storing
      [enabled]="false"
      type="localStorage"
      storageKey="gridClientPortalInstructionResponseTypeDetails"
    />
  </dx-data-grid>
</div>
