import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import {
  DxScrollViewModule,
  DxDataGridModule,
  DxBulletModule,
  DxFunnelModule,
  DxPieChartModule,
  DxChartModule,
  DxSelectBoxModule,
  DxTabsModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import {
  RealizedTargetChartModule,
  MonthlyGoalsCardModule,
} from 'src/app/components';
import { CardAnalyticsModule } from 'src/app/components/library/card-analytics/card-analytics.component';
import { FinancialNumberKpiModule } from 'src/app/components/utils/financial-number-kpi/financial-number-kpi.component';
import { TimeAnalyticsPivotModule } from 'src/app/components/utils/time-analytics-pivot/time-analytics-pivot.component';
import { TimeEncodationTrackCardModule } from 'src/app/components/utils/time-encodation-track-card/time-encodation-track-card.component';
import { TimeFollowupCardModule } from 'src/app/components/utils/time-followup-card/time-followup-card.component';
import { TimeRealizedToTargetGraphModule } from 'src/app/components/utils/time-realized-to-target-graph/time-realized-to-target-graph.component';
import { ToolbarAnalyticsModule } from 'src/app/components/utils/toolbar-analytics/toolbar-analytics.component';
import { WipCardModule } from 'src/app/components/utils/wip-card/wip-card.component';
import { ApplyPipeModule } from 'src/app/pipes/apply.pipe';
import { AuthPermissionService } from 'src/app/services/auth/permission.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { TimeReportingService } from 'src/app/services/time-registration/reporting.service';
import { Employee } from 'src/app/types/employee/employee';
import { Dates, analyticsPanelItems } from 'src/app/types/resource';
import { environment } from 'src/environments/environment';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { ItemClickEvent } from 'devextreme/ui/tabs';
import { CasetypeReportingModule } from './casetype-reporting/casetype-reporting.component';
import { WipReportingModule } from './wip-reporting/wip-reporting.component';
import { ClientReportingModule } from './client-reporting/client-reporting.component';

@Component({
  selector: 'app-time-registration-reporting-personal',
  templateUrl: './time-registration-reporting-personal.component.html',
  styleUrls: ['./time-registration-reporting-personal.component.scss'],
})
export class TimeRegistrationReportingPersonalComponent implements OnInit {
  analyticsPanelItems = analyticsPanelItems;

  Employee: Employee = null;
  Employees: DataSource = null;

  currentStartDate: string = new Date(
    new Date().getFullYear(),
    0,
    1
  ).toISOString();
  currentEndDate: string = new Date(
    new Date().getFullYear(),
    11,
    31
  ).toISOString();

  //Statistics => Can be moved to the subcomponents later on.
  BillableHoursInPeriod: number = 0;
  BilledTimeInPeriod: number = 0;
  BilledTimeInEuroInPeriod: number = 0;
  BillableTimeToBeInvoicedInPeriod: number = 0;

  currentReportingTabId: number = 1;

  CurrentEmployeeId: number = null;

  Year: number = new Date().getFullYear();

  graphTabs: String[] = ['General', 'Client', 'CaseType'];

  constructor(
    private TimeReportingSvc: TimeReportingService,
    private EmployeeSvc: EmployeeService,
    public PermissionSvc: AuthPermissionService
  ) {}

  ngOnInit(): void {
    this.Employees = new DataSource({
      store: AspNetData.createStore({
        key: 'EmployeeId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Employee/Lookup`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method == 'load') {
            ajaxOptions.data.ActiveOnly = true;
          }
        },
      }),
      sort: ['FullName'],
    });

    this.EmployeeSvc.getEmployee().subscribe((data) => {
      if (data) {
        this.Employee = data;
        this.CurrentEmployeeId = data.EmployeeId;
      } else {
        this.EmployeeSvc.getMyself().subscribe((result: any) => {
          this.Employee = result;
          this.CurrentEmployeeId = result.EmployeeId;
        });
      }
    });

    this.loadStatistics();
  }

  onEmployeeChanged(e) {
    this.CurrentEmployeeId = e.value;
    this.EmployeeSvc.getEmployeeById(this.CurrentEmployeeId).subscribe(
      (data) => {
        this.Employee = data;
      }
    );
    this.loadStatistics();
  }

  onTabClick(e: ItemClickEvent) {
    this.currentReportingTabId = e.itemData.id;
  }

  selectionChange(Date: Dates) {
    this.currentStartDate = Date.startDate;
    this.currentEndDate = Date.endDate;
    this.loadStatistics();
  }

  loadStatistics() {
    this.getBilledTime();
    this.getEncodedTime();
    this.getBillableTimeToBeInvoiced();
  }

  getEncodedTime() {
    this.TimeReportingSvc.getBillableTimeInPeriod(
      this.CurrentEmployeeId,
      this.currentStartDate,
      this.currentEndDate
    ).subscribe((data) => {
      this.BillableHoursInPeriod = data.BillableHours;
    });
  }

  getBilledTime() {
    this.TimeReportingSvc.getBilledTimeInPeriod(
      this.CurrentEmployeeId,
      this.currentStartDate,
      this.currentEndDate
    ).subscribe((data) => {
      this.BilledTimeInPeriod = data.BilledTime;
      this.BilledTimeInEuroInPeriod = data.BilledTimeInEuro;
    });
  }

  getBillableTimeToBeInvoiced() {
    this.TimeReportingSvc.getBillableTimeToBeInvoicedInPeriod(
      this.CurrentEmployeeId,
      this.currentStartDate,
      this.currentEndDate
    ).subscribe((data) => {
      this.BillableTimeToBeInvoicedInPeriod = data.BillableTimeToBeInvoiced;
    });
  }
}

@NgModule({
  providers: [],
  exports: [],
  declarations: [TimeRegistrationReportingPersonalComponent],
  imports: [
    DxScrollViewModule,
    DxDataGridModule,
    DxBulletModule,
    DxFunnelModule,
    DxPieChartModule,
    DxChartModule,
    CardAnalyticsModule,
    ToolbarAnalyticsModule,
    ApplyPipeModule,
    FinancialNumberKpiModule,
    CommonModule,
    RealizedTargetChartModule,
    MonthlyGoalsCardModule,
    WipCardModule,
    TimeFollowupCardModule,
    TimeEncodationTrackCardModule,
    TimeAnalyticsPivotModule,
    TimeRealizedToTargetGraphModule,
    DxSelectBoxModule,
    DxTabsModule,
    CasetypeReportingModule,
    ClientReportingModule,
    WipReportingModule,
  ],
})
export class TimeRegistrationReportingPersonalModule {}
