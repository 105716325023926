<dx-scroll-view class="view-wrapper-scroll"
  ><div class="view-wrapper">
    <titlebar titleText="Bad Payers - PEBU" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      (onEditorPreparing)="onEditorPreparing($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      >
        <dxo-popup
          [showTitle]="true"
          [title]="'Bad Payer'"
          height="auto"
          width="30vw"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form>
          <dxi-item dataField="NameId" [colSpan]="2">
            <dxi-validation-rule
              type="required"
              message="Bad Payer is required"
            />
            <dxo-label text="Bad Payer" />
          </dxi-item>
          <dxi-item dataField="Remark" [colSpan]="2">
            <dxo-label text="Remark" />
          </dxi-item>
          <dxi-item dataField="RealAmount" [colSpan]="2">
            <dxo-label text="Budgeted amount" />
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="new remark"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="this.dataGrid.instance.addRow()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="NameId" caption="Legal name" alignment="center">
        <dxo-lookup
          [dataSource]="badpayers.store()"
          valueExpr="NameId"
          displayExpr="BadPayerName"
        />
      </dxi-column>
      <dxi-column dataField="RealAmount" caption="Real Amount" width="7%" />
      <dxi-column dataField="Remark" caption="Remark" alignment="center" />
      <dxi-column type="buttons">
        <dxi-button name="edit" />
        <dxi-button name="delete" />
      </dxi-column>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="badPayersPEBU"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
