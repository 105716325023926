<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  height="auto"
  width="20vw"
>
  <dx-form [(formData)]="templateType" validationGroup="validationGroup">
    <dxi-item itemType="group">
      <dxi-item
        dataField="BudgetSubCategoryLabel"
        editorType="dxTextBox"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxi-validation-rule
          type="required"
          message="Budget SubCategoryLabel is required"
        />
        <dxo-label text="Budget SubCategoryLabel" />
      </dxi-item>
      <dxi-item
        dataField="BudgetCategoryId"
        editorType="dxLookup"
        [editorOptions]="{
          dataSource: budgetCategories,
          displayExpr: 'BudgetCategoryLabel',
          valueExpr: 'BudgetCategoryId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule
          type="required"
          message="Budget Category is required"
        />
        <dxo-label text="Budget Category" />
      </dxi-item>
      <dxi-item
        dataField="ReportingLogo"
        editorType="dxTextBox"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxo-label text="Reporting Logo" />
      </dxi-item>
    </dxi-item>
  </dx-form>
</form-popup>
