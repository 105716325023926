<dx-scroll-view class="view-wrapper-scroll"
  ><div class="view-wrapper">
    <titlebar titleText="Amadeus Invoices Overview" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onExporting)="onExporting($event)"
      (onRowUpdating)="onRowUpdating($event)"
      (onSelectionChanged)="onSelectionChanged($event)"
      [wordWrapEnabled]="true"
      [rowAlternationEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-filter-row [visible]="true" applyFilter="auto" />
      <dxo-selection mode="multiple" />
      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export
        [enabled]="true"
        [fileName]="'Amadeus Invoices'"
        [allowExportSelectedData]="false"
      />

      <dxo-toolbar>
        <dxi-item
          location="before"
          locateInMenu="auto"
          widget="dxButton"
          [disabled]="hasSelectedRows"
          [options]="{
            text: 'Get Summary of Selected Rows',
            icon: 'fas fa-file-excel',
            stylingMode: 'text',
            onClick: getSummaryOfSelectedRows
          }"
        />

        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [disabled]="hasSelectedRows"
          [options]="{
            text: 'Clear Selection',
            icon: '',
            stylingMode: 'contained',
            onClick: clearSelection
          }"
        />
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="exportButton" locateInMenu="auto" />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        dataField="PurchaseOrder"
        caption="Purchase Order"
        width="7%"
        alignment="center"
      />
      <dxi-column
        dataField="AgentName"
        caption="Agent Name"
        alignment="center"
      />
      <dxi-column
        dataField="AgentInvoice"
        caption="Agent's Invoice #"
        alignment="center"
        width="6%"
      />
      <dxi-column
        dataField="DateOfInvoice"
        caption="Date of Invoice"
        dataType="date"
        alignment="center"
        width="7%"
        sortIndex="0"
        sortOrder="desc"
      />
      <dxi-column
        dataField="AmadeusRef"
        caption="Amadeus Ref"
        alignment="center"
        width="8%"
      />
      <dxi-column
        dataField="AgentRef"
        caption="Agent Ref"
        alignment="center"
        width="8%"
      />
      <dxi-column
        dataField="ServiceDate"
        caption="Service Date"
        dataType="date"
        width="7%"
        alignment="center"
      />
      <dxi-column
        dataField="DescriptionComments"
        caption="Description / Comment"
        alignment="center"
      />
      <dxi-column
        dataField="ChargingType"
        caption="Charging Type"
        alignment="center"
        width="7%"
      />
      <dxi-column
        dataField="WorkCodeType"
        caption="Work Code Type"
        alignment="center"
        width="7%"
      />
      <dxi-column
        dataField="Currency"
        caption="Currency"
        alignment="center"
        width="5%"
      />
      <dxi-column
        dataField="AmountExclVat"
        caption="Amount Excl Vat"
        width="6%"
      />

      <dxo-master-detail [enabled]="true" template="detail" />
      <div *dxTemplate="let details of 'detail'">
        <div>
          <div>Time Encoded</div>
          <finance-reporting-amadeus-overview-grid-details
            [key]="details.key"
          />
        </div>
      </div>

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridFinanceReportingAmadeusInvoiceOverview"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
