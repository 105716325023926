import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxLookupModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { ClientPortalService } from 'src/app/services/client-portal/client-portal.service';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';

@Component({
  selector: 'client-portal-instructions-mapping',
  templateUrl: './client-portal-instructions-mapping.component.html',
  styleUrls: ['./client-portal-instructions-mapping.component.scss'],
})
export class ClientPortalInstructionMappingComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  categories: any;

  status: any;

  actions: any;

  responseTypes: any;

  constructor(private clientPortalService: ClientPortalService) {
    this.url = this.clientPortalService.getUrl();

    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkFlowMappingId',
        loadUrl: `${this.url}/WorkFlowMapping`,
        insertUrl: `${this.url}/WorkFlowMapping`,
        updateUrl: `${this.url}/WorkFlowMapping`,
        deleteUrl: `${this.url}/WorkFlowMapping`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });

    this.responseTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'WfresponseTypeId',
        loadUrl: `${this.url}/WfResponseType/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'Label',
    });

    this.categories = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkFlowCategoryId',
        loadUrl: `${this.url}/WorkFlowCategory/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'WorkFlowCategoryDescription',
    });

    this.status = new DataSource({
      store: AspNetData.createStore({
        key: 'WfstatusTextId',
        loadUrl: `${this.url}/WfStatusText/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'StatusText',
    });

    this.actions = new DataSource({
      store: AspNetData.createStore({
        key: 'WfactionTextId',
        loadUrl: `${this.url}/WfActionText/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'ActionText',
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('InstructionsMapping');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'InstructionsMapping.xlsx'
        );
      });
    });
  }

  onClickRedirectToStatus(): void {
    //redirect to page named provider-list in another tab
    window.open('/#/client-portal-instructions-status-text', '_blank');
  }

  onClickRedirectToAction(): void {
    //redirect to page named provider-list in another tab
    window.open('/#/client-portal-instructions-action-text', '_blank');
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    DxLookupModule,
  ],
  declarations: [ClientPortalInstructionMappingComponent],
  exports: [],
})
export class ClientPortalInstructionMappingModule {}
