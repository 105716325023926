<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="New Quote" />
    <div class="separator"></div>
    <dx-tab-panel>
      <dxi-item title="1. Client/Prospect" icon="user">
        <fee-calculator-wizard-organization (stepCompleted)="organizationStepCompleted($event)" />
      </dxi-item>
      <dxi-item title="2. Services" icon="orderedlist" [disabled]="!organizationCompleted">
        <fee-calculator-wizard-services (stepCompleted)="serviceStepCompleted($event)" />
      </dxi-item>
      <dxi-item title="3. Overview" icon="datausage" [disabled]="!servicesCompleted">
        <!-- Content for Tab 3 -->
      </dxi-item>
    </dx-tab-panel>
  </div>
</dx-scroll-view>
