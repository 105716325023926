import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTabPanelModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { FeeCalculatorService } from 'src/app/services/fee-calculator/fee-calculator.service';
import { CaseService } from 'src/app/services/case/case.service';
import { FeeCalculatorWizardServicesSelectorModule } from "./fee-calculator-wizard-services-selector/fee-calculator-wizard-services-selector.component";

@Component({
  selector: 'fee-calculator-wizard-services',
  templateUrl: './fee-calculator-wizard-services.component.html',
  styleUrls: ['./fee-calculator-wizard-services.component.scss'],
})
export class FeeCalculatorWizardServicesComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Output() stepCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() QuoteId: number = 0;

  dataSource: DataSource;
  svcUrl: string;
  serviceTypesDataSource: DataSource;
  caseTypesDataSource: DataSource;
  showServicePopup: boolean = false;


  constructor(private FeeCalcSvc: FeeCalculatorService, private CaseSvc: CaseService) {
    this.svcUrl = FeeCalcSvc.getUrl();
  }

  /// The datasource should be loaded here for quotes that exist already, a new one should be created for new quotes.
  /// This will allow us to save quotes and edit them later on.
  ngOnInit() {
    this.stepCompleted.emit(true);
    this.initDataSource();
    this.initServiceTypes();
    this.initCaseTypes();
  }

  initDataSource(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.svcUrl}/quote`,
        loadParams: { QuoteId: this.QuoteId },
      }),
    });
  }

  initServiceTypes(): void {
     this.serviceTypesDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ServiceTypeId',
        loadUrl: `${this.svcUrl}/serviceType`,
      }),
    });
  }

  initCaseTypes(): void {
    this.caseTypesDataSource = new DataSource({
     store: AspNetData.createStore({
       key: 'ServiceTypeId',
       loadUrl: `${this.CaseSvc.getUrl()}/CaseType/Lookup`,
     }),
   });
 }
}

@NgModule({
  exports: [FeeCalculatorWizardServicesComponent],
  declarations: [FeeCalculatorWizardServicesComponent],
  providers: [],
  imports: [CommonModule, TitleBarModule, DxDataGridModule, DxButtonModule, FeeCalculatorWizardServicesSelectorModule],
})
export class FeeCalculatorWizardServicesModule {}
