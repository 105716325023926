<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  (onShow)="onShow()"
  height="auto"
  width="50vw"
>
  <dx-form [(formData)]="workcode">
    <dxi-item
      dataField="WorkCodeDescription"
      editorType="dxTextBox"
      [disabled]="true"
    >
      <dxo-label text="Work Code Description" />
    </dxi-item>
    <dxi-item dataField="WorkCodeDescriptionDutch" editorType="dxTextBox">
      <dxo-label text="Dutch" />
    </dxi-item>
    <dxi-item dataField="WorkCodeDescriptionEnglish" editorType="dxTextBox">
      <dxo-label text="English" />
    </dxi-item>
    <dxi-item dataField="WorkCodeDescriptionFrench" editorType="dxTextBox">
      <dxo-label text="French" />
    </dxi-item>
  </dx-form>
</form-popup>
