<div class="cards grey">
<financial-number-kpi
    [total]="Invoices"
    [title]="'Invoices'">
</financial-number-kpi>
<financial-valuta-kpi
[total]="Margin"
[title]="'Margin €'"
></financial-valuta-kpi>
<financial-valuta-kpi
[total]="Sales"
[title]="'Sales €'"
></financial-valuta-kpi>
<financial-number-kpi
[total]="MarginPerc"
[title]="'Margin %'"
></financial-number-kpi>
</div>
