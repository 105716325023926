<dx-chart [title]="Title" [dataSource]="ChartData">
  <dxi-series
    valueField="TargettedHours"
    name="Target"
    color="grey"
  ></dxi-series>
  <dxi-series
    valueField="BilledHours"
    name="Realized"
    color="orange"
  ></dxi-series>
  <dxo-common-series-settings
    #commonSeries
    argumentField="MonthName"
    [type]="'area'"
  >
    <dxo-point [visible]="true"></dxo-point>
  </dxo-common-series-settings>
  <dxo-margin [bottom]="20"></dxo-margin>
  <dxo-argument-axis [valueMarginsEnabled]="false"></dxo-argument-axis>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
  </dxo-legend>
  <dxo-tooltip [enabled]="true"></dxo-tooltip>
</dx-chart>
