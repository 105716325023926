<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  height="auto"
  width="30vw"
>
  <dx-form [(formData)]="responseType">
    <dxi-item itemType="group" caption="Patricia" [colCount]="2">
      <dxi-item
        [colSpan]="2"
        dataField="Label"
        editorType="dxTextBox"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxi-validation-rule type="required" message="Label is required" />
        <dxo-label text="Label" />
      </dxi-item>
      <dxi-item
        dataField="AllowDocumentUpload"
        editorType="dxCheckBox"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxo-label text="Allow Document Upload" />
      </dxi-item>
      <dxi-item
        dataField="AllowFreeText"
        editorType="dxCheckBox"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxo-label text="Allow Free Text" />
      </dxi-item>
    </dxi-item>
    <dxi-item
      itemType="group"
      caption="Response Options"
      [colCount]="2"
      [colSpan]="2"
    >
      <client-portal-instruction-response-type-details
        [entity]="responseType"
      />
    </dxi-item>
  </dx-form>
</form-popup>
