<header>
  <dx-toolbar class="header-toolbar">
    <dxi-item
      *ngIf="menuToggleEnabled"
      location="before"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu
      }"
    >
    </dxi-item>
    <dxi-item location="before" *ngIf="imageSource">
      <ng-container *dxTemplate>
        <img [src]="imageSource" alt="logo" />
      </ng-container>
    </dxi-item>

    <dxi-item location="after">
      <ng-container *dxTemplate>
        <portal-shortcut />
      </ng-container>
    </dxi-item>

    <dxi-item location="after">
      <ng-container *dxTemplate>
        <patricia-shortcut />
      </ng-container>
    </dxi-item>

    <dxi-item location="after">
      <ng-container *dxTemplate>
        <time-registration-shortcut />
      </ng-container>
    </dxi-item>

    <dxi-item location="after">
      <ng-container *dxTemplate>
        <wiki-shortcut />
      </ng-container>
    </dxi-item>

    <dxi-item location="after" locateInMenu="never">
      <ng-container *dxTemplate>
        <theme-switcher />
      </ng-container>
    </dxi-item>

    <dxi-item location="after" locateInMenu="auto">
      <ng-container *dxTemplate>
        <notifications-panel />
      </ng-container>
    </dxi-item>

    <dxi-item location="after" locateInMenu="auto" menuItemTemplate="menuItem">
      <div *dxTemplate="let data of 'item'">
        <user-panel
          [user]="user"
          [menuItems]="userMenuItems"
          menuMode="context"
        />
      </div>
    </dxi-item>
    <div *dxTemplate="let data of 'menuItem'">
      <user-panel [user]="user" [menuItems]="userMenuItems" menuMode="list" />
    </div>
  </dx-toolbar>
</header>
