<card-analytics
  [titleText]="title"
  [isLoading]="total === null"
  [contentClass]="contentClass"
>
  <div class="total">
    {{ total | currency : "EUR" : "€" : "1.0-0" }}
  </div>
  <!-- <div class="percentage" *ngIf="percentage !== 0">
    <i [class]="'dx-icon-' + (percentage > 0 ? 'spinup' : 'spindown')"></i>
    <span>{{ abs(percentage) }}%</span>
  </div> -->
</card-analytics>
