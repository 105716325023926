import { Component, ViewChild, NgModule, OnInit } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxDataGridComponent,
  DxDropDownButtonModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { ContactStatusModule, TitleBarModule } from 'src/app/components';
import { contactStatusList } from 'src/app/types/contact';
import { CommonModule } from '@angular/common';
import { CRMService } from 'src/app/services/prospection/crm.service';
import { CrmFieldStatusLookupModule } from 'src/app/components/lookups/crm-field-status-lookup/crm-field-status-lookup.component';
import { EmployeeNameLookupModule } from 'src/app/components/lookups/employee-name-lookup/employee-name-lookup.component';
import { FormPopupModule } from 'src/app/components';
import DataSource from 'devextreme/data/data_source';
import { CrmPropsectImportToPxModule } from 'src/app/components/crm/crm-prospect-import-to-px/crm-prospect-import-to-px.component';
import { CrmProspectEditModule } from 'src/app/components/crm/crm-prospect-edit/crm-prospect-edit.component';
import { CrmProspectNewModule } from 'src/app/components/crm/crm-prospect-new/crm-prospect-new.component';
import { CrmProspectTodoModule } from 'src/app/components/crm/crm-prospect-todo/crm-prospect-todo.component';
import notify from 'devextreme/ui/notify';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';

@Component({
  templateUrl: './crm-prospects.component.html',
  styleUrls: ['./crm-prospects.component.scss'],
})
export class CrmProspectsComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  statusList = contactStatusList;

  dataSource: any;

  url: string;

  employeeId: number;

  fieldStatusId: number;

  isAddPopupOpened: boolean = false;

  isEditPopupOpened: boolean = false;

  isTodoPopupOpened: boolean = false;

  isImportFormOpened: boolean = false;

  editedRow: any;

  importedRow: any;

  constructor(private crmService: CRMService) {
    this.url = this.crmService.getUrl();
  }

  ngOnInit(): void {
    this.loadDataSource();
  }

  loadDataSource = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ProspectId',
        loadUrl: `${this.url}/crm/prospect`,
        insertUrl: `${this.url}/crm/prospect`,
        deleteUrl: `${this.url}/crm/prospect`,
        updateUrl: `${this.url}/crm/prospect`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method === 'load') {
            if (this.employeeId) {
              ajaxOptions.data.Employee = this.employeeId;
            }
            if (this.fieldStatusId) {
              ajaxOptions.data.FieldStatus = this.fieldStatusId;
            }
          }
        },
      }),
    });
  };

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onEmployeeSelectionChanged = (e) => {
    this.employeeId = e;
    this.dataGrid.instance.getDataSource().reload();
  };

  onFieldStatusSelectionChanged = (e) => {
    this.fieldStatusId = e;
    this.dataGrid.instance.getDataSource().reload();
  };

  onSaveClickNew(e: any): void {
    this.dataSource
      .store()
      .insert(e.prospect)
      .done((data) => {
        if (e.contacts.length > 0) {
          this.crmService
            .insertProspectContact(e.contacts, data.ProspectId)
            .subscribe({
              next: (x) => {},
              error: (err) => {
                const errorMessage = err.error.Errors.GeneralErrors[0];
                notify(errorMessage, 'Error', 3000);
              },
            });
        }
        this.refreshData();
      });
  }

  onCloseAdd = (e: boolean) => {
    this.isAddPopupOpened = e;
  };

  addNew() {
    this.isAddPopupOpened = true;
  }

  onSaveClickEdit(e: any): void {
    this.dataSource.store().update(e.ProspectId, this.editedRow);

    var rowIndex = this.dataGrid.instance.getRowIndexByKey(e.ProspectId);
    var row = this.dataGrid.instance.getVisibleRows()[rowIndex].data;
    if (!row) return;
    Object.assign(row, this.editedRow);
    this.dataGrid.instance.repaintRows([rowIndex]);
  }

  onEdit = (e: any) => {
    this.editedRow = { ...e.row.data };
    this.isEditPopupOpened = true;
  };

  onCloseEdit = (e: boolean) => {
    this.isEditPopupOpened = e;
  };

  onImportClick = (e) => {
    this.importedRow = { ...e.row.data };

    this.isImportFormOpened = true;
  };

  onCloseImport = (e: boolean) => {
    this.isImportFormOpened = e;
  };

  onOpenTodo = (e: any) => {
    this.editedRow = { ...e.row.data };
    this.isTodoPopupOpened = true;
  };

  onCloseTodo = (e: boolean) => {
    this.isTodoPopupOpened = e;
  };

  importToPatricia = (e: any) => {
    const info = e;
    const prospectId = this.importedRow.ProspectId;

    this.crmService.importToPx(prospectId, info).subscribe({
      next: (x) => {},
      error: (err) => {
        const errorMessage = err.error.Errors.GeneralErrors[0];
        notify(errorMessage, 'Error', 3000);
      },
      complete: () => {
        this.refreshData();
      },
    });
  };

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Clients');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Prospects.xlsx'
        );
      });
    });
  }
}

@NgModule({
  imports: [
    DxButtonModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    FormPopupModule,
    ContactStatusModule,
    CommonModule,
    TitleBarModule,
    DxScrollViewModule,
    CrmFieldStatusLookupModule,
    EmployeeNameLookupModule,
    CrmProspectNewModule,
    CrmProspectEditModule,
    CrmPropsectImportToPxModule,
    CrmProspectTodoModule,
  ],
  providers: [],
  exports: [],
  declarations: [CrmProspectsComponent],
})
export class CrmProspectsModule {}
