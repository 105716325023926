import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VatService {
  roundToNearestFiveCents(value: number): number {
    const shiftedValue = value * 1000;
    const lastNumber = shiftedValue % 10;

    switch (lastNumber) {
      case 1:
      case 2:
      case 6:
      case 7:
        return (Math.floor(shiftedValue / 5) * 5) / 1000;
      case 3:
      case 4:
      case 8:
      case 9:
        return (Math.ceil(shiftedValue / 5) * 5) / 1000;
      default:
        return value;
    }
  }

  getVATPercentageList(): number[] {
    return [0, 6, 12, 21];
  }
}
