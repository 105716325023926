<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Pavis Instruction" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-selection
        [selectAllMode]="'allPages'"
        [showCheckBoxesMode]="'always'"
        mode="multiple"
      />
      <dxo-editing
        mode="popup"
        [allowAdding]="false"
        [allowUpdating]="false"
        [allowDeleting]="false"
      />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: 'Transfer requests to Pavis',
            icon: '',
            stylingMode: 'button',
            onClick: transferToPavis
          }"
        />
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxo-summary>
        <dxi-total-item column="CaseNumber" summaryType="count">
        </dxi-total-item>
      </dxo-summary>

      <dxi-column type="buttons">
        <dxi-button
          hint="See in patricia"
          icon="fas fa-horse"
          [onClick]="openPatricia"
        />
      </dxi-column>
      <dxi-column dataField="CaseNumber" caption="Case">
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column dataField="ServiceFee" caption="Service Fee">
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column dataField="OfficialFee" caption="Official Fee">
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column dataField="CalystaFee" caption="Calysta Fee">
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column dataField="TotalFee" caption="Total Fee">
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column dataField="InstructionLabel" caption="Instruction Label" />
      <dxi-column dataField="Name" caption="Client" />
      <dxi-column
        dataField="EventSchemeId"
        caption="Official DL."
        width="85px"
      />
      <dxi-column
        dataField="InternalEventSchemeId"
        caption="Internal DL."
        width="85px"
      />
      <dxi-column
        dataField="InstructedOn"
        caption="Instructed On"
        dataType="date"
      />
      <dxi-column
        dataField="InstructionFrom"
        caption="Instruction From"
        dataType="date"
      />
      <dxi-column
        dataField="InstructBefore"
        caption="Instruct Before"
        dataType="date"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridPavisInstruction"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
