<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Clients" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [remoteOperations]="true"
      [focusedRowEnabled]="false"
      [showBorders]="true"
      (onRowUpdating)="onRowUpdating($event)"
      (onExporting)="onExporting($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-editing
        mode="popup"
        [allowAdding]="false"
        [allowUpdating]="false"
        [allowDeleting]="false"
      />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export [enabled]="true" />
      <dxo-filter-row [visible]="true" />
      <dxo-header-filter [visible]="true" [allowSelectAll]="false" />

      <dxo-toolbar>
        <dxi-item location="before">
          <employee-name-lookup [valueChange]="onEmployeeSelectionChanged" />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="exportButton" locateInMenu="auto" />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        dataField="Name"
        caption="Name"
        sortOrder="asc"
        alignment="center"
        [allowHeaderFiltering]="false"
      />
      <dxi-column
        dataField="ResponsiblePerson"
        caption="Responsible Person"
        alignment="center"
        [allowHeaderFiltering]="false"
      />
      <dxi-column
        dataField="BecameProspectOn"
        caption="Became Prospect On"
        alignment="center"
        dataType="date"
        [allowHeaderFiltering]="false"
      />
      <dxi-column
        dataField="BecameClientOn"
        caption="Became Client On"
        alignment="center"
        dataType="date"
        [allowHeaderFiltering]="false"
      />
      <dxi-column
        dataField="Segmentation"
        caption="Segmentation"
        alignment="center"
        [allowHeaderFiltering]="false"
      />
      <dxi-column
        dataField="Origin"
        caption="Origin"
        alignment="center"
        [allowHeaderFiltering]="false"
      />
      <dxi-column
        dataField="Pme"
        caption="Pme"
        alignment="center"
        [allowHeaderFiltering]="false"
      />
      <dxi-column
        dataField="Pole"
        caption="Pole"
        alignment="center"
        [allowHeaderFiltering]="false"
      />
      <dxi-column dataField="RoleType" caption="Type" alignment="center" />
      <dxi-column
        dataField="Margin"
        caption="Margin this year"
        alignment="center"
      >
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column
        dataField="Sales"
        caption="Sales this year"
        alignment="center"
      >
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column
        dataField="PxNameId"
        caption="PxName Id"
        [visible]="false"
        [allowHeaderFiltering]="false"
      />
      <dxi-column type="buttons">
        <dxi-button icon="fa-regular fa-eye" [onClick]="openClientInPortal" />
        <dxi-button
          icon="fa-solid fa-basket-shopping"
          [onClick]="openIpAssets"
        />
      </dxi-column>

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />
      <dxo-master-detail [enabled]="true" template="detail" />
      <div *dxTemplate="let details of 'detail'">
        <div>
          <div>Contacts</div>
          <dx-data-grid
            [allowColumnResizing]="true"
            [allowColumnReordering]="true"
            [dataSource]="details.data.Contacts"
            (onRowUpdating)="onRowUpdating($event)"
            [wordWrapEnabled]="true"
          >
            <dxi-column
              dataField="FirstName"
              caption="First Name"
              alignment="center"
            />
            <dxi-column
              dataField="LastName"
              caption="Last Name"
              alignment="center"
            />
            <dxi-column
              dataField="Function"
              caption="Function"
              alignment="center"
            />
            <dxi-column dataField="Email" caption="Email" alignment="center" />
            <dxi-column dataField="Phone" caption="Phone" alignment="center" />
            <dxi-column
              dataField="MobilePhone"
              caption="Mobile Phone"
              alignment="center"
            />
            <dxi-column
              dataField="Language"
              caption="Language"
              alignment="center"
            />

            <dxo-state-storing
              [enabled]="false"
              type="localStorage"
              storageKey="gridCrmClients"
            />
          </dx-data-grid>
        </div>
      </div>
    </dx-data-grid>
    <crm-ip-assets
      [(visible)]="isViewIpAssetPopupOpened"
      [clientId]="selectClient?.PxNameId"
    />
  </div>
</dx-scroll-view>
