import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { DxButtonModule } from 'devextreme-angular';

@Component({
  selector: 'portal-shortcut',
  templateUrl: './portal-shortcut.component.html',
  styleUrls: ['./portal-shortcut.component.scss'],
})
export class PortalShortCutComponent {
  constructor() {}

  onButtonClick() {
    //open new tab with url of Patricia
    window.open('https://portal.calysta.eu/home', '_blank');
  }
}

@NgModule({
  imports: [CommonModule, DxButtonModule],
  declarations: [PortalShortCutComponent],
  exports: [PortalShortCutComponent],
})
export class PortalShortcutModule {}
