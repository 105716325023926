import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DxChartComponent, DxChartModule } from 'devextreme-angular';
import { TimeReportingService } from 'src/app/services/time-registration/reporting.service';
import { MarginReporting } from 'src/app/types/timeregistration/MarginReporting';

@Component({
  selector: 'margin-reporting',
  templateUrl: './margin-reporting.component.html',
  styleUrls: ['./margin-reporting.component.scss'],
})
export class MarginReportingComponent implements OnInit, OnChanges {
  @ViewChild('chart') chart: DxChartComponent;

  @Input() TeamId: number;
  @Input() Year: number;

  @Output() onMonthClick = new EventEmitter<any>();

  // Datasource for the chart.
  MarginReportingDataSource: MarginReporting[];

  constructor(private TimeReportingSvc: TimeReportingService) {
    this.onChartPointClick = this.onChartPointClick.bind(this);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.TeamId && this.Year) {
      this.loadChartData();
    }
  }

  ngOnInit() {
    if (this.TeamId && this.Year) {
      this.loadChartData();
    }
  }

  loadChartData() {
    this.chart.instance.showLoadingIndicator();
    this.TimeReportingSvc.getMarginReporting(this.TeamId, this.Year).subscribe({
      next: (data) => {
        this.MarginReportingDataSource = data;
      },
      error: (error) => {
        console.error(error);
      },
      complete: () => {
        this.chart.instance.hideLoadingIndicator();
      },
    });
  }

  onChartPointClick(e) {
    this.onMonthClick.emit(e.target.data.MonthNumber);
  }
}

@NgModule({
  providers: [],
  exports: [MarginReportingComponent],
  declarations: [MarginReportingComponent],
  imports: [DxChartModule],
})
export class MarginReportingModule {}
