<form-popup
  [(visible)]="visible"
  (save)="onLinkClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  (onShow)="onShow()"
  [closeOnSave]="false"
  saveText="link"
>
  <dx-form [(formData)]="charge">
    <dxi-item itemType="group" [colCount]="2" caption="Charge:">
      <dxi-item
        dataField="CostInvoiceNumber"
        editorType="dxTextBox"
        [editorOptions]="{ readOnly: true }"
        [colSpan]="1"
        caption="Invoice In:"
      />
      <dxi-item
        dataField="WorkCodeLabel"
        editorType="dxTextBox"
        [editorOptions]="{ readOnly: true }"
        [colSpan]="1"
        caption="WorkCode:"
      />
      <dxi-item
        dataField="NonVatAmount"
        editorType="dxNumberBox"
        [editorOptions]="{ readOnly: true }"
        [colSpan]="1"
        caption="Amount:"
      >
        <dxo-format type="currency" [precision]="2" />
      </dxi-item>
      <dxi-item
        dataField="Description"
        editorType="dxTextBox"
        [editorOptions]="{ readOnly: true }"
        [colSpan]="1"
        caption="Description"
      />
    </dxi-item>
    <dxi-item itemType="group" [colCount]="2" caption="Linkable charges:">
      <dx-data-grid
        [allowColumnResizing]="true"
        [allowColumnReordering]="true"
        class="grid"
        height="50vh"
        [dataSource]="dataSource"
        [showBorders]="true"
        [rowAlternationEnabled]="true"
        [remoteOperations]="true"
        (onRowUpdating)="onRowUpdating($event)"
        [wordWrapEnabled]="true"
        (onSelectionChanged)="onSelectionChanged($event)"
      >
        <dxo-load-panel [showPane]="true" />
        <dxo-scrolling mode="virtual" />
        <dxo-column-chooser [enabled]="true" />
        <dxo-search-panel
          [visible]="true"
          width="20vw"
          placeholder="Search..."
        />
        <dxo-selection
          mode="multiple"
          showCheckBoxesMode="always"
          [allowSelectAll]="false"
        />

        <dxo-toolbar>
          <dxi-item location="after">
            <div *dxTemplate>
              <dx-button icon="refresh" (onClick)="refreshData()" />
            </div>
          </dxi-item>
          <dxi-item location="after" name="columnChooserButton" />
          <dxi-item location="after" name="searchPanel" locateInMenu="never" />
        </dxo-toolbar>

        <dxi-column
          dataField="InvoiceNumber"
          caption="Invoice N°"
          sortOrder="asc"
        />
        <dxi-column dataField="CalystaReference" sortOrder="asc" caption="Case">
        </dxi-column>
        <dxi-column
          dataField="WorkCodeLabel"
          caption="Workcode"
          dataType="string"
        />
        <dxi-column
          dataField="CreatedOn"
          caption="Created On"
          dataType="date"
          [visible]="false"
        />
        <dxi-column
          dataField="CreatedByLabel"
          caption="Created By"
          [visible]="false"
        />
        <dxi-column
          dataField="Description"
          caption="Description"
          dataType="string"
        />
        <dxi-column
          dataField="Quantity"
          caption="Quantity"
          [visible]="false"
          dataType="number"
        >
        </dxi-column>
        <dxi-column
          dataField="UnitPrice"
          caption="Unit Price"
          dataType="number"
          [visible]="false"
        >
        </dxi-column>
        <dxi-column
          dataField="VATPercentage"
          caption="VAT %"
          dataType="number"
          [visible]="false"
        >
        </dxi-column>
        <dxi-column dataField="NonVatAmount" caption="Excl. VAT">
          <dxo-format type="currency" [precision]="2" />
        </dxi-column>
        <dxi-column dataField="VatAmount" caption="VAT" [visible]="false">
          <dxo-format type="currency" [precision]="2" />
        </dxi-column>
        <dxi-column dataField="AmountTot" caption="Total" [visible]="false">
          <dxo-format type="currency" [precision]="2" />
        </dxi-column>

        <dxo-state-storing
          [enabled]="false"
          type="localStorage"
          storageKey="gridFinanceChargeLinkToExistingInvoice"
        />
      </dx-data-grid>
    </dxi-item>
  </dx-form>
  <dx-popup
    #warningPopup
    [(visible)]="showHandleDiff"
    width="400"
    height="auto"
    title="Warning, charges have different amounts"
  >
    <p>
      There is a difference between the amount of the two charges, do you want
      to calculate the difference ?
    </p>
    <div class="warning-buttons">
      <dx-button text="Yes" (onClick)="yes()" />
      <dx-button text="No" (onClick)="no()" />
      <dx-button text="Cancel" (onClick)="cancel()" />
    </div>
  </dx-popup>
</form-popup>
