<dx-lookup
  placeholder="Select Team"
  [dataSource]="datasource"
  displayExpr="TeamLabel"
  valueExpr="TeamId"
  (onValueChanged)="valueChanged($event)"
  [searchEnabled]="true"
  [showClearButton]="true"
  width="auto"
  class="lookup"
  [value]="value ?? defaultValue"
  (onInitialized)="onInitialized($event)"
/>
