<titlebar
  titleText="Todo"
  [useButton]="true"
  iconButton="fa-solid fa-square-up-right"
  (onButtonClicked)="onClickRedirect()"
/>
<div class="separator"></div>
<dx-data-grid
  #grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  height="90%"
  [dataSource]="dataSource"
  [loadPanel]="{ enabled: true }"
  [remoteOperations]="true"
  (onRowUpdating)="onRowUpdating($event)"
>
  <dxo-scrolling mode="virtual" [showScrollbar]="'always'" />

  <dxo-sorting mode="multiple" />

  <dxo-toolbar>
    <dxi-item
      location="after"
      widget="dxButton"
      locateInMenu="never"
      [options]="{
        text: '',
        icon: 'refresh',
        stylingMode: 'text',
        onClick: refreshData
      }"
    />
    <dxi-item location="after" name="columnChooserButton" locateInMenu="auto" />
    <dxi-item location="after" name="searchPanel" locateInMenu="never" />
  </dxo-toolbar>

  <dxi-column dataField="ProspectName" caption="Prospect" />
  <dxi-column dataField="ActionName" caption="Action" />
  <dxi-column
    dataField="Deadline"
    caption="Deadline"
    dataType="date"
    sortOrder="desc"
  />
  <dxi-column dataField="Comments" caption="Comments" />
  <dxi-column
    dataField="FullName"
    caption="Person Responsible"
    [visible]="false"
  />
  <dxi-column type="buttons">
    <dxi-button icon="check" [onClick]="markAsDone" />
  </dxi-column>

  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="gridHomeTodoSection"
  />
</dx-data-grid>

<crm-todo-mark-as-done-form
  [(visible)]="isMarkAsDonePopupOpened"
  (save)="onTodoMarkAsDoneComplete($event)"
  [titleText]="'Mark Todo As Done'"
  (visibleChange)="onCloseMarkAsDone($event)"
  [todo]="todoToMarkAsDone"
/>
