import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { SilentRequest } from '@azure/msal-browser';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { switchMap, filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class CalystaAPIInterceptor implements HttpInterceptor {
  private msalTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  constructor(private msalService: MsalService) {
    this.initializeToken();

    //run checkTokenValidity every 5 minutes
    setInterval(() => {
      this.checkTokenValidity();
    }, 300000);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const apiUrl = environment.CalystaApiBaseURL;
    if (req.urlWithParams.startsWith(apiUrl)) {
      return from(this.ensureMsalToken()).pipe(
        switchMap((token) => {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });

          return next.handle(req);
        })
      );
    }
    return next.handle(req);
  }

  // Check when the token expires, and renew it when necessary.
  private checkTokenValidity() {
    // Get the token expiration time
    const expiration =
      this.msalService.instance.getActiveAccount()?.idTokenClaims?.exp;
    const now = new Date().getTime() / 1000;
    const timeToExpire = expiration - now;

    // Renew the token 5 minutes before it expires
    if (timeToExpire < 300) {
      this.initializeToken();
    }
  }

  private ensureMsalToken(): Promise<string | null> {
    return new Promise<string | null>((resolve) => {
      const cachedToken = this.msalTokenSubject.getValue();

      if (cachedToken !== null) {
        this.checkTokenValidity();
        resolve(cachedToken);
      } else {
        this.initializeToken();
        this.msalTokenSubject
          .pipe(
            filter((token) => token !== null),
            take(1)
          )
          .subscribe((token) => {
            resolve(token);
          });
      }
    });
  }

  private initializeToken(): void {
    let accountObj: any;
    if (this.msalService.instance.getActiveAccount()) {
      accountObj = this.msalService.instance.getActiveAccount();
    } else {
      accountObj = this.msalService.instance.getAllAccounts()[0];
    }

    if (accountObj) {
      this.msalService.instance.setActiveAccount(accountObj);
    } else {
      // ask user to log in via prompt popup
      this.msalService.loginPopup().subscribe((response) => {
        this.msalService.instance.setActiveAccount(response.account);
      });
    }

    let tokenRequest: SilentRequest = {
      //scopes: ['User.Read'],
      scopes: [
        'api://095477a9-2dfb-478b-8c6e-90be6704d6ad/API.Read',
        'api://095477a9-2dfb-478b-8c6e-90be6704d6ad/API.Write',
      ],

      account: this.msalService.instance.getActiveAccount(),
    };

    this.msalService.acquireTokenSilent(tokenRequest).subscribe(
      (tokenResponse) => {
        const token = tokenResponse.accessToken;
        this.msalTokenSubject.next(token);
      },
      (error) => {
        console.error(error);
        this.msalService.loginRedirect().subscribe(
          (response) => {
            this.msalService.loginRedirect();
          },
          (error) => {
            console.error(error);
            this.msalTokenSubject.next(null);
          }
        );
      }
    );
  }
}

/*
IT'S CAUSING INFINITE LOOP SOMEHOW, SO I COMMENTED IT OUT FOR NOW

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { SilentRequest } from '@azure/msal-browser';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { switchMap, filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class CalystaAPIInterceptor implements HttpInterceptor {
  private msalTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  private tokenCheckInterval: any;
  private tokenIsBeingRefreshed = false;

  constructor(private msalService: MsalService) {
    this.initializeToken();

    //run checkTokenValidity every 5 minutes
    setInterval(() => {
      this.checkTokenValidity();
    }, 300000);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const apiUrl = environment.CalystaApiBaseURL;
    if (req.urlWithParams.startsWith(apiUrl)) {
      return from(this.ensureMsalToken()).pipe(
        switchMap((token) => {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });

          return next.handle(req);
        })
      );
    }
    return next.handle(req);
  }

  // Check when the token expires, and renew it when necessary.
  private checkTokenValidity() {
    // Get the token expiration time
    const expiration =
      this.msalService.instance.getActiveAccount()?.idTokenClaims?.exp;
    const now = new Date().getTime() / 1000;

    if (!expiration) {
      return false;
    }

    const timeToExpire = expiration - now;

    // Renew the token 5 minutes before it expires
    if (timeToExpire < 300) {
      if (!this.tokenIsBeingRefreshed) {
        this.tokenIsBeingRefreshed = true;
        this.initializeToken();
        this.tokenIsBeingRefreshed = false;
      }
      return false;
    }

    this.setDynamicTokenCheck(timeToExpire);
    return true;
  }

  private setDynamicTokenCheck(timeToExpire: number) {
    if (this.tokenCheckInterval) {
      clearInterval(this.tokenCheckInterval);
    }

    const checkInterval = Math.max((timeToExpire - 300) * 1000, 0);
    this.tokenCheckInterval = setInterval(() => {
      this.checkTokenValidity();
    }, checkInterval);
  }

  private ensureMsalToken(): Promise<string | null> {
    return new Promise<string | null>((resolve, reject) => {
      const cachedToken = sessionStorage.getItem('msal_token');

      if (cachedToken !== null) {
        if (this.checkTokenValidity()) {
          resolve(cachedToken);
        } else {
          this.initializeTokenAndResolve(resolve, reject);
        }
      } else {
        this.initializeTokenAndResolve(resolve, reject);
      }
    });
  }

  private initializeTokenAndResolve(
    resolve: (token: string | null) => void,
    reject: (reason?: any) => void
  ) {
    this.initializeToken();
    this.msalTokenSubject
      .pipe(
        filter((token) => token !== null),
        take(1)
      )
      .subscribe({
        next: (token) => {
          resolve(token);
        },
        error: (error) => {
          console.error('Token initialization failed:', error);
          reject(error);
        },
      });
  }

  // Making a small cleanup to get rid of the authentication issues.
  private initializeToken(): void {
    let accountObj =
      this.msalService.instance.getActiveAccount() ||
      this.msalService.instance.getAllAccounts()[0];

    if (accountObj) {
      this.msalService.instance.setActiveAccount(accountObj);
    } else {
      this.msalService.loginPopup().subscribe(
        (response) => {
          this.msalService.instance.setActiveAccount(response.account);
          this.acquireToken();
        },
        (error) => {
          console.error('Error logging in', error);
          this.msalTokenSubject.next(null);
        }
      );
      return;
    }

    this.acquireToken();
  }

  // I have splitted the methods to make the error logging a bit more easy.
  // According to the docs the split makes it easier to resolve errors later on.
  private acquireToken(): void {
    const tokenRequest: SilentRequest = {
      scopes: [
        'api://095477a9-2dfb-478b-8c6e-90be6704d6ad/API.Read',
        'api://095477a9-2dfb-478b-8c6e-90be6704d6ad/API.Write',
      ],
      account: this.msalService.instance.getActiveAccount(),
    };

    this.msalService.acquireTokenSilent(tokenRequest).subscribe(
      (tokenResponse) => {
        const token = tokenResponse.accessToken;
        sessionStorage.setItem('msal_token', token);
        this.msalTokenSubject.next(token);
        this.checkTokenValidity();
      },
      (error) => {
        console.error('Error in the Silent Token Acquisition', error);
        this.msalService.loginRedirect().subscribe(
          () => {
            console.log('Redirection for token renewal');
          },
          (redirError) => {
            console.log('Login redirect error', redirError);
            this.msalTokenSubject.next(null);
          }
        );
      }
    );
  }
}
*/
