<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Cases Basic Info" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onExporting)="onExporting($event)"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export
        [enabled]="true"
        [fileName]="'Cases Basic Info'"
        [allowExportSelectedData]="false"
      />
      <dxo-editing
        mode="popup"
        [allowAdding]="false"
        [allowUpdating]="true"
        [allowDeleting]="false"
      >
        <dxo-popup title="Case" [showTitle]="true" width="35vw" height="auto">
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form>
          <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
            <dxi-item
              dataField="ConversionName"
              editorType="dxTextBox"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxi-validation-rule
                type="required"
                message="Conversion Name is required"
              />
              <dxo-label text="Conversion Name" />
            </dxi-item>
            <dxi-item
              dataField="Family"
              editorType="dxTextBox"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="Family" />
            </dxi-item>
            <dxi-item
              dataField="CaseType"
              editorType="dxLookup"
              [editorOptions]="{
                dataSource: caseTypes,
                displayExpr: 'CaseTypeLabel',
                valueExpr: 'CaseTypeCode',
                searchEnabled: true,
                validationMessageMode: 'always'
              }"
            >
              <dxi-validation-rule
                type="required"
                message="CaseType is required"
              />
              <dxo-label text="CaseType" />
            </dxi-item>
            <dxi-item
              dataField="Country"
              editorType="dxLookup"
              [editorOptions]="{
                dataSource: countries,
                displayExpr: 'Label',
                valueExpr: 'CountryId',
                searchEnabled: true,
                validationMessageMode: 'always'
              }"
            >
              <dxo-label text="Country" />
            </dxi-item>
            <dxi-item
              dataField="CatchWord"
              editorType="dxTextBox"
              [colSpan]="2"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="CatchWord" />
            </dxi-item>
            <dxi-item
              dataField="Status"
              editorType="dxTextBox"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxi-validation-rule
                type="required"
                message="Status is required"
              />
              <dxo-label text="Status" />
            </dxi-item>
            <dxi-item
              dataField="ApplicationType"
              editorType="dxLookup"
              [editorOptions]="{
                dataSource: applicationTypes,
                displayExpr: 'ApplicationTypeLabel',
                valueExpr: 'ApplicationTypeLabel',
                searchEnabled: true,
                validationMessageMode: 'always',
              }"
            >
              <dxi-validation-rule
                type="required"
                message="Application Type is required"
              />
              <dxo-label text="Application Type" />
            </dxi-item>
            <dxi-item
              dataField="ServiceLevel"
              editorType="dxLookup"
              [editorOptions]="{
                dataSource: serviceLevels,
                displayExpr: 'ServiceLevelLabel',
                valueExpr: 'ServiceLevelLabel',
                searchEnabled: true,
                validationMessageMode: 'always',
              }"
            >
              <dxi-validation-rule
                type="required"
                message="Service Level is required"
              />
              <dxo-label text="Service Level" />
            </dxi-item>
            <dxi-item
              dataField="Team"
              editorType="dxLookup"
              [editorOptions]="{
                dataSource: teams,
                displayExpr: 'TeamLabel',
                valueExpr: 'TeamLabel',
                searchEnabled: true,
                validationMessageMode: 'always'
              }"
            >
              <dxi-validation-rule type="required" message="Team is required" />
              <dxo-label text="Team" />
            </dxi-item>
            <dxi-item
              dataField="CaseResponsible"
              editorType="dxTextBox"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxi-validation-rule
                type="required"
                message="CaseResponsible is required"
              />
              <dxo-label text="CaseResponsible" />
            </dxi-item>
            <dxi-item
              dataField="PLTeam"
              editorType="dxLookup"
              [editorOptions]="{
                dataSource: plTeams,
                displayExpr: 'TeamLabel',
                valueExpr: 'TeamLabel',
                searchEnabled: true,
                validationMessageMode: 'always'
              }"
            >
              <dxo-label text="PLTeam" />
            </dxi-item>
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-toolbar>
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: 'Import from File',
            icon: 'exportxlsx',
            stylingMode: 'button',
            onClick: importFromFile
          }"
        />
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: 'Import into PX',
            icon: '',
            stylingMode: 'button',
            onClick: importToPx
          }"
        />
        <dxi-item location="before" name="exportButton" locateInMenu="never" />
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: 'Import from File',
            icon: 'exportxlsx',
            stylingMode: 'button',
            onClick: importFromFile
          }"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        dataField="ConversionName"
        caption="Conversion Name"
        alignment="center"
      />
      <dxi-column dataField="CaseBasicInfoId" caption="Id" [visible]="false" />
      <dxi-column dataField="Family" caption="Family" alignment="center" />
      <dxi-column
        dataField="CaseType"
        caption="Case Type"
        alignment="center"
        width="5%"
      />
      <dxi-column
        dataField="CatchWord"
        caption="Catch Word"
        alignment="center"
      />
      <dxi-column
        dataField="Country"
        caption="Country"
        alignment="center"
        width="5%"
      />
      <dxi-column dataField="Status" caption="Status" alignment="center" />
      <dxi-column
        dataField="ApplicationType"
        caption="Application Type"
        alignment="center"
      />
      <dxi-column
        dataField="ServiceLevel"
        caption="Service Level"
        alignment="center"
      />
      <dxi-column
        dataField="Team"
        caption="Team"
        alignment="center"
        width="8%"
      />
      <dxi-column
        dataField="CaseResponsible"
        caption="Case Responsible"
        alignment="center"
        width="6%"
      />
      <dxi-column
        dataField="PLTeam"
        caption="PLTeam"
        alignment="center"
        width="5%"
      />
      <dxi-column
        dataField="PxCaseNumber"
        caption="Case Number"
        alignment="center"
        width="10%"
      />
      <dxi-column dataField="PxCaseId" caption="PxCaseId" [visible]="false" />

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      >
      </dxo-pager>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridConversionCaseBasicInfo"
      />
    </dx-data-grid>
    <conversion-import-case-from-file
      [(visible)]="importFromFilePopupVisible"
      (save)="onImportFromFile($event)"
      [titleText]="'Import Cases From File'"
      (visibleChange)="onClosedImportFromFilePopup($event)"
      [width]="'20vw'"
      [height]="'40vh'"
    />
  </div>
</dx-scroll-view>
