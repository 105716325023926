<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  height="auto"
  width="50vw"
>
  <dx-form [(formData)]="subCategory">
    <dxi-item itemType="group">
      <dxi-item
        dataField="BudgetSubCategoryLabel"
        editorType="dxTextBox"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxi-validation-rule
          type="required"
          message="Budget SubCategoryLabel is required"
        />
        <dxo-label text="Budget SubCategoryLabel" />
      </dxi-item>
      <dxi-item
        dataField="BudgetCategoryId"
        editorType="dxLookup"
        [editorOptions]="{
          dataSource: budgetCategories,
          displayExpr: 'BudgetCategoryLabel',
          valueExpr: 'BudgetCategoryId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule
          type="required"
          message="Budget Category is required"
        />
        <dxo-label text="Budget Category" />
      </dxi-item>
      <dxi-item
        dataField="ReportingLogo"
        editorType="dxTextBox"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxo-label text="Reporting Logo" />
      </dxi-item>
      <dxi-item
        itemType="group"
        caption="Linked CostItems"
        [colCount]="2"
        [colSpan]="2"
      >
        <budget-sub-category-details [entity]="subCategory" />
      </dxi-item>
    </dxi-item>
  </dx-form>
</form-popup>
