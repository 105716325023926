import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule, DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { InvoiceDetailService } from 'src/app/services/invoicing/Invoice/InvoiceDetailService.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';

@Component({
  selector: 'finance-draft-invoice-details',
  templateUrl: './finance-draft-invoice-details.component.html',
  styleUrls: ['./finance-draft-invoice-details.component.scss'],
})
export class FinanceDraftInvoiceDetailsComponent implements OnInit, OnChanges {
  @Input() invoiceHeaderId: number;
  @Input() ReadOnly: boolean = true;

  InvoiceDetailsDataSource: DataSource;
  url: string;

  constructor(private InvoiceDetailSvc: InvoiceDetailService) {
    this.url = this.InvoiceDetailSvc.getUrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.invoiceHeaderId != null &&
      changes.invoiceHeaderId.previousValue !=
        changes.invoiceHeaderId.currentValue
    )
      this.loadDataSource();
  }

  ngOnInit() {
    this.loadDataSource();
  }

  loadDataSource() {
    this.InvoiceDetailsDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'InvoiceDetailId',
        loadUrl: `${this.url}/all`,
        updateUrl: `${this.url}`,
        loadParams: { invoiceHeaderId: this.invoiceHeaderId },
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };
}

@NgModule({
  declarations: [FinanceDraftInvoiceDetailsComponent],
  exports: [FinanceDraftInvoiceDetailsComponent],
  bootstrap: [FinanceDraftInvoiceDetailsComponent],
  imports: [BrowserModule, DxFormModule, DxDataGridModule],
})
export class FinanceDraftInvoiceDetailsModule {}
