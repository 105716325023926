import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxTagBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';

@Component({
  selector: 'popup-file-upload',
  templateUrl: './popup-file-upload.component.html',
  styleUrls: ['./popup-file-upload.component.scss'],
})
export class PopupFileUploadComponent {
  @Input() titleText = '';

  @Input() visible = false;

  @Input() height = '25vh';

  @Input() width = '25vw';

  @Input() accept = '*';

  @Input() label = '';

  @Output() onSave = new EventEmitter<any>();

  @Output() onClose = new EventEmitter<boolean>();

  document: any;

  constructor() {}

  onCloseClick(e: boolean): void {
    this.document = null;
    this.visible = e;
    this.onClose.emit(e);
  }

  onSaveClick(): void {
    this.onSave.emit(this.document);

    //wait 3 sec then empty document
    setTimeout(() => {
      this.document = null;
    }, 3000);
  }

  onFileChanged = (e) => {
    this.document = e.value[0];
  };
}

@NgModule({
  declarations: [PopupFileUploadComponent],
  exports: [PopupFileUploadComponent],
  bootstrap: [PopupFileUploadComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
  ],
})
export class PopupFileUploadModule {}
