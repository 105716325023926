import {
  Component,
  NgModule,
  Output,
  Input,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  inject,
  OnInit,
} from '@angular/core';
import {
  DxTreeViewModule,
  DxTreeViewComponent,
  DxTreeViewTypes,
} from 'devextreme-angular/ui/tree-view';
import * as events from 'devextreme/events';
import { AuthPermissionService } from 'src/app/services/auth/permission.service';
import { navigation } from '../../../app-navigation';
import { AppPermissions } from 'src/app/routes/permissions';

@Component({
  selector: 'side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss'],
})
export class SideNavigationMenuComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @ViewChild(DxTreeViewComponent, { static: true })
  menu!: DxTreeViewComponent;

  @Output()
  selectedItemChanged = new EventEmitter<DxTreeViewTypes.ItemClickEvent>();

  @Output()
  openMenu = new EventEmitter<any>();

  @Input()
  get compactMode() {
    return this._compactMode;
  }

  @Input()
  set selectedItem(value: String) {
    this._selectedItem = value;
    this.setSelectedItem();
  }

  get selectedItem(): String {
    return this._selectedItem;
  }

  set compactMode(val) {
    this._compactMode = val;

    if (!this.menu.instance) {
      return;
    }

    if (val) {
      this.menu.instance.collapseAll();
    } else {
      this.menu.instance.expandItem(this._selectedItem);
    }
  }

  private _selectedItem!: String;

  private _items!: Record<string, unknown>[];

  get items() {
    if (!this._items) {
      this._items = navigation
        .filter((item) => this.hasOneOfRequiredPermissions(item))
        .map((item) => {
          if (item.path && !/^\//.test(item.path)) {
            item.path = `/${item.path}`;
          }
          return { ...item, expanded: !this._compactMode };
        });
    }

    return this._items;
  }

  private _compactMode = false;

  constructor(
    private elementRef: ElementRef,
    private permissionsService: AuthPermissionService
  ) {}

  ngOnInit(): void {
    this.permissionsService.getPermissions().subscribe((permissions) => {
      this._items = this.filterItemsBasedOnPermissions(navigation);
    });
  }

  setSelectedItem() {
    if (!this.menu.instance) {
      return;
    }

    this.menu.instance.selectItem(this.selectedItem);
  }

  onItemClick(event: DxTreeViewTypes.ItemClickEvent) {
    this.selectedItemChanged.emit(event);
  }

  ngAfterViewInit() {
    this.setSelectedItem();
    events.on(this.elementRef.nativeElement, 'dxclick', (e: Event) => {
      this.openMenu.next(e);
    });
  }

  ngOnDestroy() {
    events.off(this.elementRef.nativeElement, 'dxclick');
  }

  private filterItemsBasedOnPermissions(items: any[]): any[] {
    return items
      .filter((item) => this.hasOneOfRequiredPermissions(item))
      .map((item) => {
        if (item.items) {
          item.items = this.filterItemsBasedOnPermissions(item.items);
        }
        return item;
      });
  }

  private hasOneOfRequiredPermissions(item: any): boolean {
    const requiredPermissions: any[] = item.requiredPermissions;
    if (
      !requiredPermissions ||
      requiredPermissions.length === 0 ||
      this.permissionsService.hasPermission(AppPermissions.ADMIN)
    ) {
      return true; // No required permissions specified or admin, show the item
    }

    return requiredPermissions.some((permission) =>
      this.permissionsService.hasPermission(permission)
    );
  }

  onItemExpanded(e: DxTreeViewTypes.ItemExpandedEvent) {
    const allNodes = this.flattenNodes(this.menu.instance.getNodes())
      .filter((node) => node.key !== e.node.key)
      .map((node) => {
        return {
          key: node.key,
          depthLevel: this.getDepthLevel(node),
        };
      });

    //get the level of depth of the node clicked (e)
    const depthLevel = this.getDepthLevel(e.node);

    allNodes.forEach((node) => {
      if (depthLevel == node.depthLevel) {
        e.component.collapseItem(node.key);
      }
    });
  }

  getDepthLevel(node: DxTreeViewTypes.Node<any>): number {
    let depthLevel = 0;
    while (node.parent) {
      depthLevel++;
      node = node.parent;
    }
    return depthLevel;
  }

  flattenNodes(
    nodes: DxTreeViewTypes.Node<any>[]
  ): DxTreeViewTypes.Node<any>[] {
    let result: DxTreeViewTypes.Node<any>[] = [];
    nodes.forEach((node) => {
      result.push(node);
      if (node.children) {
        result = result.concat(this.flattenNodes(node.children));
      }
    });
    return result;
  }
}

@NgModule({
  imports: [DxTreeViewModule],
  declarations: [SideNavigationMenuComponent],
  exports: [SideNavigationMenuComponent],
})
export class SideNavigationMenuModule {}
