import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  DxFormModule,
  DxLoadIndicatorModule,
  DxDataGridModule,
  DxSelectBoxModule,
} from 'devextreme-angular';
import { CalystaAPIDocketingService } from 'src/app/services/calysta-api/docketing.service';
import { GraphAPIMailService } from 'src/app/services/graphapi/graphapi-mail.service';
import { GraphMessage, attachment } from 'src/app/types/graph/message';

@Component({
  selector: 'app-docket-mail-form-attachments',
  templateUrl: './docket-mail-form-attachments.component.html',
  styleUrls: ['./docket-mail-form-attachments.component.scss'],
})
export class DocketMailFormAttachmentsComponent implements OnChanges {
  @Input() Mailbox: string;
  @Input() Mail: GraphMessage;
  @Input() categoryId: number;
  @Input() subCategoryId: number;

  categoryLabel: string;
  subCategoryLabel: string;

  categoryLookup: any;
  subCategoryLookup: any;

  attachmentsDataSource: attachment[];

  constructor(private GraphAPISvc: GraphAPIMailService) {
    this.getFilteredSubCategories = this.getFilteredSubCategories.bind(this);
  }

  /*ngOnInit(): void {
    this.CalyAPISvc.getCategories().subscribe(
      (res: any) => (this.categoryLookup = res.data),
    );
    this.CalyAPISvc.getSubCategories().subscribe(
      (res: any) => (this.subCategoryLookup = res.data),
    );
  }*/

  getFilteredSubCategories(options) {
    return {
      store: this.subCategoryLookup,
      filter: options.data
        ? ['categoryId', '=', options.data.categoryId]
        : null,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { email } = changes;

    if (changes.Mail) {
      const currentValue = changes.Mail.currentValue;
      const previousValue = changes.Mail.previousValue;
      if (
        currentValue &&
        previousValue &&
        currentValue.id != previousValue.id
      ) {
        this.GraphAPISvc.getMessageAttachments(
          this.Mailbox,
          this.Mail.id
        ).subscribe((res) => (this.attachmentsDataSource = res));
      }
    }

    if (changes.categoryId) {
      if (changes.categoryId.currentValue != changes.categoryId.previousValue) {
        this.attachmentsDataSource.forEach((element) => {
          element.categoryId = this.categoryId;
          element.subCategoryId = null;
        });
      }
    }

    if (changes.subCategoryId) {
      if (
        changes.subCategoryId.currentValue !=
        changes.subCategoryId.previousValue
      ) {
        this.attachmentsDataSource.forEach((element) => {
          element.categoryId = this.categoryId;
          element.subCategoryId = this.subCategoryId;
        });
      }
    }
  }

  rowUpdateEvent(e): void {
    if (e.newData['categoryId'] !== undefined) {
      this.categoryLabel = this.categoryLookup
        .filter((x) => x.categoryId == e.newData['categoryId'])
        .map((a) => a.label)[0];
      e.key['Value'] = this.categoryLabel.replace(/\d+([A-Z]{1})?_/g, '');
    }

    if (e.newData['subCategoryId'] !== undefined) {
      this.subCategoryLabel = this.subCategoryLookup
        .filter((x) => x.subCategoryId == e.newData['subCategoryId'])
        .map((a) => a.label)[0];
      e.key['Value'] =
        this.categoryLabel.replace(/\d+([A-Z]{1})?_/g, '') +
        '_' +
        this.subCategoryLabel.replace(/\d+([A-Z]{1})?_/g, '');
    }
  }

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule,
    DxDataGridModule,
    DxSelectBoxModule,
  ],
  declarations: [DocketMailFormAttachmentsComponent],
  exports: [DocketMailFormAttachmentsComponent],
})
export class DocketMailFormAttachmentsModule {}
