<div>
  <dx-data-grid
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    class="grid"
    height="80vh"
    [dataSource]="dataSource"
    [showBorders]="true"
    [remoteOperations]="true"
    (onRowUpdating)="onRowUpdating($event)"
    [wordWrapEnabled]="true"
  >
    <dxo-load-panel [enabled]="true" />
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
    <dxo-sorting mode="multiple" />
    <dxo-header-filter [visible]="false" />
    <dxo-editing
      mode="popup"
      [allowAdding]="true"
      [allowUpdating]="true"
      [allowDeleting]="true"
    />

    <dxo-column-chooser [enabled]="true" />
    <dxo-search-panel [visible]="true" width="20vw" />

    <dxo-toolbar>
      <dxi-item location="after" locateInMenu="never">
        <dx-button
          text="New Budget Sales"
          icon="plus"
          type="default"
          stylingMode="contained"
          (onClick)="addNew()"
        />
      </dxi-item>
      <dxi-item
        location="after"
        widget="dxButton"
        locateInMenu="never"
        [options]="{
          text: '',
          icon: 'refresh',
          stylingMode: 'text',
          onClick: refreshData
        }"
      />
      <dxi-item
        location="after"
        name="columnChooserButton"
        locateInMenu="auto"
      />
      <dxi-item location="after" name="searchPanel" locateInMenu="never" />
    </dxo-toolbar>

    <dxi-column
      dataField="Year"
      caption="Year"
      alignment="center"
      sortOrder="desc"
      [sortIndex]="2"
    />
    <dxi-column
      dataField="Month"
      caption="Month"
      alignment="center"
      sortOrder="desc"
      [sortIndex]="1"
    >
      <dxo-lookup
        [dataSource]="monthsMapping"
        valueExpr="key"
        displayExpr="value"
      />
    </dxi-column>
    <dxi-column dataField="Margin" caption="Margin Amount" alignment="center">
      <dxo-format type="currency" precision="2" />
    </dxi-column>
    <dxi-column dataField="Sales" caption="Sales Amount" alignment="center">
      <dxo-format type="currency" precision="2" />
    </dxi-column>
    <dxi-column
      dataField="MarginBonus"
      caption="Margin Bonus Amount"
      alignment="center"
    >
      <dxo-format type="currency" precision="2" />
    </dxi-column>
    <dxi-column
      dataField="SalesBonus"
      caption="Sales Bonus Amount"
      alignment="center"
    >
      <dxo-format type="currency" precision="2" />
    </dxi-column>
    <dxi-column type="buttons">
      <dxi-button icon="edit" [onClick]="onEdit" />
      <dxi-button name="delete" />
    </dxi-column>

    <dxo-master-detail [enabled]="true" template="detail" />
    <div *dxTemplate="let details of 'detail'">
      <div>
        <dx-data-grid
          [allowColumnResizing]="true"
          [allowColumnReordering]="true"
          [dataSource]="details.data.Contributors"
          title="Contributors"
          (onRowUpdating)="onRowUpdating($event)"
        >
          <dxi-column dataField="TeamId" caption="Team" alignment="center">
            <dxo-lookup
              [dataSource]="teams.store()"
              valueExpr="TeamId"
              displayExpr="TeamLabel"
            />
          </dxi-column>
          <dxi-column
            dataField="ContributionPercentage"
            caption="Contribution Percentage"
            alignment="center"
          >
            <dxo-format type="percent" precision="2" />
          </dxi-column>
        </dx-data-grid>
      </div>
    </div>

    <dxo-state-storing
      [enabled]="false"
      type="localStorage"
      storageKey="gridBugdetConfigurationFiguresSalesTarget"
    />
  </dx-data-grid>

  <budget-configuration-administration-figures-sales-target-new
    [(visible)]="isAddPopupOpened"
    (save)="onSaveClickNew($event)"
    [titleText]="'New Budget Sales'"
    (visibleChange)="onCloseAdd($event)"
  />
  <budget-configuration-administration-figures-sales-target-edit
    [(visible)]="isEditPopupOpened"
    (save)="onSaveClickEdit($event)"
    [titleText]="'Edit Budget Sales'"
    (visibleChange)="onCloseEdit($event)"
    [budgetSales]="editedRow"
  />
</div>
