import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class BankService {
  url = apiUrl + 'api/bank';
  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  importFromFile(formData: FormData) {
    return this.http.post<any>(
      `${this.url}/BankAccountTrack/UploadBankExport`,
      formData
    );
  }

  process() {
    return this.http.post<any>(`${this.url}/BankAccountTrack/Process`, {});
  }

  validate(id: number) {
    const body = {
      BankAccountValidationId: id,
    };

    return this.http.put<any>(
      `${this.url}/BankAccountValidation/ValidateInvoicePayment`,
      body
    );
  }
}
