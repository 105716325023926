import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { ClientPortalSegmentCaseSegmentEditModule } from 'src/app/components/client-portal/client-portal-segments-case-segment-edit/client-portal-segments-case-segment-edit.component';
import { ClientPortalSegmentCaseSegmentNewModule } from 'src/app/components/client-portal/client-portal-segments-case-segment-new/client-portal-segments-case-segment-new.component';
import { ClientPortalService } from 'src/app/services/client-portal/client-portal.service';

@Component({
  selector: 'client-portal-segments-case-segments',
  templateUrl: './client-portal-segments-case-segments.component.html',
  styleUrls: ['./client-portal-segments-case-segments.component.scss'],
})
export class ClientPortalCaseSegmentsComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  isAddPopupOpened: boolean = false;

  isEditPopupOpened: boolean = false;

  editedRow: any;

  constructor(private clientPortalService: ClientPortalService) {
    this.url = this.clientPortalService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'LinkId',
        loadUrl: `${this.url}/ClientCaseLink`,
        insertUrl: `${this.url}/ClientCaseLink`,
        updateUrl: `${this.url}/ClientCaseLink`,
        deleteUrl: `${this.url}/ClientCaseLink`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onSaveClickNew(e: any): void {
    this.dataSource.store().insert(e);
  }

  onSaveClickEdit(e: any): void {
    this.dataSource.store().update(e.LinkId, this.editedRow);

    var rowIndex = this.dataGrid.instance.getRowIndexByKey(e.LinkId);
    var row = this.dataGrid.instance.getVisibleRows()[rowIndex].data;
    if (!row) return;
    Object.assign(row, this.editedRow);
    this.dataGrid.instance.repaintRows([rowIndex]);
  }

  addNew() {
    this.isAddPopupOpened = true;
  }

  onEdit = (e: any) => {
    this.editedRow = { ...e.row.data };
    this.isEditPopupOpened = true;
  };

  onCloseAdd = (e: boolean) => {
    this.isAddPopupOpened = e;
  };

  onCloseEdit = (e: boolean) => {
    this.isEditPopupOpened = e;
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    ClientPortalSegmentCaseSegmentNewModule,
    ClientPortalSegmentCaseSegmentEditModule,
  ],
  declarations: [ClientPortalCaseSegmentsComponent],
  exports: [],
})
export class ClientPortalCaseSegmentsModule {}
