import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';
import { Statistics } from 'src/app/types/invoicing/reporting/Statistics';
import { BusinessStatistics } from 'src/app/types/invoicing/reporting/BusinessStatistics';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class SalesAnalyticsService {
  constructor(private http: HttpClient) {}

  public getBaseUrl = () => {
    return `${apiUrl}api/Invoice`;
  };

  public getInvoicesByPeriod = (StartDate: string, EndDate: string) => {
    {
      let params = new HttpParams()
        .set('StartDate', StartDate)
        .set('EndDate', EndDate);

      return this.http
        .get<any>(`${apiUrl}api/Invoice/InvoiceHeader/InvoicesInPeriod`, {
          params,
        })
        .pipe(map((data) => data.InvoiceCount));
    }
  };

  public getSalesByPeriod = (StartDate: string, EndDate: string) => {
    {
      let params = new HttpParams()
        .set('StartDate', StartDate)
        .set('EndDate', EndDate);

      return this.http
        .get<any>(`${apiUrl}api/Invoice/InvoiceHeader/SalesInPeriod`, {
          params,
        })
        .pipe(map((data) => data.Sales));
    }
  };

  public getMarginByPeriod = (StartDate: string, EndDate: string) => {
    {
      let params = new HttpParams()
        .set('StartDate', StartDate)
        .set('EndDate', EndDate);

      return this.http
        .get<any>(`${apiUrl}api/Invoice/InvoiceHeader/MarginInPeriod`, {
          params,
        })
        .pipe(map((data) => data.Margin));
    }
  };

  public getMarginToRealized = (StartDate: string, EndDate: string, SalesBudgetId : number = null) => {
    let params = new HttpParams()
      .set('StartDate', StartDate)
      .set('EndDate', EndDate)
    if(SalesBudgetId) {
      params = params.set('SalesBudgetId', SalesBudgetId.toString());
    }

    return this.http
      .get<any>(`${apiUrl}api/Invoice/SalesDashboardView/TotalStatistics`, {
        params,
      })
      .pipe<Statistics[]>(map((data) => data.Data));
  };

  public getTargetsByBusiness = (StartDate: string, EndDate: string) => {
    let params = new HttpParams()
      .set('StartDate', StartDate)
      .set('EndDate', EndDate);

    return this.http
      .get<any>(`${apiUrl}api/Invoice/SalesDashboardView/BusinessStatistics`, {
        params,
      })
      .pipe<BusinessStatistics[]>(map((data) => data.Data));
  };

  public getWIPMarginAmount = () => {
    return this.http.get<any>(`${apiUrl}api/Invoice/WIP/Amount`);
  };
}
