<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  height="auto"
  width="60vw"
>
  <dx-form [(formData)]="event">
    <dxi-item itemType="group" [colCount]="2">
      <dxi-item
        dataField="EventSchemeId"
        editorType="dxNumberBox"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxi-validation-rule
          type="required"
          message="Event Scheme Id is required"
        />
        <dxo-label text="Event Scheme Id" />
      </dxi-item>
      <dxi-item
        dataField="CaseTypeId"
        editorType="dxLookup"
        [editorOptions]="{
          dataSource: caseTypes,
          displayExpr: 'CaseTypeLabel',
          valueExpr: 'CaseTypeId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxo-label text="Case type" />
      </dxi-item>
      <dxi-item
        dataField="Sequence"
        editorType="dxNumberBox"
        [colSpan]="2"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxo-label text="Sequence" />
      </dxi-item>
      <dxi-item
        dataField="GraceDeadline"
        editorType="dxCheckBox"
        [editorOptions]="{
          text: 'Grace Deadline',
          validationMessageMode: 'always'
        }"
      >
        <dxo-label text="Grace Deadline" />
      </dxi-item>
    </dxi-item>

    <dxi-item itemType="group" caption="Details" [colCount]="2" [colSpan]="2">
      <event-configuration-details
        [entity]="event"
        [datasource]="detailDataSource"
      />
    </dxi-item>
  </dx-form>
</form-popup>
