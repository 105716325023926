import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  url = apiUrl + 'api/currency';
  constructor() {}

  getUrl() {
    return this.url;
  }
}
