import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular';

@Component({
  selector: 'wiki-shortcut',
  templateUrl: './wiki-shortcut.component.html',
  styleUrls: [],
})
export class WikiShortcutComponent {
  constructor() {}

  onButtonClick() {
    //open new tab with url of wiki
    window.open(
      'https://wiki.calysta.eu/login/f91f6830-7424-48a7-8231-8453cf1dc5c6/callback',
      '_blank'
    );
  }
}

@NgModule({
  imports: [CommonModule, DxButtonModule],
  declarations: [WikiShortcutComponent],
  exports: [WikiShortcutComponent],
})
export class WikiShortcutModule {}
