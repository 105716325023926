import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxPopupModule,
} from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { DataQualityService } from 'src/app/services/data-quality/data-quality.service';

@Component({
  selector: 'data-quality-check-validation',
  templateUrl: './data-quality-check-validation.component.html',
  styleUrls: ['./data-quality-check-validation.component.scss'],
})
export class DataQualityCheckValidationComponent {
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  @Input() criteria: string;

  @Input() selectedLines: any[];

  confirmationData: any;

  constructor(private dataQualityService: DataQualityService) {}

  onCancelButtonClick = () => {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  };

  onSaveButtonClick = () => {
    this.visible = false;
    this.visibleChange.emit(this.visible);

    let caseNumbers: string[] = [];

    if (this.selectedLines.length !== 0) {
      caseNumbers = this.selectedLines.map((x) => x.CaseNumber);
    }

    //get last part of url
    const webPageName = window.location.href.split('/').pop();
    const grid = webPageName + '_' + this.criteria;

    this.dataQualityService
      .checkData(
        caseNumbers,
        grid,
        this.confirmationData['Remarks'],
        this.confirmationData['Status']
      )
      .subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
        complete: () => {
          this.confirmationData = {};
        },
      });
  };
}

@NgModule({
  declarations: [DataQualityCheckValidationComponent],
  exports: [DataQualityCheckValidationComponent],
  bootstrap: [DataQualityCheckValidationComponent],
  imports: [BrowserModule, DxPopupModule, DxButtonModule, DxFormModule],
})
export class DataQualityCheckValidationModule {}
