import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class BudgetService {
  url = apiUrl + 'api/budget';
  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  getBudgetFollowUpAnalysis(periodStart: Date, periodEnd: Date) {
    var url = this.url + '/BudgetFollowUpAnalysis';

    if (periodStart && periodEnd) {
      url +=
        '?periodStart=' +
        periodStart.toISOString() +
        '&periodEnd=' +
        periodEnd.toISOString();
    }

    return this.http.get(url);
  }

  getBudgetBankAccount() {
    return this.http.get(this.url + '/BankAccount');
  }

  getBugdetTypes() {
    return this.http.get<any>(this.url + '/BudgetSales/Types');
  }

  postContributors(salesBudgetDetailsId: number, contributors: any) {
    return this.http.post(
      this.url + `/BudgetSales/Contributor/${salesBudgetDetailsId}`,
      {
        Contributors: contributors,
      }
    );
  }
}
