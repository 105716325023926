<dx-data-grid
  id="wipGrid"
  [dataSource]="wipDataSource"
  [showBorders]="true"
  [remoteOperations]="false"
  height="100%"
  title="Time to be invoiced"
>
  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
  <dxo-scrolling mode="virtual"></dxo-scrolling>
  <dxi-column
    dataField="ClientName"
    caption="Client"
    groupIndex="0"
    groupCellTemplate="clientTemplate"
  />
  <div *dxTemplate="let data of 'clientTemplate'">
    <div style="color: orangered">
      {{ data.displayValue }} (Total: {{ data.summaryItems[0].value }} Hours)
    </div>
  </div>
  <dxi-column dataField="CaseNumber" caption="Case" groupIndex="1" />
  <dxi-column dataField="Hours" caption="Hours to invoice" />

  <dxo-summary>
    <dxi-group-item
      column="Hours"
      summaryType="sum"
      displayFormat="Total: {0} hours"
    >
    </dxi-group-item>
  </dxo-summary>

  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="gridWipReporting"
  />
</dx-data-grid>
