<dx-data-grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [dataSource]="dataSource"
  [wordWrapEnabled]="true"
>
  <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
  <dxi-column
    dataField="InvoiceLineCategoryId"
    caption="Invoice Line Category Label"
  >
    <dxo-lookup
      [dataSource]="invoiceLineCategories.store()"
      valueExpr="InvoiceLineCategoryId"
      displayExpr="InvoiceLineCategoryLabel"
    />
  </dxi-column>
  <dxi-column dataField="CalystaReference" caption="Case Number" />
  <dxi-column dataField="CaseResponsible" caption="Case Responsible" />
  <dxi-column dataField="Description" caption="Description" />
  <dxi-column dataField="NonVatAmount" caption="Amount Non VAT">
    <dxo-format type="fixedPoint" [precision]="2" />
  </dxi-column>
  <dxi-column dataField="Vatrate" caption="VAT Rate" />
  <dxi-column dataField="VatAmount" caption="VAT Amount">
    <dxo-format type="fixedPoint" [precision]="2" />
  </dxi-column>
  <dxi-column dataField="Amount" caption="Total Amount">
    <dxo-format type="fixedPoint" [precision]="2" />
  </dxi-column>
  <dxi-column dataField="WorkCodeLabel" caption="Work Code" />

  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="gridFinanceInvoicesOutDetails"
  />
</dx-data-grid>
