import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { DxScrollViewModule, DxTabPanelModule } from 'devextreme-angular';
import { TitleBarModule } from '../../../components/library/titlebar/titlebar.component';
import { FeeCalculatorWizardOrganizationModule } from './fee-calculator-wizard-organization/fee-calculator-wizard-organization.component';
import { FeeCalculatorWizardServicesModule } from './fee-calculator-wizard-services/fee-calculator-wizard-services.component';

@Component({
  selector: 'fee-calculator-wizard',
  templateUrl: './fee-calculator-wizard.component.html',
  styleUrls: ['./fee-calculator-wizard.component.scss'],
})
export class FeeCalculatorWizardComponent implements OnInit {

  organizationCompleted: boolean = false;
  servicesCompleted: boolean = false;

  constructor() {}

  ngOnInit() {}

  organizationStepCompleted = (completed: boolean) => {
    this.organizationCompleted = completed;
  };

  serviceStepCompleted = (completed: boolean) => {
    this.servicesCompleted = completed;
  };
}

@NgModule({
  exports: [],
  declarations: [FeeCalculatorWizardComponent],
  providers: [],
  imports: [
    CommonModule,
    DxTabPanelModule,
    TitleBarModule,
    FeeCalculatorWizardOrganizationModule,
    FeeCalculatorWizardServicesModule,
    DxScrollViewModule,
  ],
})
export class FeeCalculatorWizardModule {}
