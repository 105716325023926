<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Fee Calculator" />
    <div class="separator"></div>
    <titlebar titleText="Ongoing Quotes" />
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [remoteOperations]="true"
      [focusedRowEnabled]="false"
      [showBorders]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-editing
        mode="popup"
        [allowAdding]="false"
        [allowUpdating]="false"
        [allowDeleting]="false"
      />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item location="before" name="searchPanel" locateInMenu="never" />
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="before"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="before">
          <dx-check-box text="Show All" [(value)]="showFilterRow" />
        </dxi-item>
        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="new quote"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="newQuote()"
          />
        </dxi-item>
      </dxo-toolbar>

      <dxi-column
      caption="Client"
      dataField="ClientName"
    ></dxi-column>
      <dxi-column
        caption="Created By"
        dataField="CreatedBy"
        [visible]="false"
        ></dxi-column>
      <dxi-column
        caption="Team"
        dataField="TeamId"
        calculateDisplayValue="TeamLabel"
        ></dxi-column>
      <dxi-column
        caption="Created On"
        dataField="CreatedOn"
        dataType="date"
        format="shortDate"
      ></dxi-column>
      <dxi-column
        caption="Status"
        dataField="QuoteStatusId"
        calculateDisplayValue="QuoteStatusLabel"
        ></dxi-column>
      <dxi-column
        caption="Total"
        dataField="TotalAmount"
        dataType="number"
        format="currency"
      ></dxi-column>

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
