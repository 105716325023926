import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDropDownButtonModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'home-patricia-deadlines-section',
  templateUrl: './home-patricia-deadlines-section.component.html',
  styleUrls: ['./home-patricia-deadlines-section.component.scss'],
})
export class HomePatriciaDeadlinesSectionComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() dataSource: any;

  @Input() pageSize?: number = 15;

  @Input() allowPager?: boolean = true;

  @Input() allowScrolling?: boolean = true;

  cases: any;

  url = environment.CalystaApiBaseURL + 'api/';

  ngOnInit(): void {
    this.cases = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${this.url}Patricia/CaseDetailedInfo/lookup`,
      }),
      sort: 'CaseNumber',
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onClickRedirect() {
    window.open('/#/patricia-events-deadlines');
  }

  openPatricia(e) {
    //open new tab on url http://patricia.calysta.eu/pat/#!case-view/ {data.CaseNumber}
    const caseNumber = e.row.data.CaseNumber;
    window.open(
      `http://patricia.calysta.eu/pat/#!case-view/${caseNumber}`,
      '_blank'
    );
    e.event.preventDefault();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
  ],
  exports: [HomePatriciaDeadlinesSectionComponent],
  declarations: [HomePatriciaDeadlinesSectionComponent],
  providers: [],
})
export class HomePatriciaDeadlinesSectionModule {}
