<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  [height]="height"
>
  <dx-form>
    <dxi-item itemType="group" [colCount]="3">
      <dxi-item
        dataField="Document"
        editorType="dxFileUploader"
        [editorOptions]="{
          validationMessageMode: 'always',
          uploadMode: 'useForm',
          onValueChanged: onFileChanged
        }"
      >
        <dxo-label text="Import Invoice" />
      </dxi-item>
    </dxi-item>
  </dx-form>
</form-popup>
