import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  InvolvedCaseReponse,
  InvolvedCaseRequest,
} from 'src/app/types/docketing/InvolvedCases';
import { FileNameRequest } from 'src/app/types/docketing/FileNameParameters';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class CalystaAPIDocketingService {
  url = apiUrl + 'api/docketing';

  constructor(private http: HttpClient) {}

  /*getCategories() {
    return this.http.get(this.url + '/category');
  }

  getSubCategories() {
    return this.http.get(this.url + '/subcategory');
  }*/

  getDocketFileName(
    CaseIds: number[],
    CategoryId: number,
    SubCategoryId: number,
    receiptDate: string
  ) {
    return this.http.get(`${this.url}Files/FileName`, {
      params: {
        CaseIds: JSON.stringify(CaseIds),
        CategoryId: CategoryId.toString(),
        SubCategoryId: SubCategoryId.toString(),
        ReceiptDate: receiptDate,
      },
    });
  }

  /*public getInvolvedCases = (Mailbox: string, MailId: string) => {
    return this.http
      .get<InvolvedCaseReponse>(`${this.url}Files/CheckInvolvedCases`, {
        params: {
          mailBox: Mailbox,
          mailId: MailId,
        },
      })
      .pipe(map((res) => res.data));
  };*/

  public docketFile = (file: any, docketingLines: any) => {
    var formData = new FormData();

    formData.append('File', file);
    formData.append('DocketingLines', JSON.stringify(docketingLines));

    return this.http.post(this.url + '/saveFiles', formData);
  };
}
