<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Deadlines" />
    <div class="separator"></div>

    <div class="date-range-container">
      <div class="date-range-title">Internal Date Range</div>
      <dx-date-range-box
        [width]="'325px'"
        [value]="dateRange"
        displayFormat="shortDate"
        (onValueChanged)="onDateChanged($event)"
        [showClearButton]="true"
      />
    </div>
    <dx-tab-panel
      class="dx-theme-background-color"
      width="100%"
      [animationEnabled]="true"
      [swipeEnabled]="true"
      [tabsPosition]="'top'"
      [items]="deadlineTabs"
    >
      <div *dxTemplate="let tabPanelItem of 'item'">
        <ng-container [ngSwitch]="tabPanelItem">
          <div *ngSwitchCase="deadlineTabs[0]">
            <patricia-events-deadlines-personal [dates]="dateRange" />
          </div>
          <div *ngSwitchCase="deadlineTabs[1]">
            <patricia-events-deadlines-team [dates]="dateRange" />
          </div>
        </ng-container>
      </div>
    </dx-tab-panel>
  </div>
</dx-scroll-view>
