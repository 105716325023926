<div>
  <div class="user-info">
    <div class="image-container" *ngIf="showAvatar">
      <div
        [style.backgroundImage]="'url(' + user?.avatarSource + ')'"
        class="user-image"
      ></div>
    </div>
    <div class="user-name">{{ user?.name }}</div>
  </div>
  <dx-list
    #userInfoList
    [items]="menuItems"
    [elementAttr]="{
      class: 'user-info-list'
    }"
    (onItemClick)="handleListItemClick($event)"
  ></dx-list>
</div>
