import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { ClientPortalInstructionsResponseTypeEditModule } from 'src/app/components/client-portal/client-portal-instructions-response-type-edit/client-portal-instructions-response-type-edit.component';
import { ClientPortalInstructionsResponseTypeNewModule } from 'src/app/components/client-portal/client-portal-instructions-response-type-new/client-portal-instructions-response-type-new.component';
import { ClientPortalService } from 'src/app/services/client-portal/client-portal.service';

@Component({
  selector: 'client-portal-instructions-response-type',
  templateUrl: './client-portal-instructions-response-type.component.html',
  styleUrls: ['./client-portal-instructions-response-type.component.scss'],
})
export class ClientPortalInstructionResponseTypeComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  isAddPopupOpened: boolean = false;

  isEditPopupOpened: boolean = false;

  editedRow: any;

  constructor(private clientPortalService: ClientPortalService) {
    this.url = this.clientPortalService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'WfResponseTypeId',
        loadUrl: `${this.url}/WfResponseType`,
        insertUrl: `${this.url}/WfResponseType`,
        updateUrl: `${this.url}/WfResponseType`,
        deleteUrl: `${this.url}/WfResponseType`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onSaveClickNew(e: any): void {
    this.dataSource.store().insert(e);
  }

  onSaveClickEdit(e: any): void {
    this.dataSource.store().update(e.WfResponseTypeId, this.editedRow);

    var rowIndex = this.dataGrid.instance.getRowIndexByKey(e.WfResponseTypeId);
    var row = this.dataGrid.instance.getVisibleRows()[rowIndex].data;
    if (!row) return;
    Object.assign(row, this.editedRow);
    this.dataGrid.instance.repaintRows([rowIndex]);
  }

  addNew() {
    this.isAddPopupOpened = true;
  }

  onEdit = (e: any) => {
    this.editedRow = { ...e.row.data };
    this.isEditPopupOpened = true;
  };

  onCloseAdd = (e: boolean) => {
    this.isAddPopupOpened = e;
  };

  onCloseEdit = (e: boolean) => {
    this.isEditPopupOpened = e;
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    ClientPortalInstructionsResponseTypeNewModule,
    ClientPortalInstructionsResponseTypeEditModule,
  ],
  declarations: [ClientPortalInstructionResponseTypeComponent],
  exports: [],
})
export class ClientPortalInstructionResponseTypeModule {}
