<dx-data-grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [dataSource]="DataSource"
  [remoteOperations]="false"
  id="gridContainer"
  class="grid"
  [wordWrapEnabled]="true"
  [height]="'70vh'"
  [showBorders]="true"
>
  <dxo-search-panel [visible]="true" width="20vw" placeholder="Search..." />

  <dxo-grouping #expand [autoExpandAll]="false" />
  <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
  <dxo-paging [pageSize]="100" />

  <dxi-column dataField="Year" caption="Year" />
  <dxi-column
    dataField="Month"
    caption="Month"
    dataType="date"
    [calculateCellValue]="getMonthLabel"
  />
  <dxi-column dataField="Margin" caption="Margin" dataType="currency" />
  <dxi-column dataField="Sales" caption="Sales" dataType="currency" />
  <dxi-column dataField="MarginBonus" caption="Margin Bonus" />
  <dxi-column
    dataField="SalesBonus"
    caption="Sales Bonus"
    dataType="currency"
  />
  <dxo-summary>
    <dxi-total-item column="MarginBonus" summaryType="sum" />
    <dxi-total-item column="SalesBonus" summaryType="sum" />
    <dxi-total-item column="Margin" summaryType="sum" />
    <dxi-total-item column="Sales" summaryType="sum" />
  </dxo-summary>

  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="gridBudgetReporting"
  />
</dx-data-grid>
