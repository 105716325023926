<div>
  <div
    *ngIf="
      getWorkCodeType() == 'TI' ||
      getWorkCodeType() == 'FF' ||
      getWorkCodeType() == 'SC'
    "
  >
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      [dataSource]="dataSource"
      [remoteOperations]="false"
      [wordWrapEnabled]="true"
    >
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxi-column dataField="EmployeeName" caption="Employee" />
      <dxi-column
        dataField="WorkedOn"
        caption="Worked On"
        dataType="date"
        format="dd/MM/yyyy"
      />
      <dxi-column dataField="WorkedTime" caption="Worked Time" />
      <dxi-column dataField="BillingType" caption="Billing Type" />
      <dxi-column dataField="HourlyRate" caption="Hourly Rate" />
      <dxi-column dataField="TotalAmount" caption="Total Amount">
        <dxo-format type="fixedPoint" [precision]="2" />
      </dxi-column>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridFinanceChargeDetails"
      />
    </dx-data-grid>
  </div>
  <div
    *ngIf="
      getWorkCodeType() == 'AG' ||
      getWorkCodeType() == 'AS' ||
      getWorkCodeType() == 'AO' ||
      getWorkCodeType() == 'OF'
    "
  >
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      [dataSource]="dataSource"
      [remoteOperations]="false"
      [wordWrapEnabled]="true"
    >
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxi-column dataField="InvoiceNumber" caption="Invoice Number" />
      <dxi-column
        dataField="InvoiceDate"
        caption="InvoiceDate"
        dataType="date"
        format="dd/MM/yyyy"
      />
      <dxi-column dataField="TotalAmount" caption="Total Amount">
        <dxo-format type="currency" [precision]="2" />
      </dxi-column>
      <dxi-column dataField="CurrencyCode" caption="Currency" />
      <dxi-column dataField="Provider" caption="Provider" />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="storage"
      />
    </dx-data-grid>
  </div>
</div>
