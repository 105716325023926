import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxLookupModule, DxSelectBoxModule } from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { ServiceLevelService } from 'src/app/services/service-level/service-level.service';

@Component({
  selector: 'service-level-lookup',
  templateUrl: './service-level-lookup.component.html',
  styleUrls: ['./service-level-lookup.component.scss'],
})
export class ServiceLevelLookupComponent implements OnInit {
  @Input() value = false;
  @Input() valueChange: (value: number) => void;

  datasource: any;

  url: string;

  constructor(private serviceLevelService: ServiceLevelService) {
    this.url = this.serviceLevelService.getUrl();
  }

  ngOnInit(): void {
    this.datasource = new DataSource({
      store: AspNetData.createStore({
        key: 'ServiceId',
        loadUrl: `${this.url}ServiceLevelLookup/Lookup`,
      }),
      sort: 'ServiceLevelLabel',
    });
  }

  valueChanged(e) {
    this.valueChange(e.value);
  }
}

@NgModule({
  imports: [BrowserModule, DxLookupModule, DxSelectBoxModule],
  declarations: [ServiceLevelLookupComponent],
  exports: [ServiceLevelLookupComponent],
  providers: [ServiceLevelLookupComponent],
})
export class ServiceLevelLookupModule {}
