<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Invoices In" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onExporting)="onExporting($event)"
      (onRowUpdating)="onRowUpdating($event)"
      (onRowUpdated)="onRowUpdated($event)"
      (onEditCanceled)="onEditCanceled($event)"
      (onInitNewRow)="onInitNewRow($event)"
      (onEditingStart)="onEditingStart($event)"
      (onRowInserted)="onRowInserted($event)"
      (onEditorPreparing)="onEditorPreparing($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-filter-row [visible]="true" />
      <dxo-editing mode="popup" [allowAdding]="true" [allowUpdating]="true">
        <dxo-popup
          title="Invoice In"
          [showTitle]="true"
          [onShown]="onShownEditPopup"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form [colCount]="3" [customizeItem]="customizeItem">
          <dxi-item
            itemType="group"
            [colCount]="1"
            [colSpan]="1"
            caption="Document"
          >
            <dxi-item dataField="Document" template="fileUploaderTemplate">
            </dxi-item>
            <dxi-item>
              <div id="pdf-preview">
                <pdf-preview
                  [previewExists]="documentLinked"
                  [BlobFile]="blobFile"
                />
              </div>
            </dxi-item>
          </dxi-item>
          <dxi-item
            itemType="group"
            [colCount]="2"
            [colSpan]="2"
            caption="Info"
          >
            <dxi-item dataField="BookingDate" />
            <dxi-item
              dataField="InvoiceDate"
              editorType="dxDateBox"
              [editorOptions]="{
                validationMessageMode: 'always',
                acceptCustomValue: false,
                openOnFieldClick: true
              }"
            />
            <dxi-item dataField="ToBePayedBefore" />
            <dxi-item dataField="CostStatusId" caption="Status">
              <dxo-label text="Status" />
            </dxi-item>
            <dxi-item dataField="ExternalInvoiceId" />
            <dxi-item dataField="EmployeeId" editorType="dxLookup">
              <dxo-label text="Employee" />
            </dxi-item>
            <dxi-item dataField="CaseId" editorType="dxLookup">
              <dxo-label text="Calysta Case" />
            </dxi-item>
            <dxi-item itemType="group" [colCount]="4">
              <dxi-item [colSpan]="3" dataField="ProviderId">
                <dxo-label text="Provider" />
              </dxi-item>
              <dxi-item
                itemType="button"
                [colSpan]="1"
                [buttonOptions]="{
                  icon: 'fas fa-arrow-up-right-from-square',
                  onClick: onClickRedirectToAction
                }"
              />
            </dxi-item>
            <dxi-item
              dataField="BudgetPeriodStart"
              editorType="dxDateBox"
              [editorOptions]="{
                type: 'date',
                dateSerializationFormat: 'yyyy-MM-dd',
                displayFormat: 'dd/MM/yyyy',
                validationMessageMode: 'always',
                acceptCustomValue: false,
                openOnFieldClick: true
              }"
            >
              <dxo-label text="Budget From" />
            </dxi-item>
            <dxi-item
              dataField="BudgetPeriodEnd"
              editorType="dxDateBox"
              [editorOptions]="{
                type: 'date',
                dateSerializationFormat: 'yyyy-MM-dd',
                displayFormat: 'dd/MM/yyyy',
                validationMessageMode: 'always',
                acceptCustomValue: false,
                openOnFieldClick: true
              }"
            >
              <dxo-label text="Budget Until" />
            </dxi-item>
            <dxi-item
              [colSpan]="2"
              dataField="CostItemId"
              editorType="dxLookup"
            />
            <dxi-item [colSpan]="2" dataField="Description" />
          </dxi-item>
          <dxi-item
            itemType="group"
            [colCount]="4"
            [colSpan]="3"
            caption="Financial"
          >
            <dxi-item
              dataField="IsCredit"
              editorType="dxCheckBox"
              [colSpan]="1"
            />
            <dxi-item
              dataField="CurrencyId"
              [colSpan]="1"
              editorType="dxLookup"
              [label]="{ text: 'Currency' }"
              [editorOptions]="{
                dataSource: currencies,
                displayExpr: 'CurrencyLabel',
                valueExpr: 'CurrencyId',
                searchEnabled: true,
                validationMessageMode: 'always'
              }"
            />
            <dxi-item
              dataField="NonVatAmount"
              [colSpan]="2"
              [editorOptions]="NonVatEditorOptions"
            />
            <dxi-item
              dataField="VatAmount"
              [colSpan]="2"
              [editorOptions]="VatEditorOptions"
            />
            <dxi-item
              dataField="Amount"
              [colSpan]="2"
              [editorOptions]="TotalEditorOptions"
            />
          </dxi-item>
          <dxi-item itemType="group" [colSpan]="3" caption="Invoice Lines">
            <dxi-item dataField="CostLines">
              <div *dxTemplate="let data of 'detailsTemplate'">
                <app-invoices-in-details
                  [CostHeaderId]="CostHeaderId"
                  (CostLineDetailOutput)="onDetailsChanged($event)"
                  [CostHeader]="CostHeader"
                />
              </div>
            </dxi-item>
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export
        [enabled]="true"
        [fileName]="'Invoices In'"
        [allowExportSelectedData]="false"
      />

      <div *dxTemplate="let data of 'fileUploaderTemplate'">
        <div id="fileuploader-container">
          <dx-file-uploader
            [multiple]="false"
            [accept]="'application/pdf'"
            validationMessageMode="always"
            [uploadMode]="'useForm'"
            (onValueChanged)="onFileChanged($event)"
          />
          <dxo-label text="Invoice Document" />
        </div>
      </div>

      <dxo-toolbar>
        <dxi-item location="before" locateInMenu="auto">
          <div class="date-range-container">
            <div class="date-range-title">Invoice Date Range</div>
            <dx-date-range-box
              title="Invoice Date"
              [startDate]="thisYear"
              [endDate]="now"
              (onValueChanged)="onDateChanged($event)"
              [showClearButton]="true"
              [width]="300"
              [max]="now"
            />
          </div>
        </dxi-item>
        <dxi-item location="before" locateInMenu="auto">
          <cost-status-lookup [valueChange]="onStatusChanged" />
        </dxi-item>
        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="new invoice"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="dataGrid.instance.addRow()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="exportButton" locateInMenu="auto" />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        dataField="CostHeaderId"
        caption="ID"
        [visible]="false"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="InvoiceId"
        caption="Invoice number"
        sortIndex="desc"
        sortOrder="1"
      />
      <dxi-column
        dataField="CostItemId"
        caption="Cost Item"
        [visible]="false"
        [allowSearch]="false"
        [setCellValue]="setCellValue"
      >
        <dxo-lookup
          valueExpr="CostItemId"
          displayExpr="Label"
          [dataSource]="costItemsDataSource.store()"
        />
      </dxi-column>
      <dxi-column
        dataField="Description"
        caption="Description"
        [visible]="false"
      />
      <dxi-column
        dataField="ExternalInvoiceId"
        caption="External Invoice number"
      >
        <dxi-validation-rule
          type="required"
          message="External Invoice number is required"
        />
      </dxi-column>
      <dxi-column
        dataField="InvoiceDate"
        caption="Invoice Date"
        dataType="date"
        [allowSearch]="false"
      >
        <dxi-validation-rule
          type="required"
          message="Invoice date is required"
        />
      </dxi-column>
      <dxi-column
        dataField="ToBePayedBefore"
        caption="Pay Before"
        dataType="date"
        alignment="center"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="CostStatusId"
        caption="Status"
        alignment="center"
        [allowSearch]="false"
      >
        <dxo-lookup
          valueExpr="CostStatusId"
          displayExpr="StatusLabel"
          [dataSource]="costStatusDataSource.store()"
        />
      </dxi-column>
      <dxi-column dataField="ProviderId" caption="Provider" alignement="center">
        <dxi-validation-rule type="required" message="Provider is required" />
        <dxo-lookup
          valueExpr="ProviderId"
          displayExpr="Name"
          [dataSource]="providersDataSource.store()"
        />
      </dxi-column>
      <dxi-column
        dataField="NonVatAmount"
        caption="excl.VAT"
        [allowSearch]="false"
      >
        <dxo-format type="fixedPoint" [precision]="2" />
      </dxi-column>
      <dxi-column dataField="VatAmount" caption="VAT" [allowSearch]="false">
        <dxo-format type="fixedPoint" [precision]="2" />
      </dxi-column>
      <dxi-column dataField="Amount" caption="Amount" [allowSearch]="false">
        <dxo-format type="fixedPoint" [precision]="2" />
      </dxi-column>
      <dxi-column
        dataField="AmountConv"
        caption="Amount (€)"
        [visible]="false"
        [allowSearch]="false"
      >
        <dxo-format type="currency" [precision]="2" />
      </dxi-column>
      <dxi-column
        dataField="NonVatAmountConv"
        caption="excl.VAT (€)"
        [visible]="false"
        [allowSearch]="false"
      >
        <dxo-format type="currency" [precision]="2" />
      </dxi-column>
      <dxi-column
        dataField="VatAmountConv"
        caption="VAT (€)"
        [visible]="false"
        [allowSearch]="false"
      >
        <dxo-format type="currency" [precision]="2" />
      </dxi-column>
      <dxi-column
        dataField="CurrencyId"
        calculateDisplayValue="CurrencyLabel"
        caption="Currency"
        [allowSearch]="false"
      >
        <dxi-validation-rule type="required" message="Currency is required" />
      </dxi-column>
      <dxi-column
        dataField="BookingDate"
        caption="Booking Date"
        dataType="date"
        [visible]="false"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="CreationDate"
        caption="Creation Date"
        dataType="date"
        [visible]="false"
        [allowSearch]="false"
        [sortIndex]="0"
        sortOrder="desc"
      />
      <dxi-column type="buttons">
        <dxi-button name="edit" />
        <dxi-button name="delete" />
        <dxi-button
          icon="fas fa-file-pdf"
          hint="Open the invoice document"
          [onClick]="openDocument"
          [visible]="hasDocument"
        />
      </dxi-column>

      <dxi-column
        dataField="CaseId"
        [visible]="false"
        [showInColumnChooser]="false"
        [allowSearch]="false"
        [setCellValue]="setCellValue"
      >
        <dxo-lookup
          valueExpr="CaseId"
          displayExpr="CaseDetailedDescription"
          [dataSource]="cases.store()"
        />
      </dxi-column>
      <dxi-column
        dataField="EmployeeId"
        caption="Employee"
        [visible]="false"
        [showInColumnChooser]="false"
        [allowSearch]="false"
        [setCellValue]="setCellValue"
      >
        <dxo-lookup
          valueExpr="EmployeeId"
          displayExpr="FullName"
          [dataSource]="employees.store()"
        />
      </dxi-column>
      <dxi-column
        dataField="BudgetPeriodStart"
        caption="Budget From"
        dataType="date"
        [visible]="false"
        [allowSearch]="false"
        [setCellValue]="setCellValue"
      />
      <dxi-column
        dataField="BudgetPeriodEnd"
        caption="Budget Until"
        dataType="date"
        [visible]="false"
        [allowSearch]="false"
        [setCellValue]="setCellValue"
      />

      <dxo-paging [pageSize]="50" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridFinanceInvoicesIn"
      />
    </dx-data-grid>
  </div>
  <dx-load-panel
    #loadPanel
    [(visible)]="documentLoadPaneVisible"
    [position]="{ of: '#pdf-preview' }"
    [showIndicator]="true"
    [showPane]="true"
  />
</dx-scroll-view>
