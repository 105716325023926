import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import { environment } from 'src/environments/environment';
import { DataQualityCheckValidationModule } from 'src/app/components/data-quality/data-quality-check-validation/data-quality-check-validation.component';
import { Column } from 'devextreme/ui/data_grid';
import { GridType } from 'src/app/types/data-quality/grid-type';
import { DataQualityGridModule } from 'src/app/components/data-quality/data-quality-grid/data-quality-grid.component';

@Component({
  selector: 'data-quality-px-names',
  templateUrl: './data-quality-px-names.component.html',
  styleUrls: ['./data-quality-px-names.component.scss'],
})
export class DataQualityPxNamesComponent {
  url: string;

  gridTypes: GridType[] = [
    {
      key: 'ClientAccountManager',
      value: 'Client Account Manager',
      description: 'Client responsible is missing',
    },
    {
      key: 'ClientVatNumber',
      value: 'Client Vat Number',
      description:
        ' Check that all clients that need to have VAT number have VAT number on name',
    },
    {
      key: 'DoubleNames',
      value: 'Double Names',
      description: 'Check for double names, one of them should be deleted',
    },
    {
      key: 'FieldOriginClientNames',
      value: 'Field Origin Client Names',
      description: 'Check if field origin for client names is filled in',
    },
  ];

  selectedGrid: GridType;

  columns: Column[] = [];

  constructor() {
    this.url = `${environment.CalystaApiBaseURL}api/DataQuality/PxNames`;
    this.selectedGrid = this.gridTypes[0];
  }

  changeData = (gridType: GridType) => {
    this.selectedGrid = gridType;

    const columns: Column[] = [
      {
        type: 'buttons',
        buttons: [
          {
            hint: 'Open Patricia',
            icon: 'fas fa-horse',
            onClick: this.openPatricia,
          },
        ],
        width: 'auto',
      },
      {
        dataField: 'NameId',
        caption: 'Name Id',
        alignment: 'center',
      },
      {
        dataField: 'Name',
        caption: 'Name',
        alignment: 'center',
      },
      {
        dataField: 'PatriciaLink',
        caption: 'Patricia Link',
        visible: false,
        showInColumnChooser: false,
      },
    ];

    //create columns depending on the selected grid
    switch (this.selectedGrid.key) {
      case 'ClientAccountManager':
        columns.push();
        break;
      case 'ClientVatNumber':
        columns.push();
        break;
      case 'DoubleNames':
        columns.push(
          {
            dataField: 'DoubleNames',
            caption: 'Double Names',
            alignment: 'center',
          },
          {
            dataField: 'VATNumber',
            caption: 'VAT',
            alignment: 'center',
          }
        );
        break;
      case 'FieldOriginClientNames':
        columns.push();
        break;
    }

    this.columns = columns;
  };

  openPatricia = (e) => {
    const patriciaLink = e.row.data.PatriciaLink;
    window.open(patriciaLink, '_blank');
    e.event.preventDefault();
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    DxPopupModule,
    DxFormModule,
    DataQualityGridModule,
  ],
  declarations: [DataQualityPxNamesComponent],
  exports: [],
})
export class DataQualityPxNamesModule {}
