import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxLookupModule,
  DxScrollViewModule,
  DxTabPanelModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import { BudgetSalesTargetGridModule } from 'src/app/components/budget/budget-sales-target-grid/budget-sales-target-grid.component';
import { BudgetService } from 'src/app/services/budget/budget.service';

@Component({
  selector: 'budget-configuration-figures-sales-target',
  templateUrl: './budget-configuration-figures-sales-target.component.html',
  styleUrls: ['./budget-configuration-figures-sales-target.component.scss'],
})
export class BudgetConfigurationFigureSalesTargetComponent implements OnInit {
  url: string;

  budgetTypes: any[];

  constructor(private budgetService: BudgetService) {
    this.url = this.budgetService.getUrl();
  }

  ngOnInit(): void {
    this.budgetService.getBugdetTypes().subscribe((data) => {
      this.budgetTypes = (data.data as any[]).map((x) => {
        return {
          id: x.Id,
          title: x.BudgetLabel,
        };
      });
    });
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    DxLookupModule,
    DxTabPanelModule,
    TitleBarModule,
    DxScrollViewModule,
    BudgetSalesTargetGridModule,
  ],
  declarations: [BudgetConfigurationFigureSalesTargetComponent],
  exports: [],
})
export class BudgetConfigurationFigureSalesTargetModule {}
