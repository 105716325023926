<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Prospect Actions" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      noDataText=""
      height="80vh"
      [dataSource]="dataSource"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      >
        <dxo-popup
          [showTitle]="true"
          [title]="'Prospect actions'"
          height="auto"
          width="30vw"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form>
          <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
            <dxi-item dataField="ActionId">
              <dxi-validation-rule
                type="required"
                message="Action is required"
              />
              <dxo-label text="Action" />
            </dxi-item>
            <dxi-item dataField="ActionEmployeeId">
              <dxo-label text="Responsible Person" />
            </dxi-item>
            <dxi-item
              dataField="Deadline"
              editorType="dxDateBox"
              [editorOptions]="{
                type: 'date',
                validationMessageMode: 'always',
                acceptCustomValue: false,
                openOnFieldClick: true
              }"
            >
              <dxi-validation-rule
                type="required"
                message="Deadline is required"
              />
              <dxo-label text="Deadline" />
            </dxi-item>
            <dxi-item
              dataField="Comments"
              editorType="dxTextBox"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="Comments" />
            </dxi-item>
            <dxi-item
              dataField="Done"
              editorType="dxCheckBox"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="Done" />
            </dxi-item>
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="Add Prospect Action"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="this.dataGrid.instance.addRow()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="ActionId" caption="Action" alignment="center">
        <dxo-lookup
          [dataSource]="actions.store()"
          displayExpr="Name"
          valueExpr="ActionId"
          searchEnabled="true"
          validationMessageMode="always"
        />
      </dxi-column>
      <dxi-column
        dataField="ActionEmployeeId"
        caption="Responsible Person"
        alignment="center"
      >
        <dxo-lookup
          [dataSource]="responsiblePersons.store()"
          displayExpr="FullName"
          valueExpr="EmployeeId"
          searchEnabled="true"
          validationMessageMode="always"
        />
      </dxi-column>
      <dxi-column
        dataField="Deadline"
        caption="Deadline"
        alignment="center"
        dataType="date"
        width="10%"
        sortOrder="desc"
        sortIndex="0"
      />
      <dxi-column dataField="Comments" caption="Comments" alignment="center" />

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridCrmProspectAction"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
