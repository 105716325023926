<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Logs" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onExporting)="onExporting($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="true" />
      <dxo-filter-row [visible]="true" />
      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export
        [enabled]="true"
        [fileName]="'Logs'"
        [allowExportSelectedData]="true"
      />

      <dxo-toolbar>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="exportButton" locateInMenu="auto" />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="Id" caption="Id" [visible]="false" />
      <dxi-column dataField="EmployeeId" caption="Employee" alignment="center">
        <dxo-lookup
          valueExpr="EmployeeId"
          displayExpr="FullName"
          [dataSource]="employees.store()"
        />
      </dxi-column>
      <dxi-column dataField="Criteria" caption="Criteria" alignment="center" />
      <dxi-column
        dataField="CaseNumber"
        caption="Case Number"
        alignment="center"
      />
      <dxi-column dataField="Status" caption="Status" alignment="center" />
      <dxi-column
        dataField="LogDate"
        caption="LogDate"
        alignment="center"
        dataType="date"
        sortIndex="0"
        sortOrder="desc"
      />
      <dxi-column dataField="Remarks" caption="Remarks" alignment="left" />
      <dxo-paging [pageSize]="14" />
      <dxo-pager
        [visible]="true"
        displayMode="adaptive"
        [showPageSizeSelector]="false"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
