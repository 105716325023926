import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: number): string {
    // Convert number to string and split it into parts before and after the decimal point
    const parts = value.toFixed(2).split('.');
    // Add commas for thousands separator
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    // Combine parts with comma as decimal separator
    return parts.join(',');
  }
}

@NgModule({
  imports: [],
  providers: [],
  exports: [CustomCurrencyPipe],
  declarations: [CustomCurrencyPipe],
})
export class CustomCurrencyPipeModule {}
