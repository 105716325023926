import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDateRangeBoxModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { RangeDatePickerModule } from 'src/app/components/utils/range-date-picker/range-date-picker.component';
import { TitleBarModule } from 'src/app/components';
import { RenewalService } from 'src/app/services/renewal/renewal.service';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'renewal-response-log',
  templateUrl: './renewal-response-log.component.html',
  styleUrls: ['./renewal-response-log.component.scss'],
})
export class RenewalPavisResponseLogComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  thisYear = new Date(new Date().getFullYear(), 0, 1);
  now = new Date();

  constructor(private renewalService: RenewalService) {
    this.url = this.renewalService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'PavisResponseLogId',
        loadUrl: `${this.url}/Pavis/PavisResponseLog`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };

          if (this.thisYear && this.now) {
            ajaxOptions.data.startDate = this.thisYear.toISOString();
            ajaxOptions.data.endDate = this.now.toISOString();
          }
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onDateChanged = (e: any): void => {
    this.thisYear = e.value[0];
    this.now = e.value[1];

    this.dataGrid.instance.getDataSource().reload();
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    RangeDatePickerModule,
    DxDateRangeBoxModule,
  ],
  declarations: [RenewalPavisResponseLogComponent],
  exports: [],
})
export class RenewalPavisResponseLogModule {}
