import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivateFn,
} from '@angular/router';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { AuthPermissionService } from '../services/auth/permission.service';
import { AppPermissions } from '../routes/permissions';

export const permissionsGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const permissionsService = inject(AuthPermissionService);

  // Get the required permissions for the route
  const requiredPermissions = route.data['requiredPermissions'];

  if (permissionsService.hasPermission(AppPermissions.ADMIN)) {
    return true;
  }

  if (requiredPermissions === undefined) {
    return false;
  }

  // Check if the user has one of the required permissions
  const hasRequiredPermissions = requiredPermissions.some((permission) =>
    permissionsService.hasPermission(permission)
  );

  // If the user doesn't have the required permissions, redirect them to an error page
  if (!hasRequiredPermissions) {
    return false;
  }

  // Otherwise, allow the route to be activated
  return true;
};
