import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import { environment } from 'src/environments/environment';
import { Column } from 'devextreme/ui/data_grid';
import { DataQualityGridModule } from 'src/app/components/data-quality/data-quality-grid/data-quality-grid.component';
import { GridType } from 'src/app/types/data-quality/grid-type';

@Component({
  selector: 'security-px-annuity',
  templateUrl: './security-px-annuity.component.html',
  styleUrls: ['./security-px-annuity.component.scss'],
})
export class SecurityPxAnnuityComponent {
  url: string;

  gridTypes: GridType[] = [
    {
      key: 'AnnuityCheckDate',
      value: 'Annuity Check Date',
      description: "Field 'Annuity Check Date' is empty",
    },
    {
      key: 'TermNotOnPebuOrAs',
      value: 'Term Not On Pebu Or As',
      description:
        'Show the all cases without one of the roles (Ann. Resp., Ann. Assis. or Ren. Resp.) linked to the LoginId (‘AS’, ‘PEBU’)',
    },
    {
      key: 'CasesWithoutAnnuityAddress',
      value: 'Cases Without Annuity Addresses',
      description: 'Cases Without Annuity Address',
    },
    {
      key: 'CasesWithoutAnnuityRenewalAgent',
      value: 'Cases Without Annuity Renewal Agent',
      description: 'Cases Without Annuity Renewal Agent',
    },
    {
      key: 'CasesWithoutNextRenewal',
      value: 'Cases Without Next Renewals',
      description: 'Cases Without Next Renewal',
    },
    {
      key: 'CasesWithoutStartDate',
      value: 'Cases Without Start Date',
      description: 'Cases without a start date',
    },
    {
      key: 'ClaimsFilledIn',
      value: 'Claims Filled In',
      description: 'Check if number of claims are filled in the diary field',
    },
    {
      key: 'DennemeyerAsAnnuityRenewalAgent',
      value: 'Dennemeyer As Annuity Renewal Agent',
      description:
        'Dennemeyer As Annuity Renewal Agent on Pat(BE,EP,PC) / TRM(BX,EU,WO) and DES (IM)',
    },
    {
      key: 'WorkFlows',
      value: 'Work Flows',
      description: 'Automatic cases with wrong work flows',
    },
  ];

  selectedGrid: GridType;

  columns: Column[] = [];

  constructor() {
    this.url = `${environment.CalystaApiBaseURL}api/Security/PxAnnuity`;
    this.selectedGrid = this.gridTypes[0];
  }

  changeData = (gridType: GridType) => {
    this.selectedGrid = gridType;

    const columns: Column[] = [
      {
        type: 'buttons',
        buttons: [
          {
            hint: 'Open Patricia',
            icon: 'fas fa-horse',
            onClick: this.openPatricia,
          },
        ],
        width: 'auto',
      },
      {
        dataField: 'CaseId',
        caption: 'Case ID',
        alignment: 'center',
      },
    ];

    //create columns depending on the selected grid
    switch (this.selectedGrid.key) {
      case 'AnnuityCheckDate':
        columns.push(
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'CaseType',
            caption: 'Case Type',
            alignment: 'center',
          },
          {
            dataField: 'Client',
            caption: 'Client',
            alignment: 'center',
          },
          {
            dataField: 'State',
            caption: 'Country',
            alignment: 'center',
          }
        );
        break;
      case 'TermNotOnPebuOrAs':
        columns.push({
          dataField: 'CaseNumber',
          caption: 'Case Number',
          alignment: 'center',
        });
        break;
      case 'CasesWithoutAnnuityAddress':
        columns.push({
          dataField: 'CaseNumber',
          caption: 'Case Number',
          alignment: 'center',
        });
        break;
      case 'CasesWithoutAnnuityRenewalAgent':
        columns.push(
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'Country',
            caption: 'Country',
            alignment: 'center',
          }
        );
        break;
      case 'CasesWithoutNextRenewal':
        columns.push(
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          }
        );
        break;
      case 'CasesWithoutStartDate':
        columns.push(
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'Status',
            caption: 'Status',
            alignment: 'center',
          }
        );
        break;
      case 'ClaimsFilledIn':
        columns.push(
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          }
        );
        break;
      case 'DennemeyerAsAnnuityRenewalAgent':
        columns.push(
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          },
          {
            dataField: 'Country',
            caption: 'Country',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          }
        );
        break;
      case 'WorkFlows':
        columns.push(
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          },
          {
            dataField: 'EventSchemeId',
            caption: 'Event Scheme Id',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          }
        );
        break;
    }

    this.columns = columns;
  };

  openPatricia = (e) => {
    const patriciaLink = e.row.data.PatriciaLink;
    window.open(patriciaLink, '_blank');
    e.event.preventDefault();
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    DxPopupModule,
    DxFormModule,
    DataQualityGridModule,
  ],
  declarations: [SecurityPxAnnuityComponent],
  exports: [],
})
export class SecurityPxAnnuityModule {}
