
<dx-tabs
#withText
[dataSource]="BudgetTabs"
[selectedIndex]="selectedBudget"
(onItemClick)="onTabClick($event)"
></dx-tabs>
        <dx-chart
          #chart
          [dataSource]="ChartData"
          (onPointClick)="onChartPointClick($event)"
          (onInitialized)="onChartInitialized($event)"
        >
          <dxi-series valueField="Budget" name="Target" color="grey"></dxi-series>
          <dxi-series
            valueField="Realized"
            name="Realized"
            color="orange"
          ></dxi-series>
          <dxo-common-series-settings
            #commonSeries
            argumentField="MonthLabel"
            [type]="'area'"
          >
            <dxo-point [visible]="true"></dxo-point>
          </dxo-common-series-settings>
          <dxo-margin [bottom]="20"></dxo-margin>
          <dxo-argument-axis [valueMarginsEnabled]="false"></dxo-argument-axis>
          <dxo-export [enabled]="true"></dxo-export>
          <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
          </dxo-legend>
          <dxo-tooltip [enabled]="true" [format]="'currency'"></dxo-tooltip>
        </dx-chart>
