import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class CostService {
  url = apiUrl + 'api/costs';
  constructor(private http: HttpClient) {}

  public getCostStatus(): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/CostStatus/Lookup`);
  }

  getUrl() {
    return this.url;
  }

  insertCostLines(costLines: any[], costHeaderId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = new HttpParams({
      fromObject: {
        Values: JSON.stringify(costLines),
        CostHeaderId: costHeaderId,
      },
    });

    return this.http.post<any>(`${this.url}/CostLine`, body, {
      headers: headers,
    });
  }

  uploadInvoiceDocument(formData: FormData, costHeaderId: number) {
    return this.http.post<any>(
      `${this.url}/CostHeader/${costHeaderId}/ImportDocument`,
      formData
    );
  }

  downloadInvoiceDocument(costHeaderId: number) {
    return this.http.get(
      `${this.url}/CostHeader/${costHeaderId}/DownloadDocument`,
      {
        responseType: 'blob',
      }
    );
  }

  getCostHeader(id: number): Observable<any> {
    return this.http.get<any>(`${this.url}/CostHeader/Charge/${id}`);
  }

  updateCostHeader(costheaderId: number, costHeader: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = new HttpParams({
      fromObject: {
        Values: JSON.stringify(costHeader),
        Key: costheaderId.toString(),
      },
    });

    return this.http.put<any>(`${this.url}/CostHeader`, body, {
      headers: headers,
    });
  }
}
