import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class EpoService {
  url = apiUrl + 'api/EPO';
  constructor(private http: HttpClient) {}

  public getFolders(): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/Folders`);
  }

  download(id: number) {
    return this.http.get(`${this.url}/Communications/Download?id=${id}`, {
      responseType: 'blob',
    });
  }

  public markAsHandled(entities: any[]) {
    return this.http.post(`${this.url}/Communications/Handle`, entities);
  }

  getUrl() {
    return this.url;
  }
}
