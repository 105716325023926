import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownBoxModule,
  DxDropDownButtonModule,
  DxLookupModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { CaseTypeLookupModule } from 'src/app/components/lookups/case-type-lookup/case-type-lookup.component';
import { CountryLookupModule } from 'src/app/components/lookups/country-lookup/country-lookup.component';
import { TitleBarModule } from 'src/app/components';
import { CalystaAPIInvoiceService } from 'src/app/services/calysta-api/invoice.service';
import DataSource from 'devextreme/data/data_source';
import { AuthPermissionService } from 'src/app/services/auth/permission.service';
import { PatriciaService } from 'src/app/services/patricia/patricia.service';
import { ServiceLevelLookupModule } from 'src/app/components/lookups/service-level-lookup/service-level-lookup.component';
import { ValueChangedEvent } from 'devextreme/ui/check_box';
import { environment } from 'src/environments/environment';
import { AppPermissions } from 'src/app/routes/permissions';

@Component({
  selector: 'finance-configuration-pricelist',
  templateUrl: './finance-configuration-pricelist.component.html',
  styleUrls: ['./finance-configuration-pricelist.component.scss'],
})
export class FinanceConfigurationPricelistComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  caseTypes: any;
  countries: any;
  servicelevels: any;
  workCodes: any;
  agents: any;
  clients: any;
  applicationTypes: any;
  serviceLevels: any;
  currencies: any;

  url: string;

  caseTypeId: number;

  countryId: string;

  serviceLevelId: string;

  isPricesMaintainer =
    this.authPermissionService.hasPermission(
      AppPermissions.FINANCIAL_PRICEMAINTAINER
    ) || this.authPermissionService.hasPermission(AppPermissions.ADMIN);

  httpClient: any;

  isGrouped: boolean = true;

  showExpiredOne: boolean = false;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  constructor(
    private invoiceService: CalystaAPIInvoiceService,
    private authPermissionService: AuthPermissionService,
    private patriciaService: PatriciaService
  ) {
    this.url = this.invoiceService.getUrl();

    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkCodeTarifId',
        loadUrl: `${this.url}/workcodetarif`,
        insertUrl: `${this.url}/workcodetarif`,
        updateUrl: `${this.url}/workcodetarif`,
        deleteUrl: `${this.url}/workcodetarif`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };

          if (method == 'load') {
            if (this.caseTypeId) {
              ajaxOptions.data.caseTypeId = this.caseTypeId;
            }

            if (this.countryId) {
              ajaxOptions.data.countryId = this.countryId;
            }

            if (this.serviceLevelId) {
              ajaxOptions.data.serviceLevelId = this.serviceLevelId;
            }

            ajaxOptions.data.ShowExpiredOne = this.showExpiredOne;
          }
        },
      }),
    });

    this.workCodes = new DataSource({
      store: AspNetData.createStore({
        key: 'workCodeId',
        loadUrl: `${this.url}/Workcode/lookup`,
      }),
      sort: ['WorkCodeLabel', 'WorkCodeDescription'],
    });

    this.agents = new DataSource({
      store: AspNetData.createStore({
        key: 'nameId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Patricia/AgentLookup/Lookup`,
      }),
      sort: 'Name',
    });

    this.clients = new DataSource({
      store: AspNetData.createStore({
        key: 'NameId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Patricia/Clients/Lookup`,
      }),
      sort: 'LegalName',
    });

    this.caseTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'caseTypeId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Case/CaseType/Lookup`,
      }),
      sort: 'CaseTypeLabel',
    });

    this.countries = new DataSource({
      store: AspNetData.createStore({
        key: 'CountryId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Country/Lookup`,
      }),
      sort: 'Label',
    });

    this.applicationTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'applicationTypeId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Patricia/ApplicationType/Lookup`,
      }),
      sort: 'ApplicationTypeLabel',
    });

    this.serviceLevels = new DataSource({
      store: AspNetData.createStore({
        key: 'ServiceId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Patricia/ServiceLevelLookup/Lookup`,
      }),
      sort: 'ServiceLevelLabel',
    });

    this.currencies = new DataSource({
      store: AspNetData.createStore({
        key: 'currencyId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Currency/lookup`,
      }),
      sort: 'CurrencyLabel',
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onCaseTypeChanged = (e) => {
    this.caseTypeId = e;
    this.refreshData();
  };

  onCountryChanged = (e) => {
    this.countryId = e;
    this.refreshData();
  };

  onServiceLevelChanged = (e: any) => {
    this.serviceLevelId = e;
    this.refreshData();
  };

  switchGroup() {
    this.isGrouped = !this.isGrouped;
  }

  switchShowExpired(e: ValueChangedEvent) {
    this.showExpiredOne = e.value;

    this.refreshData();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    DxDropDownBoxModule,
    DxDropDownButtonModule,
    DxCheckBoxModule,
    DxDateBoxModule,
    TitleBarModule,
    DxLookupModule,
    DxScrollViewModule,
    CaseTypeLookupModule,
    CountryLookupModule,
    ServiceLevelLookupModule,
  ],
  providers: [],
  declarations: [FinanceConfigurationPricelistComponent],
  exports: [],
})
export class FinanceInvoicesInModule {}
