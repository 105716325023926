import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { DxButtonModule, DxLinearGaugeModule, DxTabsModule, DxToolbarModule } from 'devextreme-angular';
import { CardAnalyticsModule } from '../../library/card-analytics/card-analytics.component';
import { TickerCardModule } from '../../library/ticker-card/ticker-card.component';

@Component({
  selector: 'time-followup-card',
  templateUrl: './time-followup-card.component.html',
  styleUrls: ['./time-followup-card.component.scss']
})
export class TimeFollowupCardComponent {
  @Input() realized: number;
  @Input() target: number;
  @Input() summaryPhrase: string;

  @Input() title: string;

  @Input() contentClass: string | null = null;

  constructor() { }

}

@NgModule({
  declarations: [TimeFollowupCardComponent],
  exports: [TimeFollowupCardComponent],
  imports: [
    CommonModule,
    DxButtonModule,
    DxTabsModule,
    DxToolbarModule,
    CardAnalyticsModule,
    TickerCardModule,
    DxLinearGaugeModule
  ],
})
export class TimeFollowupCardModule {}
