<dx-popup
  [(visible)]="visible"
  [title]="titleText"
  (onShowing)="onShow()"
  [showCloseButton]="true"
  (onHidden)="onHide()"
  height="70vh"
  width="70vw"
>
  <dx-scroll-view class="view-wrapper-scroll">
    <div class="view-wrapper">
      <div>
        <dx-data-grid
          [allowColumnResizing]="true"
          [allowColumnReordering]="true"
          id="gridContainer"
          [dataSource]="dataSource"
          [showBorders]="true"
          [remoteOperations]="true"
          width="100%"
          height="40em"
          [showBorders]="true"
          [repaintChangesOnly]="true"
          [wordWrapEnabled]="true"
          (onRowUpdating)="onRowUpdating($event)"
        >
          <dxo-load-panel [enabled]="true" />
          <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
          <dxo-sorting mode="multiple" />
          <dxo-editing
            mode="popup"
            [allowAdding]="true"
            [allowUpdating]="true"
            [allowDeleting]="true"
          >
            <dxo-popup
              [showTitle]="true"
              [title]="'Hourly Rate'"
              height="auto"
              width="30vw"
            >
              <dxi-toolbar-item
                toolbar="bottom"
                widget="dxButton"
                location="after"
                [options]="saveButtonOptions"
              />
              <dxi-toolbar-item
                toolbar="bottom"
                widget="dxButton"
                location="after"
                [options]="cancelButtonOptions"
              />
            </dxo-popup>
            <dxo-form>
              <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
                <dxi-item
                  dataField="ClientId"
                  editorType="dxLookup"
                  [editorOptions]="{
                    dataSource: clients,
                    displayExpr: 'LegalName',
                    valueExpr: 'NameId',
                    searchEnabled: true,
                    minSearchLength: 3,
                    validationMessageMode: 'always'
                  }"
                >
                  <dxo-label text="Client" />
                </dxi-item>
                <dxi-item
                  dataField="CaseTypeId"
                  [editorOptions]="{
                    validationMessageMode: 'always'
                  }"
                >
                  <dxo-label text="CaseType" />
                </dxi-item>
                <dxi-item
                  dataField="HourlyRate"
                  [editorOptions]="{
                    validationMessageMode: 'always'
                  }"
                >
                  <dxi-validation-rule
                    type="required"
                    message="HourlyRate is required"
                  />
                  <dxo-label text="HourlyRate" />
                </dxi-item>
              </dxi-item>
            </dxo-form>
          </dxo-editing>

          <dxo-column-chooser [enabled]="true" />
          <dxo-search-panel [visible]="false" />

          <dxo-toolbar>
            <dxi-item location="after" locateInMenu="never">
              <dx-button
                text="New Hourly Rate"
                icon="plus"
                type="default"
                stylingMode="contained"
                (onClick)="this.dataGrid.instance.addRow()"
              />
            </dxi-item>
            <dxi-item
              location="after"
              widget="dxButton"
              locateInMenu="never"
              [options]="{
                text: '',
                icon: 'refresh',
                stylingMode: 'text',
                onClick: refreshData
              }"
            />
            <dxi-item
              location="after"
              name="columnChooserButton"
              locateInMenu="auto"
            />
            <dxi-item
              location="after"
              name="searchPanel"
              locateInMenu="never"
            />
          </dxo-toolbar>

          <dxi-column dataField="Id" caption="Id" [visible]="false" />
          <dxi-column dataField="ClientId" caption="Px Name Id" />
          <dxi-column dataField="ClientId" caption="Client">
            <dxo-lookup
              [dataSource]="clients.store()"
              displayExpr="LegalName"
              valueExpr="NameId"
            />
          </dxi-column>
          <dxi-column dataField="CaseTypeId" caption="CaseType">
            <dxo-lookup
              [dataSource]="caseTypes.store()"
              displayExpr="CaseTypeLabel"
              valueExpr="CaseTypeId"
            />
          </dxi-column>
          <dxi-column dataField="HourlyRate" caption="HourlyRate" />
        </dx-data-grid>
      </div>
    </div>
  </dx-scroll-view>
</dx-popup>
