<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  height="auto"
  width="auto"
>
  <dx-form [(formData)]="responseType" validationGroup="validationGroup">
    <dxi-item itemType="group" caption="Patricia" [colCount]="2">
      <dxi-item
        [colSpan]="2"
        dataField="Label"
        editorType="dxTextBox"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxi-validation-rule type="required" message="Label is required" />
        <dxo-label text="Label" />
      </dxi-item>
      <dxi-item
        dataField="AllowDocumentUpload"
        editorType="dxCheckBox"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxo-label text="Allow Document Upload" />
      </dxi-item>
      <dxi-item
        dataField="AllowFreeText"
        editorType="dxCheckBox"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxo-label text="Allow Free Text" />
      </dxi-item>
    </dxi-item>
  </dx-form>
</form-popup>
