import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxToolbarModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { FeeCalculatorService } from 'src/app/services/fee-calculator/fee-calculator.service';
import { ValueChangedEvent } from 'devextreme/ui/select_box';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'fee-calculator-wizard-services-selector',
  templateUrl: './fee-calculator-wizard-services-selector.component.html',
  styleUrls: ['./fee-calculator-wizard-services-selector.component.scss'],
})
export class FeeCalculatorWizardServicesSelectorComponent implements OnInit {
  serviceTypesDataSource: DataSource;
  caseTypesDataSource: DataSource;
  workcodesDatasource: DataSource;

  selectedServiceType: number;
  selectedCaseType: number;

  caseSelectionVisible: boolean = false;

  @Input() showPopup: boolean = false;
  @Output() showPopupChange = new EventEmitter<boolean>();

  constructor(private FeeCalcSvc: FeeCalculatorService) {}

  ngOnInit() {
    this.initServiceTypes();
  }

  addServiceButtonOptions = {
    text: 'Add Service',
    type: 'default',
    onClick: () => {
      this.showPopup = false;
    },
  };

  closeButtonOptions = {
    text: 'Close',
    type: 'danger',
    onClick: () => {
      this.showPopup = false;
      this.showPopupChange.emit(this.showPopup);
    },
  };

  initServiceTypes(): void {
    this.serviceTypesDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ServiceTypeId',
        loadUrl: `${this.FeeCalcSvc.getUrl()}/serviceType`,
      }),
    });
  }

  initCaseTypes(): void {
    this.caseTypesDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseTypeId',
        loadUrl: `${this.FeeCalcSvc.getUrl()}/NewQuote/CaseType`,
        loadParams: { serviceTypeId: this.selectedServiceType },
      }),
    });
  }

  onServiceTypeChanged($event: ValueChangedEvent) {
    if (this.selectedServiceType){
      this.initCaseTypes();
      this.caseSelectionVisible = true;
    }
    else this.caseSelectionVisible = false;
  }
}

@NgModule({
  exports: [FeeCalculatorWizardServicesSelectorComponent],
  declarations: [FeeCalculatorWizardServicesSelectorComponent],
  providers: [],
  imports: [DxButtonModule, DxPopupModule, DxSelectBoxModule, DxDataGridModule, CommonModule],
})
export class FeeCalculatorWizardServicesSelectorModule {}
