<dx-popup
  [width]="1200"
  [height]="600"
  [showTitle]="true"
  title="Select service"
  [dragEnabled]="true"
  [closeOnOutsideClick]="false"
  [(visible)]="showPopup"
>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="addServiceButtonOptions"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="closeButtonOptions"
  >
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'">
    <p>Please select the desired service:</p>
    <dx-select-box
      [dataSource]="serviceTypesDataSource.store()"
      [(value)]="selectedServiceType"
      displayExpr="ServiceTypeLabel"
      valueExpr="ServiceTypeId"
      [readOnly]="false"
      [disabled]="false"
      (onValueChanged)="onServiceTypeChanged($event)"
    ></dx-select-box>
    <div *ngIf="caseSelectionVisible">
      <p>Please select the desired IP Type:</p>
      <dx-select-box
        [dataSource]="caseTypesDataSource?.store()"
        [value]="selectedCaseType"
        displayExpr="CaseTypeLabel"
        valueExpr="CaseTypeId"
        [readOnly]="false"
        [disabled]="false"
        [visible]="caseSelectionVisible"
      ></dx-select-box>
    </div>
    <!-- <div *ngIf="caseSelectionVisible">
      <p>Please select the desired Territory:</p>
      <dx-select-box
        [dataSource]="territoryDataSource?.store()"
        [value]="selectedCaseType"
        displayExpr="CaseTypeLabel"
        valueExpr="CaseTypeId"
        [readOnly]="false"
        [disabled]="false"
        [visible]="caseSelectionVisible"
      ></dx-select-box>
    </div> -->
    <dx-data-grid
      [dataSource]="workcodesDatasource"
      [showBorders]="true"
      [remoteOperations]="true"
      [repaintChangesOnly]="true"
      [wordWrapEnabled]="true"
    >
      <dxi-column caption="WorkCode"></dxi-column>
      <dxi-column caption="Description"></dxi-column>
      <dxi-column caption="UnitPrice"></dxi-column>
      <dxi-column caption="Quantity"></dxi-column>
      <dxi-column caption="Amount"></dxi-column>
    </dx-data-grid>
  </div>
</dx-popup>
