import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxTagBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { WorkcodeLookupModule } from '../../lookups/workcode-lookup/workcode-lookup.component';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { FormPopupModule } from '../../utils/form-popup/form-popup.component';
import { RwfollowUp } from 'src/app/types/invoicing/rwFollowUp';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'client-portal-segments-case-segment-new',
  templateUrl: './client-portal-segments-case-segment-new.component.html',
  styleUrls: ['./client-portal-segments-case-segment-new.component.scss'],
})
export class ClientPortalSegmentCaseSegmentNewComponent implements OnInit {
  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<RwfollowUp>();

  @Output() visibleChange = new EventEmitter<boolean>();

  responseType: any;

  clients: any;

  cases: any;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {}

  ngOnInit(): void {
    this.responseType = {};

    this.clients = new DataSource({
      store: AspNetData.createStore({
        key: 'nameId',
        loadUrl: `${this.url}Patricia/Clients/Lookup`,
      }),
      sort: 'LegalName',
    });

    this.cases = new DataSource({
      store: AspNetData.createStore({
        key: 'caseId',
        loadUrl: `${this.url}Patricia/CaseDetailedInfo/lookup`,
      }),
      sort: 'CaseNumber',
    });
  }

  handleVisible(e: boolean): void {
    this.visible = e;
    this.responseType = {};
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.save.emit(this.responseType);
  }
}

@NgModule({
  declarations: [ClientPortalSegmentCaseSegmentNewComponent],
  exports: [ClientPortalSegmentCaseSegmentNewComponent],
  bootstrap: [ClientPortalSegmentCaseSegmentNewComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,

    DxTagBoxModule,
  ],
})
export class ClientPortalSegmentCaseSegmentNewModule {}
