import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxTagBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { WorkcodeLookupModule } from '../../lookups/workcode-lookup/workcode-lookup.component';
import { environment } from 'src/environments/environment';
import { FormPopupModule } from '../../utils/form-popup/form-popup.component';
import { RwfollowUp } from 'src/app/types/invoicing/rwFollowUp';

@Component({
  selector: 'client-portal-instructions-response-type-new',
  templateUrl: './client-portal-instructions-response-type-new.component.html',
  styleUrls: ['./client-portal-instructions-response-type-new.component.scss'],
})
export class ClientPortalInstructionsResponseTypeNewComponent
  implements OnInit
{
  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<RwfollowUp>();

  @Output() visibleChange = new EventEmitter<boolean>();

  responseType: any;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {}

  ngOnInit(): void {
    this.responseType = {};
  }

  handleVisible(e: boolean): void {
    this.visible = e;
    this.responseType = {};
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.save.emit(this.responseType);
  }
}

@NgModule({
  declarations: [ClientPortalInstructionsResponseTypeNewComponent],
  exports: [ClientPortalInstructionsResponseTypeNewComponent],
  bootstrap: [ClientPortalInstructionsResponseTypeNewComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,

    DxTagBoxModule,
  ],
})
export class ClientPortalInstructionsResponseTypeNewModule {}
