<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar
      titleText="Registration"
      [useButton]="true"
      iconButton="fas fa-book"
      (onButtonClicked)="redirectToWiki()"
    />
    <div class="separator"></div>

    <div>
      <dx-data-grid
        [allowColumnResizing]="true"
        [allowColumnReordering]="true"
        id="gridContainer"
        [dataSource]="dataSource"
        [showBorders]="true"
        [remoteOperations]="true"
        width="100%"
        height="80vh"
        (onRowUpdating)="onRowUpdating($event)"
        (onEditingStart)="onEditingStart($event)"
        (onInitNewRow)="onInitNewRow($event)"
        (onRowInserted)="onRowInserted($event)"
        [repaintChangesOnly]="true"
        [wordWrapEnabled]="true"
      >
        <dxo-load-panel [enabled]="true" />
        <dxo-column-chooser [enabled]="true" />
        <dxo-sorting mode="multiple" />
        <dxo-header-filter [visible]="false" />
        <dxo-selection
          [selectAllMode]="'allPages'"
          [showCheckBoxesMode]="'always'"
          mode="multiple"
        />
        <dxo-editing
          mode="popup"
          [allowUpdating]="rowNotProcessed"
          [allowAdding]="rowNotProcessed"
          [allowDeleting]="rowNotProcessed"
        />

        <dxo-search-panel [visible]="true" width="20vw" />
        <dxo-group-panel [visible]="true" />

        <dxo-toolbar>
          <dxi-item location="before" locateInMenu="auto">
            <dx-check-box
              text="Show Processed"
              [(value)]="showProcessed"
              (onValueChanged)="onShowProcessedChanged($event)"
            />
          </dxi-item>
          <dxi-item
            location="before"
            widget="dxButton"
            locateInMenu="auto"
            [visible]="!showProcessed"
            [options]="{
              text: 'Process Time',
              icon: '',
              stylingMode: 'button',
              onClick: onClickProcess
            }"
          />

          <dxi-item location="after" locateInMenu="never">
            <dx-button
              text="new time registration"
              icon="plus"
              type="default"
              stylingMode="contained"
              (onClick)="addNew()"
            />
          </dxi-item>
          <dxi-item
            location="after"
            widget="dxButton"
            locateInMenu="never"
            [options]="{
              text: '',
              icon: 'refresh',
              stylingMode: 'text',
              onClick: refreshData
            }"
          />
          <dxi-item
            location="after"
            name="columnChooserButton"
            locateInMenu="auto"
          />
          <dxi-item location="after" name="searchPanel" locateInMenu="never" />
        </dxo-toolbar>

        <dxi-column
          dataField="RegisteredOn"
          sortIndex="0"
          sortOrder="desc"
          dataType="date"
          width="15%"
          alignment="center"
        >
          <dxi-validation-rule type="required" />
        </dxi-column>
        <dxi-column
          dataField="EmployeeId"
          caption="Employee"
          alignment="center"
          calculateDisplayValue="EmployeeName"
          [setCellValue]="onEmployeeChanged"
        >
          <dxi-validation-rule type="required" />
          <dxo-lookup
            [dataSource]="employees"
            displayExpr="FullName"
            valueExpr="EmployeeId"
          />
        </dxi-column>
        <dxi-column dataField="TotalHours" width="7%"> </dxi-column>
        <dxi-column dataField="TotalMinutes" width="7%"> </dxi-column>
        <dxi-column dataField="BillableHours" width="7%"> </dxi-column>
        <dxi-column dataField="BillableMinutes" width="7%"> </dxi-column>
        <dxi-column
          dataField="NonBillableHours"
          caption="Non-Billable Hours"
          width="7%"
        >
          <dxo-form-item [visible]="true" />
        </dxi-column>
        <dxi-column
          dataField="NonBillableMinutes"
          caption="Non-Billable Minutes"
          width="7%"
        >
          <dxo-form-item [visible]="true" />
        </dxi-column>
        <dxi-column dataField="TotalBillableAmount" width="7%">
          <dxo-format type="currency" [precision]="2"></dxo-format>
          <dxo-form-item [visible]="true" />
        </dxi-column>
        <dxi-column dataField="RegisteredBy" [visible]="false">
          <dxo-form-item [visible]="false" />
        </dxi-column>
        <dxi-column dataField="TransferedToCharge" caption="Processed">
          <dxo-form-item [visible]="false" />
        </dxi-column>

        <dxi-column type="buttons">
          <dxi-button
            icon="edit"
            [visible]="!showProcessed"
            [onClick]="onEdit"
          />
          <dxi-button name="delete" [visible]="!showProcessed" />
        </dxi-column>

        <dxo-master-detail [enabled]="true" template="detail" />
        <div *dxTemplate="let details of 'detail'">
          <div>
            <div>Details</div>
            <registration-details-form
              [entity]="details.data"
              [EmployeeId]="details.data.EmployeeId"
            />
          </div>
        </div>

        <dxo-paging [pageSize]="25" />
        <dxo-pager
          [visible]="true"
          [allowedPageSizes]="[20, 50, 100]"
          [displayMode]="'full'"
          [showPageSizeSelector]="true"
          [showInfo]="true"
          [showNavigationButtons]="true"
        />

        <dxo-state-storing
          [enabled]="false"
          type="localStorage"
          storageKey="gridTimeRegistration"
        />
      </dx-data-grid>
    </div>
    <time-registration-register-new
      [(visible)]="isAddPopupOpened"
      (save)="onSaveClickNew($event)"
      [titleText]="'New Registration'"
      (visibleChange)="onCloseAdd($event)"
    />
    <time-registration-register-new
      [(visible)]="isEditPopupOpened"
      (save)="onSaveClickEdit($event)"
      [titleText]="'New Registration'"
      (visibleChange)="onCloseEdit($event)"
      [registration]="editedRow"
    />
  </div>
</dx-scroll-view>

<dx-load-panel
  container=".view-wrapper"
  [position]="{ of: '.content' }"
  [visible]="isLoading"
  [showPane]="true"
/>
