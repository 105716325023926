<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Case Types Mapping" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      >
        <dxo-popup
          title="Computer"
          [showTitle]="true"
          height="auto"
          width="30vw"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form>
          <dxi-item itemType="group" [colCount]="3" [colSpan]="2">
            <dxi-item dataField="CaseTypeId" [colSpan]="3">
              <dxi-validation-rule
                type="required"
                message="Case Type is required"
              />
              <dxo-label text="Case Type" />
            </dxi-item>
            <dxi-item dataField="CaseMasterId" [colSpan]="3">
              <dxi-validation-rule
                type="required"
                message="Case Master is required"
              />
              <dxo-label text="Case Master" />
            </dxi-item>
            <dxi-item
              dataField="VisibleInPortal"
              [colSpan]="1"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="Visible In Portal" />
            </dxi-item>
            <dxi-item
              dataField="IsService"
              [colSpan]="1"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="IP Service" />
            </dxi-item>
            <dxi-item
              dataField="IsIp"
              [colSpan]="1"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="IP Asset" />
            </dxi-item>
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="New Mapping"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="this.dataGrid.instance.addRow()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="CaseTypeId" caption="Case Type" alignment="center">
        <dxo-lookup
          [dataSource]="caseTypes.store()"
          displayExpr="CaseTypeLabel"
          valueExpr="CaseTypeId"
        />
      </dxi-column>
      <dxi-column
        dataField="CaseMasterId"
        caption="Case Master"
        alignment="center"
      >
        <dxo-lookup
          [dataSource]="caseMasters.store()"
          displayExpr="CaseTypeLabel"
          valueExpr="CaseTypeId"
        />
      </dxi-column>
      <dxi-column
        dataField="VisibleInPortal"
        caption="Visible in portal"
        width="6%"
      />
      <dxi-column dataField="IsService" caption="IP Service" width="6%" />
      <dxi-column dataField="IsIp" caption="IP Asset" width="6%" />
      <dxi-column
        dataField="CaseTypeMappingId"
        caption="Case Type Mapping Id"
        [visible]="false"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridCaseTypeMapping"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
