import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChange,
  input,
} from '@angular/core';
import {
  DxDataGridModule,
  DxDropDownButtonModule,
  DxButtonModule,
  DxScrollViewModule,
  DxDateRangeBoxModule,
  DxTabPanelModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import { DeadlineOverviewComponent } from '../deadline-overview.component';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-deadline-details',
  templateUrl: './deadline-details.component.html',
  styleUrls: ['./deadline-details.component.scss'],
})
export class DeadlineDetailsComponent implements OnChanges {
  @Input() deadline: any;

  dataSource: any[] = [];

  constructor() {}

  ngOnChanges(changes) {
    if (this.deadline) {
      this.dataSource = [
        {
          OfficialStatusText: this.deadline.OfficialStatusText,
          OfficialActionText: this.deadline.OfficialActionText,
          OfficialDeadline: this.deadline.OfficialDeadline,
          OfficialResponsible: this.deadline.OfficialResponsibleLogin,
        },
      ];
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    DxDateRangeBoxModule,
    DxTabPanelModule,
  ],
  declarations: [DeadlineDetailsComponent],
  exports: [DeadlineDetailsComponent],
})
export class DeadlineDetailsModule {}
