<dx-popup
  [(visible)]="visible"
  [title]="titleText"
  height="auto"
  (onShown)="onShow()"
>
  <dx-form [(formData)]="charge">
    <dxi-item itemType="group" [colCount]="2" [colSpan]="4">
      <p>
        the charge has some time linked to it. You can either choose to remove
        the time linked or make the charge's time non billable and assign a new
        work code
      </p>
      <dxi-item>
        <workcode-lookup
          [workCodeCategoryId]="13"
          [value]="1"
          (valueChange)="onWorkCodeChanged($event)"
        />
      </dxi-item>
    </dxi-item>
    <dxi-item>
      <div class="warning-buttons">
        <dx-button
          text="Cancel"
          stylingMode="contained"
          type="default"
          (onClick)="onCancel()"
        />
        <dx-button
          text="Remove Time"
          stylingMode="contained"
          type="default"
          (onClick)="onRemoveTime()"
        />
        <dx-button
          text="Make the charge's time non billable"
          stylingMode="contained"
          type="default"
          (onClick)="onTransfertToNB()"
        />
      </div>
    </dxi-item>
  </dx-form>
</dx-popup>
