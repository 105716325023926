import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
  ViewChild,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxFormModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { WorkcodeLookupModule } from '../../lookups/workcode-lookup/workcode-lookup.component';
import { environment } from 'src/environments/environment';
import { FormPopupModule } from '../../utils/form-popup/form-popup.component';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';

@Component({
  selector: 'crm-todo-mark-as-done-form',
  templateUrl: './crm-todo-mark-as-done-form.component.html',
  styleUrls: ['./crm-todo-mark-as-done-form.component.scss'],
})
export class CrmToDoMarkAsDoneComponent {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() todo: any;

  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<any>();

  @Output() visibleChange = new EventEmitter<boolean>();

  url = environment.CalystaApiBaseURL + 'api/';

  dataSource: DataSource;

  outComeComment: string;

  constructor() {}

  handleVisible(e: boolean): void {
    this.visible = e;
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.todo.OutcomeComments = this.outComeComment;
    this.save.emit(this.todo);
    this.outComeComment = undefined;
  }

  onShow() {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ProspectActionId',
        loadUrl: `${this.url}prospection/todo`,
        insertUrl: `${this.url}prospection/todo`,
        updateUrl: `${this.url}prospection/todo`,
        deleteUrl: `${this.url}prospection/todo`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method === 'load') {
            ajaxOptions.data = ajaxOptions.data || {};
            ajaxOptions.data.includeDone = true;
          }
        },
      }),
      filter: [
        ['ProspectId', '=', this.todo.ProspectId],
        'and',
        ['Done', '=', true],
      ],
    });

    this.todo.IsOutcomePositive = this.todo.IsOutcomePositive ?? false;
    this.outComeComment = this.todo.OutcomeComments;
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onOutcomeCommentChange = (e) => {
    this.outComeComment = e.value;
  };
}

@NgModule({
  declarations: [CrmToDoMarkAsDoneComponent],
  exports: [CrmToDoMarkAsDoneComponent],
  bootstrap: [CrmToDoMarkAsDoneComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
    DxDataGridModule,
    DxTextAreaModule,
    DxCheckBoxModule,
  ],
})
export class CrmToDoMarkAsDoneModule {}
