import { CommonModule } from '@angular/common';
import { Component, EventEmitter, NgModule, Output } from '@angular/core';
import { DxProgressBarModule } from 'devextreme-angular';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { ValueChangedEvent } from 'devextreme/ui/file_uploader';

@Component({
  selector: 'form-file-upload',
  templateUrl: './form-file-upload.component.html',
  styleUrls: ['./form-file-upload.component.scss'],
})
export class FormFileUploadComponent {
  @Output() fileUploadedEvent: EventEmitter<any> = new EventEmitter<String>();

  isDropZoneActive = true;
  textVisible = true;

  fileValue: any[] = [];

  constructor() {
    this.onDropZoneEnter = this.onDropZoneEnter.bind(this);
    this.onDropZoneLeave = this.onDropZoneLeave.bind(this);
  }

  onDropZoneEnter(e) {
    if (e.dropZoneElement.id === 'uploader') {
      this.isDropZoneActive = true;
    }
  }

  onDropZoneLeave(e) {
    if (e.dropZoneElement.id === 'uploader') {
      this.isDropZoneActive = false;
    }
  }

  onValueChanged(e: ValueChangedEvent) {
    this.fileValue = e.value;
    this.textVisible = false;
    this.fileUploadedEvent.emit(e.value);
  }
}

@NgModule({
  imports: [CommonModule, DxFileUploaderModule, DxProgressBarModule],
  declarations: [FormFileUploadComponent],
  exports: [FormFileUploadComponent],
})
export class FormFileUploadModule {}
