<dx-scroll-view class="view-wrapper-scroll"
  ><div class="view-wrapper">
    <titlebar titleText="Work Code Categories" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      >
        <dxo-popup
          [showTitle]="true"
          [title]="'Add Language'"
          height="auto"
          width="auto"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form>
          <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
            <dxi-item dataField="WorkCodeCategoryLabel" [colSpan]="2">
              <dxi-validation-rule
                type="required"
                message="Work Code Category Label is required"
              />
            </dxi-item>
            <dxi-item dataField="BillingType" [colSpan]="1">
              <dxi-validation-rule
                type="required"
                message="Billing Type is required"
              />
            </dxi-item>
            <dxi-item dataField="TimeCode" [colSpan]="1">
              <dxo-label text="Time Code" />
            </dxi-item>
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="new workcode category"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="this.dataGrid.instance.addRow()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        dataField="WorkCodeCategoryLabel"
        caption="Category Label"
        width="10%"
      />
      <dxi-column dataField="TimeCode" caption="Time Code" width="5%" />
      <dxi-column
        dataField="BillingType"
        caption="Billing Type Label"
        alignment="center"
      >
        <dxo-lookup
          [dataSource]="billingTypes.store()"
          displayExpr="BillingTypeLabel"
          valueExpr="BillingTypeId"
        />
      </dxi-column>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridFinanceConfigurationWorkCodeCategories"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
