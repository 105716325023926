<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Employee Salaries" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
      [showColumnLines]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />

      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      >
        <dxo-popup
          [showTitle]="true"
          [title]="'Employee Salary'"
          height="auto"
          width="30vw"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form>
          <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
            <dxi-item dataField="EmployeeId" [colSpan]="2">
              <dxi-validation-rule
                type="required"
                message="Employee is required"
              />
              <dxo-label text="Employee" />
            </dxi-item>
            <dxi-item
              dataField="PeriodStart"
              [colSpan]="1"
              validationMessageMode
            >
              <dxi-validation-rule
                type="required"
                message="Period Start is required"
              />
              <dxo-label text="Period Start" />
            </dxi-item>
            <dxi-item dataField="PeriodEnd" [colSpan]="1" validationMessageMode>
              <dxi-validation-rule
                type="required"
                message="Period End is required"
              />
              <dxo-label text="Period End" />
            </dxi-item>
            <dxi-item
              dataField="Amount"
              [colSpan]="1"
              validationMessageMode
              [editorOptions]="{
                format: {
                  type: 'currency',
                  precision: 2
                }
              }"
            >
              <dxi-validation-rule
                type="required"
                message="Amount is required"
              />
              <dxo-label text="Amount" />
            </dxi-item>
            <dxi-item
              dataField="DaysWorked"
              [colSpan]="1"
              editorType="dxNumberBox"
              validationMessageMode
            >
              <dxo-label text="Days Worked" />
            </dxi-item>
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item location="after">
          <dx-button
            text="New Employee Salary"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="this.dataGrid.instance.addRow()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="EmployeeId" alignment="center" [visible]="false">
        <dxo-lookup
          [dataSource]="employees.store()"
          displayExpr="FullName"
          valueExpr="EmployeeId"
        />
      </dxi-column>
      <dxi-column
        dataField="EmployeeFullName"
        caption="Employee"
        alignment="center"
      />
      <dxi-column
        dataField="PeriodStart"
        caption="PeriodStart"
        dataType="date"
        alignment="center"
      />
      <dxi-column
        dataField="PeriodEnd"
        caption="PeriodEnd"
        dataType="date"
        alignment="center"
      />
      <dxi-column dataField="Amount" caption="Amount">
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column dataField="DaysWorked" caption="Days Worked" />

      <dxo-paging [pageSize]="50" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridAdministraionEmployeeSalaries"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
