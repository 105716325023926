import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxLookupModule, DxSelectBoxModule } from 'devextreme-angular';
import { CountryService } from 'src/app/services/country/country.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'country-lookup',
  templateUrl: './country-lookup.component.html',
  styleUrls: ['./country-lookup.component.scss'],
})
export class CountryLookupComponent implements OnInit {
  @Input() value = false;
  @Input() valueChange: (value: number) => void;

  datasource: any;

  url: string;

  constructor(private countryService: CountryService) {
    this.url = this.countryService.getUrl();
  }

  ngOnInit(): void {
    this.datasource = new DataSource({
      store: AspNetData.createStore({
        key: 'CountryId',
        loadUrl: `${this.url}/Lookup`,
      }),
      sort: 'Label',
    });
  }

  valueChanged(e) {
    this.valueChange(e.value);
  }
}

@NgModule({
  imports: [BrowserModule, DxLookupModule, DxSelectBoxModule],
  declarations: [CountryLookupComponent],
  exports: [CountryLookupComponent],
  providers: [CountryService],
})
export class CountryLookupModule {}
