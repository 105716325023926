<div>
  <a
    [style.pointer-events]="isClickable ? 'auto' : 'none'"
    (click)="handleClick()"
  >
    <div class="container">
      <div class="icon-container">
        <i class="{{ icon }} icon"></i>
      </div>
      <div class="title">
        <span class="info-title">{{ title }}</span>
      </div>
      <div class="value-container">
        <span class="value">{{ amountSpend | customCurrency }} €</span>
      </div>
      <div class="gauge-container">
        <dx-linear-gauge id="c6" [value]="getPercentSpend()" class="gauge">
          <dxo-value-indicator type="triangleMarker" color="#f05b41" />
          <dxo-range-container [offset]="10">
            <dxi-range [startValue]="75" [endValue]="100" color="#92000A" />
            <dxi-range [startValue]="25" [endValue]="75" color="#E6E200" />
            <dxi-range [startValue]="0" [endValue]="25" color="#77DD77" />
          </dxo-range-container>
          <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="25">
            <dxo-label [customizeText]="customizeText" />
            <dxo-tick color="#536878" />
          </dxo-scale>
        </dx-linear-gauge>
      </div>
      <div class="description-container">
        <span class="description">{{ description }}</span>
      </div>
    </div>
  </a>
</div>
