import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { DxChartComponent, DxChartModule, DxPivotGridComponent, DxPivotGridModule, DxScrollViewModule } from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { environment } from 'src/environments/environment';
import { Options as DataSourceConfig } from 'devextreme/ui/pivot_grid/data_source';
import dxScrollView from 'devextreme/ui/scroll_view';

@Component({
  selector: 'time-analysis',
  templateUrl: './time-analysis.component.html',
  styleUrls: ['./time-analysis.component.scss']
})
export class TimeAnalysisComponent implements OnInit, AfterViewInit {

  @ViewChild(DxPivotGridComponent, { static: false }) pivotGrid: DxPivotGridComponent;

  @ViewChild(DxChartComponent, { static: false }) chart: DxChartComponent;

  apiUrl = environment.CalystaApiBaseURL;

  pivotDataSource: DataSourceConfig = {
    remoteOperations: true,
    store: AspNetData.createStore({
      key: 'RegistrationDetailId',
      loadUrl: `${this.apiUrl}api/Boardroom/TimeAnalysis`,
    }),
    fields:[{
      caption: 'Employee',
      dataField: 'EmployeeName',
      sortOrder: 'asc',
      area: 'row',
      width: 200,
    },
    {
      caption: 'Client',
      dataField: 'ClientName',
      area: 'row',
      sortOrder: 'desc',
      sortBySummaryField: 'BillableTimeEquivalent',
    },
    {
      caption: 'Case Type',
      dataField: 'CaseType',
      area: 'row',
      sortOrder: 'asc',
    },
    {
      caption: 'Date',
      dataField: 'InvoicedOn',
      area: 'column',
      dataType: 'date',
    },
    {
      caption: 'Time Billed',
      dataField: 'BillableTimeEquivalent',
      summaryType: 'sum',
      format: 'decimal',
      area: 'data',
    }]
  };

  constructor() { }

  ngOnInit() {
    this.pivotDataSource

  }

  ngAfterViewInit(): void {
    this.pivotGrid.instance.bindChart(this.chart.instance, {
      dataFieldsDisplayMode: 'splitPanes',
      alternateDataFields: false,
    });

  }

}

@NgModule({
  providers: [],
  exports: [],
  declarations: [TimeAnalysisComponent],
  imports: [
    CommonModule,
    DxChartModule,
    DxPivotGridModule,
    DxScrollViewModule,
  ],
})
export class TimeAnalysisModule {}
