<div>
  <dx-data-grid
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    id="gridContainer"
    [dataSource]="datasource"
    [showBorders]="true"
    height="30vh"
    (onRowUpdating)="onRowUpdating($event)"
    [wordWrapEnabled]="true"
  >
    <dxo-toolbar>
      <dxi-item location="after" name="addRowButton" />
      <dxi-item
        location="after"
        widget="dxButton"
        locateInMenu="never"
        [options]="{
          text: '',
          icon: 'refresh',
          stylingMode: 'text',
          onClick: refreshData
        }"
      />
    </dxo-toolbar>

    <dxi-column dataField="FirstName" caption="First Name" />
    <dxi-column dataField="LastName" caption="Last Name" />
    <dxi-column dataField="Function" caption="Function" />
    <dxi-column dataField="Email" caption="Email" />
    <dxi-column dataField="Phone" caption="Phone" />

    <dxi-column
      dataField="LanguageId"
      caption="Language Id"
      [visible]="false"
    />

    <dxo-editing
      mode="popup"
      [allowUpdating]="true"
      [allowAdding]="true"
      [allowDeleting]="true"
    >
      <dxo-popup
        title="New Contact"
        [showTitle]="true"
        width="30vw"
        height="auto"
      >
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="saveButtonOptions"
        />
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="cancelButtonOptions"
        />
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colSpan]="2" [colCount]="2">
          <dxi-item
            [colSpan]="1"
            dataField="FirstName"
            editorType="dxTextBox"
            [editorOptions]="{ validationMessageMode: 'always' }"
          >
            <dxi-validation-rule
              type="required"
              message="First name is required"
            >
            </dxi-validation-rule>
            <dxo-label text="First name" />
          </dxi-item>
          <dxi-item
            [colSpan]="1"
            dataField="LastName"
            editorType="dxTextBox"
            [editorOptions]="{ validationMessageMode: 'always' }"
          >
            <dxi-validation-rule
              type="required"
              message="Last name is required"
            >
            </dxi-validation-rule>
            <dxo-label text="Last name" />
          </dxi-item>
          <dxi-item
            [colSpan]="1"
            dataField="Email"
            editorType="dxTextBox"
            [editorOptions]="{ validationMessageMode: 'always' }"
          >
            <dxi-validation-rule type="required" message="Email is required">
            </dxi-validation-rule>
            <dxo-label text="Email" />
          </dxi-item>
          <dxi-item
            [colSpan]="1"
            dataField="Function"
            editorType="dxTextBox"
            [editorOptions]="{ validationMessageMode: 'always' }"
          >
            <dxo-label text="Function" />
          </dxi-item>
          <dxi-item
            [colSpan]="1"
            dataField="Phone"
            editorType="dxTextBox"
            [editorOptions]="{ validationMessageMode: 'always' }"
          >
            <dxi-validation-rule type="required" message="Phone is required">
            </dxi-validation-rule>
            <dxo-label text="Phone" />
          </dxi-item>
          <dxi-item
            [colSpan]="1"
            dataField="LanguageId"
            editorType="dxLookup"
            [editorOptions]="{
              dataSource: languages,
              displayExpr: 'LanguageName',
              valueExpr: 'Id',
              searchEnabled: true,
              validationMessageMode: 'always'
            }"
          >
            <dxo-label text="Language" />
          </dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxo-state-storing
      [enabled]="false"
      type="localStorage"
      storageKey="gridCrmProspectDetails"
    />
  </dx-data-grid>
</div>
