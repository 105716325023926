<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Internal Renewals Overview" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-selection
        [selectAllMode]="'allPages'"
        [showCheckBoxesMode]="'always'"
        mode="multiple"
      />
      <dxo-editing
        mode="popup"
        [allowAdding]="false"
        [allowUpdating]="false"
        [allowDeleting]="false"
      />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: 'Request quote',
            icon: '',
            stylingMode: 'button',
            onClick: requestQuote
          }"
        />
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
        <!--<dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: 'Refresh All quotes',
            icon: '',
            stylingMode: 'button'
          }"
        />-->
      </dxo-toolbar>

      <dxi-column dataField="CaseNumber" caption="Case" alignment="left" />
      <dxi-column dataField="DeadlineId" caption="Official DL" width="8%" />
      <dxi-column
        dataField="Deadline"
        caption="Deadline"
        alignment="center"
        dataType="date"
        width="8%"
        alignment="center"
      />
      <dxi-column dataField="OfficialFee" caption="Official Fee" width="8%" />
      <dxi-column dataField="CalystaFee" caption="Calysta Fee" width="8%" />
      <dxi-column dataField="CaseId" caption="Id" [visible]="false" />
      <dxi-column
        dataField="InternalDeadlineId"
        caption="Internal Deadline id"
        [visible]="false"
      />
      <dxi-column type="buttons" [width]="110">
        <dxi-button hint="Confirm" icon="check" />
      </dxi-column>

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridRenewalInternalOverview"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
