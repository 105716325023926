import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { ConversionService } from 'src/app/services/conversion/conversion.service';

@Component({
  selector: 'conversion-case-diary',
  templateUrl: './conversion-case-diary.component.html',
  styleUrls: ['./conversion-case-diary.component.scss'],
})
export class ConversionCaseDiaryComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  constructor(private conversionService: ConversionService) {
    this.url = this.conversionService.getUrl();

    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseDiaryId',
        loadUrl: `${this.url}/CaseDiary`,
        updateUrl: `${this.url}/CaseDiary`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
  ],
  declarations: [ConversionCaseDiaryComponent],
  exports: [],
})
export class ConversionCaseDiaryModule {}
