import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'employee-hourly-rates-settings',
  templateUrl: './employee-hourly-rates-settings.component.html',
  styleUrls: ['./employee-hourly-rates-settings.component.scss'],
})
export class EmployeeHourlyRatesSettingsComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() titleText = '';

  @Input() visible = false;

  @Input() employeeId: number;

  @Output() visibleChange = new EventEmitter<boolean>();

  saveButtonOptions: any;
  cancelButtonOptions: any;

  dataSource: DataSource;

  caseTypes: DataSource;

  clients: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {
    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.clients = new DataSource({
      store: AspNetData.createStore({
        key: 'NameId',
        loadUrl: `${this.url}Patricia/Clients/Lookup`,
      }),
      sort: 'LegalName',
    });

    this.caseTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseTypeId',
        loadUrl: `${this.url}Case/CaseType/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'CaseTypeLabel',
    });
  }

  onHide() {
    this.dataSource = null;

    this.visibleChange.emit(false);
  }

  onShow() {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}Employee/HourlyRates/${this.employeeId}`,
        insertUrl: `${this.url}Employee/HourlyRates`,
        updateUrl: `${this.url}Employee/HourlyRates`,
        deleteUrl: `${this.url}Employee/HourlyRates`,
        onInserting: (values) => {
          values.employeeId = this.employeeId;
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };
}

@NgModule({
  declarations: [EmployeeHourlyRatesSettingsComponent],
  exports: [EmployeeHourlyRatesSettingsComponent],
  bootstrap: [EmployeeHourlyRatesSettingsComponent],
  imports: [
    BrowserModule,
    DxPopupModule,
    DxScrollViewModule,
    DxDataGridModule,
    DxButtonModule,
  ],
})
export class EmployeeHourlyRatesSettingsModule {}
