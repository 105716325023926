import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  DxDataGridComponent,
  DxDataGridModule,
  DxSelectBoxModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import ArrayStore from 'devextreme/data/array_store';

@Component({
  selector: 'form-file-upload-docketlist',
  templateUrl: './form-file-upload-docketlist.component.html',
  styleUrls: ['./form-file-upload-docketlist.component.scss'],
})
export class FormFileUploadDocketlistComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;

  @Input() dataSource: any[];
  @Input() fileName: any;

  categoryLookup: DataSource;
  subCategoryLookup: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {}

  ngOnInit(): void {
    this.categoryLookup = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}Docketing/Document/Category/Lookup`,
      }),
      sort: 'Label',
    });

    this.subCategoryLookup = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}Docketing/Document/SubCategory/Lookup`,
      }),
      sort: 'Label',
    });

    this.loadDataSource();
  }

  loadDataSource() {
    this.categoryLookup.load();
    this.subCategoryLookup.load();
  }

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };
}

@NgModule({
  imports: [DxDataGridModule, DxSelectBoxModule],
  declarations: [FormFileUploadDocketlistComponent],
  exports: [FormFileUploadDocketlistComponent],
})
export class FormFileUploadDocketlistModule {}
