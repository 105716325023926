import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxDateRangeBoxModule,
  DxDropDownButtonModule,
  DxScrollViewModule,
  DxTabPanelModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import { PatriciaEventDeadlinesPersonalModule } from './patricia-events-deadlines-personal/patricia-events-deadlines-personal.component';
import { PatriciaEventDeadlinesTeamModule } from './patricia-events-deadlines-team/patricia-events-deadlines-team.component';

@Component({
  selector: 'patricia-events-deadlines',
  templateUrl: './patricia-events-deadlines.component.html',
  styleUrls: ['./patricia-events-deadlines.component.scss'],
})
export class PatriciaEventDeadlinesComponent {
  deadlineTabs: String[] = ['Personal', 'Team'];

  dateRange: [Date, Date] = [
    new Date(),
    new Date(new Date().setMonth(new Date().getMonth() + 1)),
  ];

  onDateChanged(e: any) {
    this.dateRange = e.value;
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    DxDateRangeBoxModule,
    DxTabPanelModule,
    PatriciaEventDeadlinesPersonalModule,
    PatriciaEventDeadlinesTeamModule,
  ],
  declarations: [PatriciaEventDeadlinesComponent],
  exports: [],
})
export class PatriciaEventDeadlinesModule {}
