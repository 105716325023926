import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDropDownButtonModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import { CrmToDoMarkAsDoneModule } from 'src/app/components/crm/crm-todo-mark-as-done-form/crm-todo-mark-as-done-form.component';
import { WipCardModule } from '../../utils/wip-card/wip-card.component';
import DataSource from 'devextreme/data/data_source';
import { SalesAnalyticsService } from 'src/app/services';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';

@Component({
  selector: 'home-wip-section',
  templateUrl: './home-wip-section.component.html',
  styleUrls: ['./home-wip-section.component.scss'],
})
export class HomeWipSectionComponent {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  Data: any;
  DataUrl: string;

  groupType: 'Team' | 'Client' = 'Client';

  constructor(private invoiceSvc: SalesAnalyticsService) {
    this.DataUrl = this.invoiceSvc.getBaseUrl();
    this.ChangeGrouping = this.ChangeGrouping.bind(this);
  }

  ngOnInit(): void {
    this.Data = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.DataUrl}/WIP`,
      }),
    });
  }

  ChangeGrouping(e: 'Team' | 'Client') {
    this.groupType = e;
    // When grouped on Client, change it to be grouped on Team.
    if (this.groupType === 'Team') {
      this.dataGrid.instance.clearGrouping();
      this.dataGrid.instance.columnOption('Team', 'groupIndex', 0);
      this.dataGrid.instance.columnOption('Client', 'groupIndex', 1);
    } else if (this.groupType === 'Client') {
      this.dataGrid.instance.clearGrouping();
      this.dataGrid.instance.columnOption('Client', 'groupIndex', 0);
      this.dataGrid.instance.columnOption('CalystaRef', 'groupIndex', 1);
    }
  }

  onClickRedirect() {
    window.open('/#/analytics-sales-report');
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    CrmToDoMarkAsDoneModule,
    WipCardModule,
  ],
  exports: [HomeWipSectionComponent],
  declarations: [HomeWipSectionComponent],
  providers: [],
})
export class HomeWipSectionModule {}
