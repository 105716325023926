import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownBoxModule,
  DxDropDownButtonModule,
  DxLookupModule,
  DxScrollViewModule,
  DxTagBoxModule,
} from 'devextreme-angular';
import { DxoMasterDetailModule } from 'devextreme-angular/ui/nested';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { EditorPreparingEvent } from 'devextreme/ui/data_grid';
import { TitleBarModule } from 'src/app/components';
import { FeeCalculatorBillingMatrixPopupModule } from 'src/app/components/fee-calculator/fee-calculator-billing-matrix-popup/fee-calculator-billing-matrix-popup.component';
import { FeeCalculatorService } from 'src/app/services/fee-calculator/fee-calculator.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'fee-calculator-configuration-billing-matrix',
  templateUrl: './fee-calculator-configuration-billing-matrix.component.html',
  styleUrls: ['./fee-calculator-configuration-billing-matrix.component.scss'],
})
export class FeeCalculatorConfigurationBillingMatrixComponent
  implements OnInit
{
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  services: DataSource;

  caseTypes: DataSource;

  workCodes: DataSource;

  countries: DataSource;

  url: string;

  editedRow: any;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  isAddPopupVisible = false;
  isEditPopupVisible = false;

  constructor(private feeCalculatorService: FeeCalculatorService) {
    this.url = feeCalculatorService.getUrl();

    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}/billingMatrix`,
        insertUrl: `${this.url}/billingMatrix`,
        updateUrl: `${this.url}/billingMatrix`,
        deleteUrl: `${this.url}/billingMatrix`,
      }),
    });

    this.services = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${environment.CalystaApiBaseURL}api/Administration/CalystaService/Lookup`,
      }),
      sort: 'Label',
    });

    this.caseTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseTypeId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Case/CaseType/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'CaseTypeLabel',
    });

    this.countries = new DataSource({
      store: ['BX', 'XX'],
    });

    this.workCodes = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkCodeId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Invoice/WorkCode/lookup`,
      }),
      sort: ['WorkCodeLabel', 'WorkCodeDescription'],
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onEditorPreparing(e: EditorPreparingEvent) {
    if (e.dataField === 'WorkCodeIds' && e.parentType === 'dataRow') {
      if (
        e.row.data &&
        (e.row.data.CountryId === 'BX' || e.row.data.CountryId === 'XX')
      ) {
        e.editorOptions.disabled = false;
      }
    }
  }

  setCountryCellValue(newData, value) {
    let column = <any>this;
    column.defaultSetCellValue(newData, value);
  }

  onSaveClickNew(e: any): void {
    this.dataSource
      .store()
      .insert(e)
      .done((result) => {
        this.refreshData();
      });
  }

  onSaveClickEdit(e: any): void {
    this.dataSource
      .store()
      .update(e.Id, this.editedRow)
      .done(() => {
        this.refreshData();
      });
  }

  addNew() {
    this.editedRow = {
      Label: null,
      ServiceId: null,
      CaseTypeId: null,
      CountryId: null,
      BillingMatrixWorkCodeLines: [],
    };
    this.isAddPopupVisible = true;
  }

  onEdit = (e: any) => {
    this.editedRow = { ...e.row.data };
    this.isEditPopupVisible = true;
  };

  onCloseAdd = (e: boolean) => {
    this.isAddPopupVisible = e;
  };

  onCloseEdit = (e: boolean) => {
    this.isEditPopupVisible = e;
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    DxDropDownBoxModule,
    DxDropDownButtonModule,
    DxCheckBoxModule,
    DxLookupModule,
    DxTagBoxModule,
    DxDateBoxModule,
    TitleBarModule,
    DxScrollViewModule,
    DxoMasterDetailModule,
    FeeCalculatorBillingMatrixPopupModule,
  ],
  providers: [],
  declarations: [FeeCalculatorConfigurationBillingMatrixComponent],
  exports: [],
})
export class FeeCalculatorConfigurationBillingMatrixModule {}
