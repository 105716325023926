<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <div class="panels">
      <div class="left">
        <form-file-upload (fileUploadedEvent)="fileUploaded($event)" />
      </div>
      <div class="right">
        <docket-casegrid
          [FileName]="currentFileName"
          (CaseSelectionEvent)="CaseSelectionChanged($event)"
        />
      </div>
    </div>
    <div>
      <form-file-upload-docketlist
        [dataSource]="lineToDocket"
        [fileName]="currentFileName"
      />
    </div>
    <p>{{ currentFileName }}</p>
    <div class="floating-button">
      <dx-button
        text="Docket"
        [disabled]="!hasDocketingLines()"
        (onClick)="Docket()"
        stylingMode="contained"
        class="button"
      />
    </div>
  </div>
</dx-scroll-view>
