import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DxChartComponent, DxChartModule } from 'devextreme-angular';
import { MarginReportingComponent } from '../margin-reporting/margin-reporting.component';
import { TimeReportingService } from 'src/app/services/time-registration/reporting.service';
import { TimeReporting } from 'src/app/types/timeregistration/TimeReporting';

@Component({
  selector: 'time-reporting',
  templateUrl: './time-reporting.component.html',
  styleUrls: ['./time-reporting.component.scss'],
})
export class TimeReportingComponent implements OnInit, OnChanges {
  @ViewChild('TimeChart') chart: DxChartComponent;

  @Input() TeamId: number;
  @Input() Year: number;

  TimeReportingDataSource: TimeReporting[];

  constructor(private TimeReportingSvc: TimeReportingService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.TeamId && this.Year) {
      this.loadChartData();
    }
  }

  ngOnInit() {
    if (this.TeamId && this.Year) {
      this.loadChartData();
    }
  }

  OnChartInitialized(e) {
    this.chart.instance = e.component;
    this.loadChartData();
  }

  loadChartData() {
    if (this.chart) {
      this.chart.instance.showLoadingIndicator();
      this.TimeReportingSvc.getTimeReporting(this.TeamId, this.Year).subscribe(
        (data) => {
          this.TimeReportingDataSource = data;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.chart.instance?.hideLoadingIndicator();
        }
      );
    }
  }

  OnChartPointClick(e) {
    console.log(e);
  }
}

@NgModule({
  providers: [],
  exports: [TimeReportingComponent],
  declarations: [TimeReportingComponent],
  imports: [DxChartModule],
})
export class TimeReportingModule {}
