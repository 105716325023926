<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Costs Overview" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onExporting)="onExporting($event)"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-filter-row [visible]="true" />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export
        [enabled]="true"
        [fileName]="'Costs Overview'"
        [allowExportSelectedData]="false"
      />

      <dxo-toolbar>
        <dxi-item location="before" locateInMenu="auto">
          <div class="date-range-container">
            <div class="date-range-title">Invoice Date Range</div>
            <dx-date-range-box
              [startDate]="thisYear"
              [endDate]="now"
              (onValueChanged)="onDateChanged($event)"
              [showClearButton]="false"
              [width]="300"
              [max]="now"
            />
          </div>
        </dxi-item>
        <dxi-item location="before" locateInMenu="auto">
          <div class="number-container">
            <div class="number-title">Starting from invoice number</div>
            <dx-number-box
              (onValueChanged)="onInvoiceValueChanged($event)"
              width="200px"
            />
          </div>
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="exportButton" locateInMenu="auto" />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="Id" caption="Id" [visible]="false" />
      <dxi-column dataField="InvoiceId" caption="Invoice Number" />
      <dxi-column
        dataField="BookingDate"
        caption="Booking Date"
        dataType="date"
        width="6%"
        alignment="center"
        [visible]="false"
      />
      <dxi-column
        dataField="InvoiceDate"
        caption="Invoice Date"
        dataType="date"
        width="6%"
        alignment="center"
      />
      <dxi-column
        dataField="NonVatAmount"
        caption="Amount Non Vat"
        width="5%"
      />
      <dxi-column dataField="VatAmount" caption="Vat Amount" width="5%" />
      <dxi-column dataField="VatRate" caption="Vat Rate" width="4%" />
      <dxi-column dataField="TotalAmount" caption="Total Amount" width="5%" />
      <dxi-column
        dataField="Currency"
        caption="Currency Code"
        width="5%"
        alignment="center"
      />
      <dxi-column
        dataField="ExchangeRate"
        caption="Exchange Rate"
        width="5%"
        [visible]="false"
      />
      <dxi-column
        dataField="NonVatAmountEUR"
        caption="Amount non VAT EUR"
        width="5%"
        [visible]="false"
      />
      <dxi-column
        dataField="VatAmountEUR"
        caption="VAT Amount EUR"
        width="5%"
        [visible]="false"
      />
      <dxi-column
        dataField="AmountEUR"
        caption="Amount EUR"
        width="5%"
        [visible]="false"
      />
      <dxi-column dataField="Description" caption="Description" />
      <dxi-column
        dataField="BudgetPeriodStart"
        caption="Budget Period Start"
        dataType="date"
        width="6%"
      />
      <dxi-column
        dataField="BudgetPeriodEnd"
        caption="Budget Period End"
        dataType="date"
        width="6%"
      />
      <dxi-column
        dataField="FinancialDeductablePerc"
        caption="Financial Deductable Perc"
        [visible]="false"
      />
      <dxi-column
        dataField="ProviderReference"
        caption="Provider Reference"
        [visible]="false"
      />
      <dxi-column
        dataField="ProviderId"
        caption="Provider Id"
        [visible]="false"
      />
      <dxi-column
        dataField="ProviderAccount"
        caption="Provider Account"
        [visible]="false"
      />
      <dxi-column
        dataField="ProviderSwiftCode"
        caption="Provider Swift Code"
        [visible]="false"
      />
      <dxi-column
        dataField="ProviderVATNumber"
        caption="Provider VAT Number"
        [visible]="false"
      />
      <dxi-column dataField="ProviderName" caption="Provider Name" />
      <dxi-column
        dataField="ProviderStreet"
        caption="Provider Street"
        [visible]="false"
      />
      <dxi-column
        dataField="ProviderPostalCode"
        caption="Provider Postal Code"
        [visible]="false"
      />
      <dxi-column
        dataField="CostItemId"
        caption="Cost Item Id"
        [visible]="false"
      />
      <dxi-column dataField="CostItem" caption="Cost Item" alignment="center" />
      <dxi-column
        dataField="EmployeeId"
        caption="Employee Id"
        [visible]="false"
      />
      <dxi-column
        dataField="EmployeeName"
        caption="Employee Name"
        width="7%"
        alignment="center"
      />
      <dxi-column
        dataField="EmployeeCarLicensePlate"
        caption="Employee License Plate"
        [visible]="false"
      />
      <dxi-column
        dataField="CalystaReference"
        caption="Calysta Reference"
        width="8%"
        alignment="center"
      />
      <dxi-column
        dataField="CreditDebit"
        caption="CreditDebit"
        [visible]="false"
      />
      <dxi-column
        dataField="PaymentStatus"
        caption="Payement Status"
        [visible]="false"
      />

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridFinanceReportingCostOverview"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
