import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-client';

export interface IUser {
  email: string;
  name?: string;
  avatarSource?: string;
}

export interface IResponse {
  isOk: boolean;
  data?: IUser;
  message?: string;
}

const defaultUser: IUser = {
  email: '',
  name: '',
  avatarSource:
    'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/01.png',
};

@Injectable()
export class AuthService {
  private _user: IUser | null = defaultUser;
  loginDisplay = false;

  get loggedIn(): boolean {
    return !!this._user;
  }

  constructor(private router: Router, private msalService: MsalService) {}

  async getUser() {
    try {
      //get user name, email and profile picture from msal user
      const msalUserInfo = this.msalService.instance.getActiveAccount();

      var picture = await this.getProfilePicture();

      this._user = {
        email: msalUserInfo.username,
        name: msalUserInfo.name,
        avatarSource: picture || defaultUser.avatarSource,
      };
      return {
        isOk: true,
        data: this._user,
      };
    } catch {
      return {
        isOk: false,
        data: null,
      };
    }
  }

  async getProfilePicture(): Promise<string> {
    return new Promise((resolve, reject) => {
      const graphClient = MicrosoftGraph.Client.init({
        authProvider: async (done) => {
          this.msalService
            .acquireTokenSilent({
              scopes: ['User.Read'],
            })
            .subscribe({
              next: (token: any) => {
                done(null, token.accessToken);
              },
              error: (err: any) => {
                done(err, null);
              },
            });
          //done(null, token);
        },
      });

      graphClient
        .api('/me/photo/$value')
        //.responseType(MicrosoftGraph.BlobResponse)
        .get()
        .then((response) => {
          const blob = response as Blob;

          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            const base64data = reader.result;
            const profilePicture = base64data.toString();
            resolve(profilePicture);
          };
        })
        .catch((error) => {
          console.error('Error getting profile picture:', error);
          reject(error);
        });
    });
  }

  async logOut() {
    localStorage.removeItem('permissions');
    this.msalService.logout();
    this.router.navigate(['/']);
  }

  getEmail(): string {
    return this._user?.email;
  }
}
