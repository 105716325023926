<form-popup
  [(visible)]="visible"
  (save)="onSplitClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  (onShown)="onShown($event)"
  saveText="Split"
  height="40vh"
>
  <dx-form [(formData)]="charge">
    <dxi-item itemType="group" [colCount]="2" [colSpan]="4">
      <dxi-item
        dataField="NonVatAmount"
        editorType="dxNumberBox"
        [colSpan]="2"
        [disabled]="true"
        [editorOptions]="{
          validationMessageMode: 'always',
          format: { precision: 2, type: 'fixedPoint' }
        }"
      />
    </dxi-item>
    <dxi-item>
      <div class="warning-buttons">
        <dx-button
          text="50%"
          stylingMode="contained"
          type="default"
          (onClick)="onChooseSplitPercent(0.5)"
        />
        <dx-button
          text="33%"
          stylingMode="contained"
          type="default"
          (onClick)="onChooseSplitPercent(0.33)"
        />
      </div>
    </dxi-item>
    <dxi-item [colSpan]="2" [disabled]="false" caption="Split Amount">
      <dx-number-box
        validationMessageMode="always"
        [format]="{ precision: 2 }"
        [value]="splitAmount"
        (onValueChanged)="onSplitAmountChange($event)"
      />
    </dxi-item>
  </dx-form>
</form-popup>
