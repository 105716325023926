import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice } from 'src/app/types/invoicing/invoice';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  url = apiUrl + 'api/Invoice/InvoiceHeader';

  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  /*updateInvoice(invoice: Invoice): Observable<Invoice> {
    return this.http.put<Invoice>(`${this.url}`, invoice);
  }*/

  setInvoiceAsPaid(invoiceHeaderId: number, paidOn: Date): Observable<Invoice> {
    return this.http.put<Invoice>(
      `${apiUrl}api/CreditCollect/SetInvoiceAsPaid`,
      {
        InvoiceHeaderId: invoiceHeaderId,
        PaidOn: paidOn,
      }
    );
  }

  prepareMailReminder(id: number) {
    return this.http.post(`${apiUrl}api/CreditCollect/PrepareReminder`, {
      InvoiceHeaderId: id,
    });
  }
}
