<div>
  <dx-button
    class="theme-button"
    stylingMode="text"
    [icon]="'fas fa-clock'"
    (onClick)="onButtonClick()"
    hint="Register your time"
  />
  <time-registration-register-new
    [(visible)]="isAddPopupOpened"
    (save)="onSaveClickNew($event)"
    [titleText]="'New Registration'"
    (visibleChange)="onCloseAdd($event)"
  />
</div>
