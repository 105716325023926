import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {
  GraphMessage,
  ListAllMessageResponse,
  ListAttachmentResponse,
  MailFoldersResponse,
  outlookCategoryResponse,
} from 'src/app/types/graph/message';

const API_URL = 'https://graph.microsoft.com/v1.0';

@Injectable()
export class GraphAPIMailService {
  constructor(private http: HttpClient) {}

  public getAllMessages = () =>
    this.http
      .get<ListAllMessageResponse>(`${API_URL}/me/messages`, {
        params: { $top: 25 },
      })
      .pipe(map((res) => res.value));

  public getAllMailfolderMessages = (
    mailFolderId: string,
    mailboxUPN: string,
  ) =>
    this.http
      .get<ListAllMessageResponse>(
        `${API_URL}/users/${mailboxUPN}/mailFolders/${mailFolderId}/messages`,
        { params: { $top: 25 } },
      )
      .pipe(map((res) => res.value));

  public getAllMailboxFolders = (mailboxUPN: string) =>
    this.http
      .get<MailFoldersResponse>(`${API_URL}/users/${mailboxUPN}/mailFolders`, {
        params: { $top: 25 },
      })
      .pipe(map((res) => res.value));

  public getMailboxFolder = (id: string) =>
    this.http
      .get<MailFoldersResponse>(`${API_URL}/me/mailFolders`)
      .pipe(map((res) => res.value));

  //List all email messages except for the drafts.
  public getAllMailboxMessages = (mailboxUPN: string) =>
    this.http
      .get<ListAllMessageResponse>(`${API_URL}/users/${mailboxUPN}/messages`, {
        params: { $top: 200, $filter: 'isDraft eq false' },
      })
      .pipe(map((res) => res.value));

  public getOwnMailboxMessage = (messageid: string) =>
    this.http.get<GraphMessage>(`${API_URL}/me/messages/${messageid}`);

  public getOtherMailboxMessage = (messageid: string, mailboxUPN: string) =>
    this.http.get<GraphMessage>(
      `${API_URL}/users/${mailboxUPN}/messages/${messageid}`,
    );

  public getOwnMailboxCategories = () =>
    this.http
      .get<outlookCategoryResponse>(`${API_URL}/me/outlook/mastercategories'`)
      .pipe(map((res) => res.value));

  public getMailboxCategories = (mailboxUPN: string) =>
    this.http
      .get<outlookCategoryResponse>(
        `${API_URL}/users/${mailboxUPN}/outlook/masterCategories`,
      )
      .pipe(map((res) => res.value));

  public getMessageAttachments = (mailboxUPN: string, messageid: string) =>
    this.http
      .get<ListAttachmentResponse>(
        `${API_URL}/users/${mailboxUPN}/messages/${messageid}/attachments`,
      )
      .pipe(map((res) => res.value.filter((x) => x.isInline === false)));
}
