import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  url = apiUrl + 'api/country';
  constructor(private http: HttpClient) {}

  public getCountries(): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/Lookup`);
  }

  getUrl() {
    return this.url;
  }
}
