import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DxChartModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { TimeReportingService } from 'src/app/services/time-registration/reporting.service';

@Component({
  selector: 'time-realized-to-target-graph',
  templateUrl: './time-realized-to-target-graph.component.html',
  styleUrls: ['./time-realized-to-target-graph.component.scss'],
})
export class TimeRealizedToTargetGraphComponent implements OnInit, OnChanges {
  @Input() EmployeeId: number;
  @Input() Year: number;

  Title: string = 'Time invoiced by month';

  ChartData: any;

  constructor(private TimeReportingSvc: TimeReportingService) {}

  ngOnInit() {
    this.TimeReportingSvc.getBilledTimeTrack(
      this.EmployeeId,
      this.Year
    ).subscribe((data) => {
      this.ChartData = data;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.TimeReportingSvc.getBilledTimeTrack(
        this.EmployeeId,
        this.Year
      ).subscribe((data) => {
        this.ChartData = data;
      });
    }
  }
}

@NgModule({
  declarations: [TimeRealizedToTargetGraphComponent],
  exports: [TimeRealizedToTargetGraphComponent],
  imports: [CommonModule, DxChartModule],
})
export class TimeRealizedToTargetGraphModule {}
function In(
  target: TimeRealizedToTargetGraphComponent,
  propertyKey: 'Title'
): void {
  throw new Error('Function not implemented.');
}
