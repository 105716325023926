<dx-lookup
  placeholder="Select Case Type"
  [dataSource]="datasource"
  displayExpr="CaseTypeLabel"
  valueExpr="CaseTypeId"
  (onValueChanged)="valueChanged($event)"
  [searchEnabled]="true"
  value="''"
  [showClearButton]="true"
  width="auto"
  class="lookup"
>
</dx-lookup>
