import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxLookupModule, DxSelectBoxModule } from 'devextreme-angular';
import { CRMService } from 'src/app/services/prospection/crm.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'crm-field-status-lookup',
  templateUrl: './crm-field-status-lookup.component.html',
  styleUrls: ['./crm-field-status-lookup.component.scss'],
})
export class CrmFieldStatusLookupComponent implements OnInit {
  @Input() value = false;
  @Input() valueChange: (value: number) => void;
  @Input() filters: any;

  datasource: any;

  url: string;

  constructor(private crmService: CRMService) {
    this.url = this.crmService.getUrl();
  }

  ngOnInit(): void {
    this.datasource = new DataSource({
      store: AspNetData.createStore({
        key: 'FieldStatusId',
        loadUrl: `${this.url}/FieldStatus/Lookup`,
      }),
      sort: 'Name',
      filter: this.filters,
    });
  }

  valueChanged(e) {
    this.valueChange(e.value);
  }
}

@NgModule({
  imports: [BrowserModule, DxLookupModule, DxSelectBoxModule],
  declarations: [CrmFieldStatusLookupComponent],
  exports: [CrmFieldStatusLookupComponent],
  providers: [CRMService],
})
export class CrmFieldStatusLookupModule {}
