<dx-lookup
  placeholder="Select Service Level"
  [dataSource]="datasource"
  displayExpr="ServiceLevelLabel"
  valueExpr="ServiceId"
  (onValueChanged)="valueChanged($event)"
  [searchEnabled]="true"
  value="''"
  [showClearButton]="true"
  width="auto"
  class="lookup"
>
</dx-lookup>
