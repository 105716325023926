<div>
  <dx-data-grid
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    id="gridContainer"
    [dataSource]="datasource"
    [showBorders]="true"
    [remoteOperations]="false"
    height="35vh"
    (onContentReady)="onContentReady($event)"
    (onRowInserted)="onRowInserted($event)"
    (onRowUpdated)="onRowUpdated($event)"
    (onRowUpdating)="onRowUpdating($event)"
    [wordWrapEnabled]="true"
  >
    <dxo-paging [pageSize]="500" />
    <dxo-toolbar>
      <dxi-item location="after" name="addRowButton" />
      <dxi-item
        location="after"
        widget="dxButton"
        locateInMenu="never"
        [options]="{
          text: '',
          icon: 'refresh',
          stylingMode: 'text',
          onClick: refreshData
        }"
      />
    </dxo-toolbar>

    <dxi-column dataField="EmployeeId">
      <dxo-lookup
        valueExpr="EmployeeId"
        displayExpr="FullName"
        [dataSource]="employees.store()"
      />
    </dxi-column>
    <dxi-column dataField="CaseId" [setCellValue]="onCaseChanged">
      <dxo-lookup
        valueExpr="CaseId"
        displayExpr="CaseNumber"
        [dataSource]="cases.store()"
      />
    </dxi-column>
    <dxi-column dataField="WorkCodeId" [setCellValue]="onWorkcodeChanged">
      <dxo-lookup
        valueExpr="WorkCodeId"
        displayExpr="WorkCodeFullDescription"
        [dataSource]="workcodes.store()"
      />
    </dxi-column>

    <dxi-column dataField="LineDescription" caption="Description" />
    <dxi-column
      dataField="NonVatAmount"
      caption="excl.VAT"
      [setCellValue]="onNonVatAmountChanged"
    />
    <dxi-column dataField="VatAmount" caption="VAT" />
    <dxi-column dataField="Amount" caption="Total" />
    <dxi-column
      dataField="VatRate"
      caption="VAT(%)"
      [setCellValue]="onVatRateChanged"
    />
    <dxi-column dataField="CostItemId" [visible]="false" />
    <dxi-column
      dataField="BudgetPeriodStart"
      dataType="date"
      [visible]="false"
    />
    <dxi-column dataField="BudgetPeriodEnd" dataType="date" [visible]="false" />

    <dxo-editing
      mode="popup"
      [allowUpdating]="true"
      [allowAdding]="true"
      [allowDeleting]="true"
    >
      <dxo-popup
        title="Cost Line"
        [showTitle]="true"
        [width]="700"
        [height]="650"
      >
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="saveButtonOptions"
        />
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="cancelButtonOptions"
        />
      </dxo-popup>
      <dxo-form [colCount]="1">
        <dxi-item itemType="group" [colCount]="2">
          <dxi-item
            dataField="EmployeeId"
            editorType="dxLookup"
            [colSpan]="1"
            [editorOptions]="{
              dataSource: employees,
              displayExpr: 'FullName',
              valueExpr: 'EmployeeId',
              searchEnabled: true
            }"
          >
            <dxo-label text="Employee" />
          </dxi-item>
          <dxi-item
            dataField="CaseId"
            editorType="dxLookup"
            [colSpan]="1"
            [editorOptions]="{
              dataSource: cases,
              displayExpr: 'CaseNumber',
              valueExpr: 'CaseId',
              showClearButton: true,
            }"
          >
            <dxo-label text="Case" />
          </dxi-item>
          <dxi-item
            dataField="WorkCodeId"
            editorType="dxLookup"
            [colSpan]="2"
            [editorOptions]="{
              dataSource: workcodes,
              displayExpr: 'WorkCodeFullDescription',
              valueExpr: 'WorkCodeId',
              showClearButton: true,
            }"
          >
            <dxo-label text="Work Codes" />
          </dxi-item>

          <dxi-item
            dataField="BudgetPeriodStart"
            editorType="dxDateBox"
            [editorOptions]="{
              type: 'date',
              displayFormat: 'dd/MM/yyyy',
              validationMessageMode: 'always',
              acceptCustomValue: false,
              openOnFieldClick: true
            }"
            [colSpan]="1"
          />
          <dxi-item
            dataField="BudgetPeriodEnd"
            editorType="dxDateBox"
            [editorOptions]="{
              type: 'date',
              displayFormat: 'dd/MM/yyyy',
              validationMessageMode: 'always',
              acceptCustomValue: false,
              openOnFieldClick: true
            }"
            [colSpan]="1"
          />
        </dxi-item>
        <dxi-item itemType="group" caption="Financial" [colCount]="2">
          <dxi-item dataField="LineDescription" [colSpan]="2" />
          <dxi-item
            dataField="CostItemId"
            editorType="dxLookup"
            [colSpan]="2"
            [editorOptions]="{
              dataSource: costItemCategories,
              displayExpr: 'Label',
              valueExpr: 'CostItemId',
              value: headerCostItemId,
              searchEnabled: true,
            }"
          >
            <dxi-validation-rule
              type="required"
              message="Cost Item Category is required"
            />
            <dxo-label text="Cost Item Category" />
          </dxi-item>
          <dxi-item
            dataField="NonVatAmount"
            [colSpan]="1"
            editorType="dxNumberBox"
            [editorOptions]="{
              validationMessageMode: 'always',
              format: { precision: 2, type: 'fixedPoint' }
            }"
          >
            <dxi-validation-rule
              type="required"
              message="Amount Non Vat is required"
            />
          </dxi-item>
          <dxi-item
            dataField="VatRate"
            [colSpan]="1"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: vatSelectList,
            }"
          >
            <dxi-validation-rule
              type="required"
              message="Vat Rate is required"
            />
          </dxi-item>
          <dxi-item
            dataField="VatAmount"
            [colSpan]="1"
            editorType="dxNumberBox"
            [editorOptions]="{
              validationMessageMode: 'always',
              format: { precision: 2, type: 'fixedPoint' }
            }"
          >
            <dxi-validation-rule
              type="required"
              message="Amount Vat is required"
            />
          </dxi-item>
          <dxi-item
            dataField="Amount"
            [colSpan]="1"
            editorType="dxNumberBox"
            [editorOptions]="{
              validationMessageMode: 'always',
              format: { precision: 2, type: 'fixedPoint' }
            }"
          >
            <dxi-validation-rule type="required" message="Amount is required" />
          </dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxo-state-storing
      [enabled]="false"
      type="localStorage"
      storageKey="gridInvoiceInDetailsForm"
    />
  </dx-data-grid>
</div>
