<card-analytics
  [titleText]="title"
  [isLoading]="total === null"
  [contentClass]="contentClass"
>
  <div class="total">
    {{ total }}
  </div>
  <!-- <div class="percentage" *ngIf="percentage !== 0">
    <i [class]="'dx-icon-' + (percentage > 0 ? 'spinup' : 'spindown')"></i>
    <span>{{ abs(percentage) }}%</span>
  </div> -->
</card-analytics>
