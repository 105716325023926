<dx-scroll-view class="view-wrapper-scroll"
  ><div class="view-wrapper">
    <titlebar titleText="Pavis Responses Logs" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="false"
        [allowUpdating]="false"
        [allowDeleting]="false"
      />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item location="before" locateInMenu="auto">
          <div class="date-range-container">
            <div class="date-range-title">Log Date</div>
            <dx-date-range-box
              title="Log Date"
              [startDate]="thisYear"
              [endDate]="now"
              (onValueChanged)="onDateChanged($event)"
              [showClearButton]="true"
              [width]="300"
              [max]="now"
            />
          </div>
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="LogDate" caption="Log Date" />
      <dxi-column dataField="PavisId" caption="Pavis Id" />
      <dxi-column dataField="CaseNumber" caption="Case Number" />
      <dxi-column dataField="DueDate" caption="Due Date" />
      <dxi-column dataField="OrderKind" caption="Order Kind" />
      <dxi-column dataField="Annuity" caption="Annuity" />
      <dxi-column
        dataField="CaseId"
        caption="Case Id"
        [visible]="false"
      ></dxi-column>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="renewalResponseLogGridState"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
