<form-popup
  [(visible)]="visible"
  (save)="onClickConfirm()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  saveText="Confirm"
  height="auto"
  width="20vw"
>
  <dx-form [(formData)]="pavisCase" validationGroup="validationGroup">
    <dxi-item itemType="group" [colCount]="2">
      <dxi-item
        dataField="InstructionId"
        editorType="dxLookup"
        [colSpan]="2"
        [editorOptions]="{
          dataSource: instructionTypes,
          displayExpr: 'InstructionLabel',
          valueExpr: 'InstructionId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <!--<dxi-validation-rule
          type="required"
          message="Instruction is required"
        />-->
        <dxo-label text="Instruction" />
      </dxi-item>
    </dxi-item>
  </dx-form>
</form-popup>
