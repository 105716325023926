<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Subcategories" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="New Budget Subcategory"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="addNew()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        dataField="BudgetSubCategoryId"
        caption="ID"
        [visible]="false"
      />
      <dxi-column
        dataField="BudgetSubCategoryLabel"
        caption="Budget Sub Category Label"
      />
      <dxi-column type="buttons">
        <dxi-button icon="edit" [onClick]="onEdit" />
        <dxi-button name="delete" />
      </dxi-column>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridBudgetConfigurationAdministrationSubcategory"
      />
    </dx-data-grid>
    <budget-configuration-administration-subcategory-new
      [(visible)]="isAddPopupOpened"
      (save)="onSaveClickNew($event)"
      [titleText]="'New Budget Subcategory'"
      (visibleChange)="onCloseAdd($event)"
    />
    <budget-configuration-administration-subcategory-edit
      [(visible)]="isEditPopupOpened"
      (save)="onSaveClickEdit($event)"
      [titleText]="'Edit Budget Sales'"
      (visibleChange)="onCloseEdit($event)"
      [subCategory]="editedRow"
    />
  </div>
</dx-scroll-view>
