import { Component, Input, NgModule, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { TimeReportingService } from 'src/app/services/time-registration/reporting.service';
import { Employee } from 'src/app/types/employee/employee';

@Component({
  selector: 'client-reporting',
  templateUrl: './client-reporting.component.html',
  styleUrls: ['./client-reporting.component.scss']
})
export class ClientReportingComponent implements OnInit, OnChanges {

  // dataSource
  pieChartDataSource: any = null;

  // Employee.
  @Input() Employee: Employee = null;
  @Input() ReportingYear: number = new Date().getFullYear();
  @Input() ReportingMonth: number = 0;

  constructor(
    private TimeReportingSvc: TimeReportingService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ReportingYear || changes.ReportingMonth || changes.Employee) {
      this.loadStatistics();
    }
  }

  ngOnInit() {
    this.loadStatistics();
  }

  loadStatistics() {
    this.TimeReportingSvc.getBilledTimeByClient(this.Employee.EmployeeId, this.ReportingYear, this.ReportingMonth).subscribe((data) => {
      this.pieChartDataSource = data
    });
  }

}

@NgModule({
  providers: [],
  exports: [ClientReportingComponent],
  declarations: [ClientReportingComponent],
  imports: [
    DxPieChartModule
  ],
})
export class ClientReportingModule {}
