<dx-lookup
  placeholder="Select Workcode"
  [dataSource]="workcodes"
  displayExpr="WorkCodeFullDescription"
  valueExpr="WorkCodeId"
  (onValueChanged)="valueChanged($event)"
  [searchEnabled]="true"
  class="lookup"
  [value]="value"
  [showClearButton]="true"
  width="auto"
>
</dx-lookup>
