import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class InvoiceDetailService {
  url = apiUrl + 'api/Invoice/InvoiceDetails';

  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  creditInvoiceLines(
    invoiceDetailsIds: number[],
    reopensCharge: boolean
  ): Observable<any> {
    return this.http.post<any>(`${this.url}/CreateCreditNote`, {
      InvoiceDetailsIds: invoiceDetailsIds,
      ReopenCharges: reopensCharge,
    });
  }
}
