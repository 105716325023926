<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper" *ngIf="budgetTypes">
    <titlebar titleText="Sales Targets" />
    <div class="separator"></div>
    <dx-tab-panel
      class="dx-theme-background-color"
      width="100%"
      [height]="'100%'"
      [animationEnabled]="true"
      [swipeEnabled]="false"
      [items]="budgetTypes"
      tabsPosition="top"
      stylingMode="secondary"
      iconPosition="start"
    >
      <div *dxTemplate="let tabPanelItem of 'item'">
        <div class="tabpanel-item">
          <budget-sales-target-grid [budgetType]="tabPanelItem" />
        </div>
      </div>
    </dx-tab-panel>
  </div>
</dx-scroll-view>
