export const taskPanelItems: Array<{ text: string }> = [
  {
    text: 'List',
  },
  {
    text: 'Kanban Board',
  },
  {
    text: 'Gantt',
  },
];

export type PanelItem = {
  text: string;
  startDate: string;
  endDate: string;
  key: number;
  cummulative: boolean;
};

export type Dates = { startDate: string; endDate: string };

export const analyticsPanelItems: Array<PanelItem> = [
  {
    text: 'Week',
    startDate: new Date(
      new Date().setDate(new Date().getDate() - ((new Date().getDay() + 6) % 7))
    ).toDateString(),
    endDate: new Date(
      new Date().setDate(new Date().getDate() + ((7 - new Date().getDay()) % 7))
    ).toDateString(),
    key: 1,
    cummulative: false,
  },
  {
    text: 'Month',
    startDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      1
    ).toDateString(),
    endDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    ).toDateString(),
    key: 3,
    cummulative: false,
  },
  {
    text: 'Year',
    startDate: new Date(new Date().getFullYear()-1, 0, 1).toDateString(),
    endDate: new Date(new Date().getFullYear()-1, 11, 31).toDateString(),
    key: 4,
    cummulative: false,
  },
];
