<div>
  <dx-data-grid
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    id="gridContainer"
    [dataSource]="datasource"
    [showBorders]="true"
    [remoteOperations]="{ groupPaging: true }"
    height="30vh"
    (onRowUpdating)="onRowUpdating($event)"
    [wordWrapEnabled]="true"
  >
    <dxo-toolbar>
      <dxi-item location="after" name="addRowButton" />
      <dxi-item
        location="after"
        widget="dxButton"
        locateInMenu="never"
        [options]="{
          text: '',
          icon: 'refresh',
          stylingMode: 'text',
          onClick: refreshData
        }"
      />
    </dxo-toolbar>

    <dxi-column dataField="CostItemLabel" caption="Cost Item Label" />
    <dxi-column
      dataField="CostItemId"
      caption="Bookmark Id"
      [visible]="false"
    />

    <dxo-editing
      mode="popup"
      [allowUpdating]="true"
      [allowAdding]="true"
      [allowDeleting]="true"
    >
      <dxo-popup
        title="Budget Cost Item Link"
        [showTitle]="true"
        width="20vw"
        height="auto"
      >
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="saveButtonOptions"
        />
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="cancelButtonOptions"
        />
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item
            dataField="CostItemId"
            editorType="dxLookup"
            [colSpan]="2"
            [editorOptions]="{
              dataSource: costItems,
              displayExpr: 'Label',
              valueExpr: 'CostItemId'
            }"
          >
            <dxi-validation-rule
              type="required"
              message="Cost Item Category is required"
            >
            </dxi-validation-rule>
            <dxo-label text="Cost Item Category"> </dxo-label>
          </dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxo-state-storing
      [enabled]="false"
      type="localStorage"
      storageKey="gridBudgetSubCategoryDetails"
    />
  </dx-data-grid>
</div>
