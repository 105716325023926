import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import { environment } from 'src/environments/environment';
import { Column } from 'devextreme/ui/data_grid';
import { GridType } from 'src/app/types/data-quality/grid-type';
import { DataQualityGridModule } from 'src/app/components/data-quality/data-quality-grid/data-quality-grid.component';

@Component({
  selector: 'security-px-diary',
  templateUrl: './security-px-diary.component.html',
  styleUrls: ['./security-px-diary.component.scss'],
})
export class SecurityPxDiaryComponent {
  url: string;

  gridTypes: GridType[] = [
    {
      key: 'ApplicationDateNumber',
      value: 'Application Date Number',
      description: 'Check that all files have an application date and number',
    },
    {
      key: 'CasesWithGrantNumberDate',
      value: 'Cases with Grant Number Date',
      description: 'Check that all granted cases have a grant number and date',
    },
    {
      key: 'NationalApplicationNumber',
      value: 'National Application Number',
      description:
        'IT, HR and DE: validated after EPC missing National Application Number',
    },
    {
      key: 'PatentTermAdjustment',
      value: 'Patent Term Adjustments',
      description: 'Patent Term Adjustment should be filled in',
    },
    {
      key: 'PriorityData',
      value: 'Priority Data',
      description: 'Cases with priority should have priority data',
    },
  ];

  selectedGrid: GridType;

  columns: Column[] = [];

  constructor() {
    this.url = `${environment.CalystaApiBaseURL}api/Security/PxDiary`;
    this.selectedGrid = this.gridTypes[0];
  }

  changeData = (gridType: GridType) => {
    this.selectedGrid = gridType;

    const columns: Column[] = [
      {
        type: 'buttons',
        buttons: [
          {
            hint: 'Open Patricia',
            icon: 'fas fa-horse',
            onClick: this.openPatricia,
          },
        ],
        width: 'auto',
      },
      {
        dataField: 'CaseId',
        caption: 'Case ID',
        alignment: 'center',
      },
      {
        dataField: 'PatriciaLink',
        caption: 'Patricia Link',
        visible: false,
        showInColumnChooser: false,
      },
    ];

    //create columns depending on the selected grid
    switch (gridType.key) {
      case 'ApplicationDateNumber':
        columns.push(
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'Catch Word',
            alignment: 'center',
          },
          {
            dataField: 'ApplicationDate',
            caption: 'Application Date',
            dataType: 'date',
            alignment: 'center',
          }
        );
        break;
      case 'CasesWithGrantNumberDate':
        columns.push(
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'Catch Word',
            alignment: 'center',
          },
          {
            dataField: 'GrantNumber',
            caption: 'Grant Number',
            alignment: 'center',
          },
          {
            dataField: 'GrantDate',
            caption: 'Grant Date',
            dataType: 'date',
            alignment: 'center',
          }
        );
        break;
      case 'NationalApplicationNumber':
        columns.push(
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'Catch Word',
            alignment: 'center',
          }
        );
        break;
      case 'PatentTermAdjustment':
        columns.push(
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          }
        );
        break;
      case 'PriorityData':
        columns.push(
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'PriorityNumber',
            caption: 'Priority Number',
            alignment: 'center',
          },
          {
            dataField: 'PriorityCountry',
            caption: 'Priority Country',
            alignment: 'center',
          }
        );
        break;
    }

    this.columns = columns;
  };

  openPatricia = (e) => {
    const patriciaLink = e.row.data.PatriciaLink;
    window.open(patriciaLink, '_blank');
    e.event.preventDefault();
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    DxPopupModule,
    DxFormModule,
    DataQualityGridModule,
  ],
  declarations: [SecurityPxDiaryComponent],
  exports: [],
})
export class SecurityPxDiaryModule {}
