<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Mappings" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onExporting)="onExporting($event)"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      >
        <dxo-popup
          [showTitle]="true"
          [title]="'Mapping'"
          height="auto"
          width="35vw"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form>
          <dxi-item
            itemType="group"
            caption="Patricia"
            [colCount]="2"
            [colSpan]="2"
          >
            <dxi-item
              dataField="EventSchemeId"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="Event Id" />
            </dxi-item>
            <dxi-item
              dataField="Visible"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="Visible" />
            </dxi-item>
          </dxi-item>
          <dxi-item
            itemType="group"
            caption="Client Portal"
            [colCount]="2"
            [colSpan]="2"
          >
            <dxi-item dataField="WorkFlowCategoryId" editorType="dxLookup">
              <dxo-label text="Category" />
            </dxi-item>
            <dxi-item
              dataField="HasDocument"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="Has Related Doc" />
            </dxi-item>
            <dxi-item itemType="group" [colCount]="4">
              <dxi-item
                [colSpan]="3"
                dataField="WfStatusTextId"
                editorType="dxLookup"
                [editorOptions]="{
                  dataSource: status,
                  displayExpr: 'StatusText',
                  valueExpr: 'WfstatusTextId',
                  searchEnabled: true,
                  validationMessageMode: 'always'
                }"
              >
                <dxo-label text="Status" />
              </dxi-item>
              <dxi-item
                itemType="button"
                [colSpan]="1"
                [buttonOptions]="{
                  icon: 'fas fa-arrow-up-right-from-square',
                  onClick: onClickRedirectToStatus
                }"
              />
            </dxi-item>
            <dxi-item itemType="group" [colCount]="4">
              <dxi-item
                [colSpan]="3"
                dataField="WfActionTextId"
                editorType="dxLookup"
                [editorOptions]="{
                  dataSource: actions,
                  displayExpr: 'ActionText',
                  valueExpr: 'WfactionTextId',
                  searchEnabled: true,
                  validationMessageMode: 'always'
                }"
              >
                <dxo-label text="Action" />
              </dxi-item>
              <dxi-item
                itemType="button"
                [colSpan]="1"
                [buttonOptions]="{
                  icon: 'fas fa-arrow-up-right-from-square',
                  onClick: onClickRedirectToAction
                }"
              />
            </dxi-item>
            <dxi-item
              dataField="ResponseTypeId"
              editorType="dxLookup"
              [colSpan]="2"
              [editorOptions]="{
                dataSource: responseTypes,
                displayExpr: 'Label',
                valueExpr: 'WfresponseTypeId',
                searchEnabled: true,
                validationMessageMode: 'always'
              }"
            >
              <dxo-label text="Response Type" />
            </dxi-item>
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export
        [enabled]="true"
        [fileName]="'Mappings'"
        [allowExportSelectedData]="false"
      />

      <dxo-toolbar>
        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="New Mapping"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="this.dataGrid.instance.addRow()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="exportButton" />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        dataField="EventSchemeId"
        caption="EventId"
        alignment="center"
        width="5%"
      />
      <dxi-column
        dataField="PxStatusText"
        caption="Px Status Text"
        alignment="center"
      />
      <dxi-column
        dataField="PxActionText"
        caption="Px Action Text"
        alignment="center"
      />
      <dxi-column
        dataField="WorkFlowCategoryId"
        caption="Category Label"
        alignment="center"
      >
        <dxo-lookup
          [dataSource]="categories.store()"
          displayExpr="WorkFlowCategoryDescription"
          valueExpr="WorkFlowCategoryId"
        />
      </dxi-column>
      <dxi-column
        dataField="WfStatusTextId"
        caption="WF Status text"
        alignment="center"
      >
        <dxo-lookup
          [dataSource]="status.store()"
          displayExpr="StatusText"
          valueExpr="WfstatusTextId"
        />
      </dxi-column>
      <dxi-column
        dataField="WfActionTextId"
        caption="WF Action Text"
        alignment="center"
      >
        <dxo-lookup
          [dataSource]="actions.store()"
          displayExpr="ActionText"
          valueExpr="WfactionTextId"
        />
      </dxi-column>
      <dxi-column
        dataField="ResponseTypeId"
        caption="Response Type"
        alignment="center"
      >
        <dxo-lookup
          [dataSource]="responseTypes.store()"
          displayExpr="Label"
          valueExpr="WfresponseTypeId"
        />
      </dxi-column>
      <dxi-column
        dataField="Visible"
        caption="Visible"
        alignment="center"
        width="5%"
      />
      <dxi-column
        dataField="HasDocument"
        caption="Has Related Doc"
        alignment="center"
        width="5%"
        [visible]="false"
      />

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridClientPortalCaseTypeMapping"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
