import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxDataGridComponent,
  DxDataGridModule,
  DxNumberBoxModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import {
  EventConfigurationLineDetailsFormComponent,
  EventConfigurationLineDetailsFormModule,
} from '../event-configuration-line-details/event-configuration-line-details.component';

@Component({
  selector: 'event-configuration-details',
  templateUrl: './event-configuration-details.component.html',
  styleUrls: ['./event-configuration-details.component.scss'],
})
export class EventConfigurationDetailsFormComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @ViewChild(EventConfigurationLineDetailsFormComponent, { static: false })
  eventConfigurationLineDetailsFormComponent: EventConfigurationLineDetailsFormComponent;

  @Input() entity: any;

  @Input() datasource: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  instructionTypes: any;
  officialFeeWorkCodes: any;
  agentFeeWorkCodes: any;

  rowSelected: any;

  detailDataSource: DataSource;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  constructor() {
    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.instructionTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'InstructionId',
        loadUrl: `${this.url}Renewal/InstructionType`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'InstructionLabel',
    });

    this.officialFeeWorkCodes = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkCodeId',
        loadUrl: `${this.url}Invoice/WorkCode/lookup`,
      }),
      sort: ['WorkCodeLabel', 'WorkCodeDescription'],
    });

    this.agentFeeWorkCodes = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkCodeId',
        loadUrl: `${this.url}Invoice/WorkCode/lookup`,
      }),
      sort: ['WorkCodeLabel', 'WorkCodeDescription'],
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onRowInserting = () => {
    this.rowSelected = undefined;
  };

  hasRow = () => {
    return this.rowSelected !== undefined;
  };

  onShowing = (e) => {
    if (this.rowSelected !== undefined) {
      this.eventConfigurationLineDetailsFormComponent.onFocusIn(
        this.rowSelected.EventConfigurationLineId
      );
    }
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxNumberBoxModule,
    EventConfigurationLineDetailsFormModule,
  ],
  declarations: [EventConfigurationDetailsFormComponent],
  exports: [EventConfigurationDetailsFormComponent],
})
export class EventConfigurationDetailsFormModule {}
