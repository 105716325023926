<div>
  <dx-data-grid
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    id="gridContainer"
    [dataSource]="datasource"
    [showBorders]="true"
    [remoteOperations]="true"
    height="35vh"
    (onRowUpdating)="onRowUpdating($event)"
    [wordWrapEnabled]="true"
  >
    <dxo-toolbar>
      <dxi-item location="after" name="addRowButton" />
      <dxi-item
        location="after"
        widget="dxButton"
        locateInMenu="never"
        [options]="{
          text: '',
          icon: 'refresh',
          stylingMode: 'text',
          onClick: refreshData
        }"
      />
    </dxo-toolbar>

    <dxi-column dataField="TeamId" caption="Team" alignment="center">
      <dxo-lookup
        [dataSource]="teams.store()"
        valueExpr="TeamId"
        displayExpr="TeamLabel"
      />
    </dxi-column>
    <dxi-column
      dataField="ContributionPercentage"
      caption="Contribution Percentage"
      alignment="center"
    >
      <dxo-format type="percent" precision="2" />
    </dxi-column>

    <dxo-editing
      mode="popup"
      [allowUpdating]="true"
      [allowAdding]="true"
      [allowDeleting]="true"
    >
      <dxo-popup
        title="Budget Sales Target"
        [showTitle]="true"
        width="30vw"
        height="auto"
      >
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="saveButtonOptions"
        />
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="cancelButtonOptions"
        />
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item
            dataField="TeamId"
            editorType="dxLookup"
            [colSpan]="2"
            [editorOptions]="{
              dataSource: teams,
              displayExpr: 'TeamLabel',
              valueExpr: 'TeamId',
              searchEnabled: true,
              validationMessageMode: 'always'
            }"
          >
            <dxi-validation-rule type="required" message="Team is required" />
            <dxo-label text="Team" />
          </dxi-item>
          <dxi-item dataField="ContributionPercentage">
            <dxi-validation-rule
              type="range"
              min="0"
              max="1"
              message="Contribution Percentage must be between 0 and 1"
            />
            <dxo-label text="Contribution Percentage" />
          </dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxo-state-storing
      [enabled]="false"
      type="localStorage"
      storageKey="gridBudgetSalesTargetDetails"
    />
  </dx-data-grid>
</div>
