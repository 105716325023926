<dx-lookup
  placeholder="Select status"
  [dataSource]="datasource"
  displayExpr="Name"
  valueExpr="FieldStatusId"
  (onValueChanged)="valueChanged($event)"
  [searchEnabled]="true"
  value="''"
  [showClearButton]="true"
  width="auto"
  class="lookup"
>
</dx-lookup>
