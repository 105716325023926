<dx-select-box
  class="col no-gutters"
  placeholder="Subcategory"
  [dataSource]="subCategories"
  valueExpr="subCategoryId"
  displayExpr="label"
  keyExpr="categoryId"
  [(value)]="currentSubCategoryId"
  (onItemClick)="SelectSubCategory()"
  [searchEnabled]="true"
>
</dx-select-box>
