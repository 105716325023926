

<dx-data-grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  id="servicesGrid"
  [dataSource]="dataSource"
  [showBorders]="true"
  [remoteOperations]="true"
  [showBorders]="true"
  [repaintChangesOnly]="true"
  [wordWrapEnabled]="true"
>
  <dxo-load-panel [enabled]="true" />
  <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
  <dxo-sorting mode="multiple" />
  <dxo-column-chooser [enabled]="false" />
  <dxo-search-panel [visible]="false" />

  <dxo-toolbar>
    <dxi-item location="after" locateInMenu="never">
      <dx-button
        icon="plus"
        type="default"
        stylingMode="contained"
        (onClick)="showServicePopup = true"
      />
    </dxi-item>
    <dxi-item
      location="after"
      widget="dxButton"
      locateInMenu="never"
      [options]="{
        text: '',
        icon: 'refresh',
        stylingMode: 'text',
      }"
    />
    <dxi-item location="after" name="columnChooserButton" locateInMenu="auto" />
    <dxi-item location="after" name="searchPanel" locateInMenu="never" />
  </dxo-toolbar>

  <dxi-column dataField="ServiceTypeId" caption="Service Type" calculateDisplayValue="ServiceTypeLabel">
    <dxo-lookup [dataSource]="serviceTypesDataSource?.store()" displayExpr="ServiceTypeLabel" valueExpr="ServiceTypeId">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="CaseTypeId" caption="Case Type" calculateDisplayValue="CaseTypeLabel">
    <dxo-lookup [dataSource]="caseTypesDataSource?.store()" displayExpr="CaseTypeLabel" valueExpr="CaseTypeId">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="Description" caption="Description" />
  <dxi-column dataField="CoverageId" caption="Coverage" />
  <dxi-column dataField="Amount" caption="Price" format="currency" />
</dx-data-grid>


  <fee-calculator-wizard-services-selector [(showPopup)]="showServicePopup" ></fee-calculator-wizard-services-selector>

