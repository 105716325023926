import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxLookupModule, DxSelectBoxModule } from 'devextreme-angular';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { InitializedEvent } from 'devextreme/ui/lookup';
import { environment } from 'src/environments/environment';
import { PatriciaService } from 'src/app/services/patricia/patricia.service';

@Component({
  selector: 'team-lookup',
  templateUrl: './team-lookup.component.html',
  styleUrls: ['./team-lookup.component.scss'],
})
export class TeamLookupComponent implements OnInit {
  @Input() value: number;
  @Input() valueChange: (value: number) => void;

  defaultValue: number;

  datasource: any;

  url: string;

  constructor(private patriciaService: PatriciaService) {
    this.url = environment.CalystaApiBaseURL + 'api/';
  }

  ngOnInit(): void {
    this.datasource = new DataSource({
      store: AspNetData.createStore({
        key: 'TeamId',
        loadUrl: `${this.url}Patricia/Team/Lookup`,
      }),
      sort: ['TeamLabel'],
    });
  }

  valueChanged(e) {
    this.valueChange(e.value);
  }

  onInitialized(e: InitializedEvent) {
    if (this.value) {
      this.valueChange(this.value);
    } else {
      this.getDefaultValue();
    }
  }

  getDefaultValue() {
    this.patriciaService.getMyTeam().subscribe((data) => {
      this.defaultValue = data.TeamId;
      this.valueChange(this.defaultValue);
    });
  }
}

@NgModule({
  imports: [BrowserModule, DxLookupModule, DxSelectBoxModule],
  declarations: [TeamLookupComponent],
  exports: [TeamLookupComponent],
  providers: [EmployeeService],
})
export class TeamLookupModule {}
