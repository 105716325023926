<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="PX Actions" />
    <div class="separator"></div>
    <p>
      <b>{{ selectedGrid.description }}</b>
    </p>
    <data-quality-grid
      [dataKey]="'CaseId'"
      [url]="url"
      [gridTypes]="gridTypes"
      [gridType]="selectedGrid"
      fileExportName="Actions"
      [columns]="columns"
      (onGridTypeChanged)="changeData($event)"
    />
  </div>
</dx-scroll-view>
