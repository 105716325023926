import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxValidationGroupModule,
  DxValidationSummaryModule,
  DxValidatorModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { RwfollowUp } from 'src/app/types/invoicing/rwFollowUp';
import DataSource from 'devextreme/data/data_source';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';

@Component({
  selector: 'pavis-handle-instruction-form',
  templateUrl: './pavis-handle-instruction-form.component.html',
  styleUrls: ['./pavis-handle-instruction-form.component.scss'],
})
export class PavisHandleInstructionFormComponent implements OnInit {
  @Input() titleText = '';

  @Input() visible = false;

  @Input() pavisCase: any;

  @Output() save = new EventEmitter<any>();

  @Output() visibleChange = new EventEmitter<boolean>();

  instructionTypes: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {}

  ngOnInit(): void {
    this.instructionTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'InstructionId',
        loadUrl: `${this.url}Renewal/InstructionType`,
      }),
      sort: 'InstructionLabel',
    });
  }

  handleVisible = (e: boolean): void => {
    this.visible = e;
    this.visibleChange.emit(e);
  };

  onClickConfirm = (): void => {
    this.save.emit(this.pavisCase.InstructionId);
  };
}

@NgModule({
  declarations: [PavisHandleInstructionFormComponent],
  exports: [PavisHandleInstructionFormComponent],
  bootstrap: [PavisHandleInstructionFormComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxValidationGroupModule,
    DxValidationSummaryModule,
  ],
})
export class PavisHandleInstructionFormModule {}
