import {
  Component,
  NgModule,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScreenService } from 'src/app/services';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxTabsModule } from 'devextreme-angular/ui/tabs';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';

import { ItemClickEvent as TabsItemClickEvent } from 'devextreme/ui/tabs';

import { Dates, PanelItem } from 'src/app/types/resource';

@Component({
  selector: 'titlebar',
  templateUrl: './titlebar.component.html',
  styleUrls: ['./titlebar.component.scss'],
})
export class TitleBarComponent {
  @Input() selectedItems: Array<number>;

  @Input() titleText: string;

  @Input() panelItems: Array<PanelItem>;

  @Input() useButton: boolean = false;

  @Input() iconButton: string;

  @Input() icon: string;

  @Output() onButtonClicked = new EventEmitter<any>();

  @Output() selectionChanged = new EventEmitter<Dates>();

  constructor(protected screen: ScreenService) {}

  selectionChange(e: TabsItemClickEvent) {
    const dates = e.itemData.value.split('/');

    this.selectionChanged.emit({ startDate: dates[0], endDate: dates[1] });
  }

  buttonClick() {
    this.onButtonClicked.emit();
  }
}

@NgModule({
  imports: [CommonModule, DxButtonModule, DxTabsModule, DxToolbarModule],
  declarations: [TitleBarComponent],
  exports: [TitleBarComponent],
})
export class TitleBarModule {}
