<dx-popup
  #warningPopup
  [(visible)]="visible"
  width="400"
  height="auto"
  title="Confirmation of your quality check"
>
  <dx-form [(formData)]="confirmationData">
    <dxi-item itemType="group" colCount="2">
      <dxi-item
        dataField="Status"
        editorType="dxLookup"
        [colSpan]="2"
        [editorOptions]="{
          validationMessageMode: 'always',
          items: [
            'Everything is correct',
            'Still problems',
            'Urgent help needed'
          ]
        }"
      >
        <dxi-validation-rule
          type="required"
          message="Status value is required"
        />
        <dxo-label text="Status" />
      </dxi-item>
      <dxi-item dataField="Remarks" editorType="dxTextBox" [colSpan]="2">
        <dxo-label text="Remarks" />
      </dxi-item>
    </dxi-item>
  </dx-form>
  <div class="warning-buttons">
    <dx-button
      style="background-color: orangered"
      text="Save"
      (onClick)="onSaveButtonClick()"
    />
    <dx-button text="Cancel" (onClick)="onCancelButtonClick()" />
  </div>
</dx-popup>
