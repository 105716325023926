import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxTagBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';

@Component({
  selector: 'finance-charges-remove-form',
  templateUrl: './finance-charges-remove-form.component.html',
  styleUrls: ['./finance-charges-remove-form.component.scss'],
})
export class FinanceChargeRemoveFormComponent {
  @Input() charge: any;

  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<{
    removeTime: boolean;
    workCodeId?: number | null;
  }>();

  @Output() visibleChange = new EventEmitter<boolean>();

  workCodeId: number;

  constructor() {}

  onRemoveTime() {
    this.save.emit({
      removeTime: true,
    });

    this.visibleChange.emit(false);
  }

  onWorkCodeChanged(e: number) {
    this.workCodeId = e;
  }

  onTransfertToNB() {
    this.save.emit({
      removeTime: false,
      workCodeId: this.workCodeId,
    });

    this.visibleChange.emit(false);
  }

  onCancel() {
    this.visibleChange.emit(false);
  }

  onShow() {
    this.workCodeId = this.charge.WorkCodeId || null;
  }
}

@NgModule({
  declarations: [FinanceChargeRemoveFormComponent],
  exports: [FinanceChargeRemoveFormComponent],
  bootstrap: [FinanceChargeRemoveFormComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    DxPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
    DxDataGridModule,
    DxCheckBoxModule,
    DxPopupModule,
    DxButtonModule,
    WorkcodeLookupModule,
  ],
})
export class FinanceChargeRemoveFormModule {}
