import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownBoxModule,
  DxDropDownButtonModule,
  DxScrollViewModule,
  DxTextAreaModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { EditorPreparingEvent } from 'devextreme/ui/data_grid';
import notify from 'devextreme/ui/notify';
import { TitleBarModule } from 'src/app/components';
import { FinanceConfigurationWorkcodeLocalizeModule } from 'src/app/components/finance/finance-configuration/finance-configuration-workcode-localize/finance-configuration-workcode-localize.component';
import { PopupFileUploadModule } from 'src/app/components/utils/popup-file-upload/popup-file-upload.component';
import { AppPermissions } from 'src/app/routes/permissions';
import { AuthPermissionService } from 'src/app/services/auth/permission.service';
import { CalystaAPIInvoiceService } from 'src/app/services/calysta-api/invoice.service';

@Component({
  selector: 'finance-configuration-workcode',
  templateUrl: './finance-configuration-workcode.component.html',
  styleUrls: ['./finance-configuration-workcode.component.scss'],
})
export class FinanceConfigurationWorkcodeComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  categories: any;

  invoiceLineCategories: any;

  url: string;

  isLocalizePopupOpened: boolean = false;
  isImportPopupOpened: boolean = false;

  editedRow: any;

  isAdmin = this.authPermissionsService.hasPermission(AppPermissions.ADMIN);
  hasFinancialPermission = this.authPermissionsService.hasPermission(
    AppPermissions.FINANCIAL
  );

  saveButtonOptions: any;
  cancelButtonOptions: any;

  constructor(
    private invoiceService: CalystaAPIInvoiceService,
    private authPermissionsService: AuthPermissionService
  ) {
    this.url = this.invoiceService.getUrl();

    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkCodeId',
        loadUrl: `${this.url}/workcode`,
        insertUrl: `${this.url}/workcode`,
        updateUrl: `${this.url}/workcode`,
        deleteUrl: `${this.url}/workcode`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });

    this.categories = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkCodeCategoryId',
        loadUrl: `${this.url}/WorkCodeCategory/Lookup`,
      }),
      sort: 'WorkCodeCategoryLabel',
    });

    this.invoiceLineCategories = new DataSource({
      store: AspNetData.createStore({
        key: 'InvoiceLineCategoryId',
        loadUrl: `${this.url}/InvoiceLineCategory/Lookup`,
      }),
      sort: 'InvoiceLineCategoryLabel',
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onCloseLocalizePopup = (e: boolean) => {
    this.isLocalizePopupOpened = e;
    this.refreshData();
  };

  onLocalize = (e: any) => {
    this.editedRow = { ...e.row.data };
    this.isLocalizePopupOpened = true;
  };

  onEditorPreparing(e: EditorPreparingEvent) {
    if (e.parentType === 'dataRow' && e.dataField === 'WorkCodeDescription') {
      e.editorName = 'dxTextArea';
    }
  }

  onOpenImport = () => {
    this.isImportPopupOpened = true;
  };

  onSaveImport = (document: any) => {
    this.isImportPopupOpened = false;

    if (document) {
      const formData = new FormData();
      formData.append('File', document);

      this.invoiceService.importFromFile(formData).subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
        complete: () => {
          this.refreshData();
        },
      });
    }
  };

  onCloseImport = () => {
    this.isImportPopupOpened = false;
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    DxDropDownBoxModule,
    DxDropDownButtonModule,
    DxCheckBoxModule,
    DxDateBoxModule,
    TitleBarModule,
    DxScrollViewModule,
    FinanceConfigurationWorkcodeLocalizeModule,
    DxTextAreaModule,
    PopupFileUploadModule,
  ],
  providers: [],
  declarations: [FinanceConfigurationWorkcodeComponent],
  exports: [],
})
export class FinanceInvoicesInModule {}
