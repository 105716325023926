import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class PavisService {
  url = apiUrl + 'api/renewal/pavis';
  constructor(private http: HttpClient) {}

  public getPavisStatus(): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/PavisFilter/Lookup`);
  }

  getUrl() {
    return this.url;
  }

  calculateInternalRenewalQuote(pavisCasesToRenews: any[]) {
    let params = new HttpParams().set(
      'PavisCasesToRenews',
      JSON.stringify(pavisCasesToRenews)
    );

    return this.http.get<any[]>(
      `${this.url}/PavisCasesToRenew/GetRenewalQuote`,
      { params }
    );
  }

  handleInstruction(pavisCasesToRenews: any) {
    let params = new HttpParams().set(
      'PavisCase',
      JSON.stringify(pavisCasesToRenews)
    );

    return this.http.post<any[]>(
      `${this.url}/PavisCasesToRenew/HandleInstruction`,
      {
        PavisCase: pavisCasesToRenews,
      }
    );
  }

  handleInstructions(pavisCasesToRenews: any[], instructionId: number) {
    return this.http.post<any[]>(
      `${this.url}/PavisCasesToRenew/HandleInstructions`,
      {
        PavisCases: pavisCasesToRenews,
        InstructionId: instructionId,
      }
    );
  }

  importCases(cases: any) {
    return this.http.post<any[]>(
      `${this.url}/PavisCasesToRenew/ImportInPavis`,
      { CaseIds: cases }
    );
  }

  transferToPavis(data: any) {
    return this.http.post<any[]>(
      `${this.url}/PavisInstruction/TransferToPavis`,
      {
        PavisInstructionIds: data,
      }
    );
  }
}
