import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  DxAccordionModule,
  DxButtonModule,
  DxDropDownButtonModule,
  DxToolbarModule,
  DxLoadPanelModule,
  DxScrollViewModule,
  DxFormModule,
  DxValidatorModule,
  DxValidationGroupModule,
} from 'devextreme-angular';
import { ButtonClickEvent } from 'devextreme/ui/drop_down_button';
import { Subscription } from 'rxjs';
import { ScreenService } from 'src/app/services';
import { ContactStatusModule } from '../../utils/contact-status/contact-status.component';
import { FormPhotoModule } from '../../utils/form-photo/form-photo.component';
import { FormTextboxModule } from '../../utils/form-textbox/form-textbox.component';
import { GraphMessage } from 'src/app/types/graph/message';
import { GraphAPIMailService } from 'src/app/services/graphapi/graphapi-mail.service';
import { CardActivitiesModule } from '../../library/card-activities/card-activities.component';

@Component({
  selector: 'app-docket-mail-panel',
  templateUrl: './docket-mail-panel.component.html',
  styleUrls: ['./docket-mail-panel.component.scss'],
})
export class DocketMailPanelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isOpened = false;

  @Input() emailId: string;
  @Input() mailboxId: string;

  @Output() isOpenedChange = new EventEmitter<boolean>();
  @Output() docketMailClicked = new EventEmitter<GraphMessage>();

  email!: GraphMessage;

  isLoading = true;

  isEditing = false;

  isPinned = false;

  isPinEnabled = false;

  userPanelSubscriptions: Subscription[] = [];

  constructor(
    private screen: ScreenService,
    private router: Router,
    private http: HttpClient,
    private graphService: GraphAPIMailService,
  ) {
    this.userPanelSubscriptions.push(
      this.screen.changed.subscribe(this.calculatePin.bind(this)),
    );
  }

  ngOnInit(): void {
    this.calculatePin();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { emailId } = changes;

    if (emailId?.currentValue) {
      this.loadUserById(emailId.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.userPanelSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  loadUserById = (id: string) => {
    this.isLoading = true;

    this.graphService
      .getOtherMailboxMessage(this.emailId, this.mailboxId)
      .subscribe((data) => {
        this.email = data;
        this.isLoading = false;
      });
  };

  onClosePanel = () => {
    this.isOpened = false;
    this.isOpenedChange.emit(this.isOpened);
  };

  onPinClick = () => {
    this.isPinned = !this.isPinned;
  };

  onSaveClick = () => {
    return;
  };

  onActionsClick = (e) => {
    if (e) {
      switch (e.itemData) {
        case 'Docket':
          this.docketMailClicked.emit(this.email);
          break;

        default:
          break;
      }
    }
  };

  calculatePin = () => {
    this.isPinEnabled =
      this.screen.sizes['screen-large'] || this.screen.sizes['screen-medium'];
    if (this.isPinned && !this.isPinEnabled) {
      this.isPinned = false;
    }
  };

  accordionTitleClick = (e: ButtonClickEvent) => {
    e.event.stopPropagation();
  };

  toggleEdit = () => {
    this.isEditing = !this.isEditing;
  };

  navigateToDetails = () => {
    this.router.navigate(['/crm-contact-details']);
  };
}

@NgModule({
  imports: [
    DxAccordionModule,
    DxButtonModule,
    DxDropDownButtonModule,
    DxToolbarModule,
    DxLoadPanelModule,
    DxScrollViewModule,
    DxFormModule,
    DxValidatorModule,
    DxValidationGroupModule,
    CardActivitiesModule,
    FormTextboxModule,
    FormPhotoModule,
    ContactStatusModule,
    CommonModule,
  ],
  declarations: [DocketMailPanelComponent],
  exports: [DocketMailPanelComponent],
})
export class DocketMailPanelModule {}
