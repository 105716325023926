import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxFormModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxScrollViewModule,
  DxToolbarModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import DataSource from 'devextreme/data/data_source';
import { PDFPreviewModule } from 'src/app/components/library/pdf-preview/pdf-preview.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';

@Component({
  selector: 'finance-invoices-out-details',
  templateUrl: './finance-invoices-out-details.component.html',
  styleUrls: ['./finance-invoices-out-details.component.scss'],
})
export class FinanceInvoicesOutDetailsComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() invoiceHeaderId: number;

  dataSource: DataSource;

  invoiceLineCategories: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'InvoiceDetailId',
        loadUrl: `${this.url}Invoice/InvoiceDetails/all`,
        loadParams: { invoiceHeaderId: this.invoiceHeaderId },
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });

    this.invoiceLineCategories = new DataSource({
      store: AspNetData.createStore({
        key: 'InvoiceLineCategoryId',
        loadUrl: `${this.url}Invoice/InvoiceLineCategory/Lookup`,
      }),
      sort: 'InvoiceLineCategoryLabel',
    });
  }
}

@NgModule({
  declarations: [FinanceInvoicesOutDetailsComponent],
  exports: [FinanceInvoicesOutDetailsComponent],
  bootstrap: [FinanceInvoicesOutDetailsComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    DxDataGridModule,
    DxButtonModule,
    DxScrollViewModule,
    PDFPreviewModule,
    FormPopupModule,
    DxToolbarModule,
    DxLoadPanelModule,
    DxPopupModule,
  ],
})
export class FinanceInvoicesOutDetailsModule {}
