import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice } from 'src/app/types/invoicing/invoice';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class DraftInvoiceService {
  url = apiUrl + 'api/Invoice/DraftInvoice';

  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  updateDraftInvoice(invoice: Invoice): Observable<Invoice> {
    return this.http.put<Invoice>(`${this.url}`, invoice);
  }
}
