<dx-popup
  title="Select lines to credit"
  [(visible)]="visible"
  [height]="'60vh'"
  (onShowing)="onShowing()"
>
  <dx-data-grid
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    [dataSource]="invoiceDetailsDataSource"
    [remoteOperations]="true"
    (onRowUpdating)="onRowUpdating($event)"
    [wordWrapEnabled]="true"
  >
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
    <dxo-selection
      [selectAllMode]="'allPages'"
      [showCheckBoxesMode]="'always'"
      mode="multiple"
    />

    <dxi-column dataField="CalystaReference" [width]="200">
      <dxo-format type="currency" [precision]="2" />
    </dxi-column>
    <dxi-column dataField="Description" />
    <dxi-column dataField="NonVatAmount" [width]="100" caption="Amount">
      <dxo-format type="currency" [precision]="2" />
    </dxi-column>
    <dxi-column dataField="VatAmount" [width]="100" caption="VAT">
      <dxo-format type="currency" [precision]="2" />
    </dxi-column>
    <dxi-column dataField="Amount" [width]="100" caption="Total">
      <dxo-format type="currency" [precision]="2" />
    </dxi-column>

    <dxo-state-storing
      [enabled]="false"
      type="localStorage"
      storageKey="gridCreditLinesSelectionForm"
    />
  </dx-data-grid>
  <div class="form-popup-buttons-container">
    <dx-button text="Cancel" stylingMode="contained" (onClick)="onClose()" />
    <dx-button
      text="Credit"
      stylingMode="contained"
      type="default"
      (onClick)="onSaveClick()"
      [disabled]="!hasRowsSelected()"
    />
  </div>
</dx-popup>
