import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { WorkcodeLookupModule } from '../../lookups/workcode-lookup/workcode-lookup.component';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { FormPopupModule } from '../../utils/form-popup/form-popup.component';
import DataSource from 'devextreme/data/data_source';
import { CrmProspectDetailsFormModule } from '../crm-prospect-details/crm-prospect-details.component';
import ArrayStore from 'devextreme/data/array_store';

@Component({
  selector: 'crm-prospect-new',
  templateUrl: './crm-prospect-new.component.html',
  styleUrls: ['./crm-prospect-new.component.scss'],
})
export class CrmProspectNewComponent implements OnInit {
  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<{
    prospect: any;
    contacts: any[];
  }>();

  @Output() visibleChange = new EventEmitter<boolean>();

  prospect: any;

  notes: string;

  fieldStatus: DataSource;

  origins: DataSource;

  poles: DataSource;

  pmes: DataSource;

  employees: DataSource;

  segmentations: DataSource;

  languages: DataSource;

  detailDataSource: DataSource;

  arrayStore: ArrayStore;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {}

  ngOnInit(): void {
    this.prospect = {};

    this.fieldStatus = new DataSource({
      store: AspNetData.createStore({
        key: 'FieldStatusId',
        loadUrl: `${this.url}Prospection/FieldStatus/Lookup`,
      }),
      sort: 'Name',
      filter: ['!', ['Name', '=', 'Closed']],
    });

    this.origins = new DataSource({
      store: AspNetData.createStore({
        key: 'OriginId',
        loadUrl: `${this.url}Prospection/Origin/Lookup`,
      }),
      sort: 'Name',
    });

    this.poles = new DataSource({
      store: AspNetData.createStore({
        key: 'PoleId',
        loadUrl: `${this.url}Prospection/Pole/Lookup`,
      }),
      sort: 'Name',
    });

    this.pmes = new DataSource({
      store: AspNetData.createStore({
        key: 'PmeId',
        loadUrl: `${this.url}Prospection/Pme/Lookup`,
      }),
      sort: 'Name',
    });

    this.segmentations = new DataSource({
      store: AspNetData.createStore({
        key: 'SegmentationId',
        loadUrl: `${this.url}Prospection/Segmentation/Lookup`,
      }),
      sort: 'Name',
    });

    this.employees = new DataSource({
      store: AspNetData.createStore({
        key: 'EmployeeId',
        loadUrl: `${this.url}Employee/Lookup`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method == 'load') {
            ajaxOptions.data.ActiveOnly = true;
          }
        },
      }),
      sort: ['FirstName', 'LastName'],
    });

    this.arrayStore = new ArrayStore({
      data: [],
    });

    this.detailDataSource = new DataSource({
      store: this.arrayStore,
    });
  }

  handleVisible(e: boolean): void {
    this.visible = e;
    this.prospect = {};
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.prospect.Notes = this.notes;
    this.save.emit({
      prospect: this.prospect,
      contacts: this.detailDataSource.items(),
    });
    this.arrayStore.clear();

    this.notes = undefined;
  }

  onNotesChanged = (e) => {
    this.notes = e.value;
  };

  onShow() {
    this.notes = this.prospect.Notes;
  }
}

@NgModule({
  declarations: [CrmProspectNewComponent],
  exports: [CrmProspectNewComponent],
  bootstrap: [CrmProspectNewComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
    DxTextAreaModule,
    CrmProspectDetailsFormModule,
  ],
})
export class CrmProspectNewModule {}
