<dx-data-grid
  #grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [dataSource]="InvoiceReportingDataSource"
  (onExporting)="onExporting($event)"
  (onRowUpdating)="onRowUpdating($event)"
  [wordWrapEnabled]="true"
>
  <dxo-search-panel [visible]="true" width="20vw" />
  <dxo-load-panel [enabled]="true"
    ><dxo-animation [enabled]="false"></dxo-animation
  ></dxo-load-panel>
  <dxo-master-detail
    [enabled]="true"
    template="detailTemplate"
  ></dxo-master-detail>
  <dxi-column
    dataField="InvoiceDate"
    [sortIndex]="0"
    sortOrder="asc"
    caption="Date"
    dataType="date"
  />
  <dxi-column
    dataField="InvoiceHeaderId"
    caption="InvoiceHeaderId"
    [visible]="false"
  />
  <dxi-column dataField="InvoiceNumber" caption="Invoice" />
  <dxi-column dataField="ClientName" caption="Client" />
  <dxi-column dataField="Margin" caption="Margin">
    <dxo-format type="currency" [precision]="2"></dxo-format>
  </dxi-column>
  <dxi-column dataField="AmountInvoiced" caption="AmountInvoiced">
    <dxo-format type="currency" [precision]="2"></dxo-format>
  </dxi-column>
  <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
  <div *dxTemplate="let detail of 'detailTemplate'">
    <invoice-details-reporting
      [InvoiceHeaderId]="detail.data.InvoiceHeaderId"
      [TeamId]="TeamId"
    ></invoice-details-reporting>
  </div>

  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="gridInvoiceReporting"
  />
</dx-data-grid>
