<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Case Segments" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="false"
        [allowDeleting]="true"
      />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item location="after">
          <dx-button
            text="New Case Segment"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="addNew()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="Name" caption="Client" alignment="center" />
      <dxi-column
        dataField="CalystaReference"
        caption="Calysta Reference"
        alignment="center"
      />
      <dxi-column
        dataField="ClientReference"
        caption="Client Reference"
        alignment="center"
      />
      <dxi-column
        dataField="CatchWord"
        caption="CatchWord"
        alignment="center"
      />
      <dxi-column
        dataField="SegmentLabel"
        caption="Segments"
        alignment="center"
      />
      <dxi-column dataField="ClientId" caption="ClientId" [visible]="false" />
      <dxi-column dataField="LinkId" caption="LinkId" [visible]="false" />
      <dxi-column type="buttons">
        <dxi-button name="delete" />
      </dxi-column>

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridClientPortalCaseSegments"
      />
    </dx-data-grid>
    <client-portal-segments-case-segment-new
      [(visible)]="isAddPopupOpened"
      (save)="onSaveClickNew($event)"
      [titleText]="'New Case Segment'"
      (visibleChange)="onCloseAdd($event)"
    />
    <client-portal-segments-case-segment-edit
      [(visible)]="isEditPopupOpened"
      (save)="onSaveClickEdit($event)"
      [titleText]="'Edit Case Segment'"
      (visibleChange)="onCloseEdit($event)"
      [caseSegment]="editedRow"
    />
  </div>
</dx-scroll-view>
