import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { EmployeeHourlyRatesSettingsModule } from 'src/app/components/administration/employee-hourly-rates-settings/employee-hourly-rates-settings.component';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'administration-employee',
  templateUrl: './administration-employee.component.html',
  styleUrls: ['./administration-employee.component.scss'],
})
export class AdministrationEmployeeComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  computers: DataSource;

  cars: DataSource;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  isHourlyRatesPopupVisible = false;

  selectedEmployee: any;

  constructor(private employeeService: EmployeeService) {
    this.url = this.employeeService.getUrl();

    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'EmployeeId',
        loadUrl: `${this.url}`,
        insertUrl: `${this.url}`,
        updateUrl: `${this.url}`,
        deleteUrl: `${this.url}`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });

    this.computers = new DataSource({
      store: AspNetData.createStore({
        key: 'ComputerId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Computer/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'Label',
    });

    this.cars = new DataSource({
      store: AspNetData.createStore({
        key: 'CarId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Car/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: ['NumberPlate', 'Brand', 'Type'],
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  openHourlyRatesPopup = (e) => {
    this.selectedEmployee = e.row.data;
    this.isHourlyRatesPopupVisible = true;
  };

  onClosedHourlyRatesPopup = (e) => {
    this.isHourlyRatesPopupVisible = false;
  };
}

@NgModule({
  declarations: [AdministrationEmployeeComponent],
  exports: [],
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    EmployeeHourlyRatesSettingsModule,
  ],
})
export class AdministrationEmployeeModule {}
