import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  DxFormModule,
  DxLoadIndicatorModule,
  DxSelectBoxModule,
} from 'devextreme-angular';
import { CalystaAPIDocketingService } from 'src/app/services/calysta-api/docketing.service';

@Component({
  selector: 'app-docket-mail-form-categorymenu',
  templateUrl: './docket-mail-form-categorymenu.component.html',
  styleUrls: ['./docket-mail-form-categorymenu.component.scss'],
})
export class DocketMailFormCategorymenuComponent {
  @Output() categoryIdEvent: EventEmitter<number> = new EventEmitter<number>();

  categoryLookup: any[];
  currentCategoryId: number;

  constructor(private CalyAPIDocketSVC: CalystaAPIDocketingService) {
    this.categoryChange = this.categoryChange.bind(this);
  }

  /*ngOnInit(): void {
    this.CalyAPIDocketSVC.getCategories().subscribe((res: any) => {
      this.categoryLookup = res.data.sort((a, b) =>
        a.label.localeCompare(b.label),
      );
    });*
  }*/

  categoryChange() {
    this.categoryIdEvent.emit(this.currentCategoryId);
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule,
    DxSelectBoxModule,
  ],
  declarations: [DocketMailFormCategorymenuComponent],
  exports: [DocketMailFormCategorymenuComponent],
})
export class DocketMailFormCategoryMenuModule {}
