import { AppPermissions } from './routes/permissions';

export const navigation = [
  {
    key: 'home',
    text: 'Home',
    icon: 'fa-solid fa-home',
    path: '/home',
  },
  {
    key: 'analytics',
    text: 'Analytics',
    icon: 'chart',
    path: '',
    items: [
      {
        key: 'analytics-budget-dashboard',
        text: 'Budget Dashboard',
        path: '/analytics-budget-dashboard',
        requiredPermissions: [AppPermissions.ANALYTICS_FULL],
      },
      {
        key: 'analytics-sales-report',
        text: 'Sales Dashboard',
        path: '/analytics-sales-report',
      },
      {
        key: 'analytics-cost-follow-up',
        text: 'Cost Follow Up',
        path: '/cost-follow-up',
        requiredPermissions: [AppPermissions.ANALYTICS_FULL],
      },
    ],
  },
  {
    key: 'boardroom',
    text: 'Board Room',
    icon: 'chart',
    path: '',
    requiredPermissions: [AppPermissions.STATS_REPORTING],
    items: [
      {
        key: 'time-analysis',
        text: 'Time Reporting',
        path: '/time-analysis',
        requiredPermissions: [AppPermissions.STATS_REPORTING],
      },
      {
        key: 'analytics-volume-reporting',
        text: 'Volume Reporting',
        path: '/analytics-volume-reporting',
        requiredPermissions: [AppPermissions.STATS_REPORTING],
      },
    ],
  },
  {
    key: 'finance',
    text: 'Finance',
    icon: 'fas fa-credit-card',
    path: '',
    requiredPermissions: [
      AppPermissions.INVOICING,
      AppPermissions.FINANCIAL,

      AppPermissions.INVOICING_BASIC,
      AppPermissions.COST,
      AppPermissions.SUBSIDIES_BASIC,
      AppPermissions.BADPAYERS_MANAGEMENT,
      AppPermissions.INVOICING_REPORTING,
      AppPermissions.INVOICING_FULL,
      AppPermissions.INVOICING_CONFIGURATION,
      AppPermissions.WORKCODES_CONFIGURATION,
      AppPermissions.BANK_REPORTING,
    ],
    items: [
      {
        key: 'finance-invoices-in',
        text: 'Invoices In',
        icon: 'fas fa-file-invoice-dollar',
        path: '/finance-invoices-in',
        requiredPermissions: [
          AppPermissions.COST,
          AppPermissions.MANAGER,
          AppPermissions.INVOICING_FULL,
          AppPermissions.INVOICING_FULL,
        ],
      },
      {
        key: 'finance-charges-v2',
        text: 'Charges',
        icon: 'fas fa-shopping-cart',
        path: '/finance-charges-v2',
        requiredPermissions: [
          AppPermissions.INVOICING,
          AppPermissions.MANAGER,
          AppPermissions.INVOICING_BASIC,
          AppPermissions.INVOICING_FULL,
        ],
      },
      {
        key: 'finance-invoices-out',
        text: 'Invoices Out',
        icon: 'fas fa-euro-sign',
        path: '/finance-invoices-out',
        requiredPermissions: [
          AppPermissions.INVOICING,
          AppPermissions.MANAGER,
          AppPermissions.INVOICING_BASIC,
          AppPermissions.INVOICING_FULL,
        ],
      },
      {
        key: 'finance-rw-follow-up',
        text: 'Rw Follow Up',
        icon: 'fas fa-money-check-alt',
        path: '/finance-rw-follow-up',
        requiredPermissions: [
          AppPermissions.INVOICING,
          AppPermissions.SUBSIDIES_BASIC,
          AppPermissions.INVOICING_FULL,
        ],
      },
      {
        key: 'finance-creditcollect',
        text: 'Credit & Collect',
        icon: 'fas fa-wallet',
        path: '/finance-creditcollect',
        requiredPermissions: [
          AppPermissions.INVOICING,
          AppPermissions.SUBSIDIES_BASIC,
          AppPermissions.INVOICING_FULL,
        ],
      },
      {
        key: 'finance-reporting',
        text: 'Reporting',
        icon: 'fas fa-download',
        path: '',
        requiredPermissions: [
          AppPermissions.INVOICING,
          AppPermissions.BADPAYERS_MANAGEMENT,
          AppPermissions.INVOICING_FULL,
          AppPermissions.INVOICING_REPORTING,
        ],
        items: [
          {
            key: 'finance-reporting-bad-payers',
            text: 'Bad Payers',
            path: '/finance-reporting-bad-payers',
            requiredPermissions: [
              AppPermissions.INVOICING,
              AppPermissions.BADPAYERS_MANAGEMENT,
              AppPermissions.INVOICING_FULL,
            ],
          },
          {
            key: 'finance-reporting-bad-payers-pebu',
            text: 'Bad Payers - PEBU',
            path: '/finance-reporting-bad-payers-pebu',
            requiredPermissions: [
              AppPermissions.BADPAYERS_MANAGEMENT,
              AppPermissions.INVOICING_FULL,
            ],
          },
          {
            key: 'finance-reporting-cost-overview',
            text: 'Cost Overview',
            path: '/finance-reporting-cost-overview',
            requiredPermissions: [
              AppPermissions.INVOICING_REPORTING,
              AppPermissions.INVOICING_FULL,
            ],
          },
          {
            key: 'finance-reporting-cost-item-overview',
            text: 'Cost Item Overview',
            path: '/finance-reporting-cost-item-overview',
            requiredPermissions: [
              AppPermissions.INVOICING_REPORTING,
              AppPermissions.INVOICING_FULL,
            ],
          },
          {
            key: 'finance-reporting-invoice-overview',
            text: 'Invoice Overview',
            path: '/finance-reporting-invoice-overview',
            requiredPermissions: [
              AppPermissions.INVOICING,
              AppPermissions.INVOICING_REPORTING,
              AppPermissions.INVOICING_FULL,
            ],
          },
          {
            key: 'finance-reporting-invoice-subsidies',
            text: 'Invoice Subsidies',
            path: '/finance-reporting-invoice-subsidies',
            requiredPermissions: [
              AppPermissions.INVOICING,
              AppPermissions.INVOICING_REPORTING,
              AppPermissions.INVOICING_FULL,
            ],
          },
          {
            key: 'finance-reporting-amadeus-invoice-overview',
            text: 'Inv. Overview - Amadeus',
            path: '/finance-reporting-amadeus-invoice-overview',
            requiredPermissions: [
              AppPermissions.INVOICING,
              AppPermissions.INVOICING_REPORTING,
              AppPermissions.INVOICING_FULL,
            ],
          },
          {
            key: 'finance-reporting-spadel-invoice-overview',
            text: 'Inv. Overview - Spadel',
            path: '/finance-reporting-spadel-invoice-overview',
            requiredPermissions: [
              AppPermissions.INVOICING,
              AppPermissions.INVOICING_REPORTING,
              AppPermissions.INVOICING_FULL,
            ],
          },
        ],
      },
      {
        key: 'finance-configuration',
        text: 'Configuration',
        icon: 'fas fa-cog',
        path: '',
        requiredPermissions: [
          AppPermissions.FINANCIAL,
          AppPermissions.INVOICING_CONFIGURATION,
          AppPermissions.INVOICING_FULL,
          AppPermissions.WORKCODES_CONFIGURATION,
        ],
        items: [
          {
            key: 'finance-configuration-invoice-categories',
            text: 'Invoice Categories',
            path: '/finance-configuration-invoice-categories',
            requiredPermissions: [
              AppPermissions.INVOICING_CONFIGURATION,
              AppPermissions.INVOICING_FULL,
            ],
          },
          {
            key: 'finance-configuration-invoiceline-categories',
            text: 'Invoice Line Categories',
            path: '/finance-configuration-invoiceline-categories',
            requiredPermissions: [
              AppPermissions.INVOICING_CONFIGURATION,
              AppPermissions.INVOICING_FULL,
            ],
          },
          {
            key: 'finance-configuration-pricelist',
            text: 'Price List',
            path: '/finance-configuration-pricelist',
            requiredPermissions: [
              AppPermissions.FINANCIAL,
              AppPermissions.INVOICING_CONFIGURATION,
              AppPermissions.INVOICING_FULL,
            ],
          },
          {
            key: 'finance-configuration-workcode',
            text: 'Workcodes',
            path: '/finance-configuration-workcode',
            requiredPermissions: [
              AppPermissions.FINANCIAL,
              AppPermissions.WORKCODES_CONFIGURATION,
              AppPermissions.INVOICING_FULL,
            ],
          },
          {
            key: 'finance-configuration-workcode-categories',
            text: 'Workcode Categories',
            path: '/finance-configuration-workcode-categories',
            requiredPermissions: [
              AppPermissions.WORKCODES_CONFIGURATION,
              AppPermissions.INVOICING_FULL,
            ],
          },
        ],
      },
      {
        key: 'finance-calysta',
        text: 'Calysta',
        path: '',
        requiredPermissions: [
          AppPermissions.BANK_REPORTING,
          AppPermissions.INVOICING_REPORTING,
          AppPermissions.INVOICING_FULL,
        ],
        items: [
          {
            key: 'finance-calysta-bank-account-track',
            text: 'Bank Account Track',
            path: '/finance-bank-account-track',
            requiredPermissions: [
              AppPermissions.BANK_REPORTING,
              AppPermissions.INVOICING_FULL,
            ],
          },
          {
            key: 'finance-calysta-bank-account-validation',
            text: 'Bank Account Validation',
            path: '/finance-bank-account-validation',
            requiredPermissions: [
              AppPermissions.BANK_REPORTING,
              AppPermissions.INVOICING_FULL,
            ],
          },
          {
            key: 'finance-calysta-cost-item-budget',
            text: 'Cost Item Budget',
            path: '/finance-cost-item-budget',
            requiredPermissions: [
              AppPermissions.INVOICING_REPORTING,
              AppPermissions.INVOICING_FULL,
            ],
          },
        ],
      },
    ],
  },
  {
    key: 'patricia',
    text: 'Patricia',
    icon: 'fas fa-horse',
    path: '',
    items: [
      {
        key: 'patricia-client-and-prospects',
        text: 'Clients And Prospects',
        path: '/patricia-client-and-prospects',
      },
      {
        key: 'patricia-event-deadlines',
        text: 'Events',
        path: '/patricia-events-deadlines',
      },
      {
        key: 'patricia-documents',
        text: 'Documents',
        path: '',
        items: [
          {
            key: 'patricia-documents-validation',
            text: 'Validations',
            path: '/patricia-document-validation',
          },
        ],
      },
    ],
  },
  {
    key: 'provider',
    text: 'Providers',
    icon: 'fas fa-address-card',
    path: '',
    items: [
      {
        key: 'provider-list',
        text: 'Providers List',
        path: '/provider-list',
      },
    ],
  },
  {
    key: 'consistency-checks',
    text: 'Consistency Checks',
    icon: 'fas fa-check-double',
    path: '',
    requiredPermissions: [AppPermissions.DATA_QUALITY],
    items: [
      {
        key: 'consistency-checks-user-logs',
        text: 'User Logs',
        icon: 'fas fa-clipboard-check',
        path: '/consistency-checks-user-logs',
        requiredPermissions: [AppPermissions.DATA_QUALITY],
      },
      {
        key: 'data-quality',
        text: 'Data Quality',
        icon: 'fas fa-database',
        path: '',
        requiredPermissions: [AppPermissions.DATA_QUALITY],
        items: [
          {
            key: 'data-quality-px-parties',
            text: 'Px Parties',
            icon: 'fas fa-user-friends',
            path: '/data-quality-px-parties',
            requiredPermissions: [AppPermissions.DATA_QUALITY],
          },
          {
            key: 'data-quality-px-diary',
            text: 'Px Diary',
            icon: 'fas fa-calendar-alt',
            path: '/data-quality-px-diary',
            requiredPermissions: [AppPermissions.DATA_QUALITY],
          },
          {
            key: 'data-quality-px-annuity',
            text: 'Px Annuity',
            icon: 'fas fa-money-check-alt',
            path: '/data-quality-px-annuity',
            requiredPermissions: [AppPermissions.DATA_QUALITY],
          },
          {
            key: 'data-quality-px-actions',
            text: 'Px Actions',
            icon: 'fas fa-tasks',
            path: '/data-quality-px-actions',
            requiredPermissions: [AppPermissions.DATA_QUALITY],
          },
          {
            key: 'data-quality-px-names',
            text: 'Px Names',
            icon: 'fas fa-user',
            path: '/data-quality-px-names',
            requiredPermissions: [AppPermissions.DATA_QUALITY],
          },
        ],
      },
      {
        key: 'security',
        text: 'Security',
        icon: 'fas fa-shield-alt',
        path: '',
        requiredPermissions: [AppPermissions.DATA_QUALITY],
        items: [
          {
            key: 'security-px-parties',
            text: 'Px Parties',
            icon: 'fas fa-user-friends',
            path: '/security-px-parties',
            requiredPermissions: [AppPermissions.DATA_QUALITY],
          },
          {
            key: 'security-px-diary',
            text: 'Px Diary',
            icon: 'fas fa-calendar-alt',
            path: '/security-px-diary',
            requiredPermissions: [AppPermissions.DATA_QUALITY],
          },
          {
            key: 'security-px-annuity',
            text: 'Px Annuity',
            icon: 'fas fa-money-check-alt',
            path: '/security-px-annuity',
            requiredPermissions: [AppPermissions.DATA_QUALITY],
          },
          {
            key: 'security-px-actions',
            text: 'Px Actions',
            icon: 'fas fa-tasks',
            path: '/security-px-actions',
            requiredPermissions: [AppPermissions.DATA_QUALITY],
          },
          {
            key: 'security-px-names',
            text: 'Px Names',
            icon: 'fas fa-user',
            path: '/security-px-names',
            requiredPermissions: [AppPermissions.DATA_QUALITY],
          },
        ],
      },
    ],
  },
  {
    key: 'renewals',
    text: 'Renewals',
    icon: 'fas fa-window-restore',
    path: '',
    requiredPermissions: [
      AppPermissions.RENEWAL,
      AppPermissions.PAVIS,
      AppPermissions.RENEWALS_CONFIGURATION,
    ],
    items: [
      {
        key: 'renewals-internal',
        text: 'Internal',
        icon: 'fas fa-address-card',
        path: '',
        requiredPermissions: [AppPermissions.RENEWAL],
        items: [
          {
            key: 'renewals-internal-overview',
            text: 'Overview',
            path: '/renewals-internal-overview',
            requiredPermissions: [AppPermissions.RENEWAL],
          },
        ],
      },
      {
        key: 'renewals-pavis',
        text: 'Pavis',
        icon: 'user',
        path: '',
        requiredPermissions: [AppPermissions.RENEWAL, AppPermissions.PAVIS],
        items: [
          {
            key: 'renewals-pavis-import-export',
            text: 'Import-Export',
            icon: 'fas fa-sync',
            path: 'renewals-pavis-import-export',
            requiredPermissions: [AppPermissions.RENEWAL, AppPermissions.PAVIS],
          },
          {
            key: 'renewals-pavis-instructions',
            text: 'Instructions',
            icon: 'fas fa-list-ul',
            path: 'renewals-pavis-instructions',
            requiredPermissions: [AppPermissions.RENEWAL, AppPermissions.PAVIS],
          },
          {
            key: 'renewals-pavis-invoice',
            text: 'Invoice',
            icon: 'fas fa-file-invoice-dollar',
            path: 'renewals-pavis-invoice',
            requiredPermissions: [AppPermissions.RENEWAL, AppPermissions.PAVIS],
          },
          {
            key: 'renewals-pavis-send-to-pavis',
            text: 'Send To Pavis',
            icon: 'fas fa-cart-arrow-down',
            path: '/renewals-pavis-send-to-pavis',
            requiredPermissions: [AppPermissions.RENEWAL, AppPermissions.PAVIS],
          },
          {
            key: 'renewals-pavis-log',
            text: 'Logs',
            icon: 'fas fa-diagnoses',
            requiredPermissions: [AppPermissions.RENEWAL, AppPermissions.PAVIS],
            path: '',
            items: [
              {
                key: 'renewals-pavis-log-instruction-log',
                text: 'Instruction Logs',
                icon: 'fas fa-diagnoses',
                path: '/renewals-pavis-log-instruction-log',
                requiredPermissions: [
                  AppPermissions.RENEWAL,
                  AppPermissions.PAVIS,
                ],
              },
              {
                key: 'renewals-pavis-log-response-log',
                text: 'Response Logs',
                icon: 'fas fa-diagnoses',
                path: '/renewals-pavis-log-response-log',
                requiredPermissions: [
                  AppPermissions.RENEWAL,
                  AppPermissions.PAVIS,
                ],
              },
            ],
          },
          {
            key: 'renewals-pavis-configuration',
            text: 'Configuration',
            icon: 'fas fa-cog',
            path: '',
            requiredPermissions: [AppPermissions.RENEWALS_CONFIGURATION],
            items: [
              {
                key: 'renewals-pavis-configuration-event',
                text: 'Event',
                path: '/renewals-pavis-configuration-event',
                requiredPermissions: [AppPermissions.RENEWALS_CONFIGURATION],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: 'time-registration',
    text: 'Time Registration',
    icon: 'fas fa-stopwatch',
    path: '',
    items: [
      {
        key: 'time-registration-dashboard',
        text: 'Dashboard',
        icon: 'fas fa-business-time',
        path: '/time-registration-reporting-personal',
      },
      {
        key: 'time-registration-team-dashboard',
        text: 'Team Dashboard',
        icon: 'fas fa-people-group',
        path: '/time-registration-reporting-team',
      },
      {
        key: 'time-registration-register',
        text: 'Register',
        icon: 'fas fa-edit',
        path: '/time-registration-register',
      },
      {
        key: 'time-registration-configuration',
        text: 'Configuration',
        icon: 'fas fa-cog',
        path: '',
        requiredPermissions: [
          AppPermissions.TIMEREGISTRATION_CONFIGURATION,
          AppPermissions.TIMEREGISTRATION_MANAGEMENT,
          AppPermissions.TEAMS_MANAGER,
        ],
        items: [
          {
            key: 'time-registration-configuration-billing-type',
            text: 'Billing Types',
            path: '/time-registration-configuration-billing-type',
            requiredPermissions: [
              AppPermissions.TIMEREGISTRATION_CONFIGURATION,
            ],
          },
          {
            key: 'time-registration-configuration-employee-target',
            text: 'Employees Target',
            path: '/time-registration-configuration-employee-target',
            requiredPermissions: [AppPermissions.TIMEREGISTRATION_MANAGEMENT],
          },
          {
            key: 'time-registration-configuration-team-target',
            text: 'Teams Target',
            path: '/time-registration-configuration-team-target',
            requiredPermissions: [
              AppPermissions.TIMEREGISTRATION_MANAGEMENT,
              AppPermissions.TEAMS_MANAGER,
            ],
          },
        ],
      },
    ],
  },
  {
    key: 'admin-portal',
    text: 'Admin Portal',
    icon: 'fas fa-desktop',
    path: '',
    requiredPermissions: [
      AppPermissions.INVOICING,
      AppPermissions.GENERAL_MANAGEMENT,
      AppPermissions.TEMPLATE_ENGINE_CONFIGURATION,
    ],
    items: [
      {
        key: 'admin-portal-language',
        text: 'Languages',
        icon: 'fas fa-comments',
        path: '/admin-portal-languages',
        requiredPermissions: [AppPermissions.GENERAL_MANAGEMENT],
      },
      {
        key: 'admin-portal-user-management',
        text: 'User Management',
        icon: 'fas fa-users',
        path: '/admin-portal-user-management',
        requiredPermissions: [AppPermissions.ADMIN],
      },
      {
        key: 'admin-portal-service',
        text: 'Service Management',
        icon: 'fas fa-server',
        path: '/admin-portal-service',
        requiredPermissions: [AppPermissions.GENERAL_MANAGEMENT],
      },
      {
        key: 'admin-portal-template-engine',
        text: 'Template Engine',
        icon: 'fas fa-file-code',
        path: '',
        requiredPermissions: [
          AppPermissions.INVOICING,
          AppPermissions.TEMPLATE_ENGINE_CONFIGURATION,
        ],
        items: [
          {
            key: 'admin-portal-template-engine-bookmark',
            text: 'Bookmarks',
            path: '/admin-portal-template-engine-bookmark',
            requiredPermissions: [
              AppPermissions.INVOICING,
              AppPermissions.TEMPLATE_ENGINE_CONFIGURATION,
            ],
          },
          {
            key: 'admin-portal-template-engine-templates',
            text: 'Templates',
            path: '/admin-portal-template-engine-templates',
            requiredPermissions: [
              AppPermissions.INVOICING,
              AppPermissions.TEMPLATE_ENGINE_CONFIGURATION,
            ],
          },
          {
            key: 'admin-portal-template-engine-template-type',
            text: 'Template Types',
            path: '/admin-portal-template-engine-template-type',
            requiredPermissions: [
              AppPermissions.INVOICING,
              AppPermissions.TEMPLATE_ENGINE_CONFIGURATION,
            ],
          },
          {
            key: 'admin-portal-template-engine-template-bookmark',
            text: 'Template Bookmarks',
            path: '/admin-portal-template-engine-template-bookmark',
            requiredPermissions: [
              AppPermissions.INVOICING,
              AppPermissions.TEMPLATE_ENGINE_CONFIGURATION,
            ],
          },
        ],
      },
    ],
  },
  {
    key: 'budget',
    text: 'Budget',
    icon: 'fas fa-chart-line',
    path: '',
    requiredPermissions: [AppPermissions.BUDGET_MANAGEMENT],
    items: [
      {
        key: 'budget-configuration',
        text: 'Configuration',
        icon: 'fas fa-cog',
        path: '',
        requiredPermissions: [AppPermissions.BUDGET_MANAGEMENT],
        items: [
          {
            key: 'budget-configuration-administration',
            text: 'Administration',
            path: '',
            requiredPermissions: [AppPermissions.BUDGET_MANAGEMENT],
            items: [
              {
                key: 'budget-configuration-administration-category',
                text: 'Categories',
                path: '/budget-configuration-administration-category',
                requiredPermissions: [AppPermissions.BUDGET_MANAGEMENT],
              },
              {
                key: 'budget-configuration-administration-subcategory',
                text: 'SubCategories',
                path: '/budget-configuration-administration-subcategory',
                requiredPermissions: [AppPermissions.BUDGET_MANAGEMENT],
              },
            ],
          },
          {
            key: 'budget-configuration-figures',
            text: 'Figures',
            path: '',
            requiredPermissions: [AppPermissions.BUDGET_MANAGEMENT],
            items: [
              {
                key: 'budget-configuration-figures-cost',
                text: 'Sales Targets',
                path: '/budget-configuration-figures-sales-target',
                requiredPermissions: [AppPermissions.BUDGET_MANAGEMENT],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: 'administration',
    text: 'Administration',
    icon: 'fas fa-tablet-alt',
    path: '',
    requiredPermissions: [
      AppPermissions.COMPANY_MANAGEMENT_FULL,
      AppPermissions.EMPLOYEE_MANAGEMENT,
      AppPermissions.COMPANY_MANAGEMENT_BASIC,
    ],
    items: [
      {
        key: 'administration-car',
        text: 'Car',
        icon: 'fas fa-car',
        path: '',
        requiredPermissions: [AppPermissions.COMPANY_MANAGEMENT_FULL],
        items: [
          {
            key: 'administration-car-overview',
            text: 'Overview',
            path: '/administration-car-overview',
            requiredPermissions: [AppPermissions.COMPANY_MANAGEMENT_FULL],
          },
          {
            key: 'administration-car-brands',
            text: 'Brands',
            path: '/administration-car-brands',
            requiredPermissions: [AppPermissions.COMPANY_MANAGEMENT_FULL],
          },
          {
            key: 'administration-car-fuel-types',
            text: 'Fuel Types',
            path: '/administration-car-fuel-types',
            requiredPermissions: [AppPermissions.COMPANY_MANAGEMENT_FULL],
          },
          {
            key: 'administration-car-insurance-companies',
            text: 'Insurance Companies',
            path: '/administration-car-insurance-companies',
            requiredPermissions: [AppPermissions.COMPANY_MANAGEMENT_FULL],
          },
          {
            key: 'administration-car-leasing-companies',
            text: 'Leasing Companies',
            path: '/administration-car-leasing-companies',
            requiredPermissions: [AppPermissions.COMPANY_MANAGEMENT_FULL],
          },
        ],
      },
      {
        key: 'administration-employee',
        text: 'Employees',
        icon: 'fas fa-users',
        path: '',
        requiredPermissions: [
          AppPermissions.COMPANY_MANAGEMENT_FULL,
          AppPermissions.EMPLOYEE_MANAGEMENT,
        ],
        items: [
          {
            key: 'administration-employee-employees',
            text: 'Employees',
            icon: 'fas fa-users',
            path: '/administration-employee-employees',
            requiredPermissions: [
              AppPermissions.COMPANY_MANAGEMENT_FULL,
              AppPermissions.EMPLOYEE_MANAGEMENT,
            ],
          },
          {
            key: 'administration-employee-employee-salary',
            text: 'Employees Salaries',
            icon: 'fa-solid fa-hand-holding-dollar',
            path: '/administration-employee-employee-salary',
            requiredPermissions: [
              AppPermissions.COMPANY_MANAGEMENT_FULL,
              AppPermissions.EMPLOYEE_MANAGEMENT,
            ],
          },
        ],
      },
      {
        key: 'administration-contries',
        text: 'Countries',
        icon: 'fas fa-globe',
        path: '/administration-countries',
        requiredPermissions: [
          AppPermissions.COMPANY_MANAGEMENT_FULL,
          AppPermissions.COMPANY_MANAGEMENT_BASIC,
        ],
      },
      {
        key: 'administration-currency',
        text: 'Currencies',
        icon: 'fas fa-dollar-sign',
        path: '/administration-currency',
        requiredPermissions: [
          AppPermissions.COMPANY_MANAGEMENT_FULL,
          AppPermissions.COMPANY_MANAGEMENT_BASIC,
        ],
      },
      {
        key: 'administration-computers',
        text: 'Computers',
        icon: 'fas fa-laptop',
        path: '/administration-computers',
        requiredPermissions: [
          AppPermissions.COMPANY_MANAGEMENT_FULL,
          AppPermissions.COMPANY_MANAGEMENT_BASIC,
        ],
      },
    ],
  },
  {
    key: 'client-portal',
    text: 'Client Portal',
    icon: 'fa-solid fa-chalkboard-user',
    path: '',
    requiredPermissions: [
      AppPermissions.CLIENT_PORTAL,
      AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
    ],
    items: [
      {
        key: 'client-portal-account-px-follow-up',
        text: 'Account Px Follow Up',
        icon: 'fas fa-chess-knight',
        path: '/client-portal-account-px-follow-up',
        requiredPermissions: [
          AppPermissions.CLIENT_PORTAL,
          AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
        ],
      },
      {
        key: 'client-portal-case-types',
        text: 'Case Types',
        path: '',
        icon: 'user',
        items: [
          {
            key: 'client-portal-case-types-case-mapping',
            text: 'Mapping',
            path: '/client-portal-case-type-mapping',
            requiredPermissions: [AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL],
          },
        ],
      },
      {
        key: 'client-portal-instructions',
        text: 'Instructions',
        path: '',
        icon: 'user',
        requiredPermissions: [
          AppPermissions.CLIENT_PORTAL,
          AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
        ],
        items: [
          {
            key: 'client-portal-instructions-mapping',
            text: 'Mapping',
            path: '/client-portal-instructions-mapping',
            requiredPermissions: [
              AppPermissions.CLIENT_PORTAL,
              AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
            ],
          },
          {
            key: 'client-portal-instructions-action-text',
            text: 'Action Text',
            path: '/client-portal-instructions-action-text',
            requiredPermissions: [
              AppPermissions.CLIENT_PORTAL,
              AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
            ],
          },
          {
            key: 'client-portal-instructions-categories',
            text: 'Categories',
            path: '/client-portal-instructions-categories',
            requiredPermissions: [
              AppPermissions.CLIENT_PORTAL,
              AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
            ],
          },
          {
            key: 'client-portal-instructions-response-types',
            text: 'Response Types',
            path: '/client-portal-instructions-response-types',
            requiredPermissions: [AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL],
          },
          {
            key: 'client-portal-instructions-status-text',
            text: 'Status Text',
            path: '/client-portal-instructions-status-text',
            requiredPermissions: [
              AppPermissions.CLIENT_PORTAL,
              AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
            ],
          },
        ],
      },
      {
        key: 'client-portal-segments',
        text: 'Segments',
        path: '',
        icon: 'user',
        requiredPermissions: [
          AppPermissions.CLIENT_PORTAL,
          AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
        ],
        items: [
          {
            key: 'client-portal-segments-case-segments',
            text: 'Case Segments',
            path: '/client-portal-segments-case-segments',
            requiredPermissions: [
              AppPermissions.CLIENT_PORTAL,
              AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
            ],
          },
          {
            key: 'client-portal-segments-client-segments',
            text: 'Client Segments',
            path: '/client-portal-segments-client-segments',
            requiredPermissions: [
              AppPermissions.CLIENT_PORTAL,
              AppPermissions.ClIENT_PORTAL_MANAGEMENT_FULL,
            ],
          },
        ],
      },
    ],
  },
  {
    key: 'conversion',
    text: 'Conversion',
    icon: 'fa-solid fa-arrow-right-arrow-left',
    path: '',
    requiredPermissions: [AppPermissions.CASESCONVERSION_INFO],
    items: [
      {
        key: 'conversion-case-basic-info',
        text: 'Cases Basic Info',
        path: '/conversion-case-basic-info',
        requiredPermissions: [AppPermissions.CASESCONVERSION_INFO],
      },
      {
        key: 'conversion-case-diary',
        text: 'Case Diaries',
        path: '/conversion-case-diary',
        requiredPermissions: [AppPermissions.CASESCONVERSION_INFO],
      },
      {
        key: 'conversion-case-trm-class',
        text: 'Case Trm Class',
        path: '/conversion-case-trm-class',
        requiredPermissions: [AppPermissions.CASESCONVERSION_INFO],
      },
      {
        key: 'conversion-case-party',
        text: 'Case Parties',
        path: '/conversion-case-party',
        requiredPermissions: [AppPermissions.CASESCONVERSION_INFO],
      },
    ],
  },
  {
    key: 'crm',
    text: 'CRM',
    icon: 'fa-solid fa-users-viewfinder',
    path: '',
    requiredPermissions: [
      AppPermissions.PROSPECTION,
      AppPermissions.CRM_BASIC,
      AppPermissions.CRM_CONFIGURATION,
    ],
    items: [
      {
        key: 'crm-prospects',
        text: 'Prospects',
        icon: 'fa-solid fa-users-between-lines',
        path: '/crm-prospects',
        requiredPermissions: [
          AppPermissions.PROSPECTION,
          AppPermissions.CRM_BASIC,
        ],
      },
      {
        key: 'crm-clients',
        text: 'Clients',
        icon: 'fa-solid fa-users-line',
        path: '/crm-clients',
        requiredPermissions: [
          AppPermissions.PROSPECTION,
          AppPermissions.CRM_BASIC,
        ],
      },
      {
        key: 'crm-todo',
        text: 'To Do',
        icon: 'fas fa-list-check',
        path: '/crm-todo',
        requiredPermissions: [
          AppPermissions.PROSPECTION,
          AppPermissions.CRM_BASIC,
        ],
      },
      {
        key: 'crm-configuration',
        text: 'Configuration',
        icon: 'fas fa-cog',
        requiredPermissions: [
          AppPermissions.PROSPECTION,
          AppPermissions.CRM_CONFIGURATION,
        ],
        path: '',
        items: [
          {
            key: 'crm-configuration-action',
            text: 'Actions',
            path: '/crm-configuration-action',
            requiredPermissions: [AppPermissions.CRM_CONFIGURATION],
          },
          {
            key: 'crm-configuration-origin',
            text: 'Origins',
            path: '/crm-configuration-origin',
            requiredPermissions: [AppPermissions.CRM_CONFIGURATION],
          },
          {
            key: 'crm-configuration-pme',
            text: 'Pme',
            path: '/crm-configuration-pme',
            requiredPermissions: [AppPermissions.CRM_CONFIGURATION],
          },
          {
            key: 'crm-configuration-pole',
            text: 'Poles',
            path: '/crm-configuration-pole',
            requiredPermissions: [AppPermissions.CRM_CONFIGURATION],
          },
          {
            key: 'crm-configuration-segmentation',
            text: 'Segmentations',
            path: '/crm-configuration-segmentation',
            requiredPermissions: [AppPermissions.CRM_CONFIGURATION],
          },
          {
            key: 'crm-configuration-source',
            text: 'Prospect Actions',
            path: '/crm-configuration-prospect-action',
            requiredPermissions: [
              AppPermissions.PROSPECTION,
              AppPermissions.CRM_CONFIGURATION,
            ],
          },
          {
            key: 'crm-configuration-status',
            text: 'Field Status',
            path: '/crm-configuration-field-status',
            requiredPermissions: [AppPermissions.CRM_CONFIGURATION],
          },
        ],
      },
    ],
  },
  {
    key: 'epo',
    text: AppPermissions.EPO,
    path: '',
    icon: 'assets/epo_logo.svg',
    requiredPermissions: [AppPermissions.EPO],
    items: [
      {
        key: 'epo-communications',
        text: 'Communications',
        path: '/epo-communications',
        requiredPermissions: [AppPermissions.EPO],
      },
    ],
  },
  {
    key: 'views',
    text: 'Views',
    path: '',
    icon: 'fa-regular fa-window-maximize',
    items: [
      {
        key: 'views-cases-not-imported-in-pavis',
        text: 'Cases not imported in Pavis',
        path: '/views-cases-not-imported-in-pavis',
      },
    ],
  },
  {
    key: 'docket',
    text: 'Docket',
    icon: 'fa-brands fa-get-pocket',
    path: '',
    items: [
      {
        key: 'docket-mail-list',
        text: 'Emails',
        path: '/docket-mail-list',
      },
      {
        key: 'docket-file',
        text: 'Files',
        path: '/docket-file',
      },
    ],
  },
  {
    key: 'fee-calculator',
    text: 'Fee Calculator',
    icon: 'fa-solid fa-calculator',
    path: '',
    requiredPermissions: [AppPermissions.ADMIN],
    items: [
      {
        key: 'fee-calculator-calculator',
        text: 'Calculator',
        path: '/fee-calculator',
        icon: 'fas fa-calculator',
      },
      {
        key: 'fee-calculator-configuration',
        text: 'Configuration',
        path: '',
        icon: 'fas fa-cog',
        items: [
          {
            key: 'fee-calculator-configuration-billing-matrix',
            text: 'Billing Matrix',
            path: '/fee-calculator-configuration-billing-matrix',
            requiredPermissions: [AppPermissions.ADMIN],
          },
        ],
      },
    ],
  },
];
