import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Quote } from 'src/app/types/invoicing/quote';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  serviceUrl: string = `${environment.CalystaApiBaseURL}api/Invoice/WorkCodeTarif/RetrievePrice`;

  constructor(private http: HttpClient) {}

  // Make a relevant DTO for the quote.
  public CalculateQuote(workcodeId: number, caseId: number): Observable<Quote> {
    let params = new HttpParams()
      .set('CaseId', caseId)
      .set('WorkCodeId', workcodeId);

    return this.http.get<Quote>(this.serviceUrl, {
      params,
    });
  }
}
