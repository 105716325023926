import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import { environment } from 'src/environments/environment';
import { Column } from 'devextreme/ui/data_grid';
import { DataQualityGridModule } from 'src/app/components/data-quality/data-quality-grid/data-quality-grid.component';
import { GridType } from 'src/app/types/data-quality/grid-type';

@Component({
  selector: 'security-px-actions',
  templateUrl: './security-px-actions.component.html',
  styleUrls: ['./security-px-actions.component.scss'],
})
export class SecurityPxActionsComponent {
  url: string;

  gridTypes: GridType[] = [
    {
      key: 'CaseResponsibleAndAssistant',
      value: 'Case Responsible And Assistant',
      description: 'each file should have a case responsible and assistant',
    },
    {
      key: 'CasesOnOldTeams',
      value: 'Cases On Old Teams',
      description: 'cases on deactivated team to move to active team',
    },
    {
      key: 'InternalTermsAfterExternalTerms',
      value: 'Internal Terms After External Terms',
      description: 'No internal term should fall after the external term',
    },
    {
      key: 'PendingFilesNoTask',
      value: 'Pending Files No Task',
      description: 'Every open case should contain a task',
    },
    {
      key: 'SurveillanceOnParalegals',
      value: 'Surveillance On Paralegals',
      description: 'No tasks on individual paralegals',
    },
  ];

  selectedGrid: GridType;

  columns: Column[] = [];

  constructor() {
    this.url = `${environment.CalystaApiBaseURL}api/Security/PxActions`;
    this.selectedGrid = this.gridTypes[0];
  }

  changeData = (gridType: GridType) => {
    this.selectedGrid = gridType;

    const columns: Column[] = [
      {
        type: 'buttons',
        buttons: [
          {
            hint: 'Open Patricia',
            icon: 'fas fa-horse',
            onClick: this.openPatricia,
          },
        ],
        width: 'auto',
      },
      {
        dataField: 'CaseId',
        caption: 'Case ID',
        alignment: 'center',
      },
      {
        dataField: 'PatriciaLink',
        caption: 'Patricia Link',
        visible: false,
        showInColumnChooser: false,
      },
    ];

    //create columns depending on the selected grid
    switch (this.selectedGrid.key) {
      case 'CaseResponsibleAndAssistant':
        columns.push(
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'NameId',
            caption: 'NameId',
            alignment: 'center',
          },
          {
            dataField: 'Name',
            caption: 'Name',
            alignment: 'center',
          },
          {
            dataField: 'OtherResponsibleSameFamilyNumber',
            caption: 'Other Responsible Same Family Number',
            alignment: 'center',
          },
          {
            dataField: 'Responsible',
            caption: 'Responsible',
            alignment: 'center',
          },
          {
            dataField: 'AnnuityResponsible',
            caption: 'Annuity Responsible',
            alignment: 'center',
          },
          {
            dataField: 'AnnuityAssistant',
            caption: 'Annuity Assistant',
            alignment: 'center',
          }
        );
        break;
      case 'CasesOnOldTeams':
        columns.push(
          {
            dataField: 'TeamId',
            caption: 'Team Id',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'TeamLabel',
            caption: 'Team Label',
            alignment: 'center',
          },
          {
            dataField: 'CaseClosed',
            caption: 'Case Closed',
            alignment: 'center',
            dataType: 'date',
          },
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          }
        );
        break;
      case 'InternalTermsAfterExternalTerms':
        columns.push(
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'InternalDate',
            caption: 'Internal Date',
            alignment: 'center',
            dataType: 'date',
          },
          {
            dataField: 'ExternalDate',
            caption: 'External Date',
            alignment: 'center',
            dataType: 'date',
          },
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          },
          {
            dataField: 'ExternalEventSchemeId',
            caption: 'External Event Scheme Id',
            alignment: 'center',
          },
          {
            dataField: 'InternalEventSchemeId',
            caption: 'Internal Event Scheme Id',
            alignment: 'center',
          }
        );
        break;
      case 'PendingFilesNoTask':
        columns.push(
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          }
        );
        break;
      case 'SurveillanceOnParalegals':
        columns.push(
          {
            dataField: 'CaseNumber',
            caption: 'Case Number',
            alignment: 'center',
          },
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          },
          {
            dataField: 'LoginId',
            caption: 'Login Id',
            alignment: 'center',
          }
        );
        break;
    }

    this.columns = columns;
  };

  openPatricia = (e) => {
    const patriciaLink = e.row.data.PatriciaLink;
    window.open(patriciaLink, '_blank');
    e.event.preventDefault();
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    DxPopupModule,
    DxFormModule,
    DataQualityGridModule,
  ],
  declarations: [SecurityPxActionsComponent],
  exports: [],
})
export class SecurityPxActionsModule {}
