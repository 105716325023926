<dx-data-grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  style="font-size: 11px"
  [dataSource]="involvedCasesDataSource"
  [remoteOperations]="true"
  height="35vh"
  keyExpr="caseId"
  (onSelectionChanged)="CaseSelectionChanged()"
  [showBorders]="true"
  #caseGridRef
  (onRowUpdating)="onRowUpdating($event)"
  [wordWrapEnabled]="true"
>
  <dxo-filter-row [visible]="true" />
  <dxo-header-filter [visible]="false" />
  <dxo-group-panel [visible]="false" />
  <dxo-scrolling mode="virtual" />
  <dxo-editing
    mode="row"
    [allowAdding]="false"
    [allowUpdating]="false"
    [allowDeleting]="false"
  />
  <dxo-selection
    selectAllMode="allPages"
    showCheckBoxesMode="always"
    mode="multiple"
  />
  <dxo-grouping [autoExpandAll]="false" />
  <dxi-column
    dataField="CaseDetailedDescription"
    dataType="string"
    caption="Reference"
  />
  <dxi-column
    dataField="IsClosed"
    caption="closed?"
    [width]="100"
    [visible]="true"
  />

  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="gridDocketCaseGrid"
  />
</dx-data-grid>
