<dx-scroll-view [height]="800" [width]="1000" direction="vertical">
  <div style="margin-left: 20px; margin-right: 25px">
    <docket-casegrid (CaseSelectionEvent)="caseSelectionChanged($event)">
    </docket-casegrid>
  </div>
  <div style="margin-left: 10px; margin-right: 15px">
    <div class="row marges">
      <app-docket-mail-form-categorymenu
        (categoryIdEvent)="changeCurrentCategoryId($event)"
      >
      </app-docket-mail-form-categorymenu>
      <app-docket-mail-form-subcategorymenu
        [categoryId]="currentCategoryId"
        (subCategoryIdEvent)="SubCategoryChanged($event)"
      >
      </app-docket-mail-form-subcategorymenu>
    </div>
  </div>

  <div style="margin-left: 20px; margin-right: 25px; margin-top: 10px">
    <h5>Mail</h5>
    <app-docket-mail-form-mailgrid [filesToDocket]="filesToDocket">
    </app-docket-mail-form-mailgrid>
  </div>

  <app-docket-mail-form-attachments
    #appAttachment
    [Mail]="email"
    [Mailbox]="mailBox"
    [categoryId]="currentCategoryId"
    [subCategoryId]="currentSubCategoryId"
  >
  </app-docket-mail-form-attachments>

  <div style="text-align: center; margin-top: 20px">
    <div>
      <dx-button
        *ngIf="mailHasAttachments"
        stylingMode="contained"
        text="Save mail and attachments"
        type="normal"
        (onClick)="(buttonPlaceHolder)"
        style="background-color: rgb(255, 100, 044); color: azure"
      >
      </dx-button>
      <dx-button
        stylingMode="contained"
        text="Save mail"
        type="normal"
        (onClick)="(buttonPlaceHolder)"
        style="background-color: rgb(255, 100, 044); color: azure"
      >
      </dx-button>
      <dx-button
        *ngIf="mailHasAttachments"
        stylingMode="contained"
        text="Save attachments"
        type="normal"
        (onClick)="(buttonPlaceHolder)"
        style="background-color: rgb(255, 100, 044); color: azure"
      >
      </dx-button>
    </div>
  </div>
</dx-scroll-view>
