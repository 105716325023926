import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import { environment } from 'src/environments/environment';
import { DataQualityCheckValidationModule } from 'src/app/components/data-quality/data-quality-check-validation/data-quality-check-validation.component';
import { Column } from 'devextreme/ui/data_grid';
import { GridType } from 'src/app/types/data-quality/grid-type';
import { DataQualityGridModule } from 'src/app/components/data-quality/data-quality-grid/data-quality-grid.component';

@Component({
  selector: 'data-quality-px-diary',
  templateUrl: './data-quality-px-diary.component.html',
  styleUrls: ['./data-quality-px-diary.component.scss'],
})
export class DataQualityPxDiaryComponent {
  url: string;

  gridTypes: GridType[] = [
    {
      key: 'CasesCountryDesignationsWrongExts',
      value: 'Cases Country Designations Wrong Extensions',
      description:
        'Check that country designations cases have WO or IM as extension',
    },
    {
      key: 'CasesHaveExpirationDate',
      value: 'Cases Have Expiration Date',
      description: 'Check that all cases have an expiration date',
    },
    {
      key: 'ClosingDateTypeOfClosing',
      value: 'Closing Date Type Of Closing',
      description:
        'Status closed or abandoned BUT diary field Closing date and Type of closing EMPTY',
    },
    {
      key: 'DesignNumberAndViews',
      value: 'Design Number And Views',
      description: 'Design data missing in diary',
    },
    {
      key: 'ExpiredCases',
      value: 'Expired Cases',
      description: 'Expired Cases to close manually',
    },
    {
      key: 'NoRelatedFilesForTopSubPop',
      value: 'No Related Files For Top Sub Pop',
      description: 'SUB and passive TOP/POP cases should have a related case',
    },
    {
      key: 'PublicationDateNumber',
      value: 'Publication Date Number',
      description:
        'Check if every case has a publication Date and a public Number',
    },
    {
      key: 'RegisteredCasesWithoutRegistrationNumberOrDates',
      value:
        'Registered/Granted Cases Without Registration Number Or Registration Date',
      description:
        'Check registration number/date are filled when status for case is registered/granted',
    },
  ];

  selectedGrid: GridType;

  columns: Column[] = [];

  constructor() {
    this.url = `${environment.CalystaApiBaseURL}api/DataQuality/PxDiary`;
    this.selectedGrid = this.gridTypes[0];
  }

  changeData = (gridType: GridType) => {
    this.selectedGrid = gridType;

    const columns: Column[] = [
      {
        type: 'buttons',
        buttons: [
          {
            hint: 'Open Patricia',
            icon: 'fas fa-horse',
            onClick: this.openPatricia,
          },
        ],
        width: 'auto',
      },
      {
        dataField: 'CaseId',
        caption: 'Case ID',
        alignment: 'center',
      },
      {
        dataField: 'CaseNumber',
        caption: 'Case Number',
        alignment: 'center',
      },
      {
        dataField: 'PatriciaLink',
        caption: 'Patricia Link',
        visible: false,
        showInColumnChooser: false,
      },
    ];

    //create columns depending on the selected grid
    switch (gridType.key) {
      case 'CasesHaveExpirationDate':
        columns.push(
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          }
        );
        break;
      case 'ClosingDateTypeOfClosing':
        columns.push(
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'ClosedDate',
            caption: 'Closed Date',
            alignment: 'center',
            dataType: 'date',
            format: 'dd/MM/yyyy',
          },
          {
            dataField: 'TypeOfClosing',
            caption: 'Type of Closing',
            alignment: 'center',
          },
          {
            dataField: 'Client',
            caption: 'Client',
            alignment: 'center',
          }
        );
        break;
      case 'DesignNumberAndViews':
        columns.push(
          {
            dataField: 'DesignType',
            caption: 'Design Type',
            alignment: 'center',
          },
          {
            dataField: 'NumberOfDesigns',
            caption: 'Number Of Designs',
            alignment: 'center',
          },
          {
            dataField: 'NumberOfViews',
            caption: 'Number of Views',
            alignment: 'center',
          }
        );
        break;
      case 'ExpiredCases':
        columns.push();
        break;
      case 'NoRelatedFilesForTopSubPop':
        columns.push();
        break;
      case 'PublicationDateNumber':
        columns.push(
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          },
          {
            dataField: 'PublicationNumber',
            caption: 'Publication Number',
            alignment: 'center',
          },
          {
            dataField: 'PublicationDate',
            caption: 'Publication Date',
            alignment: 'center',
            dataType: 'date',
          }
        );
        break;
      case 'CasesCountryDesignationsWrongExts':
        columns.push(
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          }
        );
        break;
      case 'RegisteredCasesWithoutRegistrationNumberOrDates':
        columns.push(
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          }
        );
        break;
    }

    this.columns = columns;
  };

  openPatricia = (e) => {
    const patriciaLink = e.row.data.PatriciaLink;
    window.open(patriciaLink, '_blank');
    e.event.preventDefault();
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    DxPopupModule,
    DxFormModule,
    DataQualityGridModule,
  ],
  declarations: [DataQualityPxDiaryComponent],
  exports: [],
})
export class DataQualityPxDiaryModule {}
