<ng-content></ng-content>

<div class="menu-container">
  <dx-tree-view
    [items]="items"
    keyExpr="key"
    selectionMode="single"
    [focusStateEnabled]="false"
    expandEvent="click"
    (onItemExpanded)="onItemExpanded($event)"
    (onItemClick)="onItemClick($event)"
    width="100%"
  />
</div>
<ng-content select="app-footer"></ng-content>
