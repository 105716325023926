import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDateRangeBoxModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { RangeDatePickerModule } from 'src/app/components/utils/range-date-picker/range-date-picker.component';
import { TitleBarModule } from 'src/app/components';
import { PatriciaService } from 'src/app/services/patricia/patricia.service';
import DataSource from 'devextreme/data/data_source';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';

@Component({
  selector: 'patricia-clients-and-prospects',
  templateUrl: './patricia-clients-and-prospects.component.html',
  styleUrls: ['./patricia-clients-and-prospects.component.scss'],
})
export class BecameClientOrProspectOnComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  thisYearClient: Date = null;
  nowClient: Date = null;
  thisYearProspect: Date = null;
  nowProspect: Date = null;

  constructor(private patriciaService: PatriciaService) {
    this.url = this.patriciaService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'NameId',
        loadUrl: `${this.url}/BecameClientOrProspectOn`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };

          if (this.thisYearClient && this.nowClient) {
            ajaxOptions.data.becameClientStartDate =
              this.thisYearClient?.toISOString();
            ajaxOptions.data.becameClientEndDate =
              this.nowClient?.toISOString();
          }

          if (this.thisYearProspect && this.nowProspect) {
            ajaxOptions.data.becameProspectStartDate =
              this.thisYearProspect?.toISOString();
            ajaxOptions.data.becameProspectEndDate =
              this.nowProspect?.toISOString();
          }
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onClientDateChanged = (e: any): void => {
    this.thisYearClient = e.value[0];
    this.nowClient = e.value[1];

    this.dataGrid.instance.getDataSource().reload();
  };

  onProspectDateChanged = (e: any): void => {
    this.thisYearProspect = e.value[0];
    this.nowProspect = e.value[1];

    this.dataGrid.instance.getDataSource().reload();
  };

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Clients and Prospects');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'ClientsandProspects.xlsx'
        );
      });
    });
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    RangeDatePickerModule,
    DxDateRangeBoxModule,
  ],
  declarations: [BecameClientOrProspectOnComponent],
  exports: [],
})
export class BecameClientOrProspectOnModule {}
