<div *ngIf="themeService.currentTheme === 'dark'">
  <dx-button
    class="theme-button"
    stylingMode="text"
    [icon]="'fas fa-moon'"
    (onClick)="onButtonClick()"
  ></dx-button>
</div>
<div *ngIf="themeService.currentTheme !== 'dark'">
  <dx-button
    class="theme-button"
    stylingMode="text"
    [icon]="'fas fa-sun'"
    (onClick)="onButtonClick()"
  ></dx-button>
</div>
