import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxTagBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { RwfollowUp } from 'src/app/types/invoicing/rwFollowUp';

import DataSource from 'devextreme/data/data_source';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';

@Component({
  selector: 'budget-configuration-administration-subcategory-new',
  templateUrl:
    './budget-configuration-administration-subcategory-new.component.html',
  styleUrls: [
    './budget-configuration-administration-subcategory-new.component.scss',
  ],
})
export class BudgetConfigurationAdministrationSubCategoryNewComponent
  implements OnInit
{
  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<RwfollowUp>();

  @Output() visibleChange = new EventEmitter<boolean>();

  templateType: any;

  budgetCategories: any;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {}

  ngOnInit(): void {
    this.templateType = {};

    this.budgetCategories = new DataSource({
      store: AspNetData.createStore({
        key: 'BudgetCategoryId',
        loadUrl: `${this.url}Budget/BudgetCategory/Lookup`,
      }),
      sort: 'BudgetCategoryLabel',
    });
  }

  handleVisible(e: boolean): void {
    this.visible = e;
    this.templateType = {};
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.save.emit(this.templateType);
  }
}

@NgModule({
  declarations: [BudgetConfigurationAdministrationSubCategoryNewComponent],
  exports: [BudgetConfigurationAdministrationSubCategoryNewComponent],
  bootstrap: [BudgetConfigurationAdministrationSubCategoryNewComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
  ],
})
export class BudgetConfigurationAdministrationSubCategoryNewModule {}
