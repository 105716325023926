import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  DxFormModule,
  DxLoadIndicatorModule,
  DxDataGridModule,
  DxSelectBoxModule,
  DxDataGridComponent,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'docket-casegrid',
  templateUrl: './docket-casegrid.component.html',
  styleUrls: ['./docket-casegrid.component.scss'],
})
export class DocketCasegridComponent implements OnInit, OnChanges {
  @ViewChild('caseGridRef', { static: false }) caseGrid: DxDataGridComponent;

  @Input() FileName: string;

  @Output() CaseSelectionEvent: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();

  involvedCasesDataSource: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {
    this.CaseSelectionChanged = this.CaseSelectionChanged.bind(this);
  }

  ngOnInit(): void {
    this.involvedCasesDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${this.url}Patricia/CaseDetailedInfo/lookup`,
      }),
      sort: 'CaseDetailedDescription',
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //filter cases with filename if possible
    if (changes.FileName && changes.FileName.currentValue) {
      const fileName = changes.FileName.currentValue;
      //extracting case values from the filename
      const pattern = /[A-Z]{3}-\d+(?:-[A-Z\d]{4})?|[A-Z]{3}-\d+/g;
      const matches = fileName.match(pattern);

      if (matches) {
        //create a filter contains on cases number for each match in matches
        const caseFilters = matches.map((match) => [
          'CaseNumber',
          'contains',
          match,
        ]);
        this.involvedCasesDataSource.filter(caseFilters);

        this.caseGrid.instance.refresh();
      } else {
        //clear filter
        this.caseGrid.instance.clearFilter();
        this.caseGrid.instance.refresh();
      }
    }
  }

  CaseSelectionChanged() {
    this.CaseSelectionEvent.emit(this.caseGrid.instance.getSelectedRowsData());
  }

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule,
    DxDataGridModule,
    DxSelectBoxModule,
  ],
  declarations: [DocketCasegridComponent],
  exports: [DocketCasegridComponent],
})
export class DocketCasegridModule {}
