import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { ConversionService } from 'src/app/services/conversion/conversion.service';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { ConversionImportCaseFromFileModule } from 'src/app/components/conversion/conversion-import-case-from-file/conversion-import-case-from-file.component';
import { environment } from 'src/environments/environment';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'conversion-case-basic-info',
  templateUrl: './conversion-case-basic-info.component.html',
  styleUrls: ['./conversion-case-basic-info.component.scss'],
})
export class ConversionBasicInfoComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;
  caseTypes: any;
  applicationTypes: any;
  serviceLevels: any;
  countries: any;
  teams: any;
  plTeams: any;

  url: string;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  importFromFilePopupVisible = false;

  constructor(private conversionService: ConversionService) {
    this.url = this.conversionService.getUrl();

    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseBasicInfoId',
        loadUrl: `${this.url}/CaseBasicInfo`,
        updateUrl: `${this.url}/CaseBasicInfo`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });

    this.caseTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseTypeCode',
        loadUrl: `${environment.CalystaApiBaseURL}api/Case/CaseType/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'CaseTypeLabel',
    });

    this.applicationTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'ApplicationTypeLabel',
        loadUrl: `${environment.CalystaApiBaseURL}api/Patricia/ApplicationType/Lookup`,
      }),
      sort: 'ApplicationTypeLabel',
    });

    this.serviceLevels = new DataSource({
      store: AspNetData.createStore({
        key: 'ServiceLevelLabel',
        loadUrl: `${environment.CalystaApiBaseURL}api/Patricia/ServiceLevelLookup/Lookup`,
      }),
      sort: 'ServiceLevelLabel',
    });

    this.countries = new DataSource({
      store: AspNetData.createStore({
        key: 'CountryId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Country/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'Label',
    });

    this.teams = new DataSource({
      store: AspNetData.createStore({
        key: 'TeamLabel',
        loadUrl: `${environment.CalystaApiBaseURL}api/TimeRegistration/TeamInformation/Lookup`,
      }),
      sort: 'TeamLabel',
    });

    this.plTeams = new DataSource({
      store: new ArrayStore({
        data: [{ TeamLabel: 'TRM-PL' }, { TeamLabel: 'PAT-PL' }],
      }),
      sort: 'TeamLabel',
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('CasesBasicInfo');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'CasesBasicInfo.xlsx'
        );
      });
    });
  }

  importFromFile = () => {
    this.importFromFilePopupVisible = true;
  };

  onClosedImportFromFilePopup = (e) => {
    this.importFromFilePopupVisible = false;
  };

  onImportFromFile = (info: { document: any; name: string }) => {
    if (document) {
      const formData = new FormData();
      formData.append('File', info.document);
      formData.append('ConversionName', info.name);

      this.conversionService.importFromFile(formData).subscribe({
        next: (x) => {
          this.importFromFilePopupVisible = false;
          this.refreshData();
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
      });
    }
  };

  importToPx = () => {
    this.conversionService.importToPx().subscribe({
      next: (x) => {},
      error: (err) => {
        const errorMessage = err.error.Errors.GeneralErrors[0];
        notify(errorMessage, 'Error', 3000);
      },
      complete: () => {
        this.refreshData();
      },
    });
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    ConversionImportCaseFromFileModule,
  ],
  declarations: [ConversionBasicInfoComponent],
  exports: [],
})
export class ConversionBasicInfoModule {}
