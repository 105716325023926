import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  DxFormModule,
  DxLoadIndicatorModule,
  DxSelectBoxModule,
} from 'devextreme-angular';
import { DocketMailFormCategorymenuComponent } from '../docket-mail-form-categorymenu/docket-mail-form-categorymenu.component';
import { CalystaAPIDocketingService } from 'src/app/services/calysta-api/docketing.service';

@Component({
  selector: 'app-docket-mail-form-subcategorymenu',
  templateUrl: './docket-mail-form-subcategorymenu.component.html',
  styleUrls: ['./docket-mail-form-subcategorymenu.component.scss'],
})
export class DocketMailFormSubcategorymenuComponent implements OnChanges {
  @Output() subCategoryIdEvent: EventEmitter<number> =
    new EventEmitter<number>();
  @Input() categoryId: number;

  currentSubCategoryId: number;
  subCategories: any;

  subCategoriesDataSource: any;

  constructor(private CalyAPIDocketSVC: CalystaAPIDocketingService) {
    this.SelectSubCategory = this.SelectSubCategory.bind(this);
  }

  /*ngOnInit(): void {
    this.CalyAPIDocketSVC.getSubCategories().subscribe((res: any) => {
      this.subCategoriesDataSource = res.data;

      if (this.categoryId) {
        this.subCategories = {
          store: res.data,
          filter: ['categoryId', '=', this.categoryId],
        };
      }
    });*
  }*/

  SelectSubCategory() {
    this.subCategoryIdEvent.emit(this.currentSubCategoryId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.categoryId) {
      this.subCategories = {
        store: this.subCategoriesDataSource,
        filter: ['categoryId', '=', this.categoryId],
      };
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule,
    DxSelectBoxModule,
  ],
  declarations: [DocketMailFormSubcategorymenuComponent],
  exports: [DocketMailFormSubcategorymenuComponent],
})
export class DocketMailFormSubcategorymenuModule {}
