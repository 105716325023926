import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Employee } from 'src/app/types/employee/employee';

const apiUrl = environment.CalystaApiBaseURL;

interface LocalEmployee {
  Employee: Employee;
  oid: string;
  expirationDate: string;
}

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  url = apiUrl + 'api/employee';

  private _employee = new BehaviorSubject<Employee>(null);

  constructor(private http: HttpClient) {
    const localEmployee: LocalEmployee = JSON.parse(
      localStorage.getItem('employee')
    );

    if (
      localEmployee?.Employee.Email.length > 0 &&
      new Date(localEmployee?.expirationDate) > new Date()
    ) {
      this._employee.next(localEmployee.Employee);
    }
  }

  public getEmployeeName(): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/Lookup`);
  }

  getUrl() {
    return this.url;
  }

  getMyself() {
    return this.http.get<any>(`${this.url}/Me`);
  }

  hasEmployeeInLocalStore(): boolean {
    if (this._employee.getValue() == null) {
      return false;
    }
    return true;
  }

  getEmployee() {
    if (this._employee.getValue() == null) {
      this.loadEmployee();
    }

    return this._employee.asObservable();
  }

  getEmployeeById(EmployeeId: number): Observable<Employee> {
    let params = new HttpParams().set('Id', EmployeeId.toString());

    return this.http
      .get<any>(`${this.url}/GetById`, { params: params })
      .pipe(map((data) => data.Employee));
  }

  getLookup(ActiveOnly?: boolean): Observable<Employee[]> {
    return this.http
      .get<any>(`${this.url}/Lookup?ActiveOnly=${ActiveOnly}`)
      .pipe(map((data) => data.data));
  }

  loadEmployee() {
    this.getMyself().subscribe((result: any) => {
      const employee = result;
      this._employee.next(employee);

      const localEmployee: LocalEmployee = {
        Employee: employee,
        oid: employee.Id,
        //expire 1 week after login
        expirationDate: new Date(
          new Date().getTime() + 7 * 24 * 60 * 60 * 1000
        ).toISOString(),
      };
      localStorage.setItem('employee', JSON.stringify(localEmployee));
    });
  }
}
