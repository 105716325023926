import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { SalesAnalyticsService } from 'src/app/services';
import { CardAnalyticsModule } from '../../library/card-analytics/card-analytics.component';
import { CardGridAnalyticsModule } from '../../library/card-grid-analytics/card-grid-analytics.component';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'wip-card',
  templateUrl: './wip-card.component.html',
  styleUrls: ['./wip-card.component.scss'],
})
export class WipCardComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  Data: any;
  DataUrl: string;

  groupType: 'Team' | 'Client' = 'Client';

  constructor(private invoiceSvc: SalesAnalyticsService) {
    this.DataUrl = this.invoiceSvc.getBaseUrl();
    this.ChangeGrouping = this.ChangeGrouping.bind(this);
  }

  ngOnInit(): void {
    this.Data = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.DataUrl}/WIP`,
      }),
    });
  }

  ChangeGrouping(e: 'Team' | 'Client') {
    this.groupType = e;
    // When grouped on Client, change it to be grouped on Team.
    if (this.groupType === 'Team') {
      this.dataGrid.instance.clearGrouping();
      this.dataGrid.instance.columnOption('Team', 'groupIndex', 0);
      this.dataGrid.instance.columnOption('Client', 'groupIndex', 1);
    } else if (this.groupType === 'Client') {
      this.dataGrid.instance.clearGrouping();
      this.dataGrid.instance.columnOption('Client', 'groupIndex', 0);
      this.dataGrid.instance.columnOption('CalystaRef', 'groupIndex', 1);
    }
  }
}

@NgModule({
  providers: [SalesAnalyticsService],
  exports: [WipCardComponent],
  declarations: [WipCardComponent],
  imports: [
    DxDataGridModule,
    CardAnalyticsModule,
    CardGridAnalyticsModule,
    DxButtonModule,
  ],
})
export class WipCardModule {}
