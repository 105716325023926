import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxLookupModule,
  DxScrollViewModule,
  DxTabPanelModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { BudgetService } from 'src/app/services/budget/budget.service';
import { BudgetConfigurationAdministrationFiguresSalesTargetEditModule } from '../budget-configuration/budget-configuration-administration-figures-sales-target-edit/budget-configuration-administration-figures-sales-target-edit.component';
import { BudgetConfigurationAdministrationFiguresSalesTargetNewModule } from '../budget-configuration/budget-configuration-administration-figures-sales-target-new/budget-configuration-administration-figures-sales-target-new.component';
import notify from 'devextreme/ui/notify';
import { finalize } from 'rxjs';

@Component({
  selector: 'budget-sales-target-grid',
  templateUrl: './budget-sales-target-grid.component.html',
  styleUrls: ['./budget-sales-target-grid.component.scss'],
})
export class BudgetSalesTargetGridComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() budgetType: any;

  dataSource: any;

  url: string;

  isAddPopupOpened: boolean = false;

  isEditPopupOpened: boolean = false;

  editedRow: any;

  teams: any;

  monthsMapping = [
    {
      key: 1,
      value: 'January',
    },
    {
      key: 2,
      value: 'February',
    },
    {
      key: 3,
      value: 'March',
    },
    {
      key: 4,
      value: 'April',
    },
    {
      key: 5,
      value: 'May',
    },
    {
      key: 6,
      value: 'June',
    },
    {
      key: 7,
      value: 'July',
    },
    {
      key: 8,
      value: 'August',
    },
    {
      key: 9,
      value: 'September',
    },
    {
      key: 10,
      value: 'October',
    },
    {
      key: 11,
      value: 'November',
    },
    {
      key: 12,
      value: 'December',
    },
  ];

  constructor(private budgetService: BudgetService) {
    this.url = this.budgetService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}/BudgetSales`,
        insertUrl: `${this.url}/BudgetSales`,
        updateUrl: `${this.url}/BudgetSales`,
        deleteUrl: `${this.url}/BudgetSales`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      filter: ['SalesBudgetTypeId', '=', this.budgetType.id],
    });

    this.teams = new DataSource({
      store: AspNetData.createStore({
        key: 'TeamId',
        loadUrl: `${environment.CalystaApiBaseURL}api/TimeRegistration/TeamInformation/Lookup`,
      }),
      sort: 'TeamLabel',
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onSaveClickNew(e: any): void {
    const budgetSales = e.budgetSales;

    budgetSales.SalesBudgetTypeId = this.budgetType.id;

    const contributors = e.contributors;

    this.dataSource
      .store()
      .insert(budgetSales)
      .done((result) => {
        if (contributors.length > 0) {
          this.budgetService
            .postContributors(result.Id, contributors)
            .pipe(
              finalize(() => {
                this.refreshData();
              })
            )
            .subscribe({
              next: (x) => {},
              error: (err) => {
                const errorMessage = err.error.Errors.GeneralErrors[0];
                notify(errorMessage, 'error', 3000);
              },
            });
        } else {
          this.refreshData();
        }
      });
  }

  onSaveClickEdit(e: any): void {
    this.dataSource
      .store()
      .update(e.Id, this.editedRow)
      .done(() => {
        this.refreshData();
      });
  }

  addNew() {
    this.isAddPopupOpened = true;
  }

  onEdit = (e: any) => {
    this.editedRow = { ...e.row.data };
    this.isEditPopupOpened = true;
  };

  onCloseAdd = (e: boolean) => {
    this.isAddPopupOpened = e;
  };

  onCloseEdit = (e: boolean) => {
    this.isEditPopupOpened = e;
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    DxLookupModule,
    DxTabPanelModule,
    DxScrollViewModule,
    BudgetConfigurationAdministrationFiguresSalesTargetEditModule,
    BudgetConfigurationAdministrationFiguresSalesTargetNewModule,
  ],
  declarations: [BudgetSalesTargetGridComponent],
  exports: [BudgetSalesTargetGridComponent],
})
export class BudgetSalesTargetGridModule {}
