<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  height="30vh"
>
  <dx-form [(formData)]="charge">
    <dxi-item itemType="group" [colCount]="2">
      <dxi-item
        dataField="CaseIds"
        editorType="dxTagBox"
        [colSpan]="2"
        [editorOptions]="{
              dataSource: cases,
              displayExpr: 'CaseNumber',
              valueExpr: 'CaseId',
              showSelectionControls: true,
              applyValueMode: 'useButtons',
              opened: false,
              multiline: true,
              searchEnabled: true,
              searchExpr: ['CaseNumber'],
            }"
      >
        <dxo-label text="Cases" />
      </dxi-item>
    </dxi-item>
  </dx-form>
</form-popup>
