import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { TitleBarModule } from 'src/app/components';
import { saveAs } from 'file-saver-es';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'data-quality-user-logs',
  templateUrl: './data-quality-user-logs.component.html',
  styleUrls: ['./data-quality-user-logs.component.scss'],
})
export class ConsistencyChecksUserLogsComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: DataSource;

  employees: DataSource;

  url: string;

  constructor() {
    this.url = `${environment.CalystaApiBaseURL}api/DataQuality/UserLog`;
  }

  ngOnInit(): void {
    this.loadDataSource();

    this.employees = new DataSource({
      store: AspNetData.createStore({
        key: 'EmployeeId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Employee/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          if (method == 'load') {
            ajaxOptions.data.ActiveOnly = true;
          }
        },
      }),
      sort: ['FirstName', 'LastName'],
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  loadDataSource = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${environment.CalystaApiBaseURL}api/DataQuality/UserLog`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  };

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Logs');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Logs.xlsx`
        );
      });
    });
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    DxPopupModule,
    DxFormModule,
  ],
  declarations: [ConsistencyChecksUserLogsComponent],
  exports: [],
})
export class ConsistencyChecksUserLogsModule {}
