<dx-select-box
  class="col no-gutters"
  [dataSource]="categoryLookup"
  placeholder="Category"
  valueExpr="categoryId"
  displayExpr="label"
  [(value)]="currentCategoryId"
  #dataCategory
  (onItemClick)="categoryChange()"
  [searchEnabled]="true"
>
</dx-select-box>
