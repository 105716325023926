import { Component, HostBinding, OnDestroy } from '@angular/core';
import { AppInfoService, ScreenService, ThemeService } from './services';
import { locale } from 'devextreme/localization';
import config from 'devextreme/core/config';
import { licenseKey } from 'src/devextreme-license';
import { MsalService } from '@azure/msal-angular';
import { AuthPermissionService } from './services/auth/permission.service';
import { AuthService } from './services/auth/auth.service';
import { EmployeeService } from './services/employee/employee.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes)
      .filter((cl) => this.screen.sizes[cl])
      .join(' ');
  }

  constructor(
    themeService: ThemeService,
    private screen: ScreenService,
    private authService: AuthService,
    private authPermissionService: AuthPermissionService,
    private employeeSvc: EmployeeService,
    private msalService: MsalService,
    public appInfo: AppInfoService
  ) {
    locale(navigator.language);
    config({ defaultCurrency: 'EUR', licenseKey });
    themeService.setAppTheme();
    this.loadPermissions();
    this.loadEmployeeDetails();
  }

  loadPermissions() {
    //if permission already loaded from local storage, return
    if (this.authPermissionService.hasPermissionsInLocalStore()) return;

    this.msalService.handleRedirectObservable().subscribe(() => {
      //get id from msal account
      const account = this.msalService.instance.getAllAccounts()[0];
      if (account) {
        //load permissions
        this.authPermissionService.loadPermissions(
          account.idTokenClaims['oid']
        );

        this.authService.getUser();
      }
    });
  }

  loadEmployeeDetails() {
    //if permission already loaded from local storage, return
    if (this.employeeSvc.hasEmployeeInLocalStore()) return;

    this.msalService.handleRedirectObservable().subscribe(() => {
      //get id from msal account
      const account = this.msalService.instance.getAllAccounts()[0];
      if (account) {
        //load permissions
        this.employeeSvc.loadEmployee();
        this.authService.getUser();
      }
    });
  }

  isAuthenticated() {
    return this.authService.loggedIn;
  }

  ngOnDestroy(): void {
    this.screen.breakpointSubscription.unsubscribe();
  }
}
