import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class ConversionService {
  url = apiUrl + 'api/conversion';
  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  importFromFile(formData: FormData) {
    return this.http.post<any>(
      `${this.url}/CaseBasicInfo/UploadConversionFile`,
      formData
    );
  }

  importToPx() {
    return this.http.post<any>(`${this.url}/CaseBasicInfo/ImportCases`, {});
  }
}
