import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  DxDataGridComponent,
  DxDataGridModule,
  DxNumberBoxModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'budget-sub-category-details',
  templateUrl: './budget-sub-category-details.component.html',
  styleUrls: ['./budget-sub-category-details.component.scss'],
})
export class BudgetSubCategoryDetailsFormComponent
  implements OnChanges, OnInit
{
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() entity: any;

  datasource: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  budgetSubCategoryId: number;

  costItems: any;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  constructor() {
    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.costItems = new DataSource({
      store: AspNetData.createStore({
        key: 'CostItemId',
        loadUrl: `${this.url}Costs/CostItem/lookup`,
      }),
      sort: 'Label',
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity && this.entity?.BudgetSubCategoryId) {
      this.budgetSubCategoryId = this.entity.BudgetSubCategoryId;
      this.datasource = new DataSource({
        store: AspNetData.createStore({
          key: 'BudgetCostItemLink',
          loadUrl: `${this.url}Budget/BudgetCostItemLink/${this.budgetSubCategoryId}`,
          updateUrl: `${this.url}Budget/BudgetCostItemLink`,
          insertUrl: `${this.url}Budget/BudgetCostItemLink`,
          deleteUrl: `${this.url}Budget/BudgetCostItemLink`,
          onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            if (this.budgetSubCategoryId) {
              ajaxOptions.data.BudgetSubCategoryId = this.budgetSubCategoryId;
            }
          },
        }),
      });
    }
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };
}

@NgModule({
  imports: [CommonModule, DxDataGridModule, DxNumberBoxModule],
  declarations: [BudgetSubCategoryDetailsFormComponent],
  exports: [BudgetSubCategoryDetailsFormComponent],
})
export class BudgetSubCategoryDetailsFormModule {}
