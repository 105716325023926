<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Providers" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      >
        <dxo-popup
          [showTitle]="true"
          [title]="'Provider'"
          height="80vh"
          width="30vw"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form>
          <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
            <dxi-item dataField="Name" editorType="dxTextBox" [colSpan]="2">
              <dxi-validation-rule type="required" message="Name is required" />
              <dxo-label text="Name" />
            </dxi-item>
            <dxi-item
              dataField="VatNumber"
              editorType="dxTextBox"
              [colSpan]="2"
            >
              <dxo-label text="Vat Number" />
            </dxi-item>
            <dxi-item
              dataField="Email"
              [colSpan]="2"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="Email" />
            </dxi-item>
            <dxi-item
              dataField="Street"
              [colSpan]="2"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxi-validation-rule
                type="required"
                message="Street is required"
              />
              <dxo-label text="Street" />
            </dxi-item>
            <dxi-item
              dataField="Number"
              [colSpan]="2"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxi-validation-rule
                type="required"
                message="Number is required"
              />
              <dxo-label text="Number" />
            </dxi-item>
            <dxi-item
              dataField="PostalCode"
              [colSpan]="2"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxi-validation-rule
                type="required"
                message="Postal Code is required"
              />
              <dxo-label text="Postal Code" />
            </dxi-item>
            <dxi-item dataField="CountryId" [colSpan]="2">
              <dxi-validation-rule
                type="required"
                message="Country is required"
              />
              <dxo-label text="Country" />
            </dxi-item>
            <dxi-item
              dataField="IBAN"
              [colSpan]="2"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="IBAN/Account Number" />
            </dxi-item>
            <dxi-item
              dataField="SwiftCode"
              [colSpan]="2"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="Swiftcode" />
            </dxi-item>
            <dxi-item
              dataField="GoodsProvider"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="Goods Provider ?" />
            </dxi-item>
            <dxi-item
              dataField="Service Provider"
              [editorOptions]="{ validationMessageMode: 'always' }"
            >
              <dxo-label text="Service Provider ?" />
            </dxi-item>
          </dxi-item>
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="new Provider"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="this.dataGrid.instance.addRow()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="Name" caption="Name" alignment="center" />
      <dxi-column
        dataField="VatNumber"
        caption="Vat Number"
        alignment="center"
        width="8%"
      />
      <dxi-column dataField="Email" caption="Email" alignment="center" />
      <dxi-column dataField="Street" caption="Street" alignment="center" />
      <dxi-column
        dataField="Number"
        caption="Number"
        alignment="center"
        width="7%"
      />
      <dxi-column
        dataField="PostalCode"
        caption="Postal Code"
        alignment="center"
        width="6%"
      />
      <dxi-column
        dataField="CountryId"
        caption="Country Id"
        alignment="center"
        width="5%"
      >
        <dxo-lookup
          [dataSource]="countries.store()"
          displayExpr="Label"
          valueExpr="CountryId"
        />
      </dxi-column>
      <dxi-column
        dataField="IBAN"
        caption="IBAN/Account Number"
        alignment="center"
      />
      <dxi-column
        dataField="SwiftCode"
        caption="SwiftCode"
        alignment="center"
      />
      <dxi-column
        dataField="GoodsProvider"
        caption="Goods Provider"
        alignment="center"
        [visible]="false"
      />
      <dxi-column
        dataField="ServiceProvider"
        caption="Service Provider"
        alignment="center"
        [visible]="false"
      />

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridProviders"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
