<dx-data-grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [dataSource]="DataSource"
  [showBorders]="true"
  [showColumnLines]="true"
  [showRowLines]="true"
  (onRowUpdating)="onRowUpdating($event)"
  [wordWrapEnabled]="true"
>
  <dxi-column dataField="IsIntercoRevenue" caption="Interco" />
  <dxi-column dataField="CalystaReference" caption="Case" />
  <dxi-column dataField="WorkCodeLabel" caption="Workcode" />
  <dxi-column dataField="Description" caption="Description" />
  <dxi-column dataField="IntellectualMargin" caption="Intel Margin"
    ><dxo-format type="currency" [precision]="2"></dxo-format
  ></dxi-column>
  <dxi-column dataField="AdminMargin" caption="Admin Margin"
    ><dxo-format type="currency" [precision]="2"></dxo-format
  ></dxi-column>
  <dxi-column dataField="Amount" caption="Invoiced Amount">
    <dxo-format type="currency" [precision]="2"></dxo-format>
  </dxi-column>

  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="storage"
  />
</dx-data-grid>
