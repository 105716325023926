import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { DxPivotGridComponent, DxPivotGridModule } from 'devextreme-angular';
import { Dates } from 'src/app/types/resource';
import { Options as DataSourceConfig } from 'devextreme/ui/pivot_grid/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { CellPreparedEvent, ExportingEvent } from 'devextreme/ui/pivot_grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import { ToolbarAnalyticsModule } from '../../utils/toolbar-analytics/toolbar-analytics.component';

@Component({
  selector: 'volume-report-trm',
  templateUrl: './volume-report-trm.component.html',
  styleUrls: ['./volume-report-trm.component.scss'],
})
export class VolumeReportTRMComponent implements OnInit {
  @ViewChild('budgetedGrid', { static: false })
  budgetedGrid: DxPivotGridComponent;

  pivotGridDataSource: DataSourceConfig;
  pivotGridBudgetDataSource: DataSourceConfig;

  apiUrl = environment.CalystaApiBaseURL + 'api';

  year = environment.production ? new Date().getFullYear() : 2024;

  selectedDate: Dates = {
    startDate: new Date(this.year, 0, 1).toDateString(),
    endDate: new Date(this.year, 11, 31).toDateString(),
  };

  analyticsPanelItems = [
    {
      text: 'Q1',
      startDate: new Date(this.year, 0, 1).toDateString(),
      endDate: new Date(this.year, 2, 31).toDateString(),
      key: 1,
      cummulative: false,
    },
    {
      text: 'Q2',
      startDate: new Date(this.year, 3, 1).toDateString(),
      endDate: new Date(this.year, 5, 30).toDateString(),
      key: 2,
      cummulative: false,
    },
    {
      text: 'Q3',
      startDate: new Date(this.year, 6, 1).toDateString(),
      endDate: new Date(this.year, 8, 30).toDateString(),
      key: 3,
      cummulative: false,
    },
    {
      text: 'Q4',
      startDate: new Date(this.year, 9, 1).toDateString(),
      endDate: new Date(this.year, 11, 31).toDateString(),
      key: 4,
      cummulative: false,
    },
    {
      text: this.year.toString(),
      startDate: new Date(this.year, 0, 1).toDateString(),
      endDate: new Date(this.year, 11, 31).toDateString(),
      key: 5,
      cummulative: false,
    },
  ];

  ngOnInit(): void {
    this.loadData();
    this.loadBudgetData();
  }

  loadData = () => {
    this.pivotGridDataSource = {
      fields: [
        {
          caption: 'Category',
          width: 120,
          dataField: 'Category',
          area: 'row',
        },
        {
          caption: 'Type',
          dataField: 'Type',
          width: 150,
          area: 'column',
          sortOrder: 'desc',
        },
        {
          caption: '#',
          dataField: 'NumberOf',
          summaryType: 'sum',
          area: 'data',
        },
        {
          caption: 'Total margin',
          dataField: 'TotalMargin',
          summaryType: 'sum',
          format: {
            type: 'currency',
            precision: 2,
          },
          area: 'data',
        },
        {
          caption: 'Margin/case',
          dataField: 'AverageMargin',
          summaryType: 'sum',
          format: {
            type: 'currency',
            precision: 2,
          },
          area: 'data',
        },
        {
          caption: 'Price',
          dataField: 'Price',
          summaryType: 'sum',
          format: {
            type: 'percent',
            precision: 0,
          },
          area: 'data',
        },
        {
          caption: 'Volume',
          dataField: 'Volume',
          summaryType: 'sum',
          format: {
            type: 'percent',
            precision: 0,
          },
          area: 'data',
        },
      ],
      remoteOperations: false,
      store: AspNetData.createStore({
        key: 'Category',
        loadUrl: `${this.apiUrl}/Reporting/Volume/Realized/TRM?startDate=${this.selectedDate.startDate}&endDate=${this.selectedDate.endDate}`,
      }),
    };
  };

  loadBudgetData = () => {
    this.pivotGridBudgetDataSource = {
      fields: [
        {
          caption: 'Category',
          width: 120,
          dataField: 'Category',
          area: 'row',
        },
        {
          caption: 'Type',
          dataField: 'Type',
          width: 150,
          area: 'column',
          sortOrder: 'desc',
        },
        {
          caption: '#',
          dataField: 'NumberOf',
          summaryType: 'sum',
          area: 'data',
        },
        {
          caption: 'Total margin',
          dataField: 'TotalMargin',
          summaryType: 'sum',
          format: {
            type: 'currency',
            precision: 2,
          },
          area: 'data',
        },
        {
          caption: 'Margin/case',
          dataField: 'AverageMargin',
          summaryType: 'sum',
          format: {
            type: 'currency',
            precision: 2,
          },
          area: 'data',
        },
      ],
      store: AspNetData.createStore({
        key: 'Category',
        loadUrl: `${this.apiUrl}/Reporting/Volume/Budgeted/TRM?startDate=${this.selectedDate.startDate}&endDate=${this.selectedDate.endDate}`,
      }),
    };
  };

  selectionChange(dates: Dates) {
    this.selectedDate = dates;
    this.loadData();
  }

  onCellPrepared(e: CellPreparedEvent) {
    // Hide specific cells based on conditions
    // Hide admin time hours BTF number of case and BTF number of case last year
    // Hide admin time hours BTF margin/case and BTF margin/case last year
    if (
      e.cell.rowType === 'D' &&
      e.cell.rowPath.includes('Temps admin hors BTF') &&
      [0, 2, 5, 7].includes(e.columnIndex)
    ) {
      e.cellElement.innerHTML = '';
      e.cellElement.innerText = '';
    }

    // Hide watches number of case and watches number of case last year
    // Hide watches margin/case and watches margin/case last year
    if (
      e.cell.rowType === 'D' &&
      e.cell.rowPath.includes('Watches (93%)') &&
      [0, 2, 5, 7].includes(e.columnIndex)
    ) {
      e.cellElement.innerHTML = '';
      e.cellElement.innerText = '';
    }

    // Hide provider mark up IFE number of case and provider mark up IFE number of case last year
    // Hide provider mark up IFE margin/case and provider mark up IFE margin/case last year
    if (
      e.cell.rowType === 'D' &&
      e.cell.rowPath.includes('Provider mark up IFE') &&
      [0, 2, 5, 7].includes(e.columnIndex)
    ) {
      e.cellElement.innerHTML = '';
      e.cellElement.innerText = '';
    }

    // Hide grand total for specified columns
    if (e.cell.rowType === 'GT' && [2, 3, 4, 7, 8, 9].includes(e.columnIndex)) {
      e.cellElement.innerHTML = '';
      e.cellElement.innerText = '';
    }
  }

  onExporting(e: ExportingEvent) {
    const workbook = new Workbook();

    // Add the first worksheet for the budgetedGrid
    const budgetedWorksheet = workbook.addWorksheet('Budgeted');
    exportPivotGrid({
      component: this.budgetedGrid.instance,
      worksheet: budgetedWorksheet,
    }).then(() => {
      // Add the second worksheet for the realizedGrid
      const realizedWorksheet = workbook.addWorksheet('Realized');
      exportPivotGrid({
        component: e.component,
        worksheet: realizedWorksheet,
      }).then(() => {
        // Save the workbook as an Excel file
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            'Volume TRM.xlsx'
          );
        });
      });
    });
  }
}

@NgModule({
  declarations: [VolumeReportTRMComponent],
  exports: [VolumeReportTRMComponent],
  bootstrap: [VolumeReportTRMComponent],
  imports: [CommonModule, DxPivotGridModule, ToolbarAnalyticsModule],
})
export class VolumeReportTRMModule {}
