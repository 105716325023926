import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxTagBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { RwfollowUp } from 'src/app/types/invoicing/rwFollowUp';
import DataSource from 'devextreme/data/data_source';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';
import { BudgetSubCategoryDetailsFormModule } from '../../budget-sub-category-details/budget-sub-category-details.component';

@Component({
  selector: 'budget-configuration-administration-subcategory-edit',
  templateUrl:
    './budget-configuration-administration-subcategory-edit.component.html',
  styleUrls: [
    './budget-configuration-administration-subcategory-edit.component.scss',
  ],
})
export class BudgetConfigurationAdministrationSubCategoryEditComponent
  implements OnInit
{
  @Input() subCategory: any;

  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<RwfollowUp>();

  @Output() visibleChange = new EventEmitter<boolean>();

  budgetCategories: any;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {}

  ngOnInit(): void {
    this.budgetCategories = new DataSource({
      store: AspNetData.createStore({
        key: 'BudgetCategoryId',
        loadUrl: `${this.url}Budget/BudgetCategory/Lookup`,
      }),
      sort: 'BudgetCategoryLabel',
    });
  }

  handleVisible(e: boolean): void {
    this.visible = e;
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.save.emit(this.subCategory);
  }
}

@NgModule({
  declarations: [BudgetConfigurationAdministrationSubCategoryEditComponent],
  exports: [BudgetConfigurationAdministrationSubCategoryEditComponent],
  bootstrap: [BudgetConfigurationAdministrationSubCategoryEditComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
    BudgetSubCategoryDetailsFormModule,
  ],
})
export class BudgetConfigurationAdministrationSubCategoryEditModule {}
