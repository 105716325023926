import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { WorkcodeLookupModule } from '../../lookups/workcode-lookup/workcode-lookup.component';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { FormPopupModule } from '../../utils/form-popup/form-popup.component';
import DataSource from 'devextreme/data/data_source';
import { CrmProspectDetailsFormModule } from '../crm-prospect-details/crm-prospect-details.component';
import { EditorPreparingEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'crm-prospect-edit',
  templateUrl: './crm-prospect-edit.component.html',
  styleUrls: ['./crm-prospect-edit.component.scss'],
})
export class CrmProspectEditComponent implements OnInit {
  @Input() titleText = '';

  @Input() visible = false;

  @Input() prospect: any;

  @Output() save = new EventEmitter<any>();

  @Output() visibleChange = new EventEmitter<boolean>();

  fieldStatus: DataSource;

  origins: DataSource;

  poles: DataSource;

  pmes: DataSource;

  employees: DataSource;

  segmentations: DataSource;

  languages: DataSource;

  selectedFieldStatus: any;

  isClosedDateVisible = false;

  notes: string;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {}

  ngOnInit(): void {
    this.fieldStatus = new DataSource({
      store: AspNetData.createStore({
        key: 'FieldStatusId',
        loadUrl: `${this.url}Prospection/FieldStatus/Lookup`,
      }),
      sort: 'Name',
    });

    this.origins = new DataSource({
      store: AspNetData.createStore({
        key: 'OriginId',
        loadUrl: `${this.url}Prospection/Origin/Lookup`,
      }),
      sort: 'Name',
    });

    this.poles = new DataSource({
      store: AspNetData.createStore({
        key: 'PoleId',
        loadUrl: `${this.url}Prospection/Pole/Lookup`,
      }),
      sort: 'Name',
    });

    this.pmes = new DataSource({
      store: AspNetData.createStore({
        key: 'PmeId',
        loadUrl: `${this.url}Prospection/Pme/Lookup`,
      }),
      sort: 'Name',
    });

    this.segmentations = new DataSource({
      store: AspNetData.createStore({
        key: 'SegmentationId',
        loadUrl: `${this.url}Prospection/Segmentation/Lookup`,
      }),
      sort: 'Name',
    });

    this.employees = new DataSource({
      store: AspNetData.createStore({
        key: 'EmployeeId',
        loadUrl: `${this.url}Employee/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          if (method == 'load') {
            ajaxOptions.data.ActiveOnly = true;
          }
        },
      }),
      sort: ['FirstName', 'LastName'],
    });
  }

  handleVisible(e: boolean): void {
    this.visible = e;
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.prospect.Notes = this.notes;
    this.save.emit(this.prospect);
  }

  onFieldDataChanged(e: any): void {
    if (e.dataField === 'FieldStatusId') {
      this.selectedFieldStatus = e.value;
      this.isClosedDateVisible = this.isClosedDateRequired();
    }
  }

  isClosedDateRequired(): boolean {
    if (this.selectedFieldStatus === 2) {
      return true;
    }
    return false;
  }

  onNotesChanged = (e) => {
    this.notes = e.value;
  };

  onShow() {
    this.notes = this.prospect.Notes;
  }
}

@NgModule({
  declarations: [CrmProspectEditComponent],
  exports: [CrmProspectEditComponent],
  bootstrap: [CrmProspectEditComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
    CrmProspectDetailsFormModule,
    DxTextAreaModule,
  ],
})
export class CrmProspectEditModule {}
