import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'wip-reporting',
  templateUrl: './wip-reporting.component.html',
  styleUrls: ['./wip-reporting.component.scss'],
})
export class WipReportingComponent implements OnInit, OnChanges {
  WIPData: any;
  DataUrl: string;

  APIUrl: string = environment.CalystaApiBaseURL;

  @Input() TeamId: number;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.TeamId) {
      this.loadDataSource();
    }
  }

  ngOnInit() {
    if (this.TeamId) {
      this.loadDataSource();
    }
  }

  loadDataSource() {
    this.WIPData = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.APIUrl}api/Invoice/WIP`,
        loadParams: {
          TeamId: this.TeamId,
        },
      }),
    });
  }
}

@NgModule({
  providers: [],
  exports: [WipReportingComponent],
  declarations: [WipReportingComponent],
  imports: [DxDataGridModule],
})
export class WipReportingModule {}
