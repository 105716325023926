import {
  Component,
  Input,
  NgModule,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DxDropDownButtonModule } from 'devextreme-angular/ui/drop-down-button';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss'],
})
export class PDFPreviewComponent implements OnChanges {
  @Input() BlobFile: Blob = null;
  @Input() previewExists: boolean = false;

  sanitizedBlob: any;

  constructor(private sanitizer: DomSanitizer) {}

  // Show the PDF preview if there is a blob file, otherwise empty.
  ngOnChanges(changes: SimpleChanges) {
    if (this.BlobFile) {
      const blobUrl = URL.createObjectURL(this.BlobFile);

      this.sanitizedBlob =
        this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
    }
  }
}

@NgModule({
  imports: [DxDropDownButtonModule, CommonModule],
  declarations: [PDFPreviewComponent],
  exports: [PDFPreviewComponent],
})
export class PDFPreviewModule {}
