import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Profile } from 'src/app/types/graph/profile';

const API_URL = 'https://graph.microsoft.com/v1.0';

@Injectable()
export class GraphAPIProfileService {
  constructor(private http: HttpClient) {}

  public getProfile = () =>
    this.http.get<Profile>(`${API_URL}/me`).pipe((profile) => profile);
}
