<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Dashboard sales overview" />
    <div class="cards grey">
      <div>
        <card-analytics
          titleText="Actual Bank Balance"
          [isLoading]="!currentBankBalance"
          style="background-color: rgb(9, 169, 243) !important"
        >
          <div class="total">
            {{ currentBankBalance | customCurrency }}
          </div>
        </card-analytics>
      </div>
      <div class="card-width">
        <card-analytics
          titleText="Account Receivable"
          [isLoading]="!accountReceivableAmount"
          style="background-color: rgb(55, 175, 18) !important"
        >
          <div class="total">
            {{ accountReceivableAmount | customCurrency }}
          </div>
        </card-analytics>
      </div>
      <div class="card-width">
        <card-analytics
          titleText="Account Deductions"
          [isLoading]="!accountDeductionsAmount"
          style="background-color: rgb(189, 20, 20) !important"
        >
          <div class="total">
            {{ accountDeductionsAmount | customCurrency }}
          </div>
        </card-analytics>
      </div>
    </div>
    <div class="operationalCosts">
      <h4>Operational Costs</h4>
      <div>
        <div class="dx-date-box-label">Period:</div>
        <div>
          <dx-date-range-box
            applyValueMode="useButtons"
            [showClearButton]="true"
            (onValueChanged)="currentValueChanged($event)"
            [(value)]="currentPeriod"
          />
        </div>
      </div>
      <div class="widget-container" *ngIf="budgetCosts.length > 0">
        <dx-tab-panel
          class="dx-theme-background-color"
          width="100%"
          [height]="'100%'"
          [animationEnabled]="true"
          [swipeEnabled]="false"
          [items]="tabPanelItems"
          tabsPosition="top"
          stylingMode="secondary"
          iconPosition="start"
        >
          <div *dxTemplate="let tabPanelItem of 'item'">
            <div class="tabpanel-item">
              <ng-container [ngSwitch]="tabPanelItem">
                <div *ngSwitchCase="tabPanelItems[0]">
                  <div>
                    <div class="otherCosts">
                      <div>
                        <card-operational-costs
                          [title]="categoryLabel(3)"
                          [amountSpend]="categoryAmountSum(3)"
                          [budgetedAmount]="categoryBudgetedAmountSum(3)"
                          [description]="categoryDescription(3)"
                          [icon]="'fas ' + categoryLogo(3)"
                          [isClickable]="false"
                        />
                      </div>
                      <div class="subCosts">
                        <div *ngFor="let cost of structureCosts">
                          <card-operational-costs
                            [title]="cost.BudgetSubCategoryLabel"
                            [amountSpend]="cost.AmountSpend"
                            [budgetedAmount]="cost.BudgetedAmount"
                            [description]="
                              subCategoryDescription(cost.BudgetSubCategoryId)
                            "
                            [icon]="'fas ' + cost.Logo"
                            [isClickable]="true"
                            (onClick)="gotoLink(cost.BudgetSubCategoryId)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="tabPanelItems[1]">
                  <div>
                    <div class="otherCosts">
                      <div>
                        <card-operational-costs
                          [title]="categoryLabel(5)"
                          [amountSpend]="categoryAmountSum(5)"
                          [budgetedAmount]="categoryBudgetedAmountSum(5)"
                          [description]="categoryDescription(5)"
                          [icon]="'fas ' + categoryLogo(5)"
                          [isClickable]="false"
                        />
                      </div>
                      <div class="subCosts">
                        <div *ngFor="let cost of salaryCosts">
                          <card-operational-costs
                            [title]="cost.BudgetSubCategoryLabel"
                            [amountSpend]="cost.AmountSpend"
                            [budgetedAmount]="cost.BudgetedAmount"
                            [description]="
                              subCategoryDescription(cost.BudgetSubCategoryId)
                            "
                            [icon]="'fas ' + cost.Logo"
                            [isClickable]="true"
                            (onClick)="gotoLink(cost.BudgetSubCategoryId)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="tabPanelItems[2]">
                  <div>
                    <div class="otherCosts">
                      <div>
                        <card-operational-costs
                          [title]="categoryLabel(4)"
                          [amountSpend]="categoryAmountSum(4)"
                          [budgetedAmount]="categoryBudgetedAmountSum(4)"
                          [description]="categoryDescription(4)"
                          [icon]="'fas ' + categoryLogo(4)"
                          [isClickable]="false"
                        />
                      </div>
                      <div class="subCosts">
                        <div *ngFor="let cost of capexCosts">
                          <card-operational-costs
                            [title]="cost.BudgetSubCategoryLabel"
                            [amountSpend]="cost.AmountSpend"
                            [budgetedAmount]="cost.BudgetedAmount"
                            [description]="
                              subCategoryDescription(cost.BudgetSubCategoryId)
                            "
                            [icon]="'fas ' + cost.Logo"
                            [isClickable]="true"
                            (onClick)="gotoLink(cost.BudgetSubCategoryId)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </dx-tab-panel>
      </div>
    </div>
  </div>
</dx-scroll-view>
<dx-load-panel
  container=".view-wrapper"
  [position]="{ of: '.content' }"
  [visible]="isLoading"
  [showPane]="true"
/>
