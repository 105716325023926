<dx-popup
  [(visible)]="visible"
  [title]="titleText"
  (onShowing)="onShow()"
  [showCloseButton]="true"
  (onHidden)="onHide()"
  height="70vh"
  width="70vw"
>
  <dx-scroll-view class="view-wrapper-scroll">
    <div class="view-wrapper">
      <div>
        <dx-data-grid
          [allowColumnResizing]="true"
          [allowColumnReordering]="true"
          id="gridContainer"
          [dataSource]="dataSource"
          [showBorders]="true"
          [remoteOperations]="true"
          width="100%"
          height="40em"
          [showBorders]="true"
          [repaintChangesOnly]="true"
          [wordWrapEnabled]="true"
          (onRowUpdating)="onRowUpdating($event)"
          (onEditorPreparing)="onEditorPreparing($event)"
        >
          <dxo-load-panel [enabled]="true" />
          <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
          <dxo-sorting mode="multiple" />
          <dxo-editing
            mode="popup"
            [allowAdding]="true"
            [allowUpdating]="true"
            [allowDeleting]="true"
          >
            <dxo-popup
              [showTitle]="true"
              [title]="'To do'"
              height="auto"
              width="30vw"
            >
              <dxi-toolbar-item
                toolbar="bottom"
                widget="dxButton"
                location="after"
                [options]="saveButtonOptions"
              />
              <dxi-toolbar-item
                toolbar="bottom"
                widget="dxButton"
                location="after"
                [options]="cancelButtonOptions"
              />
            </dxo-popup>
            <dxo-form>
              <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
                <dxi-item dataField="ActionId">
                  <dxi-validation-rule
                    type="required"
                    message="Action is required"
                  />
                  <dxo-label text="Action" />
                </dxi-item>
                <dxi-item
                  dataField="EmployeeId"
                  [editorOptions]="{
                    validationMessageMode: 'always'
                  }"
                >
                  <dxi-validation-rule
                    type="required"
                    message="Employee is required"
                  />
                  <dxo-label text="Employee" />
                </dxi-item>
                <dxi-item
                  dataField="Deadline"
                  [editorOptions]="{
                    validationMessageMode: 'always'
                  }"
                >
                  <dxi-validation-rule
                    type="required"
                    message="Deadline is required"
                  >
                  </dxi-validation-rule>
                  <dxo-label text="Deadline" />
                </dxi-item>
                <dxi-item dataField="Comments">
                  <dxo-label text="Comments" />
                </dxi-item>
              </dxi-item>
            </dxo-form>
          </dxo-editing>

          <dxo-column-chooser [enabled]="true" />
          <dxo-search-panel [visible]="false" />

          <dxo-toolbar>
            <dxi-item
              location="before"
              widget="dxCheckBox"
              locateInMenu="never"
              [options]="{
                text: 'Include Done',
                icon: '',
                stylingMode: 'contained',
                value: includeDone,
                onValueChanged: includeDoneChanged
              }"
            />
            <dxi-item location="after" locateInMenu="never">
              <dx-button
                text="New Todo"
                icon="plus"
                type="default"
                stylingMode="contained"
                (onClick)="this.dataGrid.instance.addRow()"
              />
            </dxi-item>
            <dxi-item
              location="after"
              widget="dxButton"
              locateInMenu="never"
              [options]="{
                text: '',
                icon: 'refresh',
                stylingMode: 'text',
                onClick: refreshData
              }"
            />
            <dxi-item
              location="after"
              name="columnChooserButton"
              locateInMenu="auto"
            />
            <dxi-item
              location="after"
              name="searchPanel"
              locateInMenu="never"
            />
          </dxo-toolbar>

          <dxi-column
            dataField="ProspectId"
            caption="Prospect"
            alignment="center"
            [visible]="false"
          >
            <dxo-lookup
              [dataSource]="prospects.store()"
              displayExpr="ProspectName"
              valueExpr="ProspectId"
            />
          </dxi-column>
          <dxi-column dataField="ActionId" caption="Action" alignment="center">
            <dxo-lookup
              [dataSource]="actions.store()"
              displayExpr="Name"
              valueExpr="ActionId"
            />
          </dxi-column>
          <dxi-column
            dataField="Deadline"
            caption="Deadline"
            sortOrder="desc"
            dataType="date"
            alignment="center"
          />
          <dxi-column
            dataField="Comments"
            caption="Comments"
            alignment="center"
          />
          <dxi-column
            dataField="EmployeeId"
            caption="Person Responsible"
            [visible]="false"
            alignment="center"
          >
            <dxo-lookup
              [dataSource]="employees.store()"
              displayExpr="FullName"
              valueExpr="EmployeeId"
            />
          </dxi-column>
          <dxi-column
            dataField="Done"
            caption="Is Done"
            [visible]="includeDone"
          />
          <dxi-column
            dataField="DoneDate"
            caption="Done Date"
            dataType="date"
            [visible]="includeDone"
          />
          <dxi-column
            dataField="IsOutcomePositive"
            caption="Is Positive"
            [visible]="includeDone"
          />
          <dxi-column
            dataField="OutcomeComments"
            caption="Outcome Comments"
            [visible]="includeDone"
            alignment="center"
          />
          <dxi-column type="buttons">
            <dxi-button name="edit" />
            <dxi-button name="delete" />
            <dxi-button
              icon="check"
              [onClick]="markAsDone"
              [visible]="isMarkableAsDone"
            />
          </dxi-column>
        </dx-data-grid>
      </div>
      <crm-todo-mark-as-done-form
        [(visible)]="isMarkAsDonePopupOpened"
        (save)="onTodoMarkAsDoneComplete($event)"
        [titleText]="'Mark Todo As Done'"
        (visibleChange)="onCloseMarkAsDone($event)"
        [todo]="todoToMarkAsDone"
      />
    </div>
  </dx-scroll-view>
</dx-popup>
