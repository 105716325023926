import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxTagBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { WorkcodeLookupModule } from '../../lookups/workcode-lookup/workcode-lookup.component';
import { environment } from 'src/environments/environment';
import { FormPopupModule } from '../../utils/form-popup/form-popup.component';
import { RwfollowUp } from 'src/app/types/invoicing/rwFollowUp';
import { ClientPortalInstructionResponseTypeDetailsFormModule } from '../client-portal-instruction-response-type-details/client-portal-instruction-response-type-details.component';

@Component({
  selector: 'client-portal-instructions-response-type-edit',
  templateUrl: './client-portal-instructions-response-type-edit.component.html',
  styleUrls: ['./client-portal-instructions-response-type-edit.component.scss'],
})
export class ClientPortalInstructionsResponseTypeEditComponent {
  @Input() responseType: any;

  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<RwfollowUp>();

  @Output() visibleChange = new EventEmitter<boolean>();

  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {}

  handleVisible(e: boolean): void {
    this.visible = e;
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.save.emit(this.responseType);
  }
}

@NgModule({
  declarations: [ClientPortalInstructionsResponseTypeEditComponent],
  exports: [ClientPortalInstructionsResponseTypeEditComponent],
  bootstrap: [ClientPortalInstructionsResponseTypeEditComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
    ClientPortalInstructionResponseTypeDetailsFormModule,
  ],
})
export class ClientPortalInstructionsResponseTypeEditModule {}
