import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxValidatorModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { RwfollowUp } from 'src/app/types/invoicing/rwFollowUp';
import DataSource from 'devextreme/data/data_source';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';
import { EventConfigurationDetailsFormModule } from 'src/app/components/patricia/patricia-events/event-configuration-details/event-configuration-details.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';

@Component({
  selector: 'renewal-pavis-configuration-event-edit',
  templateUrl: './renewal-pavis-configuration-event-edit.component.html',
  styleUrls: ['./renewal-pavis-configuration-event-edit.component.scss'],
})
export class RenewalPavisConfigurationEventEditComponent
  implements OnInit, OnChanges
{
  @Input() event: any;

  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<RwfollowUp>();

  @Output() visibleChange = new EventEmitter<boolean>();

  caseTypes: any;

  url = environment.CalystaApiBaseURL + 'api/';

  detailDataSource: DataSource;

  constructor() {}

  ngOnInit(): void {
    this.caseTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseTypeId',
        loadUrl: `${this.url}Case/CaseType/Lookup`,
      }),
      sort: 'CaseTypeLabel',
    });
  }

  ngOnChanges(): void {
    if (this.visible) {
      this.detailDataSource = new DataSource({
        store: AspNetData.createStore({
          key: 'EventConfigurationLineId',
          loadUrl: `${this.url}Renewal/EventConfigurationLine/${this.event.EventConfigurationId}`,
          insertUrl: `${this.url}Renewal/EventConfigurationLine`,
          updateUrl: `${this.url}Renewal/EventConfigurationLine`,
          deleteUrl: `${this.url}Renewal/EventConfigurationLine`,
          onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            if (this.event.EventConfigurationId) {
              ajaxOptions.data.EventConfigurationId =
                this.event.EventConfigurationId;
            }
          },
        }),
      });
    }
  }

  handleVisible(e: boolean): void {
    this.visible = e;
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.save.emit(this.event);
  }
}

@NgModule({
  declarations: [RenewalPavisConfigurationEventEditComponent],
  exports: [RenewalPavisConfigurationEventEditComponent],
  bootstrap: [RenewalPavisConfigurationEventEditComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    EventConfigurationDetailsFormModule,
  ],
})
export class RenewalPavisConfigurationEventEditModule {}
