<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  height="85vh"
  width="50vw"
  (onShow)="onShow()"
>
  <dx-form [(formData)]="prospect" validationGroup="validationGroup">
    <dxi-item itemType="group" caption="Company" [colCount]="4">
      <dxi-item
        dataField="Name"
        editorType="dxTextBox"
        [colSpan]="2"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxi-validation-rule type="required" message="Name is required" />
        <dxo-label text="Name" />
      </dxi-item>
      <dxi-item
        dataField="EmployeeId"
        editorType="dxLookup"
        [colSpan]="2"
        [editorOptions]="{
          dataSource: employees,
          displayExpr: 'FullName',
          valueExpr: 'EmployeeId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule
          type="required"
          message="Responsible Person is required"
        />
        <dxo-label text="Responsible Person" />
      </dxi-item>
      <dxi-item
        dataField="BecameProspectOn"
        editorType="dxDateBox"
        [colSpan]="2"
        [editorOptions]="{
          validationMessageMode: 'always',
          acceptCustomValue: false,
          openOnFieldClick: true
        }"
      >
        <dxi-validation-rule
          type="required"
          message="Became Prospect On is required"
        />
        <dxo-label text="Became Prospect On" />
      </dxi-item>
      <dxi-item
        dataField="SegmentationId"
        editorType="dxLookup"
        [colSpan]="2"
        [editorOptions]="{
          dataSource: segmentations,
          displayExpr: 'Name',
          valueExpr: 'SegmentationId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxo-label text="Segmentation" />
      </dxi-item>
      <dxi-item
        dataField="PoleId"
        editorType="dxLookup"
        [colSpan]="2"
        [editorOptions]="{
          dataSource: poles,
          displayExpr: 'Name',
          valueExpr: 'PoleId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxo-label text="Pole" />
      </dxi-item>
      <dxi-item
        dataField="OriginId"
        editorType="dxLookup"
        [colSpan]="2"
        [editorOptions]="{
          dataSource: origins,
          displayExpr: 'Name',
          valueExpr: 'OriginId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="Origin is required" />
        <dxo-label text="Origin" />
      </dxi-item>
      <dxi-item
        dataField="PmeId"
        editorType="dxLookup"
        [colSpan]="2"
        [editorOptions]="{
          dataSource: pmes,
          displayExpr: 'Name',
          valueExpr: 'PmeId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxo-label text="PME" />
      </dxi-item>
      <dxi-item
        dataField="NumberOfTrademark"
        editorType="dxNumberBox"
        [colSpan]="1"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxo-label text="Number of Trademark" />
      </dxi-item>
      <dxi-item
        dataField="NumberOfPatent"
        editorType="dxNumberBox"
        [colSpan]="1"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxo-label text="Number of Patents" />
      </dxi-item>
      <dxi-item
        dataField="ReputationOfProspect"
        editorType="dxCheckBox"
        [colSpan]="1"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxo-label text="Reputation Of Prospect" />
      </dxi-item>
      <dxi-item
        dataField="Potential"
        editorType="dxCheckBox"
        [colSpan]="1"
        [editorOptions]="{ validationMessageMode: 'always' }"
      >
        <dxo-label text="Potential" />
      </dxi-item>
      <dxi-item
        dataField="FieldStatusId"
        editorType="dxLookup"
        [colSpan]="2"
        [editorOptions]="{
          dataSource: fieldStatus,
          displayExpr: 'Name',
          valueExpr: 'FieldStatusId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule
          type="required"
          message="Field Status is required"
        />
        <dxo-label text="Field Status" />
      </dxi-item>
      <dxi-item dataField="Notes" [colSpan]="4">
        <dx-text-area
          validationMessageMode="always"
          [height]="90"
          (onValueChanged)="onNotesChanged($event)"
          [(value)]="notes"
        />
        <dxo-label text="Notes" />
      </dxi-item>
    </dxi-item>
    <dxi-item itemType="group" caption="Contacts" [colCount]="2" [colSpan]="2">
      <crm-prospect-details
        [entity]="prospect"
        [datasource]="detailDataSource"
      />
    </dxi-item>
  </dx-form>
</form-popup>
