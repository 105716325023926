import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DxDropDownButtonComponent,
  DxDropDownButtonModule,
} from 'devextreme-angular/ui/drop-down-button';
import { UserMenuSectionModule } from '../user-menu-section/user-menu-section.component';
import { DxButtonModule, DxListModule, DxMenuModule } from 'devextreme-angular';
import { RegistrationService } from 'src/app/services/time-registration/registration.service';
import { Router } from '@angular/router';
import { ContentReadyEvent } from 'devextreme/ui/drop_down_button';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'notifications-panel',
  templateUrl: 'notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss'],
})
export class NotificationsPanelComponent implements OnInit {
  @ViewChild('dropDownButton') notificationsDropDown: DxDropDownButtonComponent;

  notifications: {
    unprocessedRegistrations: boolean;
  } = {
    unprocessedRegistrations: false,
  };

  menuItems: any[] = [];

  constructor(
    private timeRegistration: RegistrationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.timeRegistration.getRegistration(false).subscribe({
      next: (data) => {
        this.notifications.unprocessedRegistrations = data.totalCount > 0;

        if (this.notifications.unprocessedRegistrations) {
          this.menuItems.push({
            text: 'You have some unprocessed registrations',
            backgroundColor: '#cf0a1bc4',
            onClick: () => {
              this.router.navigate(['/time-registration-register']);
            },
          });

          this.notificationsDropDown.instance.open();
        }
      },
      error: (err) => {
        const errorMessage = err.error.Errors.GeneralErrors[0];
        notify(errorMessage, 'Error', 3000);
      },
      complete: () => {},
    });
  }

  numberOfNotifications() {
    //get the number of properties set to true in the notifications object
    let count = 0;

    for (let key in this.notifications) {
      if (this.notifications[key]) {
        count++;
      }
    }

    return count;
  }
}

@NgModule({
  imports: [
    DxDropDownButtonModule,
    UserMenuSectionModule,
    CommonModule,
    DxButtonModule,
    DxDropDownButtonModule,
    DxListModule,
    DxMenuModule,
  ],
  declarations: [NotificationsPanelComponent],
  exports: [NotificationsPanelComponent],
})
export class NotificationsPanelModule {}
