<dx-popup
  [title]="titleText"
  [(visible)]="visible"
  [fullScreen]="!!(screen.xSmallScreenChanged | async)"
  [width]="width"
  [wrapperAttr]="getWrapperAttrs | apply : wrapperAttr"
  (onHidden)="close()"
  (onShowing)="onShowing()"
  (onShown)="onShownEvent()"
  [height]="height"
>
  <dxi-toolbar-item toolbar="bottom" location="center">
    <div
      class="form-popup-buttons-container"
      [class.flex-buttons]="+width <= 360"
    >
      <dx-button
        [text]="saveText"
        stylingMode="contained"
        type="default"
        [disabled]="isSaveDisabled"
        (onClick)="onSaveClick()"
      />
      <dx-button text="Cancel" stylingMode="contained" (onClick)="close()" />
    </div>
  </dxi-toolbar-item>
  <ng-content></ng-content>
</dx-popup>
