<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Prospects" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [remoteOperations]="true"
      [focusedRowEnabled]="false"
      [showBorders]="true"
      (onRowUpdating)="onRowUpdating($event)"
      (onExporting)="onExporting($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      />
      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export [enabled]="true" />

      <dxo-toolbar>
        <dxi-item location="before">
          <crm-field-status-lookup
            [valueChange]="onFieldStatusSelectionChanged"
            [filters]="['!', ['Name', '=', 'Client']]"
          />
        </dxi-item>
        <dxi-item location="before">
          <employee-name-lookup [valueChange]="onEmployeeSelectionChanged" />
        </dxi-item>
        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="New Prospect"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="addNew()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="exportButton" locateInMenu="auto" />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        dataField="FieldStatusName"
        caption="Status"
        sortOrder="asc"
        alignment="center"
        width="11%"
      />
      <dxi-column
        dataField="Name"
        caption="Name"
        alignment="center"
        width="11%"
      />
      <dxi-column
        dataField="ResponsiblePerson"
        caption="Responsible Person"
        alignment="center"
        width="11%"
      />
      <dxi-column
        dataField="BecameProspectOn"
        caption="Became Prospect On"
        dataType="date"
        alignment="center"
        width="11%"
      />
      <dxi-column
        dataField="Segmentation"
        caption="Segmentation"
        alignment="center"
        width="11%"
      />
      <dxi-column
        dataField="Origin"
        caption="Origin"
        alignment="center"
        width="11%"
      />
      <dxi-column
        dataField="Pme"
        caption="Pme"
        alignment="center"
        width="11%"
      />
      <dxi-column
        dataField="Pole"
        caption="Pole"
        alignment="center"
        width="11%"
      />
      <dxi-column
        dataField="ClosedDate"
        caption="Closed Date"
        dataType="date"
        alignment="center"
        width="11%"
      />
      <dxi-column
        dataField="Notes"
        caption="Notes"
        [visible]="false"
        width="11%"
      />
      <dxi-column
        dataField="ProspectId"
        caption="Prospect Id"
        [visible]="false"
      />
      <dxi-column type="buttons">
        <dxi-button icon="fas fa-list-check" [onClick]="onOpenTodo" />
        <dxi-button icon="edit" [onClick]="onEdit" />
        <dxi-button name="delete" />
        <dxi-button
          icon="fas fa-horse"
          hint="import prospect into patricia"
          [onClick]="onImportClick"
        />
      </dxi-column>

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />
      <dxo-master-detail [enabled]="true" template="detail" />
      <div *dxTemplate="let details of 'detail'">
        <div>
          <div>Contacts</div>
          <dx-data-grid
            [allowColumnResizing]="true"
            [allowColumnReordering]="true"
            [dataSource]="details.data.Contacts"
            (onRowUpdating)="onRowUpdating($event)"
            [wordWrapEnabled]="true"
          >
            <dxi-column
              dataField="FirstName"
              caption="First Name"
              alignment="center"
            />
            <dxi-column
              dataField="LastName"
              caption="Last Name"
              alignment="center"
            />
            <dxi-column
              dataField="Function"
              caption="Function"
              alignment="center"
            />
            <dxi-column dataField="Email" caption="Email" alignment="center" />
            <dxi-column dataField="Phone" caption="Phone" alignment="center" />
            <dxi-column
              dataField="MobilePhone"
              caption="Mobile Phone"
              alignment="center"
            />
            <dxi-column
              dataField="Language"
              caption="Language"
              alignment="center"
            />

            <dxo-state-storing
              [enabled]="false"
              type="localStorage"
              storageKey="gridCrmProspects"
            />
          </dx-data-grid>
        </div>
      </div>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="storage"
      />
    </dx-data-grid>
    <crm-prospect-new
      [(visible)]="isAddPopupOpened"
      (save)="onSaveClickNew($event)"
      [titleText]="'New Prospect'"
      (visibleChange)="onCloseAdd($event)"
    />
    <crm-prospect-edit
      [(visible)]="isEditPopupOpened"
      (save)="onSaveClickEdit($event)"
      [titleText]="'Edit Prospect'"
      (visibleChange)="onCloseEdit($event)"
      [prospect]="editedRow"
    />
    <crm-prospect-todo
      [(visible)]="isTodoPopupOpened"
      (save)="onSaveClickEdit($event)"
      [titleText]="editedRow?.Name + ' Todos'"
      (visibleChange)="onCloseTodo($event)"
      [prospectId]="editedRow?.ProspectId"
    />
    <crm-prospect-import-to-px
      [(visible)]="isImportFormOpened"
      [titleText]="'Import prospect to Patricia'"
      (save)="importToPatricia($event)"
      (visibleChange)="onCloseImport($event)"
      [height]="'35vh'"
    />
  </div>
</dx-scroll-view>
