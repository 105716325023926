import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DxDataGridModule } from 'devextreme-angular';
import { DxoMasterDetailModule } from 'devextreme-angular/ui/nested';
import { TitleBarModule } from 'src/app/components';
import DataSource from 'devextreme/data/data_source';
import { TimeReportingService } from 'src/app/services/time-registration/reporting.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'invoice-details-reporting',
  templateUrl: './invoice-details-reporting.component.html',
  styleUrls: ['./invoice-details-reporting.component.scss'],
})
export class InvoiceDetailsReportingComponent implements OnInit, OnChanges {
  @Input() InvoiceHeaderId: number;
  @Input() TeamId: number;

  DataSource: DataSource;

  constructor(private TimeReportingSvc: TimeReportingService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.loadInvoiceDetailsReporting();
    }
  }

  ngOnInit() {
    this.loadInvoiceDetailsReporting();
  }

  loadInvoiceDetailsReporting() {
    this.DataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'InvoiceDetailId',
        loadUrl: `${this.TimeReportingSvc.getUrl()}/TeamReporting/GetInvoiceReportingDetails`,
        loadParams: {
          InvoiceHeaderId: this.InvoiceHeaderId,
          TeamId: this.TeamId,
        },
      }),
    });
  }

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };
}

@NgModule({
  providers: [],
  exports: [InvoiceDetailsReportingComponent],
  declarations: [InvoiceDetailsReportingComponent],
  imports: [TitleBarModule, DxDataGridModule, DxoMasterDetailModule],
})
export class InvoiceDetailsReportingModule {}
