import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDropDownButtonModule,
  DxLoadPanelModule,
  DxLookupModule,
  DxScrollViewModule,
  DxTagBoxModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { CalystaAPIInvoiceService } from 'src/app/services/calysta-api/invoice.service';
import notify from 'devextreme/ui/notify';
import { InvoiceLineCategoryLookupModule } from 'src/app/components/lookups/invoice-line-category-lookup/invoice-line-category-lookup.component';
import { ItemClickEvent } from 'devextreme/ui/drop_down_button';
import { CostService } from 'src/app/services/financial/cost/cost.service';
import { CellPreparedEvent, RowPreparedEvent } from 'devextreme/ui/data_grid';
import { FinanceChargeEditModule } from 'src/app/components/finance/finance-charges/finance-charge-edit/finance-charge-edit.component';
import { FinanceChargeNewModule } from 'src/app/components/finance/finance-charges/finance-charge-new/finance-charge-new.component';
import { FinanceChargesCopyToCasesFormModule } from 'src/app/components/finance/finance-charges/finance-charges-copy-to-cases-form/finance-charges-copy-to-cases-form.component';
import { FinanceChargesDetailsModule } from 'src/app/components/finance/finance-charges/finance-charges-details/finance-charges-details.component';
import { FinanceChargesLinkToExistingInvoiceModule } from 'src/app/components/finance/finance-charges/finance-charges-link-to-existing-invoice/finance-charges-link-to-existing-invoice.component';
import { FinanceChargesMoveToCasesFormModule } from 'src/app/components/finance/finance-charges/finance-charges-move-to-cases-form/finance-charges-move-to-cases-form.component';
import { FinanceChargeRemoveFormModule } from 'src/app/components/finance/finance-charges/finance-charges-remove-form/finance-charges-remove-form.component';
import { FinanceChargesSplitFormModule } from 'src/app/components/finance/finance-charges/finance-charges-split-form/finance-charges-split-form.component';
import { FinanceCostHeaderEditModule } from 'src/app/components/finance/finance-cost/finance-cost-header-edit/finance-cost-header-edit.component';
import { FinanceDraftInvoiceModule } from 'src/app/components/finance/finance-draft-invoice/finance-draft-invoice.component';
import { ThemeService } from 'src/app/services';
import { finalize } from 'rxjs';

@Component({
  selector: 'finance-chargesold',
  templateUrl: './finance-charges.component.html',
  styleUrls: ['./finance-charges.component.scss'],
})
export class FinanceChargesComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  chargesDatasource: DataSource;

  removeVatButtonVisible: boolean = false;

  selectedRows: any[] = [];

  url: string;

  popupType:
    | 'new'
    | 'edit'
    | 'remove'
    | 'linkToExistingInvoice'
    | 'copyToCases'
    | 'split'
    | 'draftInvoice'
    | 'moveToCase'
    | 'editCostHeader'
    | null = null;

  selectedCharge: any;

  relatedInvoiceFile: any = null;

  serviceLoading = false;

  showInvoiced: boolean = false;

  invoiceButtonVisible: boolean = false;

  selectedNonVatAmount: number = 0;

  selectedVatAmount: number = 0;

  categoryIds: number[] = [];

  categories: any;

  buttons: {
    value: number | string;
    name: string;
    icon?: string;
  }[];

  constructor(
    private invoiceService: CalystaAPIInvoiceService,
    private costService: CostService,
    private themeService: ThemeService
  ) {
    this.url = invoiceService.getUrl();

    this.openRelatedInvoice = this.openRelatedInvoice.bind(this);

    this.buttons;
  }

  ngOnInit(): void {
    this.loadDatasource();

    this.categories = new DataSource({
      store: AspNetData.createStore({
        key: 'InvoiceLineCategoryId',
        loadUrl: `${this.url}/InvoiceLineCategory/Lookup`,
      }),
      sort: 'InvoiceLineCategoryLabel',
    });

    this.loadButtons();
  }

  loadButtons = () => {
    this.buttons = [
      {
        value: 'openRelatedDocument',
        name: 'Open related invoice',
        icon: 'textdocument',
      },
      {
        value: 'linkToExistingInvoice',
        name: 'Link to existing invoice',
        icon: 'fas fa-link',
      },
      {
        value: 'copyToCases',
        name: 'Copy to other cases',
        icon: 'far fa-copy',
      },
      { value: 'remove', name: 'Remove', icon: 'trash' },
    ];
  };

  refreshData = () => {
    this.relatedInvoiceFile = null;
    this.dataGrid.instance.refresh();
  };

  loadDatasource() {
    this.chargesDatasource = new DataSource({
      store: AspNetData.createStore({
        key: 'ChargeId',
        loadUrl: `${this.url}/charge`,
        insertUrl: `${this.url}/charge`,
        updateUrl: `${this.url}/charge`,
        deleteUrl: `${this.url}/charge`,
        loadParams: { IncludeInvoiced: this.showInvoiced },
        onBeforeSend: (method, ajaxOptions) => {
          //add categoryIds to the request
          if (this.categoryIds.length > 0) {
            ajaxOptions.data.categoryIds = this.categoryIds;
          }
        },
      }),
    });
  }

  selectAllInClientGroup(e: any) {
    const groupKey = e.data.key;

    //get current filter
    var currentFilters = this.dataGrid.instance.getCombinedFilter()[0];

    var clientFilter = ['ClientName', '=', groupKey];

    var combinedFilters = currentFilters
      ? [currentFilters, 'and', clientFilter]
      : clientFilter;

    this.chargesDatasource
      .store()
      .load({ filter: combinedFilters })
      .then((items: any) => {
        const selectedKeys = items.map((item) => item.ChargeId);
        this.selectedRows.push(...selectedKeys);
      });
  }

  selectAllInCaseGroup(e: any) {
    const groupKey = e.data.key;

    //get current filter
    var currentFilters = this.dataGrid.instance.getCombinedFilter()[0];

    var clientFilter = ['CaseSummaryCaly', '=', groupKey];

    var combinedFilters = currentFilters
      ? [currentFilters, 'and', clientFilter]
      : clientFilter;

    this.chargesDatasource
      .store()
      .load({ filter: combinedFilters })
      .then((items: any) => {
        const selectedKeys = items.map((item) => item.ChargeId);
        this.selectedRows.push(...selectedKeys);
      });
  }

  OpenRowAmount = (e) => {
    var amount: number = e.summaryItems[0].value;
    //round value to 2 decimals
    return amount.toFixed(2);
  };

  onSaveClick(e: any): void {
    this.dataGrid.instance
      .getDataSource()
      .store()
      .insert(e)
      .then(() => {
        this.popupType = null;
        this.refreshData();
      })
      .catch((err) => {
        const errorMessage = err;
        notify(errorMessage, 'Error', 10000);
      });
  }

  onSaveClickEdit(e: any): void {
    this.dataGrid.instance
      .getDataSource()
      .store()
      .update(e.ChargeId, this.selectedCharge)
      .then(() => {
        this.popupType = null;
        this.refreshData();
      })
      .catch((err) => {
        const errorMessage = err;
        notify(errorMessage, 'Error', 10000);
      });
  }

  onSaveClickCopyToCases(e: any) {
    const casesIds = e.CaseIds;

    this.invoiceService
      .copyToCases(this.selectedCharge.ChargeId, casesIds)
      .subscribe({
        next: (x) => {
          this.refreshData();
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
        complete: () => {
          this.popupType = null;
        },
      });
  }

  onSaveClickMoveToCases(e: any) {
    const casesId = e;

    this.invoiceService.moveToCases(this.selectedRows, casesId).subscribe({
      next: (x) => {
        this.popupType = null;
        this.refreshData();
      },
      error: (err) => {
        const errorMessage = err.error.Errors.GeneralErrors[0];
        notify(errorMessage, 'Error', 3000);
      },
      complete: () => {
        this.popupType = null;
      },
    });
  }

  onSaveClickLinkToInvoiced(e: any) {
    const selectedRowKey = e.selectedRowKey;
    const handleDiff = e.handleDiff;

    this.invoiceService
      .linkToInvoice(this.selectedCharge.ChargeId, selectedRowKey, handleDiff)
      .subscribe({
        next: (x) => {
          this.refreshData();
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
        complete: () => {
          this.popupType = null;
        },
      });
  }

  onSaveClickSplit(e: any) {
    const splitAmount = e;

    this.invoiceService
      .splitCharge(this.selectedCharge.ChargeId, splitAmount)
      .subscribe({
        next: (x) => {
          this.dataGrid.instance.refresh();
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
        complete: () => {
          this.popupType = null;
        },
      });
  }

  onSaveClickRemove(e: any) {
    const removeTime = e.removeTime;

    const workCodeOnRemove = e.workCodeId;

    this.invoiceService
      .removeCharge(this.selectedCharge.ChargeId, removeTime, workCodeOnRemove)
      .subscribe({
        next: (x) => {
          this.dataGrid.instance.refresh();
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
        complete: () => {
          this.popupType = null;
        },
      });
  }

  onClosedPopup(e) {
    this.popupType = null;
    this.dataGrid.instance.refresh();
  }

  onEdit = (e: any) => {
    this.selectedCharge = { ...e.row.data };
    this.popupType = 'edit';
  };

  isNotFinalized = (e) => {
    return e.row.data.InvoiceFinalized === false;
  };

  isLinkable = (e) => {
    const workCodes = [1, 7, 8, 9, 10];
    const data = e;

    return (
      workCodes.includes(data.WorkCodeCategoryId) &&
      data.CostLineId !== null &&
      data.InvoiceFinalized === false
    );
  };

  isInvoiceConnected(e): boolean {
    return e.row.data.InvoiceNumber ? true : false;
  }

  openRelatedInvoice(data): void {
    this.serviceLoading = true;
    this.invoiceService
      .getInvoice(data.InvoiceHeaderId)
      .pipe(
        finalize(() => {
          this.serviceLoading = false;
        })
      )
      .subscribe({
        next: (x) => {
          this.selectedCharge = x;
        },
        complete: () => {
          if (data) {
            this.popupType = 'draftInvoice';
          }
        },
      });
  }

  onClickLink = (e) => {
    this.selectedCharge = { ...e };
    this.popupType = 'linkToExistingInvoice';
  };

  onClickCopy = (e) => {
    this.selectedCharge = { ...e };
    this.popupType = 'copyToCases';
  };

  onClickSplit = (e) => {
    this.selectedCharge = { ...e.row.data };
    this.popupType = 'split';
  };

  onRemove = (e: any) => {
    this.selectedCharge = { ...e };

    if (this.selectedCharge.CostLineId !== null) {
      notify(
        'This cost is linked to an incoming Invoice, the Invoice should be modified, not the Charge.',
        'error',
        3000
      );
      return;
    }

    if (this.selectedCharge.HasTime) {
      this.popupType = 'remove';
    } else {
      this.dataGrid.instance
        .getDataSource()
        .store()
        .remove(this.selectedCharge.ChargeId)
        .then(() => {
          this.refreshData();
        })
        .catch((err) => {
          const errorMessage = err;
          notify(errorMessage, 'Error', 10000);
        });
    }
  };

  onCategoryChanged = (e: any): void => {
    this.categoryIds = e.value;
    this.loadDatasource();
  };

  removeVat = () => {
    const selectedRows = this.dataGrid.instance
      .getSelectedRowsData()
      .map((x) => x.ChargeId);

    this.invoiceService.removeVat(selectedRows).subscribe({
      next: (x) => {
        this.dataGrid.instance.refresh();
      },
      error: (err) => {
        const errorMessage = err.error.Errors.GeneralErrors[0];
        notify(errorMessage, 'error', 3000);
      },
    });
  };

  addCharge() {
    this.popupType = 'new';
  }

  moveToCases = () => {
    this.popupType = 'moveToCase';
  };

  toggleShowInvoiced(e) {
    this.showInvoiced = !this.showInvoiced;

    this.loadDatasource();
  }

  createInvoice = (e) => {
    const charges = this.selectedRows;
    this.invoiceService.createDraftInvoice(charges).subscribe({
      next: (x) => {
        this.selectedCharge = x;
      },
      error: (err) => {
        const errorMessage = err.error.Errors.GeneralErrors[0];
        notify(errorMessage, 'error', 3000);
      },
      complete: () => {
        this.popupType = 'draftInvoice';
      },
    });
  };

  onEditorPreparing(e) {
    if (e.type !== 'selection') return;

    if (e.parentType === 'dataRow' && e.row && !this.isSelectable(e.row.data)) {
      e.editorOptions.disabled = true;
    }

    if (e.parentType == 'headerRow' && e.command == 'select') {
      e.editorElement.remove();
    }
  }

  isSelectable(item: any) {
    if (item.InvoiceNumber) return false;
    return true;
  }

  //When selecting and deselecting charges
  // => Given a quick view on the total amount that has been selected.
  selectionChangedHandler(e): void {
    const deselectRowKeys: number[] = [];
    const dataGrid = e.component;

    e.selectedRowsData.forEach((item) => {
      if (!this.isSelectable(item)) deselectRowKeys.push(dataGrid.keyOf(item));
    });
    if (deselectRowKeys.length) {
      dataGrid.deselectRows(deselectRowKeys);
    }

    //remove deselected rows from e.selectedRowsData
    e.selectedRowsData = e.selectedRowsData.filter(
      (x) => !deselectRowKeys.includes(x.ChargeId)
    );

    this.selectedNonVatAmount = this.dataGrid.instance
      .getSelectedRowsData()
      //dont include invoiced one
      .filter((x) => !x.InvoiceFinalized)
      .reduce((acc, cur) => acc + cur.NonVatAmount, 0);

    //round value to 2 decimals
    this.selectedNonVatAmount =
      Math.round(this.selectedNonVatAmount * 100) / 100;

    if (e.selectedRowsData.length > 0) {
      this.invoiceButtonVisible = true;
    } else {
      this.invoiceButtonVisible = false;
    }

    this.selectedVatAmount = this.dataGrid.instance
      .getSelectedRowsData()
      .reduce((acc, cur) => acc + cur.VatAmount, 0);
    if (this.selectedVatAmount != 0) {
      this.removeVatButtonVisible = true;
    } else {
      this.removeVatButtonVisible = false;
    }
  }

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onClickMenuButtonClick(row: any) {
    this.loadButtons();

    const data = row.data;

    if (data.InvoiceNumber == null) {
      this.buttons = this.buttons.filter(
        (button) => button.value !== 'openRelatedDocument'
      );
    }

    if (!this.isLinkable(data)) {
      this.buttons = this.buttons.filter(
        (button) => button.value !== 'linkToExistingInvoice'
      );
    }

    if (data.CostLineId) {
      this.buttons = this.buttons.filter(
        (button) => button.value !== 'copyToCases'
      );
    }
  }

  onItemClick(e: ItemClickEvent, row: any) {
    const data = row.data;

    switch (e.itemData.value) {
      case 'openRelatedDocument':
        this.openRelatedInvoice(data);
        break;
      case 'linkToExistingInvoice':
        this.onClickLink(data);
        break;
      case 'copyToCases':
        this.onClickCopy(data);
        break;
      case 'remove':
        this.onRemove(data);
        break;
    }
  }

  hasCostline = (e: any) => {
    return e.row.data.CostLineId !== null && e.row.data.CostLineId !== 0;
  };

  EditCostHeader = (e: any) => {
    this.selectedCharge = { ...e.row.data };
    this.popupType = 'editCostHeader';
  };

  onSaveEditCostHeader = (e: any) => {
    this.costService.updateCostHeader(e.CostHeaderId, e).subscribe({
      next: (x) => {
        this.selectedCharge = x;
      },
      error: (err) => {
        const errorMessage = err.error.Errors.GeneralErrors[0];
        notify(errorMessage, 'Error', 3000);
      },
      complete: () => {
        this.popupType = null;
        this.dataGrid.instance.refresh();
      },
    });
  };

  onRowPrepared(row: RowPreparedEvent) {
    if (row.rowType === 'data' && row.data.InvoiceFinalized === true) {
      if (this.themeService.isDark) {
        row.rowElement.style.backgroundColor = '#bdb9b9';
      } else {
        row.rowElement.style.backgroundColor = '#e6e4e4';
      }
    }

    if (
      row.rowType === 'data' &&
      row.data.InvoiceHeaderId !== null &&
      row.data.InvoiceFinalized === false
    ) {
      if (this.themeService.isDark) {
        row.rowElement.style.backgroundColor = '#909090';
      } else {
        row.rowElement.style.backgroundColor = '#e6e4e4';
      }
    }
  }

  onCellPrepared(cell: CellPreparedEvent) {
    if (
      cell.rowType == 'data' &&
      (cell.column as any).command == 'select' &&
      cell.row.data.InvoiceFinalized === true
    ) {
      const checkbox: any = cell.cellElement.querySelector(
        '.dx-select-checkbox'
      );
      checkbox.style.display = 'none';
    }
  }

  clearSelection = () => {
    this.dataGrid.instance.clearSelection();
  };
}

@NgModule({
  declarations: [FinanceChargesComponent],
  exports: [],
  providers: [],
  imports: [
    CommonModule,
    DxDataGridModule,
    DxScrollViewModule,
    TitleBarModule,
    DxTextBoxModule,
    DxButtonModule,
    FinanceChargeEditModule,
    FinanceChargeNewModule,
    FinanceChargeRemoveFormModule,
    FinanceChargesLinkToExistingInvoiceModule,
    FinanceChargesCopyToCasesFormModule,
    FinanceChargesSplitFormModule,
    FinanceDraftInvoiceModule,
    DxLoadPanelModule,
    InvoiceLineCategoryLookupModule,
    FinanceChargesMoveToCasesFormModule,
    DxDropDownButtonModule,
    FinanceCostHeaderEditModule,
    FinanceChargesDetailsModule,
    DxLookupModule,
    DxTagBoxModule,
  ],
})
export class FinanceChargesModule {}
