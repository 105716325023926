import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import DataSource from 'devextreme/data/data_source';
import { environment } from 'src/environments/environment';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import {
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
} from 'devextreme-angular';

@Component({
  selector: 'finance-invoices-out-time-encoded',
  templateUrl: './finance-invoices-out-time-encoded.component.html',
  styleUrls: ['./finance-invoices-out-time-encoded.component.scss'],
})
export class FinanceInvoicesOutTimeEncodedComponent {
  @Input() visible: boolean = false;

  @Input() invoiceHeaderId: number | undefined;

  @Output() visibleChange = new EventEmitter<boolean>();

  datasource: DataSource;

  billingTypes: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {
    this.billingTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'BillingTypeId',
        loadUrl: `${this.url}TimeRegistration/BillingType/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'BillingTypeLabel',
    });
  }

  onShow() {
    this.datasource = new DataSource({
      store: AspNetData.createStore({
        key: 'RegistrationDetailId',
        loadUrl: `${this.url}Invoice/InvoiceHeader/Time`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.data.invoiceHeaderId = this.invoiceHeaderId;
        },
      }),
    });
  }

  onHide() {
    this.datasource = null;

    this.visibleChange.emit(false);
  }
}

@NgModule({
  declarations: [FinanceInvoicesOutTimeEncodedComponent],
  exports: [FinanceInvoicesOutTimeEncodedComponent],
  bootstrap: [FinanceInvoicesOutTimeEncodedComponent],
  imports: [BrowserModule, DxDataGridModule, DxScrollViewModule, DxPopupModule],
})
export class FinanceInvoicesOutTimeEncodedModule {}
