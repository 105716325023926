import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { PermissionEditModule } from 'src/app/components/permissions/permissions-edit/permissions-edit.component';
import { AdministrationService } from 'src/app/services/administration/administration.service';

@Component({
  selector: 'portal-admin-user',
  templateUrl: './portal-admin-user.component.html',
  styleUrls: ['./portal-admin-user.component.scss'],
})
export class AdminPortalUserComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  isEditPopupVisible = false;

  selectUserId: any;

  selectUserPermissionsIds: any[] = [];

  url: string;

  constructor(private administrationService: AdministrationService) {
    this.url = this.administrationService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}/Permission/Users`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'UserName',
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onEditClick = (e) => {
    this.isEditPopupVisible = true;
    this.selectUserId = e.row.data.Id;
    this.selectUserPermissionsIds = e.row.data.Permissions;
  };

  onCloseEdit = (e: boolean) => {
    this.isEditPopupVisible = e;
    this.refreshData();
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    PermissionEditModule,
  ],
  declarations: [AdminPortalUserComponent],
  exports: [],
})
export class AdminPortalUserModule {}
