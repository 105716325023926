<card-analytics
  [titleText]="title"
  [isLoading]="realized === null"
  [contentClass]="contentClass"
>
  <div class="total">
    {{ realized  }} {{ summaryPhrase }}
  </div>

</card-analytics>
