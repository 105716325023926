<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  (onShow)="onShow()"
>
  <dx-form [(formData)]="registration">
    <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
      <dxi-item
        [colSpan]="2"
        #employeeLookup
        dataField="EmployeeId"
        editorType="dxLookup"
        [editorOptions]="{
          dataSource: employees,
          displayExpr: 'FullName',
          valueExpr: 'EmployeeId',
          searchEnabled: true,
          validationMessageMode: 'always',
        }"
      >
        <dxo-label text="Employee" />
      </dxi-item>
      <dxi-item itemType="group" caption="Summary" [colCount]="3" [colSpan]="2">
        <dxi-item
          dataField="TotalHours"
          editorType="dxNumberBox"
          [disabled]="true"
          [editorOptions]="{
            validationMessageMode: 'always',
            value: getTotalHours(),
            format: { type: 'fixedPoint' }
          }"
        />
        <dxi-item
          dataField="TotalMinutes"
          editorType="dxNumberBox"
          [disabled]="true"
          [editorOptions]="{
            validationMessageMode: 'always',
            value: getTotalMinutes(),
            format: { type: 'fixedPoint' }
          }"
        />
        <dxi-item />
        <dxi-item
          dataField="NonBillableHours"
          editorType="dxNumberBox"
          [disabled]="true"
          [editorOptions]="{
            validationMessageMode: 'always',
            value: getNonBillableHours(),
            format: { type: 'fixedPoint' }
          }"
        />
        <dxi-item
          dataField="NonBillableMinutes"
          editorType="dxNumberBox"
          [disabled]="true"
          [editorOptions]="{
            validationMessageMode: 'always',
            value: getNonBillableMinutes(),
            format: { type: 'fixedPoint' }
          }"
        />
        <dxi-item
          dataField="BillableHours"
          editorType="dxNumberBox"
          [disabled]="true"
          [editorOptions]="{
            validationMessageMode: 'always',
            value: getBillableHours(),
            format: { type: 'fixedPoint' }
          }"
        />
        <dxi-item
          dataField="BillableMinutes"
          editorType="dxNumberBox"
          [disabled]="true"
          [editorOptions]="{
            validationMessageMode: 'always',
            value: getBillableMinutes(),
            format: { type: 'fixedPoint' }
          }"
        />
        <dxi-item
          dataField="TotalBillableAmount"
          editorType="dxNumberBox"
          [disabled]="true"
          [editorOptions]="{
            validationMessageMode: 'always',
            format: { type: 'currency', precision: 2 },
            value: getTotalBillableAmount()
          }"
        />
      </dxi-item>
    </dxi-item>
    <dxi-item
      itemType="group"
      caption="Registration Details"
      [colCount]="2"
      [colSpan]="2"
    >
      <registration-details-form
        [entity]="registration"
        [datasource]="detailDataSource"
        (timesChange)="updateTimes($event)"
        [EmployeeId]="registration.EmployeeId"
      />
    </dxi-item>
  </dx-form>
</form-popup>
