import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Language } from 'src/app/types/case/language';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class CaseService {
  url = apiUrl + 'api/case';
  constructor(private http: HttpClient) {}

  public getCaseTypes(): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/CaseType/Lookup`);
  }

  getUrl() {
    return this.url;
  }

  getAccountAddressLanguage(caseId: number): Observable<Language> {
    let params: HttpParams = new HttpParams()
      .set('CaseId', caseId)
      .set('RoleTypeId', 5);
    return this.http.get<any>(`${this.url}/Language`, { params: params });
  }

  getCaseById(caseId: number): Observable<any> {
    return this.http.get<any>(`${this.url}/${caseId}`);
  }
}
