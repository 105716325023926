<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  [height]="height"
  [width]="width"
>
  <dx-form>
    <dxi-item itemType="group">
      <dxi-item
        datafield="ConversionName"
        editorType="dxTextBox"
        [editorOptions]="{
          onValueChanged: onConversionNameChanged,
        }"
      >
        <dxo-label text="Conversion Name" />
      </dxi-item>
      <dxi-item
        dataField="Document"
        editorType="dxFileUploader"
        [editorOptions]="{
          validationMessageMode: 'always',
          uploadMode: 'useForm',
          onValueChanged: onFileChanged,
          accept: '.xlsx',
        }"
      >
        <dxo-label text="Import" />
      </dxi-item>
    </dxi-item>
  </dx-form>
</form-popup>
