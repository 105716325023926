<dx-lookup
  placeholder="Select Employee"
  [dataSource]="datasource"
  displayExpr="FullName"
  valueExpr="EmployeeId"
  (onValueChanged)="valueChanged($event)"
  [searchEnabled]="true"
  [showClearButton]="true"
  width="auto"
  class="lookup"
  [value]="value ?? defaultValue"
  (onInitialized)="onInitialized($event)"
>
</dx-lookup>
