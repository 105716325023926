import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  DxDataGridComponent,
  DxDataGridModule,
  DxNumberBoxModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'client-portal-instruction-response-type-details',
  templateUrl:
    './client-portal-instruction-response-type-details.component.html',
  styleUrls: [
    './client-portal-instruction-response-type-details.component.scss',
  ],
})
export class ClientPortalInstructionResponseTypeDetailsFormComponent
  implements OnChanges, OnInit
{
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() entity: any;

  datasource: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  wfResponseTypeId: number;

  teams: any;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  constructor() {
    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.teams = new DataSource({
      store: AspNetData.createStore({
        key: 'TeamId',
        loadUrl: `${this.url}TimeRegistration/TeamInformation/Lookup`,
      }),
      sort: 'TeamLabel',
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity && this.entity?.WfResponseTypeId) {
      this.wfResponseTypeId = this.entity.WfResponseTypeId;
      this.datasource = new DataSource({
        store: AspNetData.createStore({
          key: 'WfResponseTypeOptionsId',
          loadUrl: `${this.url}ClientPortal/WfResponseTypeOptions/${this.wfResponseTypeId}`,
          updateUrl: `${this.url}ClientPortal/WfResponseTypeOptions`,
          insertUrl: `${this.url}ClientPortal/WfResponseTypeOptions`,
          deleteUrl: `${this.url}ClientPortal/WfResponseTypeOptions`,
          onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            if (this.wfResponseTypeId) {
              ajaxOptions.data.WfResponseTypeId = this.wfResponseTypeId;
            }
          },
        }),
      });
    }
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };
}

@NgModule({
  imports: [CommonModule, DxDataGridModule, DxNumberBoxModule],
  declarations: [ClientPortalInstructionResponseTypeDetailsFormComponent],
  exports: [ClientPortalInstructionResponseTypeDetailsFormComponent],
})
export class ClientPortalInstructionResponseTypeDetailsFormModule {}
