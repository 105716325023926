<dx-chart
  #chart
  [dataSource]="MarginReportingDataSource"
  (onPointClick)="onChartPointClick($event)"
>
  <dxi-series valueField="MonthTarget" name="Target" color="grey" />
  <dxi-series valueField="Margin" name="Realized" color="orange" />
  <dxo-common-series-settings
    #commonSeries
    argumentField="MonthLabel"
    [type]="'area'"
  >
    <dxo-point [visible]="true" />
  </dxo-common-series-settings>
  <dxo-margin [bottom]="20" />
  <dxo-argument-axis [valueMarginsEnabled]="false" />
  <dxo-export [enabled]="true" />
  <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" />
  <dxo-tooltip [enabled]="true" contentTemplate="tooltipTemplate" />
  <div *dxTemplate="let info of 'tooltipTemplate'">
    <div class="TooltipHeader">
      {{ info.argument }}
    </div>
    <div class="TooltipBody">
      <div>
        <span class="DataFieldName">Admin. Margin: </span>
        <span class="ResultValue">{{ info.point.data.AdminMargin }} €</span>
      </div>
      <div>
        <span class="DataFieldName">Intel. Margin: </span>
        <span class="ResultValue">
          {{ info.point.data.IntellectualMargin }} €
        </span>
      </div>
      <div>
        <span class="DataFieldName">Total Margin: </span>
        <span class="ResultValue">{{ info.point.data.Margin }} €</span>
      </div>
      <div>
        <span class="DataFieldName">Month Target: </span>
        <span class="ResultValue">{{ info.point.data.MonthTarget }} €</span>
      </div>
    </div>
  </div>
</dx-chart>
