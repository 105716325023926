import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  DxDataGridComponent,
  DxDataGridModule,
  DxDateBoxModule,
  DxNumberBoxModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import {
  ContentReadyEvent,
  RowInsertedEvent,
  RowUpdatedEvent,
} from 'devextreme/ui/data_grid';
import { VatService } from 'src/app/services/vat.service';
import { WorkcodeService } from 'src/app/services/invoicing/workcode/workcode.service';
import { CaseService } from 'src/app/services/case/case.service';

@Component({
  selector: 'invoice-in-details-form',
  templateUrl: './invoice-in-details-form.component.html',
  styleUrls: ['./invoice-in-details-form.component.scss'],
})
export class InvoiceInDetailsFormComponent implements OnChanges, OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() entity: any;

  @Input() headerCostItemId: any;

  @Output() totalChange = new EventEmitter<{
    amount: number;
    vat: number;
    total: number;
  }>();

  @Input() useDatasource: boolean = false;

  @Input() datasource: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  costHeaderId: number;
  cases: any;
  workcodes: any;
  employees: any;
  costItemCategories: any;

  clientLanguageId: any = 3;

  saveButtonOptions: any;
  cancelButtonOptions: any;
  vatSelectList: any = null;

  constructor(
    private vatSvc: VatService,
    private WorkCodeSvc: WorkcodeService,
    private CaseSvc: CaseService
  ) {
    this.onNonVatAmountChanged = this.onNonVatAmountChanged.bind(this);
    this.onVatRateChanged = this.onVatRateChanged.bind(this);
    this.onCaseChanged = this.onCaseChanged.bind(this);
    this.onWorkcodeChanged = this.onWorkcodeChanged.bind(this);

    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.cases = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${this.url}Patricia/CaseDetailedInfo/Lookup`,
      }),
      sort: 'CaseNumber',
    });

    this.workcodes = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkCodeId',
        loadUrl: `${this.url}Invoice/WorkCode/lookup`,
      }),
      sort: ['WorkCodeLabel', 'WorkCodeDescription'],
    });

    this.employees = new DataSource({
      store: AspNetData.createStore({
        key: 'EmployeeId',
        loadUrl: `${this.url}Employee/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };

          if (method == 'load') {
            ajaxOptions.data.ActiveOnly = true;
          }
        },
      }),
      sort: ['FirstName', 'LastName'],
    });

    this.costItemCategories = new DataSource({
      store: AspNetData.createStore({
        key: 'CostItemId',
        loadUrl: `${this.url}Costs/CostItem/lookup`,
      }),
      sort: 'Label',
    });

    this.vatSelectList = this.vatSvc.getVATPercentageList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.useDatasource === false &&
      changes.entity !== undefined &&
      this.entity?.CostHeaderId !== undefined &&
      this.entity?.CostHeaderId !== null
    ) {
      this.costHeaderId = this.entity.CostHeaderId;
      this.datasource = new DataSource({
        store: AspNetData.createStore({
          key: 'CostLineId',
          loadUrl: `${this.url}Costs/CostLine/${this.costHeaderId}`,
          insertUrl: `${this.url}Costs/CostLine`,
          updateUrl: `${this.url}Costs/CostLine`,
          deleteUrl: `${this.url}Costs/CostLine`,
          onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            if (this.costHeaderId) {
              ajaxOptions.data.CostHeaderId = this.costHeaderId;
            }
          },
        }),
      });
    }
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onContentReady(e: ContentReadyEvent) {
    this.sendNewTotal();
  }

  onRowUpdated(e: RowUpdatedEvent) {
    this.sendNewTotal();
  }

  onRowInserted(e: RowInsertedEvent) {
    this.sendNewTotal();
  }

  sendNewTotal() {
    if (this.datasource?.items().length > 0) {
      const total: number = this.datasource
        .items()
        .map((x) => x.Amount)
        .reduce((a, b) => a + b, 0);

      const amount: number = this.datasource
        .items()
        .map((x) => x.NonVatAmount)
        .reduce((a, b) => a + b, 0);

      const vatAmount: number = this.datasource
        .items()
        .map((x) => x.VatAmount)
        .reduce((a, b) => a + b, 0);

      const amounts = {
        amount: amount,
        vat: vatAmount,
        total: total,
      };

      this.totalChange.emit(amounts);
    }
  }

  onVatRateChanged(newData, value, currentRowData) {
    newData.VatRate = value;

    if (currentRowData.NonVatAmount != null) {
      let TotalAmount: number =
        (+value / 100 + 1) * currentRowData.NonVatAmount;
      let VatAmount: number = TotalAmount - currentRowData.NonVatAmount;
      let VatAmountRounded: number = +this.vatSvc
        .roundToNearestFiveCents(VatAmount)
        .toFixed(2);
      newData.Amount = TotalAmount;
      newData.VatAmount = VatAmountRounded;
    }
  }

  onNonVatAmountChanged(newData, value, currentRowData) {
    newData.NonVatAmount = value;

    if (currentRowData.VatRate != null) {
      let TotalAmount: number = (+currentRowData.VatRate / 100 + 1) * value;
      let VatAmount: number = TotalAmount - value;
      let VatAmountRounded: number = +this.vatSvc
        .roundToNearestFiveCents(VatAmount)
        .toFixed(2);
      newData.Amount = TotalAmount;
      newData.VatAmount = VatAmountRounded;
    }
  }

  onCaseChanged(newData, value, currentRowData) {
    newData.CaseId = value;
    if (value != null) {
      this.getCaseClientLanguagePromise(value).then((data) => {
        this.clientLanguageId = data;
      });
    }
  }

  onWorkcodeChanged(newData, value, currentRowData) {
    if (value != null) {
      return this.getWorkCodeDescriptionPromise(value).then((data) => {
        newData.WorkCodeId = value;
        newData.LineDescription = data;
      });
    } else {
      newData.WorkCodeId = value;
      newData.LineDescription = null;
    }
  }

  getWorkCodeDescriptionPromise(WorkCodeId) {
    return new Promise((resolve) => {
      this.WorkCodeSvc.getWorkCode(WorkCodeId, this.clientLanguageId).subscribe(
        (data) => {
          resolve(data.InvoiceDescription);
        }
      );
    });
  }

  getCaseClientLanguagePromise(CaseId) {
    return new Promise((resolve) => {
      this.CaseSvc.getAccountAddressLanguage(CaseId).subscribe((data) => {
        resolve(data.LanguageId);
      });
    });
  }
}

@NgModule({
  imports: [CommonModule, DxDataGridModule, DxNumberBoxModule, DxDateBoxModule],
  declarations: [InvoiceInDetailsFormComponent],
  exports: [InvoiceInDetailsFormComponent],
})
export class InvoiceInDetailsFormModule {}
