<div style="margin-left: 20px; margin-right: 25px; margin-top: 10px">
  <h5>Attachments:</h5>
  <dx-data-grid
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    style="font-size: 11px"
    #dataGridRef
    [dataSource]="attachmentsDataSource"
    [showBorders]="true"
    (onRowUpdating)="rowUpdateEvent($event)"
    (onRowUpdating)="onRowUpdating($event)"
    [wordWrapEnabled]="true"
  >
    <dxo-editing mode="cell" [allowUpdating]="true" />
    <dxo-selection class="MyDXO" showCheckBoxesMode="always" mode="multiple" />

    <dxi-column
      [width]="140"
      [visible]="true"
      dataField="categoryId"
      caption="Category"
    >
      <dxo-lookup
        [dataSource]="categoryLookup"
        valueExpr="categoryId"
        displayExpr="label"
      />
    </dxi-column>

    <dxi-column
      [width]="220"
      [visible]="true"
      dataField="subCategoryId"
      caption="SubCategory"
    >
      <dxo-lookup
        [dataSource]="getFilteredSubCategories"
        valueExpr="subCategoryId"
        displayExpr="label"
      />
    </dxi-column>

    <dxi-column
      [width]="365"
      dataField="name"
      dataType="stringLength"
      caption="Attachment description (portal)"
      [allowEditing]="true"
    >
    </dxi-column>

    <dxo-state-storing
      [enabled]="false"
      type="localStorage"
      storageKey="gridDocketMailFormAttachments"
    />
  </dx-data-grid>
</div>
