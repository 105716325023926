<div class="panel" [ngClass]="{ pin: isPinned, open: isOpened }">
  <div class="data-wrapper">
    <ng-container *ngIf="!isLoading">
      <div class="data-part">
        <dx-toolbar class="panel-toolbar">
          <dxi-item location="before">
            <span class="contact-name value">Details:</span>
          </dxi-item>
          <dxi-item location="before">
            <!-- <contact-status [value]="user.status"></contact-status> -->
          </dxi-item>
          <dxi-item location="after" [visible]="isPinEnabled">
            <dx-button
              [icon]="isPinned ? 'pin' : 'unpin'"
              (onClick)="onPinClick()"
            />
          </dxi-item>
          <dxi-item location="after">
            <dx-button icon="close" (onClick)="onClosePanel()" />
          </dxi-item>
        </dx-toolbar>
      </div>

      <dx-scroll-view class="panel-scroll">
        <dxi-item itemType="group">
          <dxi-item cssClass="accent">
            <form-textbox
              label="From:"
              [value]="email.from.emailAddress.address"
              [isEditing]="isEditing"
            />
          </dxi-item>
          <dxi-item>
            <form-textbox
              label="On:"
              [(value)]="email.receivedDateTime"
              [isEditing]="isEditing"
            />
          </dxi-item>

          <dxi-item cssClass="accent">
            <form-textbox
              label="Assigned to"
              [(value)]="email.subject"
              [isEditing]="isEditing"
            />
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" cssClass="contact-fields-group">
          <dxi-item>
            <div *dxTemplate>
              <form-textbox
                [(value)]="email.subject"
                [isEditing]="isEditing"
                icon="tel"
                mask="+1(000)000-0000"
              />
            </div>
          </dxi-item>
          <dxi-item>
            <div *dxTemplate>
              <form-textbox
                [(value)]="email.subject"
                [isEditing]="isEditing"
                icon="email"
                [validators]="[{ type: 'email' }]"
              />
            </div>
          </dxi-item>
          <dxi-item>
            <div *dxTemplate>
              <form-textbox
                [(value)]="email.subject"
                [isEditing]="isEditing"
                icon="home"
                [validators]="[]"
              />
            </div>
          </dxi-item>
        </dxi-item>

        <div class="data-part data-part-toolbar border">
          <dx-toolbar>
            <dxi-item
              location="after"
              widget="dxDropDownButton"
              [options]="{
                width: 120,
                text: 'Actions',
                stylingMode: 'contained',
                items: ['Call', 'Send Fax', 'Send Email', 'Docket'],
                onItemClick: onActionsClick
              }"
            />
          </dx-toolbar>
        </div>

        <div class="data-part">
          <dx-accordion [multiple]="true" [collapsible]="true">
            <dxi-item title="Preview">
              <div *dxTemplate="let i of 'item'">
                <div [innerHTML]="email.body.content"></div>
              </div>
            </dxi-item>

            <dxi-item title="Attachments">
              <!-- <card-activities [activities]="user.activities"></card-activities> -->
            </dxi-item>
          </dx-accordion>
        </div>
      </dx-scroll-view>
    </ng-container>

    <dx-load-panel
      [width]="300"
      [visible]="isLoading"
      [showPane]="false"
      container=".panel"
      [position]="{ of: '.panel' }"
    />
  </div>
</div>
