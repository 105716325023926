import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

interface FeeRequest {
  ClientId: number;
  ProspectId: number;
  ServiceId: number;
  SubsidyId: number;
  ExistingCases: {
    CaseIds: number[];
    ServiceId: number;
    SubsidyId: number;
  }[];
  NewCases: {
    CaseTypeId: number;
    ApplicationTypeId: number;
    ServiceLevelId: number;
    CountryId: string;
    TrademarkTypeId: number;
    ServiceId: number;
    SubsidyId: number;
  }[];
}

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class FeeCalculatorService {
  url = apiUrl + 'api/feecalculator';
  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  CalculateFee(request: FeeRequest) {
    return this.http.post<any>(`${this.url}/CalculateFee`, request);
  }
}
