<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  [height]="height"
>
  <dx-form [(formData)]="info">
    <dxi-item itemType="group" [colCount]="3">
      <dxi-item
        dataField="Street"
        editorType="dxTextBox"
        [colSpan]="1"
        [editorOptions]="{
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="Street is required">
        </dxi-validation-rule>
        <dxo-label text="Street" />
      </dxi-item>
      <dxi-item
        dataField="ZipCode"
        editorType="dxTextBox"
        [colSpan]="1"
        [editorOptions]="{
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="ZipCode is required">
        </dxi-validation-rule>
        <dxo-label text="ZipCode" />
      </dxi-item>
      <dxi-item
        dataField="City"
        editorType="dxTextBox"
        [colSpan]="1"
        [editorOptions]="{
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="City is required">
        </dxi-validation-rule>
        <dxo-label text="City" />
      </dxi-item>
      <dxi-item
        dataField="StateId"
        editorType="dxLookup"
        [colSpan]="1"
        [editorOptions]="{
          dataSource: countries,
          displayExpr: 'Label',
          valueExpr: 'CountryId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="StateId is required">
        </dxi-validation-rule>
        <dxo-label text="State" />
      </dxi-item>
      <dxi-item
        dataField="Province"
        [colSpan]="2"
        [editorOptions]="{
          validationMessageMode: 'always'
        }"
      >
        <dxo-label text="Province" />
      </dxi-item>
      <dxi-item
        dataField="VatNumber"
        editorType="dxTextBox"
        [colSpan]="1"
        [editorOptions]="{
          validationMessageMode: 'always'
        }"
      >
        <dxo-label text="Vat Number" />
        <dxi-validation-rule type="required" message="Vat Number is required">
        </dxi-validation-rule>
      </dxi-item>
      <dxi-item
        dataField="PhoneCompany"
        [colSpan]="1"
        [editorOptions]="{
          validationMessageMode: 'always'
        }"
      >
        <dxo-label text="PhoneCompany" />
      </dxi-item>
      <dxi-item
        dataField="EmailCompany"
        [colSpan]="1"
        [editorOptions]="{
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="EmailCompany is required">
        </dxi-validation-rule>
        <dxo-label text="EmailCompany" />
      </dxi-item>
    </dxi-item>
  </dx-form>
</form-popup>
