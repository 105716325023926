import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDropDownButtonModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import { CRMService } from 'src/app/services/prospection/crm.service';
import { CrmToDoMarkAsDoneModule } from 'src/app/components/crm/crm-todo-mark-as-done-form/crm-todo-mark-as-done-form.component';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'home-todo-section',
  templateUrl: './home-todo-section.component.html',
  styleUrls: ['./home-todo-section.component.scss'],
})
export class HomeTodoSectionComponent {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() dataSource: any;

  @Input() pageSize?: number = 10;

  @Input() allowPager?: boolean = true;

  @Input() allowScrolling?: boolean = true;

  todoToMarkAsDone: any;

  isMarkAsDonePopupOpened: boolean = false;

  constructor(private crmService: CRMService) {}

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  markAsDone = (e) => {
    this.todoToMarkAsDone = { ...e.row.data };
    this.isMarkAsDonePopupOpened = e;
  };

  onTodoMarkAsDoneComplete = (e) => {
    this.crmService
      .markTodoAsDone(
        e.ProspectActionId,
        e.IsOutcomePositive,
        e.OutcomeComments,
        e.DoneDate
      )
      .subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
        complete: () => {
          this.refreshData();
        },
      });
  };

  onCloseMarkAsDone = (e: boolean) => {
    this.isMarkAsDonePopupOpened = e;
  };

  onClickRedirect() {
    window.open('/#/crm-todo');
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    CrmToDoMarkAsDoneModule,
  ],
  exports: [HomeTodoSectionComponent],
  declarations: [HomeTodoSectionComponent],
  providers: [],
})
export class HomeTodoSectionModule {}
