<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  height="auto"
  width="50vw"
  (onShown)="onShown()"
>
  <dx-form [(formData)]="entity">
    <dxi-item itemType="group" colCount="3" [colSpan]="2">
      <dxi-item dataField="Label" editorType="dxTextBox" [colSpan]="3" />
      <dxi-item
        dataField="ServiceId"
        editorType="dxLookup"
        [colSpan]="1"
        [editorOptions]="{
          dataSource: services,
          displayExpr: 'Label',
          valueExpr: 'Id',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="Service is required" />
        <dxo-label text="Service" />
      </dxi-item>
      <dxi-item
        dataField="CaseTypeId"
        editorType="dxLookup"
        [colSpan]="1"
        [editorOptions]="{
          dataSource: caseTypes,
          displayExpr: 'CaseTypeLabel',
          valueExpr: 'CaseTypeId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="Case Type is required" />
        <dxo-label text="Case Type" />
      </dxi-item>
      <dxi-item
        dataField="CountryId"
        editorType="dxLookup"
        [colSpan]="1"
        [editorOptions]="{
          dataSource: countries,
          searchEnabled: true,
          validationMessageMode: 'always',
          onValueChanged: onCountryChanged
        }"
      >
        <dxi-validation-rule type="required" message="Country is required" />
        <dxo-label text="Country" />
      </dxi-item>
      <dxi-item itemType="group" caption="Lines" [colSpan]="3">
        <fee-calculator-billing-matrix-lines
          [source]="lines"
          [readOnly]="!hasCountrySelected()"
          [filteredWorkCodes]="filteredWorkCodes"
        />
      </dxi-item>
    </dxi-item>
  </dx-form>
</form-popup>
