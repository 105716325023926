<dx-scroll-view class="view-wrapper-scroll"
  ><div class="view-wrapper">
    <titlebar titleText="Spadel Invoices Overview" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onExporting)="onExporting($event)"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export
        [enabled]="true"
        [fileName]="'Spadel Invoices'"
        [allowExportSelectedData]="false"
      />

      <dxo-toolbar>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="exportButton" locateInMenu="auto" />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        dataField="Invoice"
        caption="Invoice"
        width="7%"
        alignment="center"
      />
      <dxi-column
        dataField="FirstName"
        caption="First Name"
        alignment="center"
        width="7%"
      />
      <dxi-column
        dataField="LastName"
        caption="Last Name"
        alignment="center"
        width="7%"
      />
      <dxi-column
        dataField="Email"
        caption="Email"
        alignment="center"
        width="7%"
      />
      <dxi-column
        dataField="FileReference"
        caption="File Reference"
        alignment="center"
        width="8%"
      />
      <dxi-column
        dataField="WorkedOn"
        caption="Worked On"
        dataType="date"
        alignment="center"
        width="7%"
      />
      <dxi-column
        dataField="Hours"
        caption="Hours"
        alignment="center"
        width="5%"
      />
      <dxi-column
        dataField="Minutes"
        caption="Minutes"
        alignment="center"
        width="5%"
      />
      <dxi-column
        dataField="Description"
        caption="Description"
        alignment="center"
      />
      <dxi-column dataField="Amount" caption="Amount" width="7%">
        <dxo-format type="currency" [precision]="2" />
      </dxi-column>
      <dxi-column dataField="InvoiceTotal" caption="Invoice Total" width="7%">
        <dxo-format type="currency" [precision]="2" />
      </dxi-column>

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridFinanceReportingSpadelInvoiceOverview"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
