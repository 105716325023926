import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxFormModule,
  DxLoadIndicatorModule,
  DxScrollViewModule,
  DxSelectBoxModule,
} from 'devextreme-angular';
import { CalystaAPIDocketingService } from 'src/app/services/calysta-api/docketing.service';
import { DocumentName } from 'src/app/types/docketing/DocumentName';
import { GraphMessage } from 'src/app/types/graph/message';
import { DocketMailFormMailGridModule } from '../docket-mail-form-mailgrid/docket-mail-form-mailgrid.component';
import { DocketMailFormCategoryMenuModule } from '../docket-mail-form-categorymenu/docket-mail-form-categorymenu.component';
import { DocketMailFormSubcategorymenuModule } from '../docket-mail-form-subcategorymenu/docket-mail-form-subcategorymenu.component';
import { DocketMailFormAttachmentsModule } from '../docket-mail-form-attachments/docket-mail-form-attachments.component';
import { DocketCasegridModule } from '../docket-casegrid/docket-casegrid.component';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-docket-mail-form',
  templateUrl: './docket-mail-form.component.html',
  styleUrls: ['./docket-mail-form.component.scss'],
})
export class DocketMailFormComponent implements OnChanges {
  @ViewChild('caseGridRef', { static: false })
  documentNameGrid: DxDataGridComponent;

  @Input() email!: GraphMessage;
  @Input() mailBox: string;

  SubCategoryLookup: any[];
  currentCategoryId: number;
  currentSubCategoryId: number;
  subCategoryDropDownDatasource: any;
  selectedCategory: string;
  selectedSubCategory: string;
  mailHasAttachments: boolean = false;

  currentSelectedCases: number[];

  filesToDocket: DocumentName[];

  loading = false;
  formData: any = {};

  constructor(
    private router: Router,
    private CalyAPIDocketSVC: CalystaAPIDocketingService
  ) {
    this.changeMethod = this.changeMethod.bind(this);
    this.SubCategoryChanged = this.SubCategoryChanged.bind(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { email } = changes;
    if (this.email) {
      this.mailHasAttachments = this.email.hasAttachments;
    }
  }

  changeCurrentCategoryId(newCategoryId: number) {
    this.currentCategoryId = newCategoryId;
  }

  async onSubmit(e: Event) {
    e.preventDefault();
    const { email, password } = this.formData;
    this.loading = true;

    this.loading = false;
  }

  clearCategories(): void {
    const totalCount = this.documentNameGrid.instance.totalCount();
    for (let i = 0; i < totalCount; i++) {
      this.documentNameGrid.instance.cellValue(i, 'caseId', null);
      this.documentNameGrid.instance.cellValue(i, 'mailName', '');
      this.documentNameGrid.instance.cellValue(i, 'visble', false);
    }
  }

  caseSelectionChanged(e: number[]) {
    this.currentSelectedCases = e;
    if (
      this.currentSubCategoryId != null &&
      this.currentSelectedCases.length > 0
    ) {
      this.CalyAPIDocketSVC.getDocketFileName(
        this.currentSelectedCases,
        this.currentCategoryId,
        this.currentSubCategoryId,
        Date.now().toString()
      ).subscribe({
        next: (res: []) => {
          this.filesToDocket = res;
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'error', 3000);
        },
      });
    }
  }

  SubCategoryChanged(e: number): void {
    this.currentSubCategoryId = e;
    if (
      this.currentSubCategoryId != null &&
      this.currentSelectedCases.length > 0
    ) {
      this.CalyAPIDocketSVC.getDocketFileName(
        this.currentSelectedCases,
        this.currentCategoryId,
        this.currentSubCategoryId,
        Date.now().toString()
      ).subscribe({
        next: (res: []) => {
          this.filesToDocket = res;
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'error', 3000);
        },
      });
    }
  }

  changeMethod(e): void {
    this.currentSubCategoryId = null;

    this.subCategoryDropDownDatasource = {
      store: this.SubCategoryLookup,
      filter: ['categoryId', '=', this.currentCategoryId],
    };
  }

  buttonPlaceHolder() {}

  calculateSortValue(data): any {
    const column = this as any;
    const value = column.calculateCellValue(data);
    return column.lookup.calculateCellValue(value);
  }

  logEvent(e): void {
    //this.mailFormationChanges.emit(this.mailFormation);
  }

  EnableSaveButton(e): void {
    // if (this.lock === true){
    //   this.lock = false;
    // }
  }

  StartEdit(e): void {
    this.documentNameGrid.instance.saveEditData();
  }
}
@NgModule({
  declarations: [DocketMailFormComponent],
  exports: [DocketMailFormComponent],
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule,
    DxDataGridModule,
    DxSelectBoxModule,
    DxScrollViewModule,
    DocketMailFormMailGridModule,
    DocketMailFormCategoryMenuModule,
    DocketMailFormSubcategorymenuModule,
    DocketCasegridModule,
    DxButtonModule,
    DocketMailFormAttachmentsModule,
  ],
})
export class DocketMailFormModule {}
