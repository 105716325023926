import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import {
  DxFormModule,
  DxLookupModule,
  DxTabPanelModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { CRMService } from 'src/app/services/prospection/crm.service';

@Component({
  selector: 'fee-calculator-wizard-organization',
  templateUrl: './fee-calculator-wizard-organization.component.html',
  styleUrls: ['./fee-calculator-wizard-organization.component.scss'],
})
export class FeeCalculatorWizardOrganizationComponent implements OnInit {
  feeInfo: any;
  @Output()
  stepCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  url = environment.CalystaApiBaseURL + 'api/';

  clients: DataSource;

  services: DataSource;

  constructor(private crmService: CRMService) {
    this.feeInfo = {
      Email: '',
      Address: '',
      City: '',
      Zip: '',
      Country: '',
      ServiceId: null,
    };
  }

  ngOnInit() {
    this.clients = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}Prospection/CRM/ClientsAndProspects`,
      }),
      sort: 'Name',
    });

    this.services = new DataSource({
      store: AspNetData.createStore({
        key: 'ServiceId',
        loadUrl: `${this.url}Patricia/ServiceLevelLookup/Lookup`,
      }),
      sort: 'ServiceLevelLabel',
    });

    this.stepCompleted.emit(true);
  }

  onClientChanged = (e: any) => {
    this.crmService
      .getDetailedInfo(e.value.Id, e.value.IsProspect)
      .subscribe((data) => {
        this.feeInfo.Email = data.Email;
        this.feeInfo.Address = data.Address;
        this.feeInfo.City = data.City;
        this.feeInfo.Zip = data.ZipCode;
        this.feeInfo.Country = data.Country;
        this.feeInfo.ServiceId = data.ServiceLevelId;
      });
  };

  /// This function is called when the service level is changed for the moment
  /// Should be called when the form is completed.
  onServiceLevelChanged = (e: any) => {
    this.feeInfo.ServiceId = e.value;
    if (e.value == null) {
      this.stepCompleted.emit(false);
    } else {
      this.stepCompleted.emit(true);
    }
  };
}

@NgModule({
  exports: [FeeCalculatorWizardOrganizationComponent],
  declarations: [FeeCalculatorWizardOrganizationComponent],
  providers: [],
  imports: [
    CommonModule,
    DxTabPanelModule,
    TitleBarModule,
    DxFormModule,
    DxLookupModule,
  ],
})
export class FeeCalculatorWizardOrganizationModule {}
