<div
  id="uploader"
  class="flex-box"
  [ngClass]="
    isDropZoneActive
      ? ['dx-theme-accent-as-border-color']
      : ['dx-theme-border-color']
  "
>
  <div>
    <br />
    <div *ngFor="let file of fileValue">
      <div class="selected-item">
        Name:
        <span>{{ file.name }}</span
        ><br />
        Size: <span>{{ file.size }}</span
        >bytes<br />
        Type: <span>{{ file.type }}</span
        ><br />
        Last Modified Date:
        <span>{{ file.lastModifiedDate }}</span>
      </div>
    </div>
  </div>
  <div id="dropzone-text" class="flex-box" *ngIf="textVisible">
    <span
      >Drag and drop a file here or click the area to select it from a
      folder</span
    >
  </div>
</div>
<dx-file-uploader
  #fileUploader
  dialogTrigger="#uploader"
  dropZone="#uploader"
  [multiple]="false"
  uploadMode="instantly"
  [showFileList]="false"
  [visible]="false"
  [(value)]="fileValue"
  (onDropZoneEnter)="onDropZoneEnter($event)"
  (onDropZoneLeave)="onDropZoneLeave($event)"
  (onValueChanged)="onValueChanged($event)"
/>
