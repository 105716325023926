import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import {
  CellPreparedEvent,
  Column,
  InitializedEvent,
  RowPreparedEvent,
} from 'devextreme/ui/data_grid';
import { DataQualityCheckValidationModule } from '../data-quality-check-validation/data-quality-check-validation.component';
import { GridType } from 'src/app/types/data-quality/grid-type';

@Component({
  selector: 'data-quality-grid',
  templateUrl: './data-quality-grid.component.html',
  styleUrls: ['./data-quality-grid.component.scss'],
})
export class DataQualityGridComponent implements OnChanges {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() url: string;

  @Input() dataKey: string;

  @Input() gridTypes: GridType[];

  @Input() gridType: GridType;

  @Input() fileExportName: string;

  @Input() columns: Column[];

  @Input() patriciaBaseLink: string =
    'http://patricia.calysta.eu/pat/#!case-view';

  @Input() patriciaLinkKey: string = 'CaseNumber';

  @Output() onGridTypeChanged = new EventEmitter<GridType>();

  dataSource: DataSource;

  confirmationPopupVisible = false;

  ngOnChanges(changes: SimpleChanges): void {
    const previousColumns = changes.columns.previousValue;
    const currentColumns = changes.columns.currentValue;

    if (previousColumns !== currentColumns && this.dataGrid) {
      this.dataGrid.instance.option('columns', this.columns);
      this.loadDataSource();
    }
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  loadDataSource = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: this.dataKey,
        loadUrl: `${this.url}/${this.gridType.key}`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  };

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(this.gridType.description);

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Px_${this.fileExportName}.xlsx`
        );
      });
    });
  }

  onInitialized(e: InitializedEvent) {
    this.onGridTypeChanged.emit(this.gridType);
  }

  selectedLines = (): any[] => {
    if (!this.dataGrid) {
      return [];
    }

    return this.dataGrid.instance.getSelectedRowsData();
  };

  checkData = (e: any) => {
    this.confirmationPopupVisible = true;
  };

  onChooseGridType = (e: any) => {
    this.dataGrid.instance.clearSorting();

    this.onGridTypeChanged.emit(e.itemData);
  };

  onRowPrepared = (e: RowPreparedEvent) => {
    if (e.rowType === 'data') {
      const key = e.data[this.patriciaLinkKey];
      if (key) {
        e.data.PatriciaLink = `${this.patriciaBaseLink}/${key}`;
      }
    }
  };
}

@NgModule({
  declarations: [DataQualityGridComponent],
  exports: [DataQualityGridComponent],
  bootstrap: [DataQualityGridComponent],
  imports: [BrowserModule, DxDataGridModule, DataQualityCheckValidationModule],
})
export class DataQualityGridModule {}
