<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Billing Matrices" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      (onEditorPreparing)="onEditorPreparing($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="New Billing Matrix"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="addNew()"
          />
        </dxi-item>
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="Id" caption="ID" [visible]="false" />
      <dxi-column dataField="Label" caption="Label" />
      <dxi-column dataField="ServiceId" caption="Service">
        <dxo-lookup
          valueExpr="Id"
          displayExpr="Label"
          [dataSource]="services.store()"
        />
        >
      </dxi-column>
      <dxi-column dataField="CaseTypeId" caption="Case Type">
        <dxo-lookup
          valueExpr="CaseTypeId"
          displayExpr="CaseTypeLabel"
          [dataSource]="caseTypes.store()"
        />
      </dxi-column>
      <dxi-column
        dataField="CountryId"
        caption="Country"
        [setCellValue]="setCountryCellValue"
      >
        <dxo-lookup [dataSource]="countries.store()" />
      </dxi-column>
      <dxi-column type="buttons">
        <dxi-button icon="edit" [onClick]="onEdit" />
        <dxi-button name="delete" />
      </dxi-column>

      <dxo-master-detail [enabled]="true" template="detail" />
      <div *dxTemplate="let details of 'detail'">
        <div>
          <dx-data-grid
            [allowColumnResizing]="true"
            [allowColumnReordering]="true"
            [dataSource]="details.data.BillingMatrixWorkCodeLines"
            (onRowUpdating)="onRowUpdating($event)"
            [wordWrapEnabled]="true"
          >
            <dxi-column dataField="WorkCodeId" caption="Work Code">
              <dxo-lookup
                valueExpr="WorkCodeId"
                displayExpr="WorkCodeFullDescription"
                [dataSource]="workCodes.store()"
              />
            </dxi-column>
            <dxi-column dataField="BaseFee" caption="Base Fee" />
            <dxi-column dataField="AdditionalFee" caption="Additional Fee" />

            <dxo-state-storing
              [enabled]="false"
              type="localStorage"
              storageKey="gridFeeCalculatorConfigurationWorkCodes"
            />
          </dx-data-grid>
        </div>
      </div>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridFeeCalculatorConfigurationBillingMatrix"
      />
    </dx-data-grid>
    <fee-calculator-billing-matrix-popup
      [(visible)]="isAddPopupVisible"
      (save)="onSaveClickNew($event)"
      [titleText]="'Add Billing Matrix Line'"
      (visibleChange)="onCloseAdd($event)"
      [entity]="editedRow"
    />
    <fee-calculator-billing-matrix-popup
      [(visible)]="isEditPopupVisible"
      (save)="onSaveClickEdit($event)"
      [titleText]="'Edit Billing Matrix Line'"
      (visibleChange)="onCloseEdit($event)"
      [entity]="editedRow"
    />
  </div>
</dx-scroll-view>
