<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Home" />
    <div class="separator"></div>
    <div class="container">
      <div class="pane" id="component1">
        <home-patricia-deadlines-section
          [dataSource]="deadlines"
          [pageSize]="5"
          [allowPager]="false"
          [allowScrolling]="true"
        />
      </div>
      <div class="pane" id="component2"><home-sales-report-section /></div>
      <div class="pane" id="component3">
        <home-todo-section
          [dataSource]="todos"
          [pageSize]="5"
          [allowPager]="false"
          [allowScrolling]="false"
        />
      </div>
      <div class="pane" id="component4">
        <home-wip-section />
      </div>
    </div>
  </div>
</dx-scroll-view>
