import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class PatriciaService {
  url = apiUrl + 'api/patricia';
  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  getCaseLookup(): Observable<any[]> {
    return this.http
      .get<any>(`${this.url}/CaseDetailedInfo/Lookup`)
      .pipe<any[]>(map((res) => res.data));
  }

  getCase(id: number): Observable<any> {
    return this.http.get<any>(`${this.url}/CaseDetailedInfo/${id}`);
  }

  getNameLookup(): Observable<any[]> {
    return this.http
      .get<any>(`${this.url}/Clients/Lookup`)
      .pipe<any[]>(map((res) => res.data));
  }

  getMyTeam() {
    return this.http.get<any>(`${this.url}/Team/Mine`);
  }

  getClientInformation(clientId: number): Observable<any> {
    return this.http.get<any>(`${this.url}/Clients/${clientId}`);
  }
}
