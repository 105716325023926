import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { DxButtonModule } from 'devextreme-angular';

@Component({
  selector: 'patricia-shortcut',
  templateUrl: './patricia-shortcut.component.html',
  styleUrls: ['./patricia-shortcut.component.scss']
})
export class PatriciaShortCutComponent {

  constructor() { }

  onButtonClick() {
    //open new tab with url of Patricia
    window.open(
      'http://patricia.calysta.eu/pat',
      '_blank'
    );
  }

}

@NgModule({
  imports: [CommonModule, DxButtonModule],
  declarations: [PatriciaShortCutComponent],
  exports: [PatriciaShortCutComponent],
})
export class PatriciaShortcutModule {}
