import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { TimeReportingService } from 'src/app/services/time-registration/reporting.service';

@Component({
  selector: 'budget-reporting',
  templateUrl: './budget-reporting.component.html',
  styleUrls: ['./budget-reporting.component.scss'],
})
export class BudgetReportingComponent implements OnInit, OnChanges {
  DataSource: any;
  DataUrl: string;

  APIUrl: string = environment.CalystaApiBaseURL;

  @Input() TeamId: number;

  constructor(private timeReportingSvc: TimeReportingService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.TeamId) {
      this.loadDataSource();
    }
  }

  ngOnInit() {
    if (this.TeamId) {
      this.loadDataSource();
    }
  }

  loadDataSource() {
    this.timeReportingSvc
      .getSalesBudgetByTeam(this.TeamId, new Date().getFullYear())
      .subscribe((data) => {
        this.DataSource = new DataSource({
          store: data,
        });
      });
  }

  getMonthLabel(row: any) {
    //get month name from number
    return new Date(2024, row.Month, 1).toLocaleString('default', {
      month: 'long',
    });
  }
}

@NgModule({
  providers: [],
  exports: [BudgetReportingComponent],
  declarations: [BudgetReportingComponent],
  imports: [DxDataGridModule],
})
export class BudgetReportingModule {}
