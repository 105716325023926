<div>
  <dx-data-grid
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    id="gridContainer"
    [dataSource]="datasource"
    [showBorders]="true"
    [remoteOperations]="true"
    height="30vh"
    (onRowUpdating)="onRowUpdating($event)"
    [wordWrapEnabled]="true"
  >
    <dxo-toolbar>
      <dxi-item location="after" name="addRowButton" />
      <dxi-item
        location="after"
        widget="dxButton"
        locateInMenu="never"
        [options]="{
          text: '',
          icon: 'refresh',
          stylingMode: 'text',
          onClick: refreshData
        }"
      />
    </dxo-toolbar>

    <dxi-column dataField="Country" caption="Country" />
    <dxi-column dataField="Quantity" caption="Quantity" />
    <dxi-column dataField="ServiceLevelLabel" caption="Service Level" />
    <dxi-column dataField="TrmIndividual" caption="TRM. Ind" />
    <dxi-column dataField="TrmCollective" caption="TRM. Col" />
    <dxi-column dataField="DesIndividual" caption="Des Sing." />
    <dxi-column dataField="DesMultiple" caption="Des Mul." />

    <dxi-column dataField="StateId" [visible]="false" />
    <dxi-column dataField="ApplicationTypeId" [visible]="false" />
    <dxi-column dataField="ServiceLevelId" [visible]="false" />
    <dxi-column dataField="DoNotInherit" [visible]="false" />
    <dxi-column dataField="DesClassSequence" [visible]="false" />
    <dxi-column dataField="DesMultiplyByClasses" [visible]="false" />
    <dxi-column dataField="DesClassRangeStart" [visible]="false" />
    <dxi-column dataField="DesClassRangeEnd" [visible]="false" />
    <dxi-column dataField="TrmMultiplyByClasses" [visible]="false" />
    <dxi-column dataField="TrmClassSequence" [visible]="false" />

    <dxo-editing
      mode="popup"
      [allowUpdating]="true"
      [allowAdding]="true"
      [allowDeleting]="true"
    >
      <dxo-popup
        title="Event Configuration Billing Matrix"
        [showTitle]="true"
        [width]="700"
        [height]="650"
      >
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="saveButtonOptions"
        />
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="cancelButtonOptions"
        />
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" caption="Criteria" [colCount]="3">
          <dxi-item
            dataField="StateId"
            editorType="dxLookup"
            [editorOptions]="{
              dataSource: states,
              displayExpr: 'StateLabel',
              valueExpr: 'StateId',
              searchEnabled: true,
              validationMessageMode: 'always'
            }"
          >
            <dxo-label text="State" />
          </dxi-item>
          <dxi-item
            dataField="ApplicationTypeId"
            editorType="dxLookup"
            [editorOptions]="{
              dataSource: applicationTypes,
              displayExpr: 'ApplicationTypeLabel',
              valueExpr: 'ApplicationTypeId',
              searchEnabled: true,
              validationMessageMode: 'always'
            }"
          >
            <dxo-label text="Application Type" />
          </dxi-item>
          <dxi-item
            dataField="ServiceLevelId"
            editorType="dxLookup"
            [editorOptions]="{
              dataSource: serviceLevels,
              displayExpr: 'ServiceLevelLabel',
              valueExpr: 'ServiceId',
              searchEnabled: true,
              validationMessageMode: 'always'
            }"
          >
            <dxo-label text="Service Level" />
          </dxi-item>
          <dxi-item dataField="Quantity" editorType="dxNumberBox" [colSpan]="3">
            <dxo-label text="Quantity" />
          </dxi-item>
          <dxi-item dataField="DoNotInherit" editorType="dxCheckBox">
            <dxo-label text="Do Not Inherit" />
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" caption="Trademarks" [colCount]="3">
          <dxi-item dataField="TrmIndividual" editorType="dxCheckBox">
            <dxo-label text="Individual" />
          </dxi-item>
          <dxi-item dataField="TrmCollective" editorType="dxCheckBox">
            <dxo-label text="Collective" />
          </dxi-item>
          <dxi-item
            dataField="TrmClassSequence"
            editorType="dxNumberBox"
            [colSpan]="2"
          >
            <dxo-label text="Class Sequence" />
            <dxi-item dataField="TrmMultiplyByClasses" editorType="dxCheckBox">
              <dxo-label text="Multiply by Classes" />
            </dxi-item>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" caption="Designs" [colCount]="3">
          <dxi-item dataField="DesIndividual" editorType="dxCheckBox">
            <dxo-label text="Individual" />
          </dxi-item>
          <dxi-item dataField="DesMultiple" editorType="dxCheckBox">
            <dxo-label text="Multiple" />
          </dxi-item>
          <dxi-item
            dataField="DesClassSequence"
            editorType="dxNumberBox"
            [colSpan]="3"
          >
            <dxo-label text="Class Sequence" />
            <dxi-item dataField="DesMultiple" editorType="dxNumberBox">
              <dxo-label text="Multiple" />
            </dxi-item>
            <dxi-item
              dataField="DesClassRangeStart"
              editorType="dxNumberBox"
              [colSpan]="3"
            >
              <dxo-label text="Class Range Start" />
            </dxi-item>
            <dxi-item
              dataField="DesClassRangeEnd"
              editorType="dxNumberBox"
              [colSpan]="3"
            >
              <dxo-label text="Class Range End" />
            </dxi-item>
            <dxi-item dataField="DesMultiplyByClasses" editorType="dxCheckBox">
              <dxo-label text="Multiply by Classes" />
            </dxi-item>
          </dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxo-state-storing
      [enabled]="false"
      type="localStorage"
      storageKey="gridEventConfigurationBillingDetails"
    />
  </dx-data-grid>
</div>
