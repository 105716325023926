import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxTagBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { environment } from 'src/environments/environment';
import { RwfollowUp } from 'src/app/types/invoicing/rwFollowUp';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';
import { BudgetSalesTargetContributorModule } from '../../budget-sales-target-contributors/budget-sales-target-contributors.component';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'budget-configuration-administration-figures-sales-target-new',
  templateUrl:
    './budget-configuration-administration-figures-sales-target-new.component.html',
  styleUrls: [
    './budget-configuration-administration-figures-sales-target-new.component.scss',
  ],
})
export class BudgetConfigurationAdministrationFiguresSalesTargetNewComponent
  implements OnInit
{
  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<{
    budgetSales: any;
    contributors: any[];
  }>();

  @Output() visibleChange = new EventEmitter<boolean>();

  budgetSales: any;

  url = environment.CalystaApiBaseURL + 'api/';

  monthsMapping = [
    {
      key: 1,
      value: 'January',
    },
    {
      key: 2,
      value: 'February',
    },
    {
      key: 3,
      value: 'March',
    },
    {
      key: 4,
      value: 'April',
    },
    {
      key: 5,
      value: 'May',
    },
    {
      key: 6,
      value: 'June',
    },
    {
      key: 7,
      value: 'July',
    },
    {
      key: 8,
      value: 'August',
    },
    {
      key: 9,
      value: 'September',
    },
    {
      key: 10,
      value: 'October',
    },
    {
      key: 11,
      value: 'November',
    },
    {
      key: 12,
      value: 'December',
    },
  ];

  arrayStore: ArrayStore;

  detailDataSource: DataSource;

  constructor() {}

  ngOnInit(): void {
    this.budgetSales = {};

    this.arrayStore = new ArrayStore({
      data: [],
    });

    this.detailDataSource = new DataSource({
      store: this.arrayStore,
    });
  }

  handleVisible(e: boolean): void {
    this.visible = e;
    this.budgetSales = {};
    this.arrayStore.clear();
    this.detailDataSource.reload();
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.save.emit({
      budgetSales: this.budgetSales,
      contributors: this.detailDataSource.items(),
    });
    this.arrayStore.clear();
    this.detailDataSource.reload();
  }
}

@NgModule({
  declarations: [
    BudgetConfigurationAdministrationFiguresSalesTargetNewComponent,
  ],
  exports: [BudgetConfigurationAdministrationFiguresSalesTargetNewComponent],
  bootstrap: [BudgetConfigurationAdministrationFiguresSalesTargetNewComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
    BudgetSalesTargetContributorModule,
  ],
})
export class BudgetConfigurationAdministrationFiguresSalesTargetNewModule {}
