import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  DxFormModule,
  DxLoadIndicatorModule,
  DxDataGridModule,
  DxSelectBoxModule,
  DxDataGridComponent,
} from 'devextreme-angular';
import { DocketMailFormComponent } from '../docket-mail-form/docket-mail-form.component';

@Component({
  selector: 'app-docket-mail-form-mailgrid',
  templateUrl: './docket-mail-form-mailgrid.component.html',
  styleUrls: ['./docket-mail-form-mailgrid.component.scss'],
})
export class DocketMailFormMailgridComponent implements OnChanges {
  @Input() filesToDocket: any[];

  @ViewChild('mailGrid', { static: false }) mailGrid: DxDataGridComponent;

  ngOnChanges(changes: SimpleChanges): void {
    const { email } = changes;

    if (this.filesToDocket) {
      this.mailGrid.dataSource = this.filesToDocket;
    }
  }

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule,
    DxDataGridModule,
    DxSelectBoxModule,
  ],
  declarations: [DocketMailFormMailgridComponent],
  exports: [DocketMailFormMailgridComponent],
})
export class DocketMailFormMailGridModule {}
