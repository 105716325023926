import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDateRangeBoxModule,
  DxDropDownButtonModule,
  DxScrollViewModule,
  DxTabPanelModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { TitleBarModule } from 'src/app/components';
import DataSource from 'devextreme/data/data_source';
import { environment } from 'src/environments/environment';
import { DeadlineDetailsModule } from '../deadline-overview/deadline-details/deadline-details.component';

@Component({
  selector: 'patricia-events-deadlines-personal',
  templateUrl: './patricia-events-deadlines-personal.component.html',
  styleUrls: ['./patricia-events-deadlines-personal.component.scss'],
})
export class PatriciaEventDeadlinesPersonalComponent
  implements OnInit, OnChanges
{
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() dates: [Date, Date];

  dataSource: DataSource;

  cases: any;

  url = environment.CalystaApiBaseURL + 'api/';

  ngOnInit(): void {
    this.cases = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${this.url}Patricia/CaseDetailedInfo/lookup`,
      }),
      sort: 'CaseNumber',
    });

    this.loadDataSource(this.dates[0], this.dates[1]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //if dates change, reload data
    if (changes.dates && !changes.dates.firstChange) {
      this.loadDataSource(this.dates[0], this.dates[1]);
      this.refreshData();
    }
  }

  loadDataSource = (startDate, endDate) => {
    const filter = [];

    if (startDate && endDate) {
      //get date only from date object
      startDate = new Date(startDate);
      startDate.setHours(0, 0, 0, 0);

      endDate = new Date(endDate);
      endDate.setHours(0, 0, 0, 0);

      filter.push(['InternalTermDate', '>=', startDate]);
      filter.push('and');
      filter.push(['InternalTermDate', '<', endDate]);
    }

    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'EventId',
        loadUrl: `${this.url}Patricia/Event/Deadline/Me`,
        onBeforeSend: (method, ajaxOptions) => {
          if (method === 'load') {
            ajaxOptions.data = ajaxOptions.data || {};
          }
        },
      }),
      filter: filter,
    });
  };

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  openPatricia(e) {
    //open new tab on url http://patricia.calysta.eu/pat/#!case-view/ {data.CaseNumber}
    const caseNumber = e.row.data.CaseNumber;
    window.open(
      `http://patricia.calysta.eu/pat/#!case-view/${caseNumber}`,
      '_blank'
    );
    e.event.preventDefault();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    DxDateRangeBoxModule,
    DxTabPanelModule,
    DeadlineDetailsModule,
  ],
  declarations: [PatriciaEventDeadlinesPersonalComponent],
  exports: [PatriciaEventDeadlinesPersonalComponent],
})
export class PatriciaEventDeadlinesPersonalModule {}
