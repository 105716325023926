<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Users" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
      [showColumnLines]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="false"
        [allowUpdating]="true"
        [allowDeleting]="false"
      />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="UserName" caption="Username" />
      <dxi-column
        dataField="Id"
        caption="UserId"
        [visible]="false"
      ></dxi-column>
      <dxi-column type="buttons">
        <dxi-button icon="edit" [onClick]="onEditClick" />
      </dxi-column>

      <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
      <div *dxTemplate="let details of 'detail'">
        <dx-data-grid
          [allowColumnResizing]="true"
          [allowColumnReordering]="true"
          [dataSource]="details.data.Permissions"
          (onRowUpdating)="onRowUpdating($event)"
          [wordWrapEnabled]="true"
        >
          <dxi-column dataField="Label" caption="Label" />

          <dxo-state-storing
            [enabled]="false"
            type="localStorage"
            storageKey="gridPortalAdminUser"
          />
        </dx-data-grid>
      </div>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="storage"
      />
    </dx-data-grid>
    <permissions-edit
      [visible]="isEditPopupVisible"
      [titleText]="'Permissions'"
      (visibleChange)="onCloseEdit($event)"
      [userId]="selectUserId"
      width="50vw"
      [userPermissions]="selectUserPermissionsIds"
    />
  </div>
</dx-scroll-view>
