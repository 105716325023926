<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  (onShow)="onShow()"
>
  <dx-form [(formData)]="todo">
    <dxi-item itemType="group" [colCount]="2">
      <dxi-item
        dataField="IsOutcomePositive"
        editorType="dxCheckBox"
        [colSpan]="1"
        [editorOptions]="{
          validationMessageMode: 'always',
        }"
      >
        <dxo-label text="Is Outcome Positive" />
      </dxi-item>
      <dxi-item
        dataField="DoneDate"
        editorType="dxDateBox"
        [colSpan]="1"
        [editorOptions]="{
          validationMessageMode: 'always',
          acceptCustomValue: false,
          openOnFieldClick: true
        }"
      >
        <dxi-validation-rule type="required" message="Done Date is required">
        </dxi-validation-rule>
        <dxo-label text="Done Date" />
      </dxi-item>
      <dxi-item
        dataField="OutcomeComments"
        caption="Outcome comments"
        [colSpan]="2"
      >
        <dx-text-area
          validationMessageMode="always"
          [height]="90"
          (onValueChanged)="onOutcomeCommentChange($event)"
          [(value)]="outComeComment"
        />
        <dxo-label text="Comments" />
      </dxi-item>
    </dxi-item>
    <dxi-item itemType="group" caption="History of actions done" [colCount]="1">
      <dx-data-grid
        [allowColumnResizing]="true"
        [allowColumnReordering]="true"
        class="grid"
        [dataSource]="dataSource"
        [remoteOperations]="true"
        [wordWrapEnabled]="true"
      >
        <dxo-load-panel [enabled]="true" />
        <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
        <dxo-sorting mode="multiple" />
        <dxo-editing
          mode="popup"
          [allowAdding]="false"
          [allowUpdating]="false"
          [allowDeleting]="false"
        />

        <dxo-column-chooser [enabled]="true" />
        <dxo-search-panel [visible]="true" width="20vw" />

        <dxo-toolbar>
          <dxi-item location="before" name="searchPanel" locateInMenu="never" />
          <dxi-item
            location="before"
            widget="dxButton"
            locateInMenu="never"
            [options]="{
              text: '',
              icon: 'refresh',
              stylingMode: 'text',
              onClick: refreshData
            }"
          />
          <dxi-item
            location="before"
            name="columnChooserButton"
            locateInMenu="auto"
          />
        </dxo-toolbar>

        <dxi-column
          dataField="ActionName"
          caption="Action"
          alignment="center"
        />
        <dxi-column
          dataField="Comments"
          caption="Comments"
          alignment="center"
        />
        <dxi-column
          dataField="FullName"
          caption="Person Responsible"
          alignment="center"
        />
        <dxi-column
          dataField="DoneDate"
          caption="Done Date"
          sortOrder="desc"
          alignment="center"
          dataType="date"
        />
        <dxi-column dataField="IsOutcomePositive" caption="Is Positive" />
        <dxi-column
          dataField="OutcomeComments"
          caption="Comments"
          alignment="center"
        />

        <dxo-state-storing
          [enabled]="false"
          type="localStorage"
          storageKey="gridCrmTodo"
        />
      </dx-data-grid>
    </dxi-item>
  </dx-form>
</form-popup>
