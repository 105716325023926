<div class="user-panel">
  <dx-drop-down-button
    *ngIf="menuMode === 'context'"
    stylingMode="text"
    dropDownContentTemplate="dropDownTemplate"
    [icon]="user?.avatarSource"
    [showArrowIcon]="false"
    [elementAttr]="{
      class: 'user-button'
    }"
    [dropDownOptions]="{
      width: '150'
    }"
    (onContentReady)="handleDropDownButtonContentReady($event)"
  >
    <div *dxTemplate="let data of 'dropDownTemplate'">
      <user-menu-section
        [menuItems]="menuItems"
        [showAvatar]="false"
        [user]="user"
      ></user-menu-section>
    </div>
  </dx-drop-down-button>
  <div *ngIf="menuMode === 'list'">
    <user-menu-section
      [menuItems]="menuItems"
      [showAvatar]="true"
      [user]="user"
    ></user-menu-section>
  </div>
</div>
