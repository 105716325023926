<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Volumes" />
    <div class="tabpanel-demo">
      <div class="widget-container">
        <dx-tab-panel
          class="dx-theme-background-color"
          width="100%"
          [height]="'100%'"
          [animationEnabled]="true"
          [swipeEnabled]="false"
          [items]="tabPanelItems"
          tabsPosition="top"
          stylingMode="secondary"
          iconPosition="start"
        >
          <div *dxTemplate="let tabPanelItem of 'item'">
            <div class="tabpanel-item">
              <ng-container [ngSwitch]="tabPanelItem">
                <div *ngSwitchCase="tabPanelItems[0]">
                  <volume-report-trm />
                </div>
                <div *ngSwitchCase="tabPanelItems[1]">
                  <volume-report-pat />
                </div>
              </ng-container>
            </div>
          </div>
        </dx-tab-panel>
      </div>
    </div>
  </div>
</dx-scroll-view>
