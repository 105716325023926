import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FinanceCreditcollectAmountdueModule } from './finance-creditcollect-amountdue/finance-creditcollect-amountdue.component';
import { DxScrollViewModule } from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';

@Component({
  selector: 'finance-creditcollect',
  templateUrl: './finance-creditcollect.component.html',
  styleUrls: ['./finance-creditcollect.component.scss'],
})
export class FinanceCreditcollectComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

@NgModule({
  imports: [
    CommonModule,
    FinanceCreditcollectAmountdueModule,
    DxScrollViewModule,
    TitleBarModule,
  ],
  providers: [],
  declarations: [FinanceCreditcollectComponent],
  exports: [],
})
export class FinanceCreditcollectModule {}
