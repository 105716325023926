<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <toolbar-analytics
      titleText="Sales Dashboard"
      [panelItems]="analyticsPanelItems"
      [selectedItems]="[2]"
      (selectionChanged)="selectionChange($event)"
    />
    <div class="separator"></div>

    <div class="cards grey">
      <financial-number-kpi [total]="invoices" [title]="'Invoices'" />
      <financial-valuta-kpi [total]="margin" [title]="'Margin €'" />
      <financial-valuta-kpi [total]="sales" [title]="'Sales €'" />
      <financial-number-kpi [total]="marginperc" [title]="'Margin %'" />
      <financial-total-kpi
        [total]="wipMarginTotal"
        [title]="'WIP Margin amount €'"
      />
    </div>

    <div class="cards">

      <realized-target-chart
        (onPointClick)="onChartMarginPointClick($event)"
      />
      <monthly-goals-card [Title]="'Targets'" />
    </div>
    <div class="DataGrid">
      <wip-card />
    </div>
  </div>
</dx-scroll-view>
