import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class RenewalService {
  url = apiUrl + 'api/renewal';
  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  calculateInternalRenewalQuote(internalRenewalDeadlines: any[]) {
    let params = new HttpParams().set(
      'InternalRenewalDeadlines',
      JSON.stringify(internalRenewalDeadlines)
    );

    return this.http.get<any[]>(
      `${this.url}/InternalRenewalDeadlines/GetRenewalQuote`,
      { params }
    );
  }

  processInvoice() {
    return this.http.put<any[]>(
      `${this.url}/Pavis/Invoice/Detail/ProcessInvoice`,
      {}
    );
  }

  unloadXml() {
    return this.http.delete<any[]>(
      `${this.url}/Pavis/Invoice/Detail/Unload`,
      {}
    );
  }

  importXml(formData: FormData) {
    return this.http.post<any>(
      `${this.url}/Pavis/Invoice/Detail/Import`,
      formData
    );
  }

  flagAsAddDelta(pavisInvoiceDetailId: number, isAddDelta: boolean) {
    return this.http.put<any>(
      `${this.url}/Pavis/Invoice/Detail/${pavisInvoiceDetailId}/AddDeltaToCharge/`,
      { AddDifferenceToCharge: isAddDelta }
    );
  }
}
