<dx-load-panel
  [visible]="serviceLoading"
  [message]="'Loading...'"
  [position]="{ of: '#view-port' }"
/>

<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper" id="view-port">
    <titlebar titleText="Charges" />
    <dx-data-grid
      [allowColumnResizing]="true"
      class="grid"
      height="80vh"
      [dataSource]="chargesDatasource"
      [allowColumnReordering]="true"
      [showBorders]="true"
      [remoteOperations]="true"
      [rowAlternationEnabled]="false"
      [(selectedRowKeys)]="selectedRows"
      (onEditorPreparing)="onEditorPreparing($event)"
      (onSelectionChanged)="selectionChangedHandler($event)"
      (onRowUpdating)="onRowUpdating($event)"
      (onRowPrepared)="onRowPrepared($event)"
      (onCellPrepared)="onCellPrepared($event)"
    >
      <dxo-load-panel [showPane]="false" />
      <dxo-sorting mode="multiple" />
      <dxo-column-chooser [enabled]="true" />
      <dxo-selection mode="multiple" />
      <dxo-grouping [autoExpandAll]="false" />
      <dxo-filter-row [visible]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-scrolling mode="virtual" />
      <dxo-paging [pageSize]="100" />
      <dxo-pager [showPageSizeSelector]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      />

      <dxo-toolbar>
        <dxi-item location="before" locateInMenu="auto">
          <dx-tag-box
            placeholder="Filter Category"
            [dataSource]="categories"
            displayExpr="InvoiceLineCategoryLabel"
            valueExpr="InvoiceLineCategoryId"
            [searchEnabled]="true"
            [showClearButton]="true"
            width="200px"
            (onValueChanged)="onCategoryChanged($event)"
            [showSelectionControls]="true"
            applyValueMode="instantly"
            [opened]="false"
            [multiline]="false"
          />
        </dxi-item>
        <dxi-item location="before" locateInMenu="auto">
          <dx-button
            [text]="this.showInvoiced ? 'Hide Invoiced' : 'Show Invoiced'"
            width="136"
            (onClick)="toggleShowInvoiced($event)"
          />
        </dxi-item>
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [disabled]="!selectedRows?.length"
          [options]="{
            text: 'Clear Selection',
            icon: '',
            stylingMode: 'contained',
            onClick: clearSelection
          }"
        />
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [disabled]="!invoiceButtonVisible"
          [options]="{
            text: 'Invoice ' + selectedNonVatAmount + ' €',
            icon: '',
            stylingMode: 'contained',
            onClick: createInvoice
          }"
        />
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [disabled]="!removeVatButtonVisible"
          [options]="{
            text: 'Remove VAT',
            icon: '',
            stylingMode: 'contained',
            onClick: removeVat
          }"
        />
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [disabled]="!selectedRows?.length"
          [options]="{
            text: 'Move to another case(s)',
            icon: '',
            stylingMode: 'contained',
            onClick: moveToCases
          }"
        />

        <dxi-item location="after" locateInMenu="never">
          <dx-button
            text="new charge"
            icon="plus"
            type="default"
            stylingMode="contained"
            (onClick)="addCharge()"
          />
        </dxi-item>
        <dxi-item location="after">
          <div *dxTemplate>
            <dx-button icon="refresh" (onClick)="refreshData()" />
          </div>
        </dxi-item>
        <dxi-item name="exportButton" locateInMenu="never" />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="never"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column type="buttons" alignment="right" width="4%">
        <dxi-button
          icon="fas fa-file-invoice"
          hint="Edit cost Header"
          [onClick]="EditCostHeader"
          [visible]="hasCostline"
        />
      </dxi-column>
      <dxi-column
        dataField="ChargeId"
        caption="Charge ID"
        dataType="number"
        [visible]="false"
      />
      <dxi-column
        dataField="ClientName"
        dataType="string"
        caption="Client"
        [allowSearch]="true"
        [groupIndex]="0"
        groupCellTemplate="clientGroupCellTemplate"
      />
      <div *dxTemplate="let data of 'clientGroupCellTemplate'">
        <div style="color: orangered">
          {{ data.displayValue }} ({{ OpenRowAmount(data) }} €)
          <dx-button
            text="Select client"
            stylingMode="contained"
            style="float: right"
            (onClick)="selectAllInClientGroup(data)"
          />
        </div>
      </div>
      <dxi-column
        dataField="CaseSummaryCaly"
        caption="Case"
        dataType="string"
        [groupIndex]="1"
        [autoExpandGroup]="true"
        groupCellTemplate="caseGroupCellTemplate"
      />
      <div *dxTemplate="let data of 'caseGroupCellTemplate'">
        <div>
          {{ data.displayValue }} ({{ OpenRowAmount(data) }} €)
          <dx-button
            text="Select case"
            stylingMode="contained"
            style="float: right"
            (onClick)="selectAllInCaseGroup(data)"
          />
        </div>
      </div>
      <dxi-column
        dataField="WorkCodeLabel"
        caption="Workcode"
        dataType="string"
        width="8%"
        alignment="left"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="CreatedOn"
        caption="Created On"
        dataType="date"
        sortIndex="0"
        sortOrder="desc"
        width="7%"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="CreatedByLabel"
        [allowSearch]="false"
        caption="Created By"
        width="8%"
      />
      <dxi-column
        dataField="Description"
        caption="Description"
        dataType="string"
        alignment="center"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="Quantity"
        caption="Quantity"
        [visible]="false"
        dataType="number"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="UnitPrice"
        caption="Unit Price"
        dataType="number"
        [visible]="false"
      >
        <dxo-format type="fixedPoint" [precision]="2" />
      </dxi-column>
      <dxi-column
        dataField="NonVatAmount"
        caption="Excl. VAT"
        width="7%"
        [allowSearch]="false"
      >
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column
        dataField="VatAmount"
        caption="VAT"
        width="7%"
        [allowSearch]="false"
      >
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column
        dataField="VatPercentage"
        caption="VAT %"
        dataType="number"
        width="7%"
        [visible]="true"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="AmountTot"
        caption="Total"
        width="7%"
        [allowSearch]="false"
      >
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column
        dataField="CurrencyCode"
        caption="Currency"
        width="5%"
        [visible]="false"
      />
      <dxi-column
        dataField="InvoiceNumber"
        caption="Invoice Number"
        width="8%"
      />
      <dxi-column dataField="IsBadPayer" [visible]="false" />
      <dxi-column type="buttons" alignment="right" width="4%">
        <dxi-button
          icon="edit"
          [onClick]="onEdit"
          hint="edit charge"
          [visible]="isNotFinalized"
        />
        <dxi-button
          icon="fas fa-scissors"
          hint="Split charge"
          [onClick]="onClickSplit"
        />
      </dxi-column>

      <dxi-column cellTemplate="cellTemplate" alignment="right" width="4%" />
      <div *dxTemplate="let data of 'cellTemplate'">
        <dx-drop-down-button
          icon="fas fa-ellipsis-vertical"
          [items]="buttons"
          [showArrowIcon]="false"
          [dropDownOptions]="{ width: '10vw' }"
          displayExpr="name"
          keyExpr="value"
          (onButtonClick)="onClickMenuButtonClick(data)"
          (onItemClick)="onItemClick($event, data)"
        />
      </div>

      <dxo-summary>
        <dxi-group-item
          column="NonVatAmount"
          summaryType="sum"
          displayFormat="{0} open amount"
        />
      </dxo-summary>

      <dxo-master-detail [enabled]="true" template="detail" />
      <div *dxTemplate="let details of 'detail'">
        <finance-charges-details
          [chargeId]="details.data.ChargeId"
          [workCode]="details.data.WorkCodeLabel"
        />
      </div>

      <dxo-paging [pageSize]="30" />
      <dxo-pager
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[30, 50, 100]"
        [displayMode]="'full'"
        [visible]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridFinanceCharges"
      />
    </dx-data-grid>

    <finance-charge-new
      [visible]="popupType === 'new'"
      (save)="onSaveClick($event)"
      titleText="New Charge"
      (visibleChange)="onClosedPopup($event)"
    />
    <finance-charge-edit
      [visible]="popupType === 'edit'"
      (save)="onSaveClickEdit($event)"
      [titleText]="'Edit Charge'"
      (visibleChange)="onClosedPopup($event)"
      [charge]="selectedCharge"
    />
    <finance-draft-invoice
      [InvoiceHeaderId]="selectedCharge?.InvoiceHeaderId"
      [BlobFile]="relatedInvoiceFile"
      [visible]="popupType === 'draftInvoice'"
      (save)="refreshData()"
      (visibleChange)="onClosedPopup($event)"
    />
    <finance-charges-copy-to-cases-form
      [visible]="popupType === 'copyToCases'"
      (save)="onSaveClickCopyToCases($event)"
      [titleText]="'Copy to other cases'"
      (visibleChange)="onClosedPopup($event)"
      [charge]="selectedCharge"
    />
    <finance-charges-link-to-existing-invoice
      [visible]="popupType === 'linkToExistingInvoice'"
      (save)="onSaveClickLinkToInvoiced($event)"
      [titleText]="'Link to existing invoice'"
      (visibleChange)="onClosedPopup($event)"
      [charge]="selectedCharge"
    />
    <finance-charges-split-form
      [visible]="popupType === 'split'"
      (save)="onSaveClickSplit($event)"
      [titleText]="'Split charges'"
      (visibleChange)="onClosedPopup($event)"
      [charge]="selectedCharge"
    />
    <finance-charges-remove-form
      [visible]="popupType === 'remove'"
      (save)="onSaveClickRemove($event)"
      [titleText]="'Remove charges'"
      (visibleChange)="onClosedPopup($event)"
      [charge]="selectedCharge"
    />
    <finance-charges-move-to-cases-form
      [visible]="popupType === 'moveToCase'"
      (save)="onSaveClickMoveToCases($event)"
      [titleText]="'Move to other cases'"
      (visibleChange)="onClosedPopup($event)"
    />
    <finance-cost-header-edit
      [titleText]="'Edit cost header'"
      [visible]="popupType === 'editCostHeader'"
      [charge]="selectedCharge"
      (visibleChange)="onClosedPopup($event)"
      (save)="onSaveEditCostHeader($event)"
    />
  </div>
</dx-scroll-view>
