import { Component, Input, NgModule, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DxPieChartModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { TimeReportingService } from 'src/app/services/time-registration/reporting.service';
import { Employee } from 'src/app/types/employee/employee';

@Component({
  selector: 'casetype-reporting',
  templateUrl: './casetype-reporting.component.html',
  styleUrls: ['./casetype-reporting.component.scss']
})
export class CasetypeReportingComponent implements OnInit, OnChanges {

  // dataSource
  pieChartDataSource: any = null;

  @Input() Employee: Employee = null;
  @Input() ReportingYear: number = new Date().getFullYear();
  @Input() ReportingMonth: number = 0;

  constructor(
    private TimeReportingSvc: TimeReportingService
  ) { }

  ngOnInit() {
    if (this.Employee)
      this.loadStatistics();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ReportingYear || changes.ReportingMonth || changes.Employee) {
      this.loadStatistics();
    }
  }

  loadStatistics() {
    this.TimeReportingSvc.getBilledTimeByCaseType(this.Employee.EmployeeId, this.ReportingYear, this.ReportingMonth).subscribe((data) => {
      this.pieChartDataSource = data
    });
  }
}

@NgModule({
  providers: [],
  exports: [CasetypeReportingComponent],
  declarations: [CasetypeReportingComponent],
  imports: [
    DxPieChartModule
  ],
})
export class CasetypeReportingModule { }

