<div>
  <dx-data-grid
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    id="gridContainer"
    [dataSource]="datasource"
    [showBorders]="true"
    [remoteOperations]="true"
    (onEditingStart)="onRowUpdating($event)"
    (onInitNewRow)="onRowInserting()"
    height="30vh"
    (onRowUpdating)="onRowUpdating($event)"
    [wordWrapEnabled]="true"
  >
    <dxo-toolbar>
      <dxi-item location="after" name="addRowButton" />
      <dxi-item
        location="after"
        widget="dxButton"
        locateInMenu="never"
        [options]="{
          text: '',
          icon: 'refresh',
          stylingMode: 'text',
          onClick: refreshData
        }"
      />
    </dxo-toolbar>

    <dxi-column dataField="InstructionLabel" caption="Instruction Label" />
    <dxi-column dataField="NextEventSchemeId" caption="Next Event Scheme Id" />

    <dxi-column dataField="InstructionTypeId" [visible]="false" />
    <dxi-column dataField="AgentWorkCodeId" [visible]="false" />
    <dxi-column dataField="OFWorkCodeId" [visible]="false" />

    <dxo-editing
      mode="popup"
      [allowUpdating]="true"
      [allowAdding]="true"
      [allowDeleting]="true"
    >
      <dxo-popup
        title="Event Configuration Line"
        [showTitle]="true"
        [width]="'20vw'"
        [height]="'auto'"
        [onShowing]="onShowing"
      >
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="saveButtonOptions"
        />
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="cancelButtonOptions"
        />
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item
            dataField="InstructionTypeId"
            editorType="dxLookup"
            [colSpan]="1"
            [editorOptions]="{
              dataSource: instructionTypes,
              displayExpr: 'InstructionLabel',
              valueExpr: 'InstructionId',
              searchEnabled: true,
              validationMessageMode: 'always'
            }"
          >
            <dxi-validation-rule type="required" />

            <dxo-label text="Instruction Type" />
          </dxi-item>
          <dxi-item dataField="NextEventSchemeId">
            <dxi-validation-rule type="required" />
            <dxo-label text="Next Event Scheme Id" />
          </dxi-item>
          <dxi-item
            dataField="OFWorkCodeId"
            editorType="dxLookup"
            [colSpan]="1"
            [editorOptions]="{
              dataSource: officialFeeWorkCodes,
              displayExpr: 'WorkCodeLabel',
              valueExpr: 'WorkCodeId',
              searchEnabled: true,
              validationMessageMode: 'always'
            }"
          >
            <dxi-validation-rule type="required" />
            <dxo-label text="Official Fee WC" />
          </dxi-item>
          <dxi-item
            dataField="AgentWorkCodeId"
            editorType="dxLookup"
            [colSpan]="1"
            [editorOptions]="{
              dataSource: agentFeeWorkCodes,
              displayExpr: 'WorkCodeLabel',
              valueExpr: 'WorkCodeId',
              searchEnabled: true,
              validationMessageMode: 'always'
            }"
          >
            <dxi-validation-rule type="required" />
            <dxo-label text="Agent Fee WC" />
          </dxi-item>
          <dxi-item
            itemType="group"
            [colCount]="2"
            [colSpan]="2"
            caption="Billing Information"
            [visible]="hasRow()"
          >
            <event-configuration-line-details
              [entity]="rowSelected"
              [datasource]="detailDataSource"
            />
          </dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

    <dxo-state-storing
      [enabled]="false"
      type="localStorage"
      storageKey="gridEventConfigurationDetails"
    />
  </dx-data-grid>
</div>
