import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import { environment } from 'src/environments/environment';
import { Column } from 'devextreme/ui/data_grid';
import { DataQualityGridModule } from 'src/app/components/data-quality/data-quality-grid/data-quality-grid.component';
import { GridType } from 'src/app/types/data-quality/grid-type';

@Component({
  selector: 'data-quality-px-actions',
  templateUrl: './data-quality-px-actions.component.html',
  styleUrls: ['./data-quality-px-actions.component.scss'],
})
export class DataQualityPxActionsComponent {
  url: string;

  gridTypes: GridType[] = [
    {
      key: 'CasesOnWrongPlTeam',
      value: 'Cases On Wrong Pl Team',
      description: 'Cases On Wrong Paralegal Team',
    },
    {
      key: 'ClosedAbandonedFilesWithSurveillances',
      value: 'Closed Abandoned Files With Surveillances',
      description: 'No Closed case should have remaining actions',
    },
    {
      key: 'RoleEventAndEventScheme',
      value: 'Role Event And Event Scheme',
      description: 'Term on wrong action responsible',
    },
    {
      key: 'SurveillanceOnTasksWithFreeText',
      value: 'Surveillance On Tasks With Free Text',
      description: 'Replace free text workflow by an official workflow',
    },
  ];

  selectedGrid: GridType;

  columns: Column[] = [];

  constructor() {
    this.url = `${environment.CalystaApiBaseURL}api/DataQuality/PxActions`;
    this.selectedGrid = this.gridTypes[0];
  }

  changeData = (gridType: GridType) => {
    this.selectedGrid = gridType;

    const columns: Column[] = [
      {
        type: 'buttons',
        buttons: [
          {
            hint: 'Open Patricia',
            icon: 'fas fa-horse',
            onClick: this.openPatricia,
          },
        ],
        width: 'auto',
      },
      {
        dataField: 'CaseId',
        caption: 'Case ID',
        alignment: 'center',
      },
      {
        dataField: 'CaseNumber',
        caption: 'Case Number',
        alignment: 'center',
      },
      {
        dataField: 'PatriciaLink',
        caption: 'Patricia Link',
        visible: false,
        showInColumnChooser: false,
      },
    ];

    //create columns depending on the selected grid
    switch (this.selectedGrid.key) {
      case 'CasesOnWrongPlTeam':
        columns.push(
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          },
          {
            dataField: 'TeamOnCase',
            caption: 'Team On Case',
            alignment: 'center',
          },
          {
            dataField: 'TeamOnClient',
            caption: 'Team On Client',
            alignment: 'center',
          }
        );
        break;
      case 'ClosedAbandonedFilesWithSurveillances':
        columns.push({
          dataField: 'Team',
          caption: 'Team',
          alignment: 'center',
        });
        break;
      case 'RoleEventAndEventScheme':
        columns.push(
          {
            dataField: 'EventSchemeId',
            caption: 'Event Scheme Id',
            alignment: 'center',
          },
          {
            dataField: 'LoginRoleEvent',
            caption: 'Login Role Event',
            alignment: 'center',
          },
          {
            dataField: 'RoleTheory',
            caption: 'Role Theory',
            alignment: 'center',
          },
          {
            dataField: 'EventFamily',
            caption: 'Event Family',
            alignment: 'center',
          },
          {
            dataField: 'IsExternal',
            caption: 'Is External',
            alignment: 'center',
          },
          {
            dataField: 'RolePractice',
            caption: 'Role Practice',
            alignment: 'center',
          }
        );
        break;
      case 'SurveillanceOnTasksWithFreeText':
        columns.push(
          {
            dataField: 'Team',
            caption: 'Team',
            alignment: 'center',
          },
          {
            dataField: 'CatchWord',
            caption: 'CatchWord',
            alignment: 'center',
          }
        );
        break;
    }

    this.columns = columns;
  };

  openPatricia = (e) => {
    const patriciaLink = e.row.data.PatriciaLink;
    window.open(patriciaLink, '_blank');
    e.event.preventDefault();
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    DxPopupModule,
    DxFormModule,
    DataQualityGridModule,
  ],
  declarations: [DataQualityPxActionsComponent],
  exports: [],
})
export class DataQualityPxActionsModule {}
