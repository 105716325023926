import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import { TitleBarModule } from 'src/app/components';
import { environment } from 'src/environments/environment';
import { DataQualityCheckValidationModule } from 'src/app/components/data-quality/data-quality-check-validation/data-quality-check-validation.component';
import { Column } from 'devextreme/ui/data_grid';
import { GridType } from 'src/app/types/data-quality/grid-type';
import { DataQualityGridModule } from 'src/app/components/data-quality/data-quality-grid/data-quality-grid.component';

@Component({
  selector: 'security-px-names',
  templateUrl: './security-px-names.component.html',
  styleUrls: ['./security-px-names.component.scss'],
})
export class SecurityPxNamesComponent {
  url: string;

  gridTypes: GridType[] = [
    {
      key: 'OrganisationIsPme',
      value: 'Organisation Is PME',
      description:
        'Organisation without PME fieCompany size should be added in the extended tab (for BE subsidies)',
    },
  ];

  selectedGrid: GridType;

  columns: Column[] = [];

  constructor() {
    this.url = `${environment.CalystaApiBaseURL}api/Security/PxNames`;
    this.selectedGrid = this.gridTypes[0];
  }

  changeData = (gridType: GridType) => {
    this.selectedGrid = gridType;

    const columns: Column[] = [
      {
        type: 'buttons',
        buttons: [
          {
            hint: 'Open Patricia',
            icon: 'fas fa-horse',
            onClick: this.openPatricia,
          },
        ],
        width: 'auto',
      },
      {
        dataField: 'NameId',
        caption: 'Name Id',
        alignment: 'center',
      },
      {
        dataField: 'Name',
        caption: 'Name',
        alignment: 'center',
      },
      {
        dataField: 'PatriciaLink',
        caption: 'Patricia Link',
        visible: false,
        showInColumnChooser: false,
      },
    ];

    //create columns depending on the selected grid
    switch (this.selectedGrid.key) {
      case 'OrganisationIsPme':
        columns.push(
          {
            dataField: 'Size',
            caption: 'Size',
            alignment: 'center',
          },
          {
            dataField: 'Address',
            caption: 'Address',
            alignment: 'center',
          },
          {
            dataField: 'City',
            caption: 'City',
            alignment: 'center',
          }
        );
        break;
    }

    this.columns = columns;
  };

  openPatricia = (e) => {
    const patriciaLink = e.row.data.PatriciaLink;
    window.open(patriciaLink, '_blank');
    e.event.preventDefault();
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
    DxPopupModule,
    DxFormModule,
    DataQualityGridModule,
  ],
  declarations: [SecurityPxNamesComponent],
  exports: [],
})
export class SecurityPxNamesModule {}
