import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DxDataGridComponent,
  DxDataGridModule,
  DxDateBoxModule,
  DxFormModule,
  DxPopupModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { InvoiceService } from 'src/app/services/invoicing/Invoice/InvoiceService.service';
import { finalize } from 'rxjs';
import notify from 'devextreme/ui/notify';
import { PatriciaService } from 'src/app/services/patricia/patricia.service';

@Component({
  selector: 'finance-creditcollect-amoundue-details',
  templateUrl: './finance-creditcollect-amoundue-details.component.html',
  styleUrls: ['./finance-creditcollect-amoundue-details.component.scss'],
})
export class FinanceCreditcollectAmoundueDetailsComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() clientId: number;

  @Input() amountOverdue: number;

  @Output() refreshDataEvent = new EventEmitter();

  detailsDataSource: DataSource;

  dataSource: DataSource<any, any>;

  showPaidPopup = false;

  url = environment.CalystaApiBaseURL + 'api/';

  paidData: any = {};

  constructor(
    private invoiceService: InvoiceService,
    private patriciaService: PatriciaService
  ) {
    this.paidData.PaidOn = null;
    this.paidData.InvoiceHeaderId = null;
  }

  ngOnInit() {
    this.detailsDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'InvoiceHeaderId',
        loadUrl: `${this.url}CreditCollect/InvoicesByClient`,
        loadParams: { ClientId: this.clientId, UnPaidOnly: true },
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  openDocument = (e) => {
    window.open(e.row.data.InvoiceDocument, '_blank');
  };

  openPaidPopup = (e) => {
    this.showPaidPopup = true;
    this.paidData.InvoiceHeaderId = e.row.data.InvoiceHeaderId;
  };

  onPaidOnChanged = (e) => {
    this.paidData.PaidOn = e.value;
  };

  ConfirmPaid = () => {
    this.showPaidPopup = false;

    //send to api
    this.invoiceService
      .setInvoiceAsPaid(this.paidData.InvoiceHeaderId, this.paidData.PaidOn)
      .pipe(
        finalize(() => {
          this.refreshData();

          //reset data
          this.paidData.PaidOn = null;
          this.paidData.InvoiceHeaderId = null;
        })
      )
      .subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'error', 3000);
        },
      });
  };

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  CancelPaid = () => {
    this.showPaidPopup = false;

    //reset data
    this.paidData.PaidOn = null;
    this.paidData.InvoiceHeaderId = null;
  };

  HasAmountDue = () => {
    return this.amountOverdue > 0;
  };

  openMail = (e) => {
    const id = e.row.data.InvoiceHeaderId;

    this.invoiceService.prepareMailReminder(id).subscribe({
      next: (x) => {
        notify(
          'Mail prepared in your draft folder in your mailbox',
          'success',
          5000
        );
      },
      error: (err) => {
        const errorMessage = err.error.Errors.GeneralErrors[0];
        notify(errorMessage, 'error', 3000);
      },
    });
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxPopupModule,
    DxFormModule,
    DxDateBoxModule,
  ],
  providers: [],
  declarations: [FinanceCreditcollectAmoundueDetailsComponent],
  exports: [FinanceCreditcollectAmoundueDetailsComponent],
})
export class FinanceCreditcollectAmoundueDetailsModule {}
