import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class ServiceLevelService {
  url = apiUrl + 'api/Patricia/';
  constructor(private http: HttpClient) {}

  public getServiceLevels(): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}ServiceLevelLookup/Lookup`);
  }

  getUrl() {
    return this.url;
  }
}
