import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxDropDownButtonModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import {
  FormPopupModule,
  ContactStatusModule,
  FormPopupComponent,
} from 'src/app/components';
import DataSource from 'devextreme/data/data_source';
import { GraphMessage, MailFolder } from 'src/app/types/graph/message';
import { GraphAPIMailService } from 'src/app/services/graphapi/graphapi-mail.service';
import { SelectionChangedEvent } from 'devextreme/ui/drop_down_button';
import { RowClickEvent } from 'devextreme/ui/data_grid';
import { DocketMailPanelModule } from 'src/app/components/docket/docket-mail-panel/docket-mail-panel.component';
import { DocketMailFormModule } from 'src/app/components/docket/docket-mail-form/docket-mail-form.component';
import { CalystaAPIDocketingService } from 'src/app/services/calysta-api/docketing.service';
import { Mailbox } from 'src/app/types/docketing/Mailbox';
import { GraphAPIProfileService } from 'src/app/services/graphapi/graphapi-profile.service';
import { Profile } from 'src/app/types/graph/profile';
import { CardActivitiesModule } from 'src/app/components/library/card-activities/card-activities.component';

@Component({
  selector: 'app-docket-mail-list',
  templateUrl: './docket-mail-list.component.html',
  styleUrls: ['./docket-mail-list.component.scss'],
  providers: [GraphAPIMailService, GraphAPIProfileService],
})
export class DocketMailListComponent implements OnInit {
  @ViewChild('docketMailPopup', { static: true })
  docketMailPopup: FormPopupComponent;

  currentEmail: GraphMessage = null;

  mailBoxFolderList!: MailFolder[];

  mailboxList: Mailbox[] = [];

  isPanelOpened = false;

  emailId: string;
  currentMailbox: string;

  dataSource: DataSource;

  constructor(
    private mailSvc: GraphAPIMailService,
    private CalystaAPIDocketingSVC: CalystaAPIDocketingService,
    private graphApiProfileService: GraphAPIProfileService
  ) {}

  ngOnInit(): void {
    this.graphApiProfileService.getProfile().subscribe((profile) => {
      this.currentMailbox = (profile as Profile).mail;

      this.mailboxList.push(
        { mailboxName: 'Personal', mailAddress: this.currentMailbox },
        { mailboxName: 'IP Secretary', mailAddress: 'secretary.ip@calysta.eu' }
      );
      this.loadMailBox(this.currentMailbox);
      this.loadMailboxFolders();
      /*this.CalystaAPIDocketingSVC.getCategories().subscribe((x) =>
        console.log(x),
      );*/
    });
  }

  loadMailboxFolders() {
    this.mailSvc.getAllMailboxFolders(this.currentMailbox).subscribe((res) => {
      this.mailBoxFolderList = res;
    });
  }

  openMailFolder = (e: SelectionChangedEvent) => {
    const { item: MailFolder }: { item: MailFolder } = e;

    this.dataSource = new DataSource<GraphMessage[], string>({
      key: 'id',
      load: () =>
        new Promise((resolve, reject) => {
          this.mailSvc
            .getAllMailfolderMessages(MailFolder.id, this.currentMailbox)
            .subscribe({
              next: (data: GraphMessage[]) => {
                resolve(data);
              },
              error: ({ message }) => reject(message),
            });
        }),
    });
  };

  changeMailbox = (e: SelectionChangedEvent) => {
    const { item: status }: { item: Mailbox } = e;
    this.currentMailbox = status.mailAddress;
    this.loadMailBox(this.currentMailbox);
    this.loadMailboxFolders();
  };

  loadMailBox(EmailAddress: string) {
    this.dataSource = new DataSource<GraphMessage[], string>({
      key: 'id',
      load: () =>
        new Promise((resolve, reject) => {
          this.mailSvc.getAllMailboxMessages(this.currentMailbox).subscribe({
            next: (data: GraphMessage[]) => {
              resolve(data);
            },
            error: ({ message }) => reject(message),
          });
        }),
    });
  }

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  rowClick(e: RowClickEvent) {
    const { data } = e;
    if (data.id) {
      this.emailId = data.id;
      this.isPanelOpened = true;
    }
  }

  onOpenedChange = (value: boolean) => {
    if (!value) {
      this.emailId = null;
    }
  };

  openDocketMail = (message: GraphMessage) => {
    this.currentEmail = message;
    this.docketMailPopup.visible = true;
  };
}

@NgModule({
  imports: [
    DxButtonModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxScrollViewModule,
    CardActivitiesModule,
    DocketMailPanelModule,
    DocketMailFormModule,
    FormPopupModule,
    ContactStatusModule,

    CommonModule,
  ],
  providers: [],
  exports: [],
  declarations: [DocketMailListComponent],
})
export class DocketMailListModule {}
