<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  height="auto"
  width="auto"
>
  <dx-form [(formData)]="responseType" validationGroup="validationGroup">
    <dxi-item itemType="group" [colCount]="1">
      <dxi-item
        dataField="ClientId"
        editorType="dxLookup"
        [editorOptions]="{
          dataSource: clients,
          displayExpr: 'LegalName',
          valueExpr: 'NameId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="Client is required" />
        <dxo-label text="Client" />
      </dxi-item>
      <dxi-item
        dataField="CaseId"
        editorType="dxLookup"
        [editorOptions]="{
          dataSource: cases,
          displayExpr: 'CaseNumber',
          valueExpr: 'CaseId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="Case is required" />
        <dxo-label text="Case" />
      </dxi-item>
    </dxi-item>
  </dx-form>
</form-popup>
