<div width="100%">
  <toolbar-analytics
    [panelItems]="analyticsPanelItems"
    [selectedItems]="[5]"
    (selectionChanged)="selectionChange($event)"
  />
  <dx-pivot-grid
    #realizedGrid
    [allowSortingBySummary]="false"
    [allowFiltering]="false"
    [showBorders]="true"
    [allowSortingBySummary]="false"
    [showRowTotals]="false"
    [showColumnTotals]="true"
    [showRowGrandTotals]="true"
    [showColumnGrandTotals]="false"
    [dataSource]="pivotGridDataSource"
    [hideEmptySummaryCells]="true"
    (onCellPrepared)="onCellPrepared($event)"
    (onExporting)="onExporting($event)"
  >
    <dxo-field-chooser [enabled]="false" [height]="400" />
    <dxo-scrolling mode="virtual" />
    <dxo-export [enabled]="true" />
  </dx-pivot-grid>

  <dx-pivot-grid
    #budgetedGrid
    [allowSortingBySummary]="false"
    [allowFiltering]="false"
    [showBorders]="true"
    [allowSortingBySummary]="false"
    [showRowTotals]="false"
    [showColumnTotals]="false"
    [showRowGrandTotals]="true"
    [showColumnGrandTotals]="false"
    [dataSource]="pivotGridBudgetDataSource"
    [hideEmptySummaryCells]="true"
    (onCellPrepared)="onCellPrepared($event)"
  >
    <dxo-field-chooser [enabled]="false" [height]="400" />
    <dxo-scrolling mode="virtual" />
  </dx-pivot-grid>
</div>
