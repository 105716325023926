<dx-toolbar>
  <dxi-item location="before">
    <span class="toolbar-header">{{ titleText }}</span>
  </dxi-item>

  <dxi-item *ngIf="panelItems" location="before">
    <dx-tabs
      [width]="(screen.xSmallScreenChanged | async) ? 150 : 'auto'"
      [showNavButtons]="false"
      [scrollByContent]="true"
      [selectedItemKeys]="selectedItems"
      keyExpr="key"
      [items]="panelItems"
      (onItemClick)="selectionChange($event)"
    />
  </dxi-item>
</dx-toolbar>
