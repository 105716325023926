<dx-data-grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  class="grid"
  noDataText=""
  height="100%"
  [dataSource]="dataSource"
  [focusedRowEnabled]="true"
  (onRowClick)="rowClick($event)"
  (onRowUpdating)="onRowUpdating($event)"
  [wordWrapEnabled]="true"
>
  <dxo-load-panel [showPane]="false"></dxo-load-panel>
  <dxo-scrolling mode="virtual"></dxo-scrolling>
  <dxo-sorting mode="multiple" />
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-search-panel
    [visible]="true"
    placeholder="Email Search"
    width="20vw"
  ></dxo-search-panel>
  <dxo-toolbar>
    <dxi-item location="before">
      <div class="grid-header">Email(s)</div>
    </dxi-item>
    <dxi-item location="before" locateInMenu="auto">
      <dx-drop-down-button
        stylingMode="text"
        [useSelectMode]="true"
        [dataSource]="mailboxList"
        displayExpr="mailboxName"
        keyExpr="mailAddress"
        [dropDownOptions]="{ width: 'auto' }"
        [selectedItemKey]="mailboxList[0]?.mailAddress"
        (onSelectionChanged)="changeMailbox($event)"
      ></dx-drop-down-button>
      <dx-drop-down-button
        stylingMode="text"
        [useSelectMode]="true"
        [dataSource]="mailBoxFolderList"
        displayExpr="displayName"
        [dropDownOptions]="{ width: 'auto' }"
        (onSelectionChanged)="openMailFolder($event)"
      ></dx-drop-down-button>
    </dxi-item>
    <dxi-item location="after" locateInMenu="auto">
      <dx-button
        text="New Contact"
        icon="plus"
        type="default"
        stylingMode="contained"
      ></dx-button>
    </dxi-item>
    <dxi-item location="after" locateInMenu="auto">
      <div class="separator"></div>
    </dxi-item>
    <dxi-item name="exportButton" />
    <dxi-item location="after" locateInMenu="auto">
      <div class="separator"></div>
    </dxi-item>
    <dxi-item name="columnChooserButton" locateInMenu="auto" />
    <dxi-item name="searchPanel" locateInMenu="auto" />
  </dxo-toolbar>
  <dxi-column
    dataField="hasAttachments"
    caption=""
    cellTemplate="cellAttachments"
    [allowHeaderFiltering]="false"
    [width]="25"
  />
  <dxi-column
    dataField="from"
    caption="From"
    [customizeText]=""
    cellTemplate="emailFrom"
    [width]="250"
  />
  <dxi-column dataField="subject" caption="Subject" />
  <dxi-column
    dataField="receivedDateTime"
    dataType="datetime"
    caption="ReceivedOn"
    sortOrder="desc"
  />
  <dxi-column dataField="categories" caption="Categories" [groupIndex]="0" />

  <div *dxTemplate="let cellInfo of 'emailFrom'" class="emailFrom-template">
    <div>
      <div *ngIf="cellInfo.data.from; else elseBlock">
        {{ cellInfo.data.from.emailAddress.address }}
      </div>
      <ng-template #elseBlock></ng-template>
    </div>
  </div>

  <div *dxTemplate="let cellInfo of 'cellAttachments'">
    <div *ngIf="cellInfo.value === true">
      <i class="dx-icon-attach"></i>
    </div>
  </div>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="gridDocketMailList"
  />
</dx-data-grid>

<app-docket-mail-panel
  [emailId]="emailId"
  [(isOpened)]="isPanelOpened"
  (docketMailClicked)="openDocketMail($event)"
  [mailboxId]="currentMailbox"
>
</app-docket-mail-panel>

<form-popup #docketMailPopup titleText="Docket Email">
  <app-docket-mail-form
    [email]="currentEmail"
    [mailBox]="currentMailbox"
  ></app-docket-mail-form>
</form-popup>
