<dx-pie-chart
  id="pieChart"
  title="Time Billed by Casetype"
  [dataSource]="pieChartDataSource"
>
<dxo-legend
  horizontalAlignment="right"
  verticalAlignment="top"
  ></dxo-legend>
  <dxo-export [enabled]="true"></dxo-export>
  <dxi-series argumentField="CaseTypeLabel" valueField="BilledHours">
    <dxo-label [visible]="true" format="fixedPoint">
      <dxo-connector [visible]="true"></dxo-connector>
    </dxo-label>
    <dxo-small-values-grouping
      mode="topN"
      [topCount]="10"
    >
    </dxo-small-values-grouping>
  </dxi-series>
</dx-pie-chart>
