<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar icon="assets/epo_logo.svg" titleText="EPO Communications" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-filter-row [visible]="true" />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item
          location="before"
          widget="dxCheckBox"
          locateInMenu="never"
          [options]="{
            text: 'See handled communications',
            onValueChanged: seeHandledCommunications,
            value: showHandledCommunications
          }"
        />
        <dxi-item location="before" locateInMenu="auto">
          <dx-lookup
            placeholder="Select Folder"
            [dataSource]="folders"
            displayExpr="FolderName"
            valueExpr="FolderId"
            (onValueChanged)="folderChanged($event)"
            [searchEnabled]="true"
            value="''"
            [showClearButton]="true"
            width="auto"
            class="lookup"
            [disabled]="!showHandledCommunications"
          />
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="Id" caption="ID" [visible]="false" />
      <dxi-column dataField="Title" caption="Title" />
      <dxi-column dataField="ApplicantName" caption="Applicant" />
      <dxi-column
        dataField="DispatchDate"
        caption="Dispatch Date"
        dataType="date"
      />
      <dxi-column dataField="ApplicationNumber" caption="Application Number" />
      <dxi-column dataField="RecipientName" caption="Recipient" />
      <dxi-column dataField="UserReference" caption="User Reference" />
      <dxi-column
        dataField="Handler"
        caption="Handler"
        [visible]="showHandledCommunications"
      />
      <dxi-column dataField="Document" caption="Document" [visible]="false" />
      <dxi-column
        dataField="DigitalFile"
        caption="Digital File"
        [visible]="false"
      />
      <dxi-column
        dataField="DocumentCode"
        caption="Document Code"
        [visible]="false"
      />
      <dxi-column dataField="Read" caption="Read" />
      <dxi-column dataField="FolderId" caption="Folder" [visible]="false" />
      <dxi-column type="buttons">
        <dxi-button
          icon="fas fa-file-zipper"
          hint="Download"
          [onClick]="Download"
        />
        <dxi-button
          icon="fas fa-thumbs-up"
          hint="Mark as handled"
          [onClick]="Handle"
          [visible]="!showHandledCommunications"
        />
      </dxi-column>

      <dxo-paging [pageSize]="50" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridEpoCommunications"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
