import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';
import {
  DxButtonModule,
  DxCircularGaugeModule,
  DxLinearGaugeModule,
} from 'devextreme-angular';
import { CustomCurrencyPipeModule } from 'src/app/pipes/customCurrency.pipe';

@Component({
  selector: 'card-operational-costs',
  templateUrl: './card-operational-costs.component.html',
  styleUrls: ['./card-operational-costs.component.scss'],
})
export class CardOperationalCostsComponent {
  @Input() title: string = '';
  @Input() amountSpend: number = 0;
  @Input() budgetedAmount: number = 0;
  @Input() icon: string = '';
  @Input() description: string = '';
  @Input() isClickable: boolean = false;

  @Output() onClick = new EventEmitter<number>();

  constructor() {}

  customizeText(arg: any) {
    return `${arg.valueText} %`;
  }

  getPercentSpend() {
    return (this.amountSpend / this.budgetedAmount) * 100;
  }

  handleClick() {
    if (this.isClickable) {
      this.onClick.emit();
    }
  }
}

@NgModule({
  imports: [
    DxCircularGaugeModule,
    DxButtonModule,
    DxLinearGaugeModule,
    CustomCurrencyPipeModule,
  ],
  declarations: [CardOperationalCostsComponent],
  exports: [CardOperationalCostsComponent],
})
export class CardOperationalCostsModule {}
