<dx-data-grid
  [dataSource]="dataSource"
  [showBorders]="true"
  [wordWrapEnabled]="true"
>
  <dxi-column
    dataField="OfficialDeadline"
    caption="Official Deadline"
    dataType="date"
    sortOrder="asc"
    sortIndex="0"
    alignment="center"
  />
  <dxi-column
    dataField="OfficialStatusText"
    caption="Status"
    dataType="string"
    alignment="center"
  />
  <dxi-column
    dataField="OfficialActionText"
    caption="Official Action Text"
    alignment="center"
    [allowHeaderFiltering]="false"
  />
  <dxi-column
    dataField="OfficialResponsible"
    caption="Official Responsible"
    alignment="center"
    [allowHeaderFiltering]="false"
  />
</dx-data-grid>
