<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Cases Diaries" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="true"
        [allowUpdating]="true"
        [allowDeleting]="true"
      >
        <dxo-popup
          [showTitle]="true"
          [title]="'Add Case Diary'"
          height="auto"
          width="auto"
        >
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="saveButtonOptions"
          />
          <dxi-toolbar-item
            toolbar="bottom"
            widget="dxButton"
            location="after"
            [options]="cancelButtonOptions"
          />
        </dxo-popup>
        <dxo-form>
          <dxi-item dataField="CaseBasicInfoId" />
          <dxi-item dataField="ExcelColumn" />
          <dxi-item dataField="DiaryField" />
          <dxi-item dataField="IsDate" />
          <dxi-item dataField="DateValue" />
          <dxi-item dataField="TextValue" />
        </dxo-form>
      </dxo-editing>

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="CaseBasicInfoId" caption="ID" [visible]="false" />
      <dxi-column
        dataField="CaseBasicInfoId"
        caption="CaseBasicInfoId"
        alignment="center"
        width="8%"
      />
      <dxi-column
        dataField="ExcelColumn"
        caption="ExcelColumn"
        alignment="center"
      />
      <dxi-column
        dataField="DiaryField"
        caption="Diary Field"
        alignment="center"
        width="8%"
      />
      <dxi-column
        dataField="IsDate"
        caption="Is Date"
        alignment="center"
        width="8%"
      />
      <dxi-column
        dataField="DateValue"
        caption="Date Value"
        dataType="date"
        alignment="center"
        width="10%"
      />
      <dxi-column
        dataField="TextValue"
        caption="Text Value"
        alignment="center"
      />

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridConversionCaseDiary"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
