<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Accounts Px Follow Up" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onExporting)="onExporting($event)"
      (onRowUpdating)="onRowUpdating($event)"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export
        [enabled]="true"
        [fileName]="'Account Px Follow Up'"
        [allowExportSelectedData]="false"
      />

      <dxo-toolbar>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="exportButton" />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="Id" caption="Id" [visible]="false" />
      <dxi-column
        dataField="EventSchemeId"
        caption="Event Scheme Id"
        alignment="center"
      ></dxi-column>
      <dxi-column
        dataField="CaseNumber"
        caption="CaseNumber"
        alignment="center"
      ></dxi-column>
      <dxi-column
        dataField="Client"
        caption="Client"
        alignment="center"
      ></dxi-column>
      <dxi-column
        dataField="DateOfOrder"
        caption="Date of order"
        dataType="date"
        alignment="center"
      ></dxi-column>
      <dxi-column
        dataField="DateOfConnection"
        caption="Date of connection"
        dataType="date"
        alignment="center"
      ></dxi-column>
      <dxi-column
        dataField="InstructionsDate"
        caption="Instructions Date"
        dataType="date"
        alignment="center"
      ></dxi-column>
      <dxi-column
        dataField="Subscription"
        caption="Subcription"
        alignment="center"
      ></dxi-column>
      <dxi-column
        dataField="SubscriptionDate"
        caption="Subscription Date"
        dataType="date"
        alignment="center"
      ></dxi-column>
      <dxi-column
        dataField="NextSubscriptionDate"
        caption="Next Subscription Date"
        dataType="date"
        alignment="center"
      ></dxi-column>
      <dxi-column
        dataField="WorkFlow"
        caption="Work Flow"
        alignment="center"
      ></dxi-column>
      <dxi-column
        dataField="StatusText"
        caption="Status Text"
        alignment="center"
      ></dxi-column>
      <dxi-column
        dataField="ActionText"
        caption="Action Text"
        alignment="center"
      ></dxi-column>
      <dxi-column
        dataField="Family"
        caption="Family"
        alignment="center"
      ></dxi-column>
      <dxi-column
        dataField="RespLoginId"
        caption="Resp Login Id"
        alignment="center"
      ></dxi-column>

      <dxo-paging [pageSize]="50" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      >
      </dxo-pager>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridClientPortalAccountPxFollowUp"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
