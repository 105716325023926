import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxLookupModule, DxSelectBoxModule } from 'devextreme-angular';
import { CalystaAPIInvoiceService } from 'src/app/services/calysta-api/invoice.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { BudgetService } from 'src/app/services/budget/budget.service';

@Component({
  selector: 'budget-subcategory-lookup',
  templateUrl: './budget-subcategory-lookup.component.html',
  styleUrls: ['./budget-subcategory-lookup.component.scss'],
})
export class BudgetSubCategoryLookupComponent implements OnInit {
  @Input() value: any;
  @Input() valueChange: (value: number) => void;

  budgetSubCategories: any;

  url: string;

  constructor(private budgetSerivce: BudgetService) {
    this.url = this.budgetSerivce.getUrl();
  }

  ngOnInit(): void {
    this.budgetSubCategories = new DataSource({
      store: AspNetData.createStore({
        key: 'BudgetSubCategoryId',
        loadUrl: `${this.url}/BudgetSubCategory/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'BudgetSubCategoryLabel',
    });
  }

  valueChanged(e) {
    this.valueChange(e.value);
  }
}

@NgModule({
  imports: [BrowserModule, DxLookupModule, DxSelectBoxModule],
  declarations: [BudgetSubCategoryLookupComponent],
  exports: [BudgetSubCategoryLookupComponent],
  providers: [CalystaAPIInvoiceService],
})
export class BudgetSubCategoryLookupModule {}
