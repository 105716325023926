<div class="messages">
  <dx-drop-down-button
    #dropDownButton
    [showArrowIcon]="false"
    [dropDownOptions]="{
      width: '150',
    }"
    icon="fas fa-bell"
    stylingMode="text"
    dropDownContentTemplate="dropDownTemplate"
  >
    <div *dxTemplate="let data of 'dropDownTemplate'">
      <dx-list [items]="menuItems">
        <div *dxTemplate="let item of 'item'">
          <div [style]="'background-color: ' + item.backgroundColor">
            <div class="item">
              {{ item.text }}
            </div>
            <dx-button
              icon="fas fa-arrow-right-long"
              stylingMode="text"
              (click)="item.onClick()"
            />
          </div>
        </div>
      </dx-list>
    </div>
  </dx-drop-down-button>
  <div *ngIf="numberOfNotifications() > 0" class="dx-badge">
    {{ numberOfNotifications() }}
  </div>
</div>
