import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxLookupModule, DxSelectBoxModule } from 'devextreme-angular';
import { PavisService } from 'src/app/services/renewal/pavis/pavis.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'pavis-filter-lookup',
  templateUrl: './pavis-filter-lookup.component.html',
  styleUrls: ['./pavis-filter-lookup.component.scss'],
})
export class PavisFilterLookupComponent implements OnInit {
  @Input() value = false;
  @Input() valueChange: (value: number) => void;

  datasource: any;

  url: string;

  constructor(private pavisService: PavisService) {
    this.url = this.pavisService.getUrl();
  }

  ngOnInit(): void {
    this.datasource = new DataSource({
      store: AspNetData.createStore({
        key: 'FilterId',
        loadUrl: `${this.url}/PavisFilter/Lookup`,
      }),
      sort: 'FilterName',
    });
  }

  valueChanged(e) {
    this.valueChange(e.value);
  }
}

@NgModule({
  imports: [BrowserModule, DxLookupModule, DxSelectBoxModule],
  declarations: [PavisFilterLookupComponent],
  exports: [PavisFilterLookupComponent],
  providers: [PavisService],
})
export class PavisFilterLookupModule {}
