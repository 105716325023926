<dx-scroll-view class="view-wrapper-scroll"
  ><div class="view-wrapper">
    <titlebar titleText="Bank Accounts Track" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-filter-row [visible]="true" />
      <dxo-editing
        mode="popup"
        [allowAdding]="false"
        [allowUpdating]="false"
        [allowDeleting]="false"
      />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: 'Import from file',
            icon: 'exportxlsx',
            stylingMode: 'button',
            onClick: importFromFile
          }"
        />
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: 'Process',
            icon: '',
            stylingMode: 'button',
            onClick: process
          }"
        />
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        dataField="TranscriptionNumber"
        caption="Transcription Number"
        alignment="center"
        width="7%"
      />
      <dxi-column
        dataField="Date"
        caption="Date"
        dataType="date"
        alignment="center"
        width="8%"
        [sortOrder]="'desc'"
      />
      <dxi-column dataField="Description" caption="Description" />
      <dxi-column dataField="IsCredit" caption="Is Credit" width="5%" />
      <dxi-column dataField="IsDebit" caption="Is Debit" width="5%" />
      <dxi-column dataField="Amount" caption="Amount" width="5%" />
      <dxi-column dataField="Currency" caption="Currency" width="5%" />
      <dxi-column
        dataField="AccountNumberThirdParty"
        caption="Account Number Third Party"
      />
      <dxi-column dataField="NameThirdParty" caption="Name Third Party" />
      <dxi-column
        dataField="StructuredCommunication"
        caption="Structured Communication"
      />
      <dxi-column dataField="FreeCommunication" caption="Free Communication" />
      <dxi-column dataField="Processed" caption="Processed" width="5%" />
      <dxi-column
        dataField="accountNumber"
        caption="Account Number"
        [visible]="false"
      />
      <dxi-column
        dataField="AddressThirdParty"
        caption="Address Third Party"
        [visible]="false"
      />
      <dxi-column dataField="Balance" caption="Balance" [visible]="false" />
      <dxi-column
        dataField="BicCodeThirdParty"
        caption="Bic Code Third Party"
        [visible]="false"
      />
      <dxi-column
        dataField="CreditAmount"
        caption="Credit Amount"
        [visible]="false"
      />
      <dxi-column
        dataField="DebitAmount"
        caption="Debit Amount"
        [visible]="false"
      />
      <dxi-column dataField="Name" caption="Name" [visible]="false" />
      <dxi-column
        dataField="TopicName"
        caption="Topic Name"
        [visible]="false"
      />
      <dxi-column dataField="Valuta" caption="Valuta" [visible]="false" />

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridFinanceBankAccountTrack"
      />
    </dx-data-grid>
    <bank-import-file
      [(visible)]="importFromFilePopupVisible"
      (save)="onImportFromFile($event)"
      [titleText]="'Import From File'"
      (visibleChange)="onClosedImportFromFilePopup($event)"
      [width]="'20vw'"
      [height]="'40vh'"
    />
  </div>
</dx-scroll-view>
