<dx-data-grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [dataSource]="WIPData"
  [remoteOperations]="true"
  id="gridContainer"
  class="grid"
  [wordWrapEnabled]="true"
  [height]="'70vh'"
  [showBorders]="true"
>
  <dxo-search-panel [visible]="true" width="20vw" placeholder="Search..." />

  <dxo-grouping #expand [autoExpandAll]="false" />
  <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
  <dxo-paging [pageSize]="100" />

  <dxi-column
    dataField="ClientName"
    caption="Client"
    dataType="string"
    groupIndex="0"
    sortOrder="asc"
    groupCellTemplate="GroupingTemplate"
  />
  <div *dxTemplate="let data of 'GroupingTemplate'">
    <div style="color: orangered">
      {{ data.displayValue }} ({{ data.summaryItems[0].value }} Margin /
      {{ data.summaryItems[1].value }} Sales €)
    </div>
  </div>
  <dxi-column
    dataField="CalystaRef"
    caption="Case"
    dataType="string"
    groupIndex="1"
    groupCellTemplate="GroupingTemplate"
  />
  <dxi-column dataField="WorkCodeLabel" caption="WorkCode label" />
  <dxi-column dataField="Description" caption="Description" />
  <dxi-column dataField="CreatedOn" caption="CreatedOn" dataType="date" />
  <dxi-column dataField="Margin" caption="Margin" dataType="currency" />
  <dxi-column dataField="ProviderCost" caption="Provider" dataType="currency" />
  <dxi-column dataField="Amount" caption="Amount" dataType="currency" />
  <dxo-summary>
    <dxi-group-item column="Margin" summaryType="sum" displayFormat="€ {0}" />
    <dxi-group-item column="Amount" summaryType="sum" displayFormat="€ {0}" />
  </dxo-summary>

  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="gridWIPReporting"
  />
</dx-data-grid>
