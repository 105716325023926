import { Component, NgModule, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  DxLoadPanelModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTabPanelModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { RealizedTargetChartModule, TitleBarModule } from 'src/app/components';
import { TimeReportingService } from 'src/app/services/time-registration/reporting.service';
import { InvoiceReporting } from 'src/app/types/timeregistration/InvoiceReporting';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { InvoiceReportingModule } from './invoice-reporting/invoice-reporting.component';
import { KpiToolbarModule } from './kpi-toolbar/kpi-toolbar.component';
import { CommonModule } from '@angular/common';
import { AuthPermissionService } from 'src/app/services/auth/permission.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MarginReportingModule } from './margin-reporting/margin-reporting.component';
import { TimeReportingModule } from './time-reporting/time-reporting.component';
import { WipReportingModule } from './wip-reporting/wip-reporting.component';
import { environment } from 'src/environments/environment';
import { BudgetReportingModule } from './budget-reporting/budget-reporting.component';

@Component({
  selector: 'app-time-registration-reporting-team',
  templateUrl: './time-registration-reporting-team.component.html',
  styleUrls: ['./time-registration-reporting-team.component.scss'],
})
export class TimeRegistrationReportingTeamComponent implements OnInit {
  activeTeams: any;

  loading: boolean = false;

  InvoiceDataLoading: boolean = false;
  Year: number = new Date().getFullYear();
  MonthNumber: number = new Date().getMonth() + 1;
  MonthLabel: String = new Date(
    this.Year,
    this.MonthNumber - 1,
    10
  ).toLocaleString('default', { month: 'long' });

  TeamId: number = 0;
  CurrentTeamName: string = '';

  graphTabs: String[] = ['Margin', 'Time', 'WIP', 'Budget'];

  InvoiceReportingDataSource: InvoiceReporting[];

  constructor(
    private TimeReportingSvc: TimeReportingService,
    public PermissionSvc: AuthPermissionService,
    private AuthService: AuthService
  ) {}

  onMonthChanged(e): void {
    this.MonthNumber = e;
    this.MonthLabel = new Date(2024, e - 1, 1).toLocaleString(
      'default',
      { month: 'long' }
    );
  }

  ngOnInit(): void {
    this.TimeReportingSvc.getPrincipalTeam()
      .toPromise()
      .then((data) => {
        if (!data) return;

        this.TeamId = data.TeamId;
        this.CurrentTeamName = data.TeamLabel;
        this.getTeamReporting();
      });

    this.activeTeams = new DataSource({
      store: AspNetData.createStore({
        key: 'TeamId',
        loadUrl: `${this.TimeReportingSvc.getUrl()}/TeamInformation/Lookup`,
        loadParams: { OnlyActive: true },
      }),
      sort: 'TeamLabel',
      filter: [
        ['TeamLabel', '<>', 'IP Secretary'],
        'and',
        ['TeamLabel', '<>', 'Support Team'],
      ],
    });

    if (!environment.production) {
      // this.TeamId = 20004;
      // this.Year = 2023;
    }
  }

  getTeamReporting(): void {}

  onTeamChanged(e): void {
    this.TeamId = e.value;
    if (this.TeamId != 0) {
      this.getTeamReporting();
    }
  }
}

@NgModule({
  providers: [],
  exports: [],
  declarations: [TimeRegistrationReportingTeamComponent],
  imports: [
    RealizedTargetChartModule,
    DxScrollViewModule,
    TitleBarModule,
    DxTabPanelModule,
    InvoiceReportingModule,
    KpiToolbarModule,
    DxSelectBoxModule,
    CommonModule,
    DxLoadPanelModule,
    MarginReportingModule,
    TimeReportingModule,
    WipReportingModule,
    BudgetReportingModule,
  ],
})
export class TimeRegistrationReportingTeamModule {}
