import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxLookupModule, DxSelectBoxModule } from 'devextreme-angular';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { InitializedEvent } from 'devextreme/ui/lookup';

@Component({
  selector: 'employee-name-lookup',
  templateUrl: './employee-name-lookup.component.html',
  styleUrls: ['./employee-name-lookup.component.scss'],
})
export class EmployeeNameLookupComponent implements OnInit {
  @Input() value: number;
  @Input() valueChange: (value: number) => void;

  defaultValue: number;

  datasource: any;

  url: string;

  constructor(private employeeService: EmployeeService) {
    this.url = this.employeeService.getUrl();
  }

  ngOnInit(): void {
    this.datasource = new DataSource({
      store: AspNetData.createStore({
        key: 'EmployeeId',
        loadUrl: `${this.url}/Lookup`,
      }),
      sort: ['FirstName', 'LastName'],
    });
  }

  valueChanged(e) {
    this.valueChange(e.value);
  }

  onInitialized(e: InitializedEvent) {
    if (this.value) {
      this.valueChange(this.value);
    } else {
      this.getDefaultValue();
    }
  }

  getDefaultValue() {
    this.employeeService.getMyself().subscribe((data) => {
      this.defaultValue = data.EmployeeId;
      this.valueChange(this.defaultValue);
    });
  }
}

@NgModule({
  imports: [BrowserModule, DxLookupModule, DxSelectBoxModule],
  declarations: [EmployeeNameLookupComponent],
  exports: [EmployeeNameLookupComponent],
  providers: [EmployeeService],
})
export class EmployeeNameLookupModule {}
