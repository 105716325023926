import { Component, Input, NgModule, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxFormModule, DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'crm-contacts-list',
  templateUrl: './crm-contacts-list.component.html',
  styleUrls: ['./crm-contacts-list.component.scss'],
})
export class CrmContactsListComponent implements OnInit {
  @Input() nameId: number = null; // This is the ID of the name from which the contacts are to be displayed

  contactsDataSource: DataSource;
  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {}

  ngOnInit() {
    this.contactsDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'NameId',
        loadUrl: `${this.url}Patricia/NameContacts`,
        loadParams: { NameId: this.nameId },
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  sendMail = (e) => {
    let email = e.row.data.Email;
    const mailtoLink = `mailto:${email}?subject=""&body="${encodeURIComponent(
      ''
    )}"`;
    window.location.href = mailtoLink;
  };

  hasEmailAddress = (e) => {
    return e.row.data.Email !== null;
  };
}

@NgModule({
  declarations: [CrmContactsListComponent],
  exports: [CrmContactsListComponent],
  bootstrap: [CrmContactsListComponent],
  imports: [BrowserModule, DxFormModule, DxDataGridModule],
})
export class CrmContactsListModule {}
