import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxLookupModule,
  DxTagBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import DataSource from 'devextreme/data/data_source';
import { ValueChangedEvent } from 'devextreme/ui/lookup';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';

@Component({
  selector: 'finance-charges-move-to-cases-form',
  templateUrl: './finance-charges-move-to-cases-form.component.html',
  styleUrls: ['./finance-charges-move-to-cases-form.component.scss'],
})
export class FinanceChargesMoveToCasesFormComponent implements OnInit {
  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<any>();

  @Output() visibleChange = new EventEmitter<boolean>();

  cases: any;

  url = environment.CalystaApiBaseURL + 'api/';

  caseId: number;

  constructor() {}

  ngOnInit(): void {
    this.cases = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${this.url}Patricia/CaseDetailedInfo/lookup`,
      }),
      sort: 'CaseNumber',
    });
  }

  handleVisible(e: boolean): void {
    this.visible = e;
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.save.emit(this.caseId);
  }

  onCaseChanged(e: ValueChangedEvent) {
    this.caseId = e.value;
  }
}

@NgModule({
  declarations: [FinanceChargesMoveToCasesFormComponent],
  exports: [FinanceChargesMoveToCasesFormComponent],
  bootstrap: [FinanceChargesMoveToCasesFormComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
    DxLookupModule,
  ],
})
export class FinanceChargesMoveToCasesFormModule {}
