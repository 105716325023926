<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="Pavis Import-Export" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [wordWrapEnabled]="true"
      [(selectedRowKeys)]="selectedRows"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-selection
        [selectAllMode]="'allPages'"
        [showCheckBoxesMode]="'always'"
        mode="multiple"
      />
      <dxo-editing
        mode="popup"
        [allowAdding]="false"
        [allowUpdating]="false"
        [allowDeleting]="false"
      />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item
          location="before"
          widget="dxButton"
          locateInMenu="auto"
          [options]="{
            text: 'Send to Pavis',
            icon: '',
            stylingMode: 'button',
            onClick: sendToPavis
          }"
        />
        <dxi-item
          location="before"
          widget="dxLookup"
          locateInMenu="auto"
          [options]="{
            placeholder: 'Type',
            items: ['Patent', 'Design', 'Trademark'],
            value: type,
            onValueChanged: onTypeChanged
          }"
        />
        <dxi-item location="before">
          <pavis-filter-lookup [valueChange]="onStatusChanged" />
        </dxi-item>
        <dxi-item
          location="before"
          widget="dxCheckBox"
          [options]="{
            text: 'Show Imported Cases',
            stylingMode: 'contained',
            value: isAllCases,
            onValueChanged: onAllCasesChanged
          }"
        />
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column type="buttons">
        <dxi-button
          hint="See in patricia"
          icon="fas fa-horse"
          [onClick]="openPatricia"
        />
      </dxi-column>
      <dxi-column dataField="CaseNumber" caption="Case" />
      <dxi-column dataField="PavisId" caption="Pavis Reference" />
      <dxi-column
        dataField="StatusLabel"
        caption="Case Status"
        [allowSearch]="false"
      />
      <dxi-column
        dataField="ApplicationDate"
        caption="Application Date"
        dataType="date"
      />
      <dxi-column
        dataField="RenewalDate"
        caption="Renewal Date"
        dataType="date"
      />
      <dxi-column
        dataField="RegistrationDate"
        caption="Registration Date"
        dataType="date"
      />
      <dxi-column dataField="CreatedOn" caption="Created On" dataType="date" />
      <dxi-column
        dataField="LastSyncedOn"
        caption="Last Synced On"
        dataType="date"
      />
      <dxi-column dataField="ServiceLevelLabel" caption="Service Level" />
      <dxi-column dataField="ImportRemarks" caption="Import Remarks" />
      <dxi-column dataField="CaseId" caption="CaseId" [visible]="false" />
      <dxi-column dataField="FilterId" caption="Status" [visible]="false" />
      <dxi-column type="buttons" [allowSearch]="false">
        <dxi-button name="edit" />
        <dxi-button name="delete" />
      </dxi-column>

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      />

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridRenewalPavisImportExport"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
