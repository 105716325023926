<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  (onShow)="onShow()"
  width="80vw"
>
  <dx-form [(formData)]="invoice" height="80vh">
    <dxi-item itemType="group" [colCount]="5">
      <dxi-item itemType="group" caption="Document" [colSpan]="2">
        <dxi-item
          dataField="Document"
          editorType="dxFileUploader"
          [editorOptions]="{
            validationMessageMode: 'always',
            uploadMode: 'useForm',
            onValueChanged: onFileChanged
          }"
        >
          <dxo-label text="Invoice Document" />
        </dxi-item>
        <dxi-item *ngIf="blobFile !== undefined && blobFile !== null">
          <pdf-preview [BlobFile]="blobFile"></pdf-preview>
        </dxi-item>
      </dxi-item>
      <dxi-item itemType="group" [colSpan]="3">
        <dxi-item itemType="group" [colCount]="2" caption="Info">
          <dxi-item
            dataField="BookingDate"
            editorType="dxDateBox"
            [editorOptions]="{
              type: 'date',
              displayFormat: 'dd/MM/yyyy',
              validationMessageMode: 'always',
              acceptCustomValue: false,
              openOnFieldClick: true
            }"
          >
            <dxo-label text="Booking Date" />
          </dxi-item>
          <dxi-item
            dataField="InvoiceDate"
            editorType="dxDateBox"
            [editorOptions]="{
              type: 'date',
              displayFormat: 'dd/MM/yyyy',
              validationMessageMode: 'always',
              max: maxDate,
              acceptCustomValue: false,
              openOnFieldClick: true
            }"
          >
            <dxi-validation-rule
              type="required"
              message="Invoice Date is required"
            />
            <dxo-label text="Invoice Date" />
          </dxi-item>
          <dxi-item
            dataField="ToBePayedBefore"
            editorType="dxDateBox"
            [editorOptions]="{
              type: 'date',
              displayFormat: 'dd/MM/yyyy',
              validationMessageMode: 'always',
              acceptCustomValue: false,
              openOnFieldClick: true
            }"
          >
            <dxo-label text="Pay Before" />
          </dxi-item>
          <dxi-item
            dataField="CostStatusId"
            editorType="dxLookup"
            [editorOptions]="{
              dataSource: statusSource,
              displayExpr: 'StatusLabel',
              valueExpr: 'CostStatusId',
              searchEnabled: true,
              validationMessageMode: 'always'
            }"
          >
            <dxo-label text="Status" />
          </dxi-item>
          <dxi-item
            dataField="ExternalInvoiceId"
            caption="External Invoice Number"
            editorType="dxTextBox"
            [editorOptions]="{
              validationMessageMode: 'always'
            }"
          >
            <dxi-validation-rule
              type="required"
              message="External Invoice Id is required"
            />
            <dxo-label text="External Invoice Id" />
          </dxi-item>
          <dxi-item
            dataField="EmployeeId"
            editorType="dxLookup"
            [editorOptions]="{
              dataSource: employees,
              displayExpr: 'FullName',
              valueExpr: 'EmployeeId',
              searchEnabled: true,
              validationMessageMode: 'always'
            }"
          >
            <dxo-label text="Employee" />
          </dxi-item>
          <dxi-item itemType="group" [colCount]="4">
            <dxi-item
              [colSpan]="3"
              dataField="ProviderId"
              editorType="dxLookup"
              [editorOptions]="{
                dataSource: providers,
                displayExpr: 'Name',
                valueExpr: 'ProviderId',
                searchEnabled: true,
                validationMessageMode: 'always'
              }"
            >
              <dxo-label text="Provider" />
            </dxi-item>
            <dxi-item
              itemType="button"
              [colSpan]="1"
              [buttonOptions]="{
                icon: 'fas fa-arrow-up-right-from-square',
                onClick: onClickRedirectToProvider
              }"
            />
          </dxi-item>
          <dxi-item
            dataField="BudgetPeriodStart"
            editorType="dxDateBox"
            [editorOptions]="{
              type: 'date',
              displayFormat: 'dd/MM/yyyy',
              validationMessageMode: 'always',
              acceptCustomValue: false,
              openOnFieldClick: true
            }"
          >
            <dxo-label text="Budget From" />
          </dxi-item>
          <dxi-item
            dataField="BudgetPeriodEnd"
            editorType="dxDateBox"
            [editorOptions]="{
              type: 'date',
              displayFormat: 'dd/MM/yyyy',
              validationMessageMode: 'always',
              acceptCustomValue: false,
              openOnFieldClick: true
            }"
          >
            <dxo-label text="Budget Until" />
          </dxi-item>
          <dxi-item
            dataField="CostItemId"
            editorType="dxLookup"
            [colSpan]="2"
            [editorOptions]="{
              dataSource: costItemCategories,
              displayExpr: 'Label',
              valueExpr: 'CostItemId',
              searchEnabled: true,
              validationMessageMode: 'always'
            }"
          >
            <dxi-validation-rule
              type="required"
              message="Cost Item Category is required"
            >
            </dxi-validation-rule>
            <dxo-label text="Cost Item Category" />
          </dxi-item>
          <dxi-item dataField="Description" [colSpan]="2">
            <dx-text-area
              validationMessageMode="always"
              [(value)]="description"
              (onValueChanged)="onDescriptionChanged($event)"
            />
            <dxo-label text="Description" />
          </dxi-item>
        </dxi-item>
      </dxi-item>
      <dxi-item
        itemType="group"
        cssClass="second-group"
        [colSpan]="5"
        [colCount]="3"
        caption="Financial"
      >
        <dxi-item
          dataField="IsCredit"
          editorType="dxCheckBox"
          [editorOptions]="{ validationMessageMode: 'always' }"
        >
          <dxo-label text="Credit Note" />
        </dxi-item>
        <dxi-item
          dataField="CurrencyId"
          editorType="dxLookup"
          [colSpan]="2"
          [editorOptions]="{
            dataSource: currencies,
            displayExpr: 'CurrencyLabel',
            valueExpr: 'CurrencyId',
            searchEnabled: true,
            validationMessageMode: 'always'
          }"
        >
          <dxi-validation-rule type="required" message="Currency is required" />
          <dxo-label text="Currency" />
        </dxi-item>
        <dxi-item dataField="NonVatAmount" [disabled]="true">
          <dxo-label text="Amount Non Vat" />
          <dx-number-box
            [value]="getNonVatAmount()"
            validationMessageMode="always"
            editorType="dxNumberBox"
            editorType="dxNumberBox"
          />
        </dxi-item>
        <dxi-item dataField="VatAmount" [disabled]="true">
          <dxo-label text="Vat Amount" />
          <dx-number-box
            [value]="getVatAmount()"
            validationMessageMode="always"
          />
        </dxi-item>
        <dxi-item dataField="Amount" [disabled]="true">
          <dxo-label text="Amount" />
          <dx-number-box
            [value]="getTotalAmount()"
            validationMessageMode="always"
          />
        </dxi-item>
      </dxi-item>
      <dxi-item
        itemType="group"
        cssClass="second-group"
        [colSpan]="5"
        caption="Cost Lines"
      >
        <invoice-in-details-form
          [entity]="invoice"
          (totalChange)="updateAmounts($event)"
        />
      </dxi-item>
    </dxi-item>
  </dx-form>
</form-popup>
