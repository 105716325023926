<dx-data-grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  style="font-size: 11px"
  [dataSource]="dataSource"
  [remoteOperations]="true"
  height="40vh"
  keyExpr="CaseId"
  [showBorders]="true"
  #docketingGridRef
  (onRowUpdating)="onRowUpdating($event)"
  [wordWrapEnabled]="true"
>
  <dxo-filter-row [visible]="true" />
  <dxo-header-filter [visible]="false" />
  <dxo-group-panel [visible]="false" />
  <dxo-scrolling mode="virtual" />
  <dxo-editing
    mode="row"
    [allowAdding]="false"
    [allowUpdating]="true"
    [allowDeleting]="false"
  />
  <dxo-grouping [autoExpandAll]="false" />

  <dxi-column
    dataField="Reference"
    dataType="string"
    caption="Case reference"
    [allowEditing]="false"
  />
  <dxi-column
    [width]="140"
    [visible]="true"
    dataField="CategoryId"
    caption="Category"
  >
    <dxo-lookup
      [dataSource]="categoryLookup.store()"
      valueExpr="Id"
      displayExpr="Label"
    />
  </dxi-column>
  <dxi-column
    [width]="220"
    [visible]="true"
    dataField="SubCategoryId"
    caption="SubCategory"
  >
    <dxo-lookup
      [dataSource]="subCategoryLookup.store()"
      valueExpr="Id"
      displayExpr="Label"
    />
  </dxi-column>
  <dxi-column
    [width]="365"
    dataField="FileName"
    dataType="stringLength"
    caption="Document name (Portal)"
    [allowEditing]="true"
  />
  <dxi-column
    dataField="Visible"
    caption="Public?"
    [width]="100"
    [visible]="true"
  />

  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="gridFormFileUploadDocketList"
  />
</dx-data-grid>
