<dx-scroll-view class="view-wrapper-scroll"
  ><div class="view-wrapper">
    <titlebar titleText="Instruction logs" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="true"
      (onRowUpdating)="onRowUpdating($event)"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="false"
        [allowUpdating]="false"
        [allowDeleting]="false"
      />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />

      <dxo-toolbar>
        <dxi-item location="before" locateInMenu="auto">
          <div class="date-range-container">
            <div class="date-range-title">Log Date</div>
            <dx-date-range-box
              title="Log Date"
              [startDate]="thisYear"
              [endDate]="now"
              (onValueChanged)="onDateChanged($event)"
              [showClearButton]="true"
              [width]="300"
              [max]="now"
            />
          </div>
        </dxi-item>
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column dataField="LogDate" caption="Log Date" />
      <dxi-column dataField="CalystaReference" caption="Calysta Reference" />
      <dxi-column dataField="UserMail" caption="User Mail" />
      <dxi-column dataField="EventSchemeId" caption="Event Scheme Id" />
      <dxi-column dataField="InstructionLabel" caption="Instruction Label" />
      <dxi-column
        dataField="TypeOfOperationLabel"
        caption="Type Of Operation"
      />
      <dxi-column dataField="CaseId" caption="CaseId" [visible]="false" />
      <dxi-column
        dataField="InstructionId"
        caption="Instruction Id"
        [visible]="false"
      />
      <dxi-column
        dataField="PavisLogId"
        caption="Pavis Log Id"
        [visible]="false"
      />

      <dxo-paging [pageSize]="20" />
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true"
      >
      </dxo-pager>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridRenewalInstructionLog"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
