<dx-scroll-view class="view-wrapper-scroll"
  ><div class="view-wrapper">
    <titlebar titleText="Costs Follow up" />
    <div class="separator"></div>
    <dx-data-grid
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      class="grid"
      height="80vh"
      [dataSource]="dataSource"
      [showBorders]="true"
      [remoteOperations]="false"
      (onExporting)="onExporting($event)"
      [wordWrapEnabled]="true"
      [showColumnLines]="true"
      [showRowLines]="true"
    >
      <dxo-load-panel [enabled]="true" />
      <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
      <dxo-sorting mode="multiple" />
      <dxo-header-filter [visible]="false" />
      <dxo-editing
        mode="popup"
        [allowAdding]="false"
        [allowUpdating]="false"
        [allowDeleting]="false"
      />

      <dxo-column-chooser [enabled]="true" />
      <dxo-search-panel [visible]="true" width="20vw" />
      <dxo-export
        [enabled]="true"
        [fileName]="'Cost FollowUps'"
        [allowExportSelectedData]="false"
      />

      <dxo-toolbar>
        <dxi-item location="before" locateInMenu="auto">
          <budget-subcategory-lookup
            [value]="budgetSubCategoryId"
            [valueChange]="onBudgetSubCategoryChanged"
          />
        </dxi-item>
        <dxi-item location="before" locateInMenu="auto">
          <div class="date-range-container">
            <div class="date-range-title">Invoice Date Range</div>
            <dx-date-range-box
              title="Period"
              [startDate]="periodStart"
              [endDate]="periodEnd"
              (onValueChanged)="onPeriodChanged($event)"
              [showClearButton]="false"
              [width]="300"
              [max]="maxDate"
            />
          </div>
        </dxi-item>
        <dxi-item location="after" name="exportButton" />
        <dxi-item
          location="after"
          widget="dxButton"
          locateInMenu="never"
          [options]="{
            text: '',
            icon: 'refresh',
            stylingMode: 'text',
            onClick: refreshData
          }"
        />
        <dxi-item
          location="after"
          name="columnChooserButton"
          locateInMenu="auto"
        />
        <dxi-item location="after" name="searchPanel" locateInMenu="never" />
      </dxo-toolbar>

      <dxi-column
        dataField="BudgetCategoryLabel"
        caption="BudgetCategoryLabel"
        [groupIndex]="0"
        groupCellTemplate="budgetCategoryGroupCellTemplate"
      />
      <dxi-column
        dataField="BudgetSubCategoryLabel"
        caption="Budget Sub Category Label"
        [groupIndex]="1"
        groupCellTemplate="budgetSubCategoryGroupCellTemplate"
      />
      <dxi-column
        dataField="InvoiceDate"
        caption="Invoice Date"
        dataType="date"
        width="7%"
      />
      <dxi-column
        dataField="InvoiceNumber"
        caption="Invoice Number"
        width="8%"
      />
      <dxi-column dataField="ProviderName" caption="Provider" />
      <dxi-column
        dataField="CostItemCode"
        caption="Cost Item Code"
        width="5%"
        alignment="right"
      />
      <dxi-column
        dataField="CostItemLabel"
        caption="Cost Item Label"
        alignment="center"
      />
      <dxi-column
        dataField="Description"
        caption="Description"
        alignment="center"
      />
      <dxi-column
        dataField="CostPeriodStart"
        caption="Cost Period Start"
        dataType="date"
        width="10%"
        alignment="center"
      />
      <dxi-column
        dataField="CostPeriodEnd"
        caption="Cost Period End"
        dataType="date"
        width="10%"
        alignment="center"
      />
      <dxi-column
        dataField="CostAmount"
        caption="Cost Amount"
        [sortIndex]="0"
        width="5%"
      >
        <dxo-format type="currency" precision="2" />
      </dxi-column>
      <dxi-column
        dataField="CostAmountForPeriod"
        caption="Cost Amount For Period"
        width="5%"
      >
        <dxo-format type="currency" precision="2" />
      </dxi-column>

      <div *dxTemplate="let data of 'budgetCategoryGroupCellTemplate'">
        <div>
          {{ data.value }} ({{ formatValue(data.summaryItems[0].value) }} €)
        </div>
      </div>

      <div *dxTemplate="let data of 'budgetSubCategoryGroupCellTemplate'">
        <div>
          {{ data.value }}
        </div>
      </div>

      <dxo-summary>
        <dxi-group-item
          column="CostAmountForPeriod"
          summaryType="sum"
          valueFormat="currency"
          displayFormat="{0}"
        />
      </dxo-summary>

      <dxo-state-storing
        [enabled]="false"
        type="localStorage"
        storageKey="gridAnalyticsCostFollowUp"
      />
    </dx-data-grid>
  </div>
</dx-scroll-view>
