import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { RegistrationDetail } from 'src/app/types/timeregistration/RegistrationDetail';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  url = apiUrl + 'api/timeregistration';
  constructor(private http: HttpClient) {}

  getUrl() {
    return this.url;
  }

  processTimeRegistration(registrationIds: number[]) {
    return this.http.put<any>(`${this.url}/Registration/ProcessTime`, {
      registrationIds: registrationIds,
    });
  }

  getHourlyRate(EmployeeId: number, CaseId: number): Observable<number> {
    let params = new HttpParams().set('EmployeeId', EmployeeId);

    if (CaseId != null) {
      params = params.set('CaseId', CaseId);
    }
    return this.http
      .get<any>(`${this.url}/HourlyRate`, { params })
      .pipe<number>(map((data) => data.HourlyRate));
  }

  getBillingType(id: number) {
    return this.http.get<any>(`${this.url}/BillingType/${id}`);
  }

  insertDetails(details: any, registrationId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const params = new HttpParams({
      fromObject: {
        Values: JSON.stringify(details),
        RegistrationId: registrationId,
      },
    });

    return this.http.post<any>(`${this.url}/Registration/Detail`, params, {
      headers: headers,
    });
  }

  insertRegistration(registration: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const params = new HttpParams({
      fromObject: {
        Values: JSON.stringify(registration),
      },
    });

    return this.http.post<any>(`${this.url}/Registration`, params, {
      headers: headers,
    });
  }

  getRegistrationDetails(registrationId: number) {
    return this.http
      .get<any>(`${this.url}/Registration/Detail/${registrationId}`)
      .pipe<RegistrationDetail[]>(map((res) => res.data));
  }

  getRegistration(showProcessed: boolean) {
    return this.http.get<any>(
      `${this.url}/Registration/Mine?ShowProcessed=${showProcessed}&RequireTotalCount=true`
    );
  }
}
