<dx-lookup
  placeholder="Select Sub Category"
  [dataSource]="budgetSubCategories"
  displayExpr="BudgetSubCategoryLabel"
  valueExpr="BudgetSubCategoryId"
  (onValueChanged)="valueChanged($event)"
  [searchEnabled]="true"
  [value]="value"
  [showClearButton]="true"
  width="auto"
  class="lookup"
>
</dx-lookup>
