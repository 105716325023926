import { Component, OnInit, NgModule } from '@angular/core';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { SalesAnalyticsService } from 'src/app/services';
import { TitleBarModule } from 'src/app/components';
import { DxSelectBoxModule, DxTabPanelModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { VolumeReportPATModule } from 'src/app/components/volume-report/volume-report-pat/volume-report-pat.component';
import { VolumeReportTRMModule } from 'src/app/components/volume-report/volume-report-trm/volume-report-trm.component';

@Component({
  templateUrl: './boardroom-volume-report.component.html',
  styleUrls: ['./boardroom-volume-report.component.scss'],
  providers: [SalesAnalyticsService],
})
export class AnalyticsVolumeReportComponent implements OnInit {
  tabPanelItems: string[] = ['Trademark', 'Patent'];

  ngOnInit(): void {}
}

@NgModule({
  providers: [],
  exports: [],
  declarations: [AnalyticsVolumeReportComponent],
  imports: [
    TitleBarModule,
    DxScrollViewModule,
    DxTabPanelModule,
    DxSelectBoxModule,
    CommonModule,
    VolumeReportTRMModule,
    VolumeReportPATModule,
  ],
})
export class AnalyticsVolumeReportModule {}
