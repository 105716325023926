import { Component, NgModule, OnInit } from '@angular/core';
import { DxButtonModule, DxScrollViewModule } from 'devextreme-angular';
import { FormFileUploadModule } from 'src/app/components/utils/form-file-upload/form-file-upload.component';
import { FormFileUploadDocketlistModule } from 'src/app/components/utils/form-file-upload-docketlist/form-file-upload-docketlist.component';
import { DocketCasegridModule } from 'src/app/components/docket/docket-casegrid/docket-casegrid.component';
import { CalystaAPIDocketingService } from 'src/app/services/calysta-api/docketing.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-docket-file',
  templateUrl: './docket-file.component.html',
  styleUrls: ['./docket-file.component.scss'],
})
export class DocketFileComponent implements OnInit {
  currentFileName: string;
  currentFile: any;
  lineToDocket: any[];

  constructor(private docketingService: CalystaAPIDocketingService) {}

  ngOnInit(): void {
    this.lineToDocket = [];
  }

  fileUploaded(e: any) {
    this.currentFileName = e[0].name;
    this.currentFile = e[0];
  }

  CaseSelectionChanged(e: any[]) {
    //if the e.lenght is greater than lineTodocket, then add the new case to lineToDocket
    //if the e.lenght is less than lineToDocket, then remove the that is in lineToDocket but not in e

    if (e.length > this.lineToDocket.length) {
      var lastItem = e[e.length - 1];

      var line = {
        CaseId: lastItem.CaseId,
        Reference: lastItem.CaseDetailedDescription,
        CategoryId: null,
        SubCategoryId: null,
        FileName: this.currentFileName,
        Visible: true,
      };

      this.lineToDocket.push(line);
    } else {
      var newLineToDocket = this.lineToDocket.filter((x) => {
        return e.some((y) => y.CaseId === x.CaseId);
      });

      this.lineToDocket = newLineToDocket;
    }
  }

  hasDocketingLines(): boolean {
    return this.lineToDocket.length > 0;
  }

  Docket() {
    this.docketingService
      .docketFile(this.currentFile, this.lineToDocket)
      .subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'error', 3000);
        },
      });
  }
}

@NgModule({
  providers: [],
  exports: [],
  declarations: [DocketFileComponent],
  imports: [
    FormFileUploadModule,
    DocketCasegridModule,
    DxScrollViewModule,
    FormFileUploadDocketlistModule,
    DxButtonModule,
  ],
})
export class DocketMailListModule {}
