import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxTagBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'bank-import-file',
  templateUrl: './bank-import-file.component.html',
  styleUrls: ['./bank-import-file.component.scss'],
})
export class BankImportFileComponent {
  @Input() titleText = '';

  @Input() visible = false;

  @Input() height = '50vh';

  @Input() width = '50vw';

  @Output() save = new EventEmitter<any>();

  @Output() visibleChange = new EventEmitter<boolean>();

  url = environment.CalystaApiBaseURL + 'api/';

  document: any;

  constructor() {}

  handleVisible(e: boolean): void {
    this.visible = e;
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.save.emit(this.document);
  }

  onFileChanged = (e) => {
    this.document = e.value[0];
  };
}

@NgModule({
  declarations: [BankImportFileComponent],
  exports: [BankImportFileComponent],
  bootstrap: [BankImportFileComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
  ],
})
export class BankImportFileModule {}
