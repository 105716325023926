<dx-data-grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  style="font-size: 11px"
  [dataSource]="filesToDocket"
  [columns]="['caseId', 'format', 'public']"
  #mailGrid
  (onRowUpdating)="onRowUpdating($event)"
  [wordWrapEnabled]="true"
>
  <dxo-editing
    [allowUpdating]="true"
    [allowAdding]="false"
    [allowDeleting]="false"
    mode="cell"
  >
  </dxo-editing>
  <dxi-column
    [width]="140"
    dataField="caseId"
    [allowEditing]="false"
    caption="Case(s)"
  />
  <dxi-column
    dataField="mailName"
    dataType="stringLength"
    [allowEditing]="true"
    caption="Mail description (portal)"
  />
  <dxi-column
    [width]="75"
    dataField="visible"
    [allowEditing]="true"
    caption="Public?"
  />

  <dxo-state-storing
    [enabled]="false"
    type="localStorage"
    storageKey="gridDocketMailFormMailgrid"
  />
</dx-data-grid>
