<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  height="30vh"
>
  <dx-form>
    <dxi-item itemType="group" [colCount]="2">
      <dxi-item editorType="dxLookup" [colSpan]="2">
        <dx-lookup
          [dataSource]="cases"
          displayExpr="CaseNumber"
          valueExpr="CaseId"
          [searchEnabled]="true"
          searchExpr="CaseNumber"
          (onValueChanged)="onCaseChanged($event)"
        />
        <dxo-label text="Cases" />
      </dxi-item>
    </dxi-item>
  </dx-form>
</form-popup>
