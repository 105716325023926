import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Workcode } from 'src/app/types/invoicing/workcode';
import { environment } from 'src/environments/environment';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class WorkcodeService {
  url = apiUrl + 'api/Invoice/WorkCode';

  constructor(private http: HttpClient) {}

  getWorkCode(WorkCodeId: number, LanguageId: number): Observable<Workcode> {
    let params = new HttpParams()
      .set('WorkCodeId', WorkCodeId)
      .set('LanguageId', LanguageId);

    return this.http
      .get<any>(`${this.url}/get`, {
        params,
      })
      .pipe(map((data) => data.WorkCodeData));
  }

  getWorkCodeTranslation(WorkCodeId: number): Observable<any> {
    let params = new HttpParams().set('WorkCodeId', WorkCodeId);

    return this.http
      .get<any>(`${this.url}/get/translation`, {
        params,
      })
      .pipe(map((data) => data.WorkCodeData));
  }

  updateTranslation(workcode: {
    WorkCodeId: number;
    WorkCodeDescriptionDutch: string;
    WorkCodeDescriptionFrench: string;
    WorkCodeDescriptionEnglish: string;
  }): Observable<any> {
    return this.http.put<any>(`${this.url}/translation`, workcode);
  }
}
