<dx-data-grid [dataSource]="contactsDataSource">
  <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
  <dxi-column dataField="Name" caption="Contact Name" alignment="left" />
  <dxi-column dataField="Role" caption="Role" />
  <dxi-column dataField="Email" caption="Email" />
  <dxi-column dataField="Phone" caption="Tel." alignment="center" />
  <dxi-column type="buttons">
    <dxi-button icon="email" [onClick]="sendMail" [visible]="hasEmailAddress" />
  </dxi-column>
</dx-data-grid>
