import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxFormModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxScrollViewModule,
  DxToolbarModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import DataSource from 'devextreme/data/data_source';
import { PDFPreviewModule } from 'src/app/components/library/pdf-preview/pdf-preview.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';

@Component({
  selector: 'finance-charges-details',
  templateUrl: './finance-charges-details.component.html',
  styleUrls: ['./finance-charges-details.component.scss'],
})
export class FinanceChargesDetailsComponent implements OnChanges {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() chargeId: number;
  @Input() workCode: string;

  dataSource: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.dataSource && changes.chargeId) {
      this.dataSource = this.dataSource = new DataSource({
        store: AspNetData.createStore({
          key: 'ChargeDetailId',
          loadUrl: `${this.url}Invoice/Charge/Details`,
          loadParams: { chargeId: this.chargeId },
          onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
          },
        }),
      });
    }
  }

  getWorkCodeType(): string {
    return this.workCode.substring(0, 2);
  }
}

@NgModule({
  declarations: [FinanceChargesDetailsComponent],
  exports: [FinanceChargesDetailsComponent],
  bootstrap: [FinanceChargesDetailsComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    DxDataGridModule,
    DxButtonModule,
    DxScrollViewModule,
    PDFPreviewModule,
    FormPopupModule,
    DxToolbarModule,
    DxLoadPanelModule,
    DxPopupModule,
  ],
})
export class FinanceChargesDetailsModule {}
