import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { CalystaAPIInvoiceService } from 'src/app/services/calysta-api/invoice.service';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { CellPreparedEvent, RowPreparedEvent } from 'devextreme/ui/data_grid';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'finance-rw-follow-up',
  templateUrl: './finance-rw-follow-up.component.html',
  styleUrls: ['./finance-rw-follow-up.component.scss'],
})
export class FinanceRwFollowUpComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  cases: any;

  invoiceHeaders: any;

  url: string;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  constructor(private invoiceService: CalystaAPIInvoiceService) {
    this.url = this.invoiceService.getUrl();

    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'RwFollowUpId',
        loadUrl: `${this.url}/rwfollowup`,
        insertUrl: `${this.url}/rwfollowup`,
        updateUrl: `${this.url}/rwfollowup`,
        deleteUrl: `${this.url}/rwfollowup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });

    this.cases = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Patricia/CaseDetailedInfo/lookup`,
      }),
      sort: 'CaseNumber',
    });

    this.invoiceHeaders = new DataSource({
      store: AspNetData.createStore({
        key: 'InvoiceHeaderId',
        loadUrl: `${this.url}/InvoiceHeader/lookup`,
      }),
      sort: 'InvoiceNumber',
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('RW Follow Up');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'RWFollowUp.xlsx'
        );
      });
    });
  }

  onCellPrepared(cell: CellPreparedEvent) {
    if (cell.rowType === 'data') {
      if (
        cell.column.dataField === 'VatAmountInvoiced' &&
        cell.data.VatIsPayed === true &&
        cell.data.InvoiceHeaderId != null
      ) {
        cell.cellElement.style.backgroundColor = 'lightgreen';
      }

      if (
        cell.column.dataField === 'AmountInvoiced' &&
        cell.data.InvoiceIsPayed === true &&
        cell.data.InvoiceHeaderId != null
      ) {
        cell.cellElement.style.backgroundColor = 'lightgreen';
      }
    }
  }

  onRowPrepared(row: RowPreparedEvent) {
    if (row.rowType === 'data') {
      if (
        row.data.VatIsPayed === true &&
        row.data.InvoiceIsPayed === true &&
        row.data.InvoiceHeaderId != null
      ) {
        row.rowElement.style.backgroundColor = 'lightgreen';
      }
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
  ],
  declarations: [FinanceRwFollowUpComponent],
  exports: [],
})
export class FinanceRwFollowUpModule {}
