import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxDataGridComponent,
  DxDataGridModule,
  DxNumberBoxModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import {
  EventConfigurationBillingsDetailsFormComponent,
  EventConfigurationBillingsDetailsFormModule,
} from '../event-configuration-billing-details/event-configuration-billing-details.component';

@Component({
  selector: 'event-configuration-line-details',
  templateUrl: './event-configuration-line-details.component.html',
  styleUrls: ['./event-configuration-line-details.component.scss'],
})
export class EventConfigurationLineDetailsFormComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @ViewChild(EventConfigurationBillingsDetailsFormComponent, { static: false })
  eventConfigurationBillingsDetailsFormComponent: EventConfigurationBillingsDetailsFormComponent;

  @Input() entity: any;

  @Input() datasource: DataSource;

  detailDataSource: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  eventConfigurationLineId: number;

  workCodes: any;

  rowSelected: any;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  constructor() {
    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit(): void {
    this.workCodes = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkCodeId',
        loadUrl: `${this.url}Invoice/WorkCode/lookup`,
      }),
      sort: ['WorkCodeLabel', 'WorkCodeDescription'],
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onRowInserting = () => {
    this.rowSelected = undefined;
  };

  onShowing = (e) => {
    if (this.rowSelected !== undefined) {
      this.eventConfigurationBillingsDetailsFormComponent.onFocusIn(
        this.rowSelected.EventConfigurationBillingId
      );
    }
  };

  hasRow = () => {
    return this.rowSelected != null;
  };

  onFocusIn = (id: number) => {
    this.datasource = new DataSource({
      store: AspNetData.createStore({
        key: 'EventConfigurationBillingId',
        loadUrl: `${this.url}Renewal/EventConfigurationBilling/${id}`,
        insertUrl: `${this.url}Renewal/EventConfigurationBilling`,
        updateUrl: `${this.url}Renewal/EventConfigurationBilling`,
        deleteUrl: `${this.url}Renewal/EventConfigurationBilling`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
          if (id) {
            ajaxOptions.data.EventConfigurationLineId = id;
          }
        },
      }),
    });
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxNumberBoxModule,
    EventConfigurationBillingsDetailsFormModule,
  ],
  declarations: [EventConfigurationLineDetailsFormComponent],
  exports: [EventConfigurationLineDetailsFormComponent],
})
export class EventConfigurationLineDetailsFormModule {}
