import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { PatriciaService } from 'src/app/services/patricia/patricia.service';

@Component({
  selector: 'patricia-document-validation',
  templateUrl: './patricia-document-validation.component.html',
  styleUrls: ['./patricia-document-validation.component.scss'],
})
export class PatriciaDocumentValidationComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  url: string;

  isValidated: boolean = false;

  constructor(private patriciaService: PatriciaService) {
    this.url = this.patriciaService.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'DocumentId',
        loadUrl: `${this.url}/CaseDocumentOverview`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
          if (this.isValidated) {
            ajaxOptions.data.isValidated = this.isValidated;
          }
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  onValidatedChanged = (e: { value: boolean }): void => {
    this.isValidated = e.value;
    this.refreshData();
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    TitleBarModule,
    DxScrollViewModule,
  ],
  declarations: [PatriciaDocumentValidationComponent],
  exports: [],
})
export class PatriciaDocumentValidationModule {}
